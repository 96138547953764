import { getMuxUrlFromGCSUrl } from '../../muxUrl';
import { MuxFitMode } from '../../types/graphql.generated';
import * as CustomScalars from '../../types/scalars';

type QuizSetCollectionQuery = {
  readonly quizSetCollectionByID: {
    readonly sets: ReadonlyArray<{
      readonly __typename: 'QuizSet';
      readonly quizSetID: string;
      readonly ID: string;
      readonly name: string;
      readonly description: string;
      readonly previewTime?: number | null;
      readonly previewOptions?: {
        readonly time?: number | null;
        readonly height?: number | null;
        readonly fitMode?: MuxFitMode | null;
      } | null;
      readonly items: ReadonlyArray<
        | {
            readonly __typename: 'LegacyQuizSetItemMultipleChoiceQuestion';
            readonly props: { readonly label: CustomScalars.GQLQuizSetItemQuestionLabel };
            readonly dir: {
              readonly feedback: CustomScalars.GQLQuizSetItemQuestionDirectiveFeedback;
              readonly prompt: string;
              readonly afterAll?: string | null;
            };
          }
        | {
            readonly __typename: 'LegacyQuizSetItemSingleChoiceQuestion';
            readonly props: { readonly label: CustomScalars.GQLQuizSetItemQuestionLabel };
            readonly dir: {
              readonly feedback: CustomScalars.GQLQuizSetItemQuestionDirectiveFeedback;
              readonly prompt: string;
              readonly afterAll?: string | null;
            };
          }
        | {
            readonly __typename: 'LegacyQuizSetItemVideo';
            readonly props: {
              readonly url: string;
              readonly title?: string | null;
              readonly compulsory?: boolean | null;
            };
          }
      >;
    }>;
  };
};

export type SetData = Omit<
  QuizSetCollectionQuery['quizSetCollectionByID']['sets'][number],
  '__typename' | 'quizSetID'
>;
export type VideoItem = Extract<SetData['items'][number], { __typename: 'LegacyQuizSetItemVideo' }>;
export type SingleChoiceItem = Extract<
  SetData['items'][number],
  { __typename: 'LegacyQuizSetItemSingleChoiceQuestion' }
>;
export type MultipleChoiceItem = Extract<
  SetData['items'][number],
  { __typename: 'LegacyQuizSetItemMultipleChoiceQuestion' }
>;

const RISK_CURVE_VOICEOVER: SetData = {
  ID: 'risk-curve-voiceover',
  name: 'Watch',
  description: 'Watch',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/v3/01_RiskCurve.mp4',
        ),
        title: 'Risk Curve',
        compulsory: true,
      },
    },
  ],
};

const MYPLAN_VOICEOVER: SetData = {
  ID: 'myplan-voiceover',
  name: 'Watch',
  description: 'Watch',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/v3/02_MyPlan.mp4',
        ),
        title: 'MyPlan',
        compulsory: true,
      },
    },
  ],
};

const HOPE_KIT_VOICEOVER: SetData = {
  ID: 'hope-kit-voiceover',
  name: 'Learn',
  description: 'Learn about the Hope Kit',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/v3/03_Hopekit.mp4',
        ),
        title: 'Hope Kit',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Games to distract me',
          '1': "Reminders of why it's worth continuing to live",
          '2': 'Things that make me laugh',
        },
      },
      dir: {
        prompt: 'What types of things belong in your Hope Kit?',
        feedback: {
          '0': {
            correct: false,
            text: `The important thing about your Hope Kit items is why you chose them. Things that distract or make you laugh aren't right.`,
          },
          '1': {
            correct: true,
            text: '',
          },
          '2': {
            correct: false,
            text: `The important thing about your Hope Kit items is why you chose them. Things that distract or make you laugh aren't quite right.`,
          },
        },
        afterAll:
          'Hope Kit items should be reminders of why you want to keep living. They can be reminders of the good things in your life, or things you look forward to.',
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'When I am entering a crisis.',
          '1': 'When I am feeling motivated.',
          '2': "When I wonder if it's worth it to keep going?",
        },
      },
      dir: {
        prompt: 'When is the best time to use your Hope Kit?',
        feedback: {
          '0': {
            correct: false,
            text: `If you think you are nearing a crisis, please use MyPlan.`,
          },
          '1': {
            correct: false,
            text: "It's fine to use your Hope Kit when you're motivated. But remember it can be especially helpful when you're feeling down.",
          },
          '2': {
            correct: true,
            text: ``,
          },
        },
        afterAll:
          'Remember: You can use your Hope Kit when you\'re having a rough time. For example, this might be after a fight, thinking you\'ve "messed up", or feeling you are a burden to others.',
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'It gets rid of all of my bad feelings',
          '1': 'It gives me hope to go on',
          '2': 'It reminds me of good things from the past',
        },
      },
      dir: {
        prompt: 'Why is the Hope Kit so important?',
        feedback: {
          '0': {
            correct: false,
            text: 'The Hope Kit will steer you away from negative feelings, not erase them.',
          },
          '1': {
            correct: true,
            text: 'The Hope Kit reminds you why life is worth living.',
          },
          '2': {
            correct: false,
            text: `The Hope Kit shouldn't be about your past, but about your future.`,
          },
        },
      },
    },
  ],
};

const GETTING_GOING_VOICEOVER: SetData = {
  ID: 'getting-going-voiceover',
  name: 'Learn',
  description: 'Watch',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/v3/04_Getting Going.mp4',
        ),
        title: 'Getting going',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Stay in bed under the covers',
          '1': 'Cook in my kitchen',
          '2': 'Lay on the couch all day',
        },
      },
      dir: {
        prompt: 'Which one of these is a way you can "get going"?',
        feedback: {
          '0': {
            correct: false,
            text: `Staying in bed or lying on the couch might be easy, but you're unlikely to feel better if you keep doing it.`,
          },
          '1': {
            correct: true,
            text: '',
          },
          '2': {
            correct: false,
            text: `Staying in bed or lying on the couch might be easy, but you're unlikely to feel better if you keep doing it.`,
          },
        },
        afterAll:
          'Get going has a simple message. It might feel really hard to leave the comfort of bed or the couch. But it will make you feel better.',
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': "When I can't sleep at night",
          '1': "When I feel there's too much going on",
          '2': "When I'm feeling down and have no motivation to do anything",
        },
      },
      dir: {
        prompt: 'When is a good time to "get going"?',
        feedback: {
          '0': {
            correct: false,
            text: `As we'll see in the session on sleep, being active too close to bedtime can disturb your sleep.`,
          },
          '1': {
            correct: false,
            text: `When you feel there's too much going on, this might be a good time to try relaxation and mindfulness. We'll cover this skill in the next session.`,
          },
          '2': {
            correct: true,
            text: ``,
          },
        },
        afterAll:
          'It\'s most important to "get going" when you are feeling down. It\'s also a great time to plan or do a "get going" activity when you are just "ok," or even doing better.',
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'It helps me fight my negative thoughts.',
          '1': 'It lets me distract myself with a passive activity.',
          '2': 'It makes me feel like nothing is wrong.',
        },
      },
      dir: {
        prompt: 'Why does "getting going" reduce your risk?',
        feedback: {
          '0': {
            correct: true,
            text: `Negative thoughts can build. By getting going, you can stop this build up of negative thoughts.`,
          },
          '1': {
            correct: false,
            text: `Getting going may help you through doing something active, not passive.`,
          },
          '2': {
            correct: false,
            text: `Getting going won't always make every bad feeling you have go away.`,
          },
        },
      },
    },
  ],
};

const RELAXATION_VOICEOVER: SetData = {
  ID: 'relaxation-voiceover',
  name: 'Watch',
  description: 'Watch',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/v3/05_Relaxation skills.mp4',
        ),
        title: 'Relaxation',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Reactions to stress that are not normal',
          '1': 'Reactions to stress like tense muscles',
          '2': 'Reactions to stress that happen only in my mind',
        },
      },
      dir: {
        prompt: 'What do relaxation skills target?',
        feedback: {
          '0': {
            correct: false,
            text: ``,
          },
          '1': {
            correct: true,
            text: ``,
          },
          '2': {
            correct: false,
            text: ``,
          },
        },
        afterAll:
          'Relaxation skills target normal physical reactions to stress, like fast heartbeat, quicker breathing, and tense muscles.',
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'They make my mind more alert and active.',
          '1': 'They reduce all of my risk after only one session.',
          '2': 'They help reset my body and mind after stress.',
        },
      },
      dir: {
        prompt: 'How do relaxation skills reduce risk?',
        feedback: {
          '0': {
            correct: false,
            text: `These skills aim to calm your body and mind rather than make them more active.`,
          },
          '1': {
            correct: false,
            text: `Practicing these skills even once can be helpful, but you won't reduce all risk this way.`,
          },
          '2': {
            correct: true,
            text: ``,
          },
        },
        afterAll:
          'Relaxation skills target normal physical reactions to stress, like fast heartbeat, quicker breathing, and tense muscles.',
      },
    },
  ],
};

const MINDFULNESS_VOICEOVER: SetData = {
  ID: 'mindfullness-voiceover',
  name: 'Mindfulness',
  description: 'Watch',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/v3/06_Mindfulness.mp4',
        ),
        title: 'Mindfulness',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Helps me learn to control them.',
          '1': 'Helps me look at them very closely and pick them apart.',
          '2': 'Distracts me so I stop thinking about the thoughts.',
        },
      },
      dir: {
        prompt: 'What does mindfulness help you do with your thoughts?',
        feedback: {
          '0': {
            correct: true,
            text: `The goal is not let your thoughts control you. You observe them without any judgment.`,
          },
          '1': {
            correct: false,
            text: `Looking too closely might allow thoughts to have too much control over your emotions and actions.`,
          },
          '2': {
            correct: false,
            text: `Distraction helps but it also teaches you to take some distance from your troubling thoughts.`,
          },
        },
        afterAll: [
          'When you do a mindfulness exercise you stop yourself from thinking about all the things that are bothering you.',
          'When negative thoughts enter your mind while you are doing the exercise you learn to look at them from a place of non-judgement and separate yourself from them. ',
          'By looking at thoughts in this way, you are less likely to let them sweep you up into intense unhelpful emotions.',
        ].join('\n\n'),
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Calms my negative physical reactions.',
          '1': "Helps me separate my thoughts about a problem from the problem when I'm stressed.",
          '2': 'Solves the root problem of my bad thoughts.',
        },
      },
      dir: {
        prompt: 'How does mindfulness help lower your risk?',
        feedback: {
          '0': {
            correct: false,
            text: `Though mindfulness can calm your body, its main purpose is to calm your mind.`,
          },
          '1': {
            correct: true,
            text: ``,
          },
          '2': {
            correct: false,
            text: `Mindfulness can help separate the thoughts you have about a problem from the problem itself.`,
          },
        },
      },
    },
  ],
};

const SLEEP_DIARY_VOICEOVER: SetData = {
  ID: 'sleep-diary-voiceover',
  name: 'Sleep Diary',
  description: 'Watch',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/v3/07_improving sleep.mp4',
        ),
        title: 'Improving sleep',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Best positions to encourage quality sleep',
          '1': 'Activities to do after waking up from sleep',
          '2': 'Things to do and/or avoid before sleep',
        },
      },
      dir: {
        prompt: 'What sleep habits will we focus on in this session?',
        feedback: {
          '0': {
            correct: false,
            text: `Quality sleep can be achieved through other pre-sleep and during-sleep habits.`,
          },
          '1': {
            correct: false,
            text: `We'll focus on what to do right before sleeping, not after sleeping.`,
          },
          '2': {
            correct: true,
            text: ``,
          },
        },
        afterAll:
          'This session will help remind you to avoid things like caffeine and phone use right before bed. It will also help you keep a better sleep schedule.',
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Stops my negative thoughts.',
          '1': 'Sleep gives me the physical and emotional reserves to face problems.',
          '2': 'Helps me follow a schedule.',
        },
      },
      dir: {
        prompt: 'How will improving your sleep lessen suicide risk?',
        feedback: {
          '0': {
            correct: false,
            text: `Getting better sleep will improve your mood, but it won't completely erase negative thoughts.`,
          },
          '1': {
            correct: true,
            text: `Better sleep gives you a better chance to think clearly about problems and not let them get you down.`,
          },
          '2': {
            correct: false,
            text: `Following a sleep schedule is important for getting better sleep, but this is not the main reason that risk is lower.`,
          },
        },
      },
    },
  ],
};

const SPOT_IT_VOICEOVER: SetData = {
  ID: 'spot-it-voiceover',
  name: 'Spot it',
  description: 'Watch',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/v3/08_Spot It.mp4',
        ),
        title: 'Spot it',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Feeling bad after you do spot something wrong.',
          '1': 'Understanding how to spot thoughts from your past.',
          '2': 'Spotting a negative thought in response to something that happens.',
        },
      },
      dir: {
        prompt: 'What does it mean to "spot" a thought?',
        feedback: {
          '0': {
            correct: false,
            text: `You may feel bad, but the feeling itself is not "spotting."`,
          },
          '1': {
            correct: false,
            text: `Your thoughts may relate to past events, but "spotting" focuses on what just happened.`,
          },
          '2': {
            correct: true,
            text: ``,
          },
        },
        afterAll:
          '"Spotting" relies on noticing your thoughts in reaction to an event that has just occurred. ',
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Helps me know which thoughts to ignore.',
          '1': 'It is a key step towards more balanced or positive thinking.',
          '2': 'Helps me focus on my physical and emotional reactions.',
        },
      },
      dir: {
        prompt: 'How does spotting thoughts reduce risk?',
        feedback: {
          '0': {
            correct: false,
            text: `You don't want to ignore your “spotted” thoughts.`,
          },
          '1': {
            correct: true,
            text: ``,
          },
          '2': {
            correct: false,
            text: `Spotting thoughts should be what you focus on after you realize you are having a physical or emotional reaction.`,
          },
        },
        afterAll:
          '"Spotting" relies on noticing your thoughts in reaction to an event that has just occurred. ',
      },
    },
  ],
};

const TEST_IT_VOICEOVER: SetData = {
  ID: 'test-it-voiceover',
  name: 'Test it',
  description: 'Watch',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/v3/09_Test it.mp4',
        ),
        title: 'Test it',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Search online to see if others agree with me',
          '1': 'Use the Hope Kit',
          '2': 'Ask some questions to see if the thought is true or not',
        },
      },
      dir: {
        prompt: 'How do I test a thought?',
        feedback: {
          '0': {
            correct: false,
            text: ``,
          },
          '1': {
            correct: false,
            text: ``,
          },
          '2': {
            correct: true,
            text: ``,
          },
        },
        afterAll:
          'Asking yourself questions about the thought helps you evaluate the evidence for and against the thought.',
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': "You may realize your thought isn't accurate.",
          '1': 'You will stop having the negative thought.',
          '2': 'Your negative thoughts will become positive thoughts.',
        },
      },
      dir: {
        prompt: 'How does "testing" a thought lower your risk?',
        feedback: {
          '0': {
            correct: true,
            text: `The ultimate goal is to not let inaccurate or unhelpful thoughts make you feel low.`,
          },
          '1': {
            correct: false,
            text: `Practicing “testing” won't stop thoughts, but will help you when they do arise.`,
          },
          '2': {
            correct: false,
            text: `Testing your thoughts will help you see both sides of the coin.`,
          },
        },
      },
    },
  ],
};

const SWITCH_IT_VOICEOVER: SetData = {
  ID: 'switch-it-voiceover',
  name: 'Switch it',
  description: 'Watch',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/v3/10_Switch it.mp4',
        ),
        title: 'Switch it',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Positive and helpful thoughts',
          '1': 'Negative, untrue and unhelpful thoughts',
          '2': 'Neutral and true thoughts',
        },
      },
      dir: {
        prompt: 'What are the types of thoughts we want to "switch"?',
        feedback: {
          '0': {
            correct: false,
            text: ``,
          },
          '1': {
            correct: true,
            text: ``,
          },
          '2': {
            correct: false,
            text: ``,
          },
        },
        afterAll:
          'The thoughts you should switch should be negative, untrue or unhelpful. Aim to switch them to balanced, neutral or encouraging thoughts.',
      },
    },
  ],
};

const COPING_CARDS_VOICEOVER: SetData = {
  ID: 'coping-cards-voiceover',
  name: 'Learn',
  description: 'Watch',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/v3/11_Coping Cards.mp4',
        ),
        title: 'Coping Cards',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Reduce risk by learning new skills.',
          '1': 'Reduce risk by spotting thoughts.',
          '2': 'Reduce risk by reminding you of skills you have already learned',
        },
      },
      dir: {
        prompt: 'What is the purpose of Coping Cards?',
        feedback: {
          '0': {
            correct: false,
            text: ``,
          },
          '1': {
            correct: false,
            text: ``,
          },
          '2': {
            correct: true,
            text: ``,
          },
        },
        afterAll:
          'Coping Cards help remind you of skills you’ve learned already and help you deal with negative thoughts. Let’s now watch someone talking about their experience with the Coping Cards.',
      },
    },
  ],
};

const RISK_CURVE_MYPLAN_REVIEW_VOICEOVER: SetData = {
  ID: 'risk-curve-myplan-review-voiceover',
  name: 'Learn',
  description: 'Watch',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/v3/12_Review SRC and MyPlan.mp4',
        ),
        title: 'Risk Curve & MyPlan',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'I always have to do each part in the right order.',
          '1': "I should use MyPlan whenever I'm feeling upset.",
          '2': 'I need to know my warning signs and use MyPlan when they arise.',
        },
      },
      dir: {
        prompt: 'What should you remember about MyPlan?',
        feedback: {
          '0': {
            correct: false,
            text: ``,
          },
          '1': {
            correct: false,
            text: ``,
          },
          '2': {
            correct: true,
            text: ``,
          },
        },
        afterAll:
          'MyPlan is for handling suicidal thoughts, not just when you’re upset or down. Use it when you spot your warning signs. Let’s now watch someone talking about reviewing the Risk Curve and MyPlan.',
      },
    },
  ],
};

const REDUCE_RISK_VOICEOVER: SetData = {
  ID: 'reduce-risk-voiceover',
  name: 'Learn',
  description: 'Watch',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/v3/13_Review Skills.mp4',
        ),
        title: 'Skills to Reduce Risk',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Ways to engage, relax, and get more rest.',
          '1': 'Ways to change my thoughts.',
          '2': 'Ways to change my relationships.',
        },
      },
      dir: {
        prompt: 'What does changing behaviors involve?',
        feedback: {
          '0': {
            correct: true,
            text: ``,
          },
          '1': {
            correct: false,
            text: ``,
          },
          '2': {
            correct: false,
            text: ``,
          },
        },
        afterAll:
          'Changing behaviors included ways to be active, relax your body and mind, and get sleep to support your physical and mental health. All of these things improve your mood.',
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Ways to move my body for better health.',
          '1': 'Ways to strengthen old thoughts.',
          '2': 'Ways to identify and alter your negative thinking.',
        },
      },
      dir: {
        prompt: 'What does changing thoughts involve?',
        feedback: {
          '0': {
            correct: false,
            text: `Thoughts are about mental aspects of health.`,
          },
          '1': {
            correct: false,
            text: `We want you to engage with new ways of thinking that are helpful.`,
          },
          '2': {
            correct: true,
            text: ``,
          },
        },
        afterAll:
          'The point of changing thoughts is to help you be less negative and more realistic in how you look at life events and yourself. Let’s now watch someone talking about reviewing the skills to reduce suicide risk.',
      },
    },
  ],
};

const POSTAVIVA_VOICEOVER: SetData = {
  ID: 'postaviva-voiceover',
  name: 'Learn',
  description: 'Watch',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/v3/14_After Aviva.mp4',
        ),
        title: 'Post-Aviva',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Stop using Aviva as I am done.',
          '1': 'Only use Aviva when I am in crisis.',
          '2': 'Keep using Aviva and find other treatments.',
        },
      },
      dir: {
        prompt: 'What should you do when you finish Aviva?',
        feedback: {
          '0': {
            correct: false,
            text: ``,
          },
          '1': {
            correct: false,
            text: ``,
          },
          '2': {
            correct: true,
            text: ``,
          },
        },
        afterAll:
          'Please continue to use Aviva to review what you’ve learned. And use your new skills to keep your suicide risk low. Check with your doctor to see if there are other issues with your mental health. Let’s now watch someone talking about how they feel after finishing Aviva.',
      },
    },
  ],
};

export const VOICEOVER_QUIZ_SET_DATA: Record<string, SetData[] | undefined> = {
  RISK_CURVE_VOICEOVER: [RISK_CURVE_VOICEOVER],
  MYPLAN_VOICEOVER: [MYPLAN_VOICEOVER],
  HOPE_KIT_VOICEOVER: [HOPE_KIT_VOICEOVER],
  GETTING_GOING_VOICEOVER: [GETTING_GOING_VOICEOVER],
  RELAXATION_VOICEOVER: [RELAXATION_VOICEOVER],
  MINDFULNESS_VOICEOVER: [MINDFULNESS_VOICEOVER],
  SLEEP_DIARY_VOICEOVER: [SLEEP_DIARY_VOICEOVER],
  SPOT_IT_VOICEOVER: [SPOT_IT_VOICEOVER],
  TEST_IT_VOICEOVER: [TEST_IT_VOICEOVER],
  SWITCH_IT_VOICEOVER: [SWITCH_IT_VOICEOVER],
  COPING_CARDS_VOICEOVER: [COPING_CARDS_VOICEOVER],
  RISK_CURVE_MYPLAN_REVIEW_VOICEOVER: [RISK_CURVE_MYPLAN_REVIEW_VOICEOVER],
  REDUCE_RISK_VOICEOVER: [REDUCE_RISK_VOICEOVER],
  POSTAVIVA_VOICEOVER: [POSTAVIVA_VOICEOVER],
};
