import differenceInMinutes from 'date-fns/differenceInMinutes';
import * as z from 'zod';

import { parseGQLTime } from '../../gqlDate';

export const Reminder = z.object({
  /**
   * Delay before (negative) or after (positive) the meal/snack time to send the reminder.
   */
  delay: z.number().int(),
  delayUnit: z.enum(['minutes', 'hours']),
});

export type Reminder = z.infer<typeof Reminder>;

export const EatingScheduleItem = z.object({
  /**
   * Time for the meal/snack in the format "HH:MM".
   */
  time: z.string(),
  reminders: z.array(Reminder),
});
export type EatingScheduleItem = z.infer<typeof EatingScheduleItem>;

export const EatingSchedule = z
  .object({
    meals: z.object({
      breakfast: EatingScheduleItem,
      lunch: EatingScheduleItem,
      dinner: EatingScheduleItem,
    }),
    snacks: z.array(EatingScheduleItem).min(1, { message: 'Must setup at least one snack' }),
  })
  .superRefine((value, context) => {
    const breakfastTime = parseGQLTime(value.meals.breakfast.time);
    const lunchTime = parseGQLTime(value.meals.lunch.time);
    const dinnerTime = parseGQLTime(value.meals.dinner.time);

    for (let i in value.snacks) {
      const snack = value.snacks[i];
      const snackTime = parseGQLTime(snack.time);
      if (
        Math.abs(differenceInMinutes(snackTime, breakfastTime)) < 120 ||
        Math.abs(differenceInMinutes(snackTime, lunchTime)) < 120 ||
        Math.abs(differenceInMinutes(snackTime, dinnerTime)) < 120
      ) {
        context.addIssue({
          code: 'custom',
          path: ['snacks', Number(i), 'time'],
          message: 'Snacks should not be within two hours of a meal',
        });
      }
    }

    // At most 1 snack is allowed between each meal
    const breakfastLunch: number[] = [];
    const lunchDinner: number[] = [];
    const dinnerBreakfast: number[] = [];
    for (let i = 0; i < value.snacks.length; i++) {
      const snackTime = parseGQLTime(value.snacks[i].time);
      if (breakfastTime <= snackTime && snackTime < lunchTime) {
        breakfastLunch.push(i);
      } else if (lunchTime <= snackTime && snackTime < dinnerTime) {
        lunchDinner.push(i);
      } else if (dinnerTime <= snackTime || snackTime < breakfastTime) {
        dinnerBreakfast.push(i);
      } else {
        // should never get here
        throw new Error('Unexpected snack time');
      }
    }
    for (const snacksBetweenMeals of [breakfastLunch, lunchDinner, dinnerBreakfast]) {
      if (snacksBetweenMeals.length > 1) {
        for (const snackIndex of snacksBetweenMeals.slice(1)) {
          context.addIssue({
            code: 'custom',
            path: ['snacks', snackIndex, 'time'],
            message: 'Only one snack is allowed between each meal',
          });
        }
      }
    }
  })
  .default({
    meals: {
      breakfast: { time: '08:00', reminders: [] },
      lunch: { time: '13:00', reminders: [] },
      dinner: { time: '19:00', reminders: [] },
    },
    snacks: [
      { time: '10:00', reminders: [] },
      { time: '16:00', reminders: [] },
      { time: '22:00', reminders: [] },
    ],
  });
export type EatingSchedule = z.infer<typeof EatingSchedule>;
