import { SetData, VOICEOVER_QUIZ_SET_DATA } from './content/quizSet/quizSetData';
import { ADOLESCENT_QUIZ_SET_DATA } from './content/quizSet/quizSetDataAdolescent';
import { ADULT_QUIZ_SET_DATA } from './content/quizSet/quizSetDataAdult';
import { STATIC_QUIZ_SET_DATA as STATIC_QUIZ_SET_DATA_OTX202 } from './content/quizSet/quizSetDataStatic';
import { STATIC_QUIZ_SET_DATA as STATIC_QUIZ_SET_DATA_OTX207 } from './content/quizSet/quizSetDataStaticOTX207';
import { ProductVariant } from './types/graphql.generated';

export function getQuizSets({
  slug,
  productSlug,
}: {
  slug: string;
  productSlug: string;
}): SetData[] {
  const shamQuizSetData =
    productSlug === ProductVariant.AVIVA_ADOLESCENT
      ? STATIC_QUIZ_SET_DATA_OTX207
      : STATIC_QUIZ_SET_DATA_OTX202;

  const sets =
    VOICEOVER_QUIZ_SET_DATA[slug] ??
    shamQuizSetData[slug] ??
    (productSlug === ProductVariant.AVIVA_ADULT
      ? ADULT_QUIZ_SET_DATA[slug]
      : ADOLESCENT_QUIZ_SET_DATA[slug]);

  if (!sets) {
    throw new Error(`Invalid slug for getQuizSets: ${slug} (productSlug: ${productSlug})`);
  }

  return sets;
}
