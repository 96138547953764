import { Label, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';
import { ContactImage, getNameStr } from '@oui/myplan/src/components/Contact';
import { EnvironmentSafetySection } from '@oui/myplan/src/components/EnvironmentSafetySection';
import { MyPlanTab, MyPlanTabContainer } from '@oui/myplan/src/screens/MyPlanTab';

import { useSupporteeMyPlan } from '@src/hooks/useSupporteeMyPlan';

function Locked({ name }: { name: string }) {
  const { $t } = useI18n();
  const { theme } = useTheme();
  return (
    <View spacing={100} testID="SupporteeMyPlan_locked">
      <Text
        text={$t(
          {
            id: 'SupporteeMyPlan_lockedDescription',
            defaultMessage:
              "This is where you can come review {supporteeName}'s plan to keep their environment safe after they complete Session 1 in their app.",
          },
          { supporteeName: name },
        )}
      />
      <Label
        text={$t({ id: 'SupporteeMyPlan_lockedLabel', defaultMessage: 'Not complete' })}
        color={theme.color.gray300}
      />
    </View>
  );
}

export function SupporteeMyPlan(_: {}) {
  const { supportee, updatedSections, loading, refetch } = useSupporteeMyPlan({});

  const nameStr = supportee
    ? getNameStr({
        name: {
          first: supportee.supportee.user.givenName,
          last: supportee.supportee.user.familyName,
        },
      })
    : '';

  if (!supportee?.supportee.composition) {
    return (
      <MyPlanTab
        loading={loading}
        data={undefined}
        refetch={refetch}
        onEdit={null}
        heading="Safety"
        lockedNode={<Locked name={supportee?.supportee.user.givenName ?? 'your patient'} />}
        headerNode={
          supportee ? (
            <View
              style={{
                paddingTop: 20,
                alignSelf: 'center',
              }}
              row
              spacing={8}
              testID="SupporteeMyPlan_supportee"
            >
              <ContactImage nameStr={nameStr} inverted imageSize={24} />
              <Label text={nameStr} color="white" />
            </View>
          ) : undefined
        }
      />
    );
  }

  return (
    <MyPlanTabContainer
      loading={loading}
      data={supportee.supportee}
      refetch={refetch}
      onEdit={null}
      heading="Safety"
      headerNode={
        supportee ? (
          <View
            style={{
              paddingTop: 20,
              alignSelf: 'center',
            }}
            row
            spacing={8}
            testID="SupporteeMyPlan_supportee"
          >
            <ContactImage nameStr={nameStr} inverted imageSize={24} />
            <Label text={nameStr} color="white" />
          </View>
        ) : undefined
      }
    >
      <View style={{ margin: 20 }}>
        <EnvironmentSafetySection
          data={supportee.supportee}
          highlightedSections={updatedSections}
          cardUI
        />
      </View>
    </MyPlanTabContainer>
  );
}
