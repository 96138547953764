import type { SetData } from '../../content/quizSet/quizSetData';
import { getMuxUrlFromGCSUrl } from '../../muxUrl';

//Session 1 - MyPlan

export const MYSTORY_LEARN_VIGNETTE_FRANK: SetData = {
  ID: 'mystory-learn-vignette-frank',
  name: 'Frank',
  description: 'Frank: 75, male, widower, had stroke',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S1/Oui_Frank_S1A_720_220228.mp4',
        ),
        title: "Frank's Story",
        compulsory: true,
      },
    },
  ],
};

export const RISK_CURVE_LEARN_VIGNETTE_FRANK_COLLAB: SetData = {
  ID: 'risk-curve-learn-vignette-frank-collab',
  name: 'Frank',
  description: 'Frank: 75, male, widower, had stroke',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S1/Oui_Frank_S1B_720_220228.mp4',
        ),
        title: 'Making Sense',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'To relive a very difficult day again',
          '1': 'To learn about your own specific warning signs so that you can keep an eye on them and work to lower your risk',
          '2': 'You probably won’t need to learn about your own Risk Curve. Your warning signs are probably the same as Frank’s warning signs',
        },
      },
      dir: {
        prompt: 'Why is it important to learn about your own Risk Curve?',
        feedback: {
          '0': {
            correct: false,
            text: 'Though it may be hard to spend time thinking about what happened before a suicide attempt, it is important to learn what pushes you up the Risk Curve.',
          },
          '1': {
            correct: true,
            text: 'By understanding what pushes you up the Risk Curve, you can keep an eye on warning signs specific to you and work to lower your risk.',
          },
          '2': {
            correct: false,
            text: 'What goes in a Risk Curve is unique to each person. It is important to learn about what things increase your risk as these things may differ from Frank’s.',
          },
        },
        afterAll: '',
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S1/Oui_Frank_S1C_720_220228.mp4',
        ),
        title: 'Risk Curve',
        compulsory: true,
      },
    },
  ],
};

//Aviva Experience

export const RISK_CURVE_LEARN_VIGNETTE_FRANK: SetData = {
  ID: 'risk-curve-learn-vignette-frank',
  name: 'Frank',
  description: 'Frank: 75, male, widower, had stroke',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S1/Oui_Frank_S1A_720_220228.mp4',
        ),
        title: "Frank's Story",
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S1/Oui_Frank_S1B_720_220228.mp4',
        ),
        title: 'Making Sense',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'To relive a very difficult day again',
          '1': 'To learn about your own specific warning signs so that you can keep an eye on them and work to lower your risk',
          '2': 'You probably won’t need to learn about your own Risk Curve. Your warning signs are probably the same as Frank’s warning signs',
        },
      },
      dir: {
        prompt: 'Why is it important to learn about your own Risk Curve?',
        feedback: {
          '0': {
            correct: false,
            text: 'Though it may be hard to spend time thinking about what happened before a suicide attempt, it is important to learn what pushes you up the Risk Curve.',
          },
          '1': {
            correct: true,
            text: 'By understanding what pushes you up the Risk Curve, you can keep an eye on warning signs specific to you and work to lower your risk.',
          },
          '2': {
            correct: false,
            text: 'What goes in a Risk Curve is unique to each person. It is important to learn about what things increase your risk as these things may differ from Frank’s.',
          },
        },
        afterAll: '',
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S1/Oui_Frank_S1C_720_220228.mp4',
        ),
        title: 'Risk Curve',
        compulsory: true,
      },
    },
  ],
};

export const MYPLAN_LEARN_VIGNETTE_FRANK: SetData = {
  ID: 'myplan-learn-vignette-frank',
  name: 'Frank',
  description: 'Frank: 75, male, widower, had stroke',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S1/Oui_Frank_S1D_720_220228.mp4',
        ),
        title: 'My Plan',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S1/Oui_Frank_S1E_720_220228.mp4',
        ),
        title: 'Safe Environment',
        compulsory: true,
      },
    },
  ],
};

//Session 2 - Hope Kit

export const HOPE_KIT_LEARN_VIGNETTE_FRANK: SetData = {
  ID: 'hope-kit-learn-frank',
  name: 'Frank',
  description: 'Frank: 75, male, widower, had stroke',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S2/Oui_Frank_S2A_720_220301.mp4',
        ),
        title: 'About Hope Kit',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'True',
          '1': 'False',
        },
      },
      dir: {
        prompt:
          'True or false? You can include anything that you want in the Hope Kit that brings you joy or helps you look forward to things in the future.',
        feedback: {
          '0': {
            correct: true,
            text: 'The great thing about the Hope Kit is that you can pick anything at all that makes you smile or feel hopeful.',
          },
          '1': {
            correct: false,
            text: 'A great thing about Hope Kit is that you can pick things that are specific to you that might make you feel hopeful. You can also add things to the Hope Kit over time!',
          },
        },
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S2/Oui_Frank_S2B_720_220301.mp4',
        ),
        title: 'What Include',
        compulsory: true,
      },
    },
  ],
};

export const HOPE_KIT_APPLY_VIGNETTE_FRANK: SetData = {
  ID: 'hope-kit-apply-frank',
  name: 'Frank',
  description: 'Frank: 75, male, widower, had stroke',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S2/Oui_Frank_S2C_720_220301.mp4',
        ),
        title: 'Making Hope Kit',
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S2/Oui_Frank_S2D_720_220301.mp4',
        ),
        title: 'Using Hope Kit',
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'It was a fun activity that he looked at one time',
          '1': 'It reminds him that life is worth living',
          '2': 'It gets rid of his bad thoughts completely',
        },
      },
      dir: {
        prompt: 'How has the Hope Kit helped Frank?',
        feedback: {
          '0': {
            correct: false,
            text: 'The Hope Kit can be used daily. Looking at things that make him feel hopeful may help Frank’s mood.',
          },
          '1': {
            correct: true,
            text: 'When Frank is having a rough day, it is hard for him to think of good things in life. The Hope Kit reminds him that there are things that he is looking forward to.',
          },
          '2': {
            correct: false,
            text: 'It can help him direct his thoughts to other things. The Hope Kit will help Frank remember the good things he can look forward to.',
          },
        },
        afterAll: '',
      },
    },
  ],
};

//Session 3 - Getting Going

export const GETTING_GOING_LEARN_VIGNETTE_FRANK: SetData = {
  ID: 'getting-going-learn-vignette-frank',
  name: 'Frank',
  description: 'Frank: 75, male, widower, had stroke',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S3/Oui_Frank_S3A_720_220301.mp4',
        ),
        title: 'What Frank does to get going',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Lying in bed and thinking about going on a vacation',
          '1': 'Thought about all the things he was missing out on by not having his car anymore',
          '2': 'Did his PT stretches',
        },
      },
      dir: {
        prompt: 'How did Frank start to “get going”?',
        feedback: {
          '0': {
            correct: false,
            text: 'Having things to look forward to is great, “getting going” includes actions and movement (doing PT stretches or some chores).',
          },
          '1': {
            correct: false,
            text: '“Getting going” includes actions and movement (doing PT stretches or some chores).',
          },
          '2': {
            correct: true,
            text: 'Frank “got going” by doing small things like his PT stretches. Though these are small steps, they are a great place to start!',
          },
        },
        afterAll: '',
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S3/Oui_Frank_S3B_720_220301.mp4',
        ),
        title: 'How Frank makes sure to get going',
        compulsory: true,
      },
    },

    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Getting angry at himself for not doing an activity',
          '1': 'Ignoring that “getting going” helps to improve his mood',
          '2': 'Making it into a game and starting with small, doable actions',
        },
      },
      dir: {
        prompt: 'What helps Frank to “get going”?',
        feedback: {
          '0': {
            correct: false,
            text: 'Getting angry for not ‘getting going’ won’t really help. Frank takes a positive approach and celebrates wins.',
          },
          '1': {
            correct: false,
            text: 'Just by remembering that getting going can help improve a person’s mood and motivate them to do more.',
          },
          '2': {
            correct: true,
            text: 'Frank’s approach can also help you. Make it a small game and reward yourself when you do well.',
          },
        },
        afterAll: '',
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S3/Oui_Frank_S3C_720_220301.mp4',
        ),
        title: 'How getting going reduces risk',
        compulsory: true,
      },
    },
  ],
};

export const GETTING_GOING_APPLY_VIGNETTE_FRANK: SetData = {
  ID: 'getting-going-apply-vignette-frank',
  name: 'Frank',
  description: 'Frank: 75, male, widower, had stroke',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S3/Oui_Frank_S3D_720_220301.mp4',
        ),
        title: 'Getting going example',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S3/Oui_Frank_S3E_720_220301.mp4',
        ),
        title: 'How use activity diary',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S3/Oui_Frank_S3F_720_220301.mp4',
        ),
        title: 'How activity diary helps',
        compulsory: true,
      },
    },
  ],
};

//Session 4 - Relax & Mind

export const RELAXATION_MINDFULNESS_LEARN_VIGNETTE_FRANK: SetData = {
  ID: 'relaxation-mindfulness-learn-vignette-frank',
  name: 'Frank',
  description: 'Frank: 75, male, widower, had stroke',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S4/Oui_Frank_S4A_720_220301.mp4',
        ),
        title: 'When use it',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S4/Oui_Frank_S4B_720_220301.mp4',
        ),
        title: 'How it has helped',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Frank practices at random times in random places',
          '1': 'Frank tries to practice every day by ending the day with relaxation or mindfulness',
          '2': 'Frank practices to get rid of all his problems',
        },
      },
      dir: {
        prompt: 'Which of these is true about Frank’s practice of relaxation and mindfulness?',
        feedback: {
          '0': {
            correct: false,
            text: 'Frank knows that having a set routine for the time and place will help with his practice.',
          },
          '1': {
            correct: true,
            text: 'Frank practices everyday so that he can improve his mindfulness and relaxation skills.',
          },
          '2': {
            correct: false,
            text: 'Though mindfulness and relaxation can be helpful, they don’t get rid of all of Frank’s problems. These skills do help Frank to relax his body and focus on the present moment.',
          },
        },
        afterAll: '',
      },
    },
  ],
};

export const RELAXATION_MINDFULNESS_APPLY_VIGNETTE_FRANK: SetData = {
  ID: 'relaxation-mindfulness-apply-vignette-frank',
  name: 'Frank',
  description: 'Frank: 75, male, widower, had stroke',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S4/Oui_Frank_S4C_720_220301.mp4',
        ),
        title: 'Apply Relaxation & Mindfulness',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S4/Oui_Frank_S4D_720_220301.mp4',
        ),
        title: 'Apply Relaxation & Mindfulness',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S4/Oui_Frank_S4E_720_220301.mp4',
        ),
        title: 'Apply Relaxation & Mindfulness',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'True',
          '1': 'False',
        },
      },
      dir: {
        prompt:
          'True or false? Since Frank learns new things quickly, he was great at relaxation right away.',
        feedback: {
          '0': {
            correct: false,
            text: 'It was hard for Frank to focus on the exercises at first. He has worked really hard to get better at these skills.',
          },
          '1': {
            correct: true,
            text: 'Frank was sometimes distracted during his practice and that is ok! Frank still has to practice and figure out how to reduce distractions. ',
          },
        },
      },
    },
  ],
};

//Session 5 - Improving Sleep

export const SLEEP_DIARY_LEARN_VIGNETTE_FRANK: SetData = {
  ID: 'sleep-diary-learn-vignette-frank',
  name: 'Frank',
  description: 'Frank: 75, male, widower, had stroke',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S5/Oui_Frank_S5A_720_220301.mp4',
        ),
        title: 'Improving sleep',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S5/Oui_Frank_S5B_720_220301.mp4',
        ),
        title: 'Improving sleep',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'He had to make all of the changes at once',
          '1': 'He had to be perfect at following the sleep tips',
          '2': 'He used the Sleep Diary to figure out where he could make small changes',
        },
      },
      dir: {
        prompt: 'What is true about the changes that Frank made to his sleep routine?',
        feedback: {
          '0': {
            correct: false,
            text: 'It can be really hard to make a lot of changes at once. Frank picked a few things to work on.',
          },
          '1': {
            correct: false,
            text: 'Making changes to a sleep routine can be really difficult. Some days will be better than others and that is okay.',
          },
          '2': {
            correct: true,
            text: 'The Sleep Diary is a great first step. By looking at his sleep patterns, Frank was able to make small changes to his sleep routine.',
          },
        },
        afterAll: '',
      },
    },

    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S5/Oui_Frank_S5C_720_220301.mp4',
        ),
        title: 'Improving sleep',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Frank gets better sleep and is better prepared to tackle challenges',
          '1': 'Frank uses his sleep diary to track his warning signs',
          '2': 'Frank has been in bed twice as much, so he gets better sleep',
        },
      },
      dir: {
        prompt: 'How did changing his sleep habits reduce Frank’s suicide risk?',
        feedback: {
          '0': {
            correct: true,
            text: 'Frank has focused on improving his sleep habits and that has helped him cope with stress. This lowers his suicide risk.',
          },
          '1': {
            correct: false,
            text: 'Warning signs are kept in Frank’s MyPlan. Frank has used his sleep diary to improve his sleep habits and that has helped him cope with stress.',
          },
          '2': {
            correct: false,
            text: 'Being in bed more often doesn’t always mean more or better rest. Frank has focused on improving his sleep habits. This has helped him cope with stress and lowered his risk.',
          },
        },
        afterAll: '',
      },
    },
  ],
};

export const SLEEP_DIARY_APPLY_VIGNETTE_FRANK: SetData = {
  ID: 'sleep-diary-apply-vignette-frank',
  name: 'Frank',
  description: 'Frank: 75, male, widower, had stroke',
  previewOptions: {
    fitMode: 'crop',
  },
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S5/Oui_Frank_S5D_720_220301.mp4',
        ),
        title: 'Apply Sleep Diary',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S5/Oui_Frank_S5E_720_220301.mp4',
        ),
        title: 'Apply Sleep Diary',
        compulsory: true,
      },
    },
  ],
};

//Session 6 - Spot it

export const SPOT_IT_LEARN_VIGNETTE_FRANK: SetData = {
  ID: 'spot-it-learn-vignette-frank',
  name: 'Frank',
  description: 'Frank: 75, male, widower, had stroke',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S6/Oui_Frank_S6A_720_220301.mp4',
        ),
        title: 'Improving sleep',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S6/Oui_Frank_S6B_720_220301.mp4',
        ),
        title: 'Improving sleep',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'He doesn’t need any clues because he spots the thoughts right away',
          '1': 'He feels tense, and will have tremors',
          '2': 'Spotting unhelpful thoughts isn’t important so he doesn’t need clues',
        },
      },
      dir: {
        prompt: 'What are clues that help Frank spot unhelpful thoughts?',
        feedback: {
          '0': {
            correct: false,
            text: 'Frank is still learning, so it’s hard for him to pick out his unhelpful thoughts. Clues that his body gives him can be helpful signals that remind him to focus on his thoughts.',
          },
          '1': {
            correct: true,
            text: 'Sometimes our bodies give us clues that let us know we aren’t feeling great. This is a good time to look at our thoughts. This is true for Frank too.',
          },
          '2': {
            correct: false,
            text: 'Thoughts can really impact our mood. Being aware of unhelpful thoughts can help Frank understand why sometimes he feels poorly.',
          },
        },
        afterAll: '',
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S6/Oui_Frank_S6C_720_220301.mp4',
        ),
        title: 'Improving sleep',
        compulsory: true,
      },
    },
  ],
};

export const SPOT_IT_APPLY_VIGNETTE_FRANK: SetData = {
  ID: 'spot-it-apply-vignette-frank',
  name: 'Frank',
  description: 'Frank: 75, male, widower, had stroke',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S6/Oui_Frank_S6D_720_220301.mp4',
        ),
        title: 'Apply Spot it',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S6/Oui_Frank_S6E_720_220315_rev.mp4',
        ),
        title: 'Apply Spot it',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S6/Oui_Frank_S6F_720_220315_rev.mp4',
        ),
        title: 'Apply Spot it',
        compulsory: true,
      },
    },

    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'He can criticize every single thought that he has',
          '1': 'He realizes all his unhelpful thoughts are true',
          '2': 'He can see the way that his thoughts impact his mood',
        },
      },
      dir: {
        prompt: 'How does using Spot It help Frank?',
        feedback: {
          '0': {
            correct: false,
            text: 'By using Spot It, Frank learned what thoughts are helpful to him and what thoughts might be unhelpful to him. In the future, he will learn tools for dealing with unhelpful thoughts.',
          },
          '1': {
            correct: false,
            text: 'Frank learned that unhelpful thoughts can increase his stress. These thoughts aren’t necessarily true.',
          },
          '2': {
            correct: true,
            text: 'Frank has learned that difficult feelings like feeling down and numb are sometimes related to unhelpful thoughts.',
          },
        },
        afterAll: '',
      },
    },
  ],
};

//Session 7 - Test it

export const TEST_IT_LEARN_VIGNETTE_FRANK: SetData = {
  ID: 'test-it-learn-vignette-frank',
  name: 'Frank',
  description: 'Frank: 75, male, widower, had stroke',
  previewOptions: {
    height: 120,
  },
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S7/Oui_Frank_S7A_720_220301.mp4',
        ),
        title: 'Test it example',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'That he often ignores evidence that helps to disprove his unhelpful thoughts',
          '1': 'They are always true',
          '2': 'His unhelpful thoughts are new',
        },
      },
      dir: {
        prompt: 'What did Frank find out about his unhelpful thoughts?',
        feedback: {
          '0': {
            correct: true,
            text: 'Frank realized that he only looked at the proof that supported his unhelpful thoughts.',
          },
          '1': {
            correct: false,
            text: 'By using ‘Test It’, Frank learned that his unhelpful thoughts are often not fully true.',
          },
          '2': {
            correct: false,
            text: 'By examining his thoughts, Frank realized that he’d had many of them for a long time.',
          },
        },
        afterAll: '',
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S7/Oui_Frank_S7B_720_220301.mp4',
        ),
        title: 'Test it example',
        compulsory: true,
      },
    },
  ],
};

export const TEST_IT_APPLY_VIGNETTE_FRANK: SetData = {
  ID: 'test-it-apply-vignette-Frank',
  name: 'Frank',
  description: 'Frank: 75, male, widower, had stroke',
  previewOptions: {
    height: 120,
  },
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S7/Oui_Frank_S7C_720_220315_rev.mp4',
        ),
        title: 'Test it practice',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S7/Oui_Frank_S7D_720_220315_rev.mp4',
        ),
        title: 'Test it practice',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S7/Oui_Frank_S7E_720_220315_rev.mp4',
        ),
        title: 'Test it practice',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Only looking at the evidence against a thought',
          '1': 'Telling yourself that you are silly for having unhelpful thoughts',
          '2': 'Asking yourself “what would a friend say about this?”',
        },
      },
      dir: {
        prompt: 'What are strategies for testing unhelpful thoughts?',
        feedback: {
          '0': {
            correct: false,
            text: 'It’s good to consider evidence both for and against a thought. The goal is to be balanced and realistic.',
          },
          '1': {
            correct: false,
            text: 'Looking at the evidence for and against thoughts, and asking yourself “what would a friend say about this,” are excellent ways to test unhelpful thoughts.',
          },
          '2': {
            correct: true,
            text: 'This is a great way to test for unhelpful thoughts.',
          },
        },
        afterAll: '',
      },
    },
  ],
};

//Session 8 - Switch it

export const SWITCH_IT_LEARN_VIGNETTE_FRANK: SetData = {
  ID: 'switch-it-learn-vignette-frank',
  name: 'Frank',
  description: 'Frank: 75, male, widower, had stroke',
  previewOptions: {
    height: 120,
  },
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S8/Oui_Frank_S8A_720_220301.mp4',
        ),
        title: 'Switch it example',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S8/Oui_Frank_S8B_720_220301.mp4',
        ),
        title: 'Switch it example',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S8/Oui_Frank_S8C_720_220301.mp4',
        ),
        title: 'Switch it example',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'True',
          '1': 'False',
        },
      },
      dir: {
        prompt:
          'True or false? When switching a thought, it is best to find the most positive thought, even if it is not realistic.',
        feedback: {
          '0': {
            correct: false,
            text: 'It is important to find a balanced and believable thought. Eg: Switch “I always fail” to “There are some things that I do well”. This is because this thought is more realistic, balanced and believable.',
          },
          '1': {
            correct: true,
            text: 'It is important to find a balanced and believable thought. Eg: Switch “I always fail” to “There are some things that I do well”. This is because this thought is more realistic, balanced and believable.',
          },
        },
      },
    },
  ],
};

export const SWITCH_IT_APPLY_VIGNETTE_FRANK: SetData = {
  ID: 'switch-it-apply-vignette-frank',
  name: 'Frank',
  description: 'Frank: 75, male, widower, had stroke',
  previewOptions: {
    height: 120,
  },
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S8/Oui_Frank_S8D_720_220315_rev.mp4',
        ),
        title: 'Switch it practice',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S8/Oui_Frank_S8E_720_220301.mp4',
        ),
        title: 'Switch it practice',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S8/Oui_Frank_S8F_720_220301.mp4',
        ),
        title: 'Switch it practice',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'True',
          '1': 'False',
        },
      },
      dir: {
        prompt:
          'True or false? Every single time that Frank switches his thoughts, he feels 100% better straight away.',
        feedback: {
          '0': {
            correct: false,
            text: 'Switching unhelpful thoughts to more helpful and realistic thoughts can reduce a hard feeling, but it will not fix everything. Frank will use all he’s learned with this app to improve his mood.',
          },
          '1': {
            correct: true,
            text: 'Switching unhelpful thoughts to more helpful and realistic thoughts can reduce a hard feeling, but it will not fix everything. Frank will use all he’s learned with this app to improve his mood.',
          },
        },
      },
    },
  ],
};

//Session 9 - Coping cards

export const COPING_CARDS_LEARN_VIGNETTE_FRANK: SetData = {
  ID: 'coping-cards-learn-vigenette-frank',
  name: 'Frank',
  description: 'Frank: 75, male, widower, had stroke',
  previewOptions: {
    height: 140,
  },
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S9/Oui_Frank_S9A_720_220301.mp4',
        ),
        title: 'Frank',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S9/Oui_Frank_S9B_720_220301.mp4',
        ),
        title: 'Frank',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'True',
          '1': 'False',
        },
      },
      dir: {
        prompt:
          'True or false? Coping cards are like a shortcut that connects tough situations with specific coping skills.',
        feedback: {
          '0': {
            correct: true,
            text: 'Frank can add specific things that are hard for him on one side and coping skills that he knows have worked in the past on the other side.',
          },
          '1': {
            correct: false,
            text: 'Frank can add specific things that are hard for him on one side and coping skills that he knows have worked in the past on the other side.',
          },
        },
      },
    },
  ],
};

export const COPING_CARDS_APPLY_VIGNETTE_FRANK: SetData = {
  ID: 'coping-cards-apply-vigenette-frank',
  name: 'Frank',
  description: 'Frank: 75, male, widower, had stroke',
  previewOptions: {
    height: 140,
  },
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S9/Oui_Frank_S9C_720_220315_rev.mp4',
        ),
        title: 'Frank',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S9/Oui_Frank_S9D_720_220315_rev.mp4',
        ),
        title: 'Frank',
        compulsory: true,
      },
    },
  ],
};

//Session 10 - RC & MPlan review

export const RISK_CURVE_MYPLAN_REVIEW_LEARN_VIGNETTE_FRANK: SetData = {
  ID: 'risk-curve-myplan-review-learn-vignette-frank',
  name: 'Frank',
  description: 'Frank: 75, male, widower, had stroke',
  previewOptions: {
    height: 140,
  },
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S10/Oui_Frank_S10A_720_220301.mp4',
        ),
        title: 'Frank',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S10/Oui_Frank_S10B_720_220301.mp4',
        ),
        title: 'Frank',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'It is important to revisit the Risk Curve and MyPlan because warning signs and coping skills can change',
          '1': 'He should never change his MyPlan',
          '2': 'Warning signs never change',
        },
      },
      dir: {
        prompt: 'What did Frank realize when reviewing the Risk Curve and MyPlan?',
        feedback: {
          '0': {
            correct: true,
            text: '',
          },
          '1': {
            correct: false,
            text: '',
          },
          '2': {
            correct: false,
            text: '',
          },
        },
        afterAll:
          'Frank has learned new things about what pushes him up the risk curve. He has also learned new tools. He can update his Risk Curve and MyPlan with the things that he has learned.',
      },
    },
  ],
};

//Session 11 - Reduce Risk review

export const REDUCE_RISK_LEARN_VIGNETTE_FRANK: SetData = {
  ID: 'reduce-risk-learn-vignette-frank',
  name: 'Frank',
  description: 'Frank: 75, male, widower, had stroke',
  previewOptions: {
    height: 140,
  },
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S11/Oui_Frank_S11A_720_220301.mp4',
        ),
        title: 'Frank',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S11/Oui_Frank_S11B_720_220301.mp4',
        ),
        title: 'Frank',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'True',
          '1': 'False',
        },
      },
      dir: {
        prompt:
          'True or false? Now that Frank has learned all of the skills in Aviva, he needs to always be using every one of them.',
        feedback: {
          '0': {
            correct: false,
            text: 'It can be pretty hard to use all the skills at all times. It is best for Frank to focus on one or two at a time, depending on what will help him most.',
          },
          '1': {
            correct: true,
            text: 'It can be pretty hard to use all the skills at all times. It is best for Frank to focus on one or two at a time, depending on what will help him most.',
          },
        },
      },
    },
  ],
};

//Session 12 - Post Aviva

export const POSTAVIVA_LEARN_VIGNETTE_FRANK: SetData = {
  ID: 'postaviva-learn-vignette-frank',
  name: 'Frank',
  description: 'Frank: 75, male, widower, had stroke',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S12/Oui_Frank_S12A_720_220301.mp4',
        ),
        title: 'Frank',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': "Continue doing the 'switch it' exercises",
          '1': 'Look into other resources like  therapy or support groups.',
          '2': 'All of the above',
        },
      },
      dir: {
        prompt: 'What could be a next step after finishing Aviva?',
        feedback: {
          '0': {
            correct: false,
            text: 'All of the above is right.',
          },
          '1': {
            correct: false,
            text: 'All of the above is right.',
          },
          '2': {
            correct: true,
            text: '',
          },
        },
        afterAll: [
          'It is a good idea to keep using Aviva, even after you have finished. Looking into other resources is also a great way to maintain skills and keep making progress.',
        ].join('\n\n'),
      },
    },
  ],
};
