import { z } from 'zod';

export const CSSRSType = 'CSSRS';
export const SuicideAttemptResponse = z.object({
  checked: z.boolean().default(false),
  recent: z.boolean().default(false),
  description: z.string().default(''),
});
export const InterruptedAttemptResponse = z.object({
  checked: z.boolean().default(false),
  recent: z.boolean().default(false),
  description: z.string().default(''),
});
export const SelfInterruptedAttemptResponse = z.object({
  checked: z.boolean().default(false),
  recent: z.boolean().default(false),
  description: z.string().default(''),
});
export const PreparatoryActsResponse = z.object({
  checked: z.boolean().default(false),
  recent: z.boolean().default(false),
  description: z.string().default(''),
});
export const SelfHarmResponse = z.object({
  checked: z.boolean().default(false),
  recent: z.boolean().default(false),
  description: z.string().default(''),
});
export const CSSRSBehavior = z.object({
  suicideAttempt: SuicideAttemptResponse,
  interruptedAttempt: InterruptedAttemptResponse,
  selfInterruptedAttempt: SelfInterruptedAttemptResponse,
  preparatoryActs: PreparatoryActsResponse,
  selfHarm: SelfHarmResponse,
});
export const SuicidalIdeationType = z.enum([
  'NON_DISCLOSE',
  'WISH_TO_BE_DEAD',
  'GENERAL_SUICIDAL_IDEATION',
  'IDEATION_WITH_METHOD_WITHOUT_INTENT',
  'IDEATION_WITH_METHOD_WITH_INTENT',
  'IDEATION_WITH_INTENT_WITHOUT_PLAN',
  'IDEATION_WITH_PLAN_WITH_INTENT',
]);
export type SuicidalIdeationType = z.infer<typeof SuicidalIdeationType>;
export const NegativeEventResponse = z.object({
  checked: z.boolean().default(false),
  recent: z.boolean().default(false),
  description: z.string().default(''),
});
export const IncarcerationOrHomelessnessResponse = z.object({
  checked: z.boolean().default(false),
  recent: z.boolean().default(false),
  description: z.string().default(''),
});
export const LonelinessResponse = z.object({
  checked: z.boolean().default(false),
  recent: z.boolean().default(false),
  description: z.string().default(''),
});
export const CSSRSActivatingEvents = z.object({
  negativeEvent: NegativeEventResponse,
  incarcerationOrHomelessness: IncarcerationOrHomelessnessResponse,
  loneliness: LonelinessResponse,
});
export const PsychiatricResponse = z.object({
  checked: z.boolean().default(false),
  recent: z.boolean().default(false),
  description: z.string().default(''),
});
export const HopelessnessResponse = z.object({
  checked: z.boolean().default(false),
  recent: z.boolean().default(false),
  description: z.string().default(''),
});
export const NonComplianceResponse = z.object({
  checked: z.boolean().default(false),
  recent: z.boolean().default(false),
  description: z.string().default(''),
});
export const NoTreatmentResponse = z.object({
  checked: z.boolean().default(false),
  recent: z.boolean().default(false),
  description: z.string().default(''),
});
export const CSSRSTreatmentHistory = z.object({
  psychiatric: PsychiatricResponse,
  hopelessness: HopelessnessResponse,
  nonCompliance: NonComplianceResponse,
  noTreatment: NoTreatmentResponse,
});
export const CSSRS = z
  .object({
    type: z.literal(CSSRSType),
    occurrenceDate: z.string().default(''),
    behavior: CSSRSBehavior,
    mostSevereIdeation: SuicidalIdeationType.default('NON_DISCLOSE'),
    activatingEvents: CSSRSActivatingEvents,
    treatmentHistory: CSSRSTreatmentHistory,
    otherRiskFactors: z.array(z.string()).default([]),
    clinicalStatus: z.array(z.string()).default([]),
    protectiveFactors: z.array(z.string()).default([]),
    otherProtectiveFactors: z.array(z.string()).default([]),
  })
  .default({
    activatingEvents: {
      incarcerationOrHomelessness: {
        checked: false,
        description: '',
        recent: false,
      },
      loneliness: {
        checked: false,
        description: '',
        recent: false,
      },
      negativeEvent: {
        checked: false,
        description: '',
        recent: false,
      },
    },
    behavior: {
      interruptedAttempt: {
        checked: false,
        description: '',
        recent: false,
      },
      preparatoryActs: {
        checked: false,
        description: '',
        recent: false,
      },
      selfHarm: {
        checked: false,
        description: '',
        recent: false,
      },
      selfInterruptedAttempt: {
        checked: false,
        description: '',
        recent: false,
      },
      suicideAttempt: {
        checked: false,
        description: '',
        recent: false,
      },
    },
    clinicalStatus: [],
    mostSevereIdeation: 'NON_DISCLOSE',
    occurrenceDate: '',
    otherProtectiveFactors: [],
    otherRiskFactors: [],
    protectiveFactors: [],
    treatmentHistory: {
      hopelessness: {
        checked: false,
        description: '',
        recent: false,
      },
      noTreatment: {
        checked: false,
        description: '',
        recent: false,
      },
      nonCompliance: {
        checked: false,
        description: '',
        recent: false,
      },
      psychiatric: {
        checked: false,
        description: '',
        recent: false,
      },
    },
    type: 'CSSRS',
  });
export type CSSRS = z.infer<typeof CSSRS>;
