{
  "AccountSettings_accountHeading": [
    {
      "type": 0,
      "value": "Account"
    }
  ],
  "AccountSettings_addressLabel": [
    {
      "type": 0,
      "value": "Home address"
    }
  ],
  "AccountSettings_authentication_biometricLabel": [
    {
      "type": 0,
      "value": "Fingerprint or Face unlock"
    }
  ],
  "AccountSettings_authentication_faceLabel": [
    {
      "type": 0,
      "value": "Face unlock"
    }
  ],
  "AccountSettings_authentication_fingerprintLabel": [
    {
      "type": 0,
      "value": "Fingerprint unlock"
    }
  ],
  "AccountSettings_authentication_irisLabel": [
    {
      "type": 0,
      "value": "Iris unlock"
    }
  ],
  "AccountSettings_authentication_pinLabel": [
    {
      "type": 0,
      "value": "PIN unlock"
    }
  ],
  "AccountSettings_cancelButton": [
    {
      "type": 0,
      "value": "Cancel"
    }
  ],
  "AccountSettings_confirmPasswordLabel": [
    {
      "type": 0,
      "value": "Confirm new password"
    }
  ],
  "AccountSettings_contactHeading": [
    {
      "type": 0,
      "value": "Contact information"
    }
  ],
  "AccountSettings_currentPasswordLabel": [
    {
      "type": 0,
      "value": "Current password"
    }
  ],
  "AccountSettings_editButton": [
    {
      "type": 0,
      "value": "Edit"
    }
  ],
  "AccountSettings_editHeading": [
    {
      "type": 0,
      "value": "Edit account settings"
    }
  ],
  "AccountSettings_email": [
    {
      "type": 0,
      "value": "Email"
    }
  ],
  "AccountSettings_emailLabel": [
    {
      "type": 0,
      "value": "Email"
    }
  ],
  "AccountSettings_familyNameLabel": [
    {
      "type": 0,
      "value": "Last name"
    }
  ],
  "AccountSettings_givenNameLabel": [
    {
      "type": 0,
      "value": "First name"
    }
  ],
  "AccountSettings_heading": [
    {
      "type": 0,
      "value": "Account"
    }
  ],
  "AccountSettings_instructionsForUseLink": [
    {
      "type": 0,
      "value": "Instructions for use"
    }
  ],
  "AccountSettings_investigationalDevice": [
    {
      "type": 0,
      "value": "CAUTION—Investigational device. Limited by Federal law to investigational use."
    }
  ],
  "AccountSettings_investigationalDeviceVersion": [
    {
      "type": 0,
      "value": "Version: "
    },
    {
      "type": 1,
      "value": "version"
    }
  ],
  "AccountSettings_legalHeading": [
    {
      "type": 0,
      "value": "Legal"
    }
  ],
  "AccountSettings_logOutLink": [
    {
      "type": 0,
      "value": "Log out"
    }
  ],
  "AccountSettings_nameLabel": [
    {
      "type": 0,
      "value": "Name"
    }
  ],
  "AccountSettings_newPasswordLabel": [
    {
      "type": 0,
      "value": "New password"
    }
  ],
  "AccountSettings_notificationsHeading": [
    {
      "type": 0,
      "value": "Notification settings"
    }
  ],
  "AccountSettings_passwordHeading": [
    {
      "type": 0,
      "value": "Password"
    }
  ],
  "AccountSettings_phoneLabel": [
    {
      "type": 0,
      "value": "Phone number"
    }
  ],
  "AccountSettings_phonePlaceholder": [
    {
      "type": 0,
      "value": "Phone number"
    }
  ],
  "AccountSettings_pushNotificationsLabel": [
    {
      "type": 0,
      "value": "Allow push notifications"
    }
  ],
  "AccountSettings_termsOfServiceLink": [
    {
      "type": 0,
      "value": "Terms of service & privacy policy"
    }
  ],
  "AccountSettings_unknownValue": [
    {
      "type": 0,
      "value": "Unknown"
    }
  ],
  "Account_connectedHeading": [
    {
      "type": 0,
      "value": "Connected"
    }
  ],
  "Account_patientLabel": [
    {
      "type": 0,
      "value": "Patient"
    }
  ],
  "Account_patientRelationHeading": [
    {
      "type": 0,
      "value": "Your relation"
    }
  ],
  "Account_patientRelationLabel": [
    {
      "type": 0,
      "value": "Relation"
    }
  ],
  "App_backButtonLabel": [
    {
      "type": 0,
      "value": "Back"
    }
  ],
  "ArtifactButton_locked": [
    {
      "type": 0,
      "value": "Locked"
    }
  ],
  "AuthScreenContainer_logoLabel": [
    {
      "type": 1,
      "value": "appName"
    },
    {
      "type": 0,
      "value": " Logo"
    }
  ],
  "AuthenticationError_emailExists": [
    {
      "type": 0,
      "value": "This email has already been taken."
    }
  ],
  "AuthenticationError_emailNotFound": [
    {
      "type": 0,
      "value": "This email + password is incorrect."
    }
  ],
  "AuthenticationError_invalidEmail": [
    {
      "type": 0,
      "value": "This email is not valid."
    }
  ],
  "AuthenticationError_invalidPassword": [
    {
      "type": 0,
      "value": "This email + password is incorrect."
    }
  ],
  "AuthenticationError_invalidPasswordNoEmail": [
    {
      "type": 0,
      "value": "This password is incorrect."
    }
  ],
  "AuthenticationError_missingEmail": [
    {
      "type": 0,
      "value": "This user does not exist."
    }
  ],
  "AuthenticationError_missingPassword": [
    {
      "type": 0,
      "value": "Please enter your password."
    }
  ],
  "AuthenticationError_tooManyAttempts": [
    {
      "type": 0,
      "value": "Too many failed login attempts. Try again in a few minutes."
    }
  ],
  "AuthenticationError_userDisabled": [
    {
      "type": 0,
      "value": "Your account has been disabled."
    }
  ],
  "AuthenticationError_userNotFound": [
    {
      "type": 0,
      "value": "Sorry, can't find that account."
    }
  ],
  "CardStack_endAccessibilityLabel": [
    {
      "type": 0,
      "value": "End of cards"
    }
  ],
  "CardStack_flippableCard_flipButton": [
    {
      "type": 0,
      "value": "Flip"
    }
  ],
  "CardStack_indexAccessibilityLabel": [
    {
      "type": 0,
      "value": "Card "
    },
    {
      "type": 1,
      "value": "index"
    },
    {
      "type": 0,
      "value": " of "
    },
    {
      "type": 1,
      "value": "count"
    }
  ],
  "CardStack_nextButton": [
    {
      "type": 0,
      "value": "Next"
    }
  ],
  "CardStack_nextButtonAccessibilityLabel": [
    {
      "type": 0,
      "value": "Next"
    }
  ],
  "CardStack_nextCardAccessibilityLabel": [
    {
      "type": 0,
      "value": "Next card"
    }
  ],
  "CardStack_previousButtonAccessibilityLabel": [
    {
      "type": 0,
      "value": "Previous"
    }
  ],
  "CardStack_progress": [
    {
      "type": 1,
      "value": "index"
    },
    {
      "type": 0,
      "value": " of "
    },
    {
      "type": 1,
      "value": "count"
    }
  ],
  "Confidentiality_messageConfidential": [
    {
      "type": 0,
      "value": "Everything shared in this app is confidential."
    }
  ],
  "Confidentiality_messagePermissions": [
    {
      "type": 0,
      "value": "We will not share anything with others unless you give specific permission."
    }
  ],
  "Confidentiality_submitButton": [
    {
      "type": 0,
      "value": "Got it"
    }
  ],
  "Confidentiality_title": [
    {
      "type": 0,
      "value": "Confidential"
    }
  ],
  "ConfirmationModal_cancelButton": [
    {
      "type": 0,
      "value": "Cancel"
    }
  ],
  "ConfirmationModal_closeButtonAccessibilityLabel": [
    {
      "type": 0,
      "value": "Close modal"
    }
  ],
  "ConfirmationModal_confirmButton": [
    {
      "type": 0,
      "value": "OK"
    }
  ],
  "Connected_expiredInvitation": [
    {
      "type": 0,
      "value": "Your invitation has expired"
    }
  ],
  "Connected_notConnectedTitle": [
    {
      "type": 0,
      "value": "Not connected"
    }
  ],
  "Connected_title": [
    {
      "type": 0,
      "value": "Connected"
    }
  ],
  "ContactsPicker_chooseButton": [
    {
      "type": 0,
      "value": "Add "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 contact"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " contacts"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "numContacts"
    }
  ],
  "ContactsPicker_searchPlaceholder": [
    {
      "type": 0,
      "value": "Search"
    }
  ],
  "Conversation_chatTabText": [
    {
      "type": 0,
      "value": "Chat"
    }
  ],
  "Conversation_markSessionComplete": [
    {
      "type": 0,
      "value": "Mark session complete"
    }
  ],
  "Conversation_videoTabActivitiesHeadline": [
    {
      "type": 0,
      "value": "Activities"
    }
  ],
  "Conversation_videoTabText": [
    {
      "type": 0,
      "value": "Videos & activities"
    }
  ],
  "Conversation_videoTabVideosHeadline": [
    {
      "type": 0,
      "value": "Videos"
    }
  ],
  "DateTimeInput_cancelButton": [
    {
      "type": 0,
      "value": "Cancel"
    }
  ],
  "DateTimeInput_confirmButton": [
    {
      "type": 0,
      "value": "Confirm"
    }
  ],
  "DateTimeInput_dateHeading": [
    {
      "type": 0,
      "value": "Pick a date"
    }
  ],
  "DateTimeInput_timeHeading": [
    {
      "type": 0,
      "value": "Pick a time"
    }
  ],
  "EatingCommitmentHealthyFood_fruits": [
    {
      "type": 0,
      "value": "Fruits"
    }
  ],
  "EatingCommitmentHealthyFood_grains": [
    {
      "type": 0,
      "value": "Grains"
    }
  ],
  "EatingCommitmentHealthyFood_healthyFats": [
    {
      "type": 0,
      "value": "Healthy fats (nuts, avocados, fish)"
    }
  ],
  "EatingCommitmentHealthyFood_leanMeats": [
    {
      "type": 0,
      "value": "Lean meats"
    }
  ],
  "EatingCommitmentHealthyFood_other": [
    {
      "type": 0,
      "value": "Other"
    }
  ],
  "EatingCommitmentHealthyFood_vegetables": [
    {
      "type": 0,
      "value": "Vegetables"
    }
  ],
  "EatingCommitmentUnhealthyFood_candy": [
    {
      "type": 0,
      "value": "Candy"
    }
  ],
  "EatingCommitmentUnhealthyFood_desserts": [
    {
      "type": 0,
      "value": "Desserts"
    }
  ],
  "EatingCommitmentUnhealthyFood_junkFood": [
    {
      "type": 0,
      "value": "Junk food"
    }
  ],
  "EatingCommitmentUnhealthyFood_other": [
    {
      "type": 0,
      "value": "Other"
    }
  ],
  "EatingCommitmentUnhealthyFood_soda": [
    {
      "type": 0,
      "value": "Soda"
    }
  ],
  "EatingCommitmentUnhealthyFood_takeout": [
    {
      "type": 0,
      "value": "Take out"
    }
  ],
  "EditLessonsLearned_cancelButton": [
    {
      "type": 0,
      "value": "Cancel"
    }
  ],
  "EditLessonsLearned_confirmationModal_cancelButton": [
    {
      "type": 0,
      "value": "Discard"
    }
  ],
  "EditLessonsLearned_confirmationModal_confirmButton": [
    {
      "type": 0,
      "value": "Save"
    }
  ],
  "EditLessonsLearned_confirmationModal_description": [
    {
      "type": 0,
      "value": "You’ve made changes. Would you like to save them before leaving?"
    }
  ],
  "EditLessonsLearned_confirmationModal_title": [
    {
      "type": 0,
      "value": "Save changes?"
    }
  ],
  "EditLessonsLearned_headerTitle": [
    {
      "type": 0,
      "value": "Edit lessons learned"
    }
  ],
  "EditLessonsLearned_saveButton": [
    {
      "type": 0,
      "value": "Save"
    }
  ],
  "EditMyPlan_backButton": [
    {
      "type": 0,
      "value": "Back"
    }
  ],
  "EditMyPlan_cancelButton": [
    {
      "type": 0,
      "value": "Cancel"
    }
  ],
  "EditMyPlan_confirmationModal_cancelButton": [
    {
      "type": 0,
      "value": "Discard"
    }
  ],
  "EditMyPlan_confirmationModal_confirmButton": [
    {
      "type": 0,
      "value": "Save"
    }
  ],
  "EditMyPlan_confirmationModal_description": [
    {
      "type": 0,
      "value": "You've made edits to MyPlan. Would you like to save them?"
    }
  ],
  "EditMyPlan_confirmationModal_title": [
    {
      "type": 0,
      "value": "Save changes?"
    }
  ],
  "EditMyPlan_saveButton": [
    {
      "type": 0,
      "value": "Save"
    }
  ],
  "EditMyPlan_stepsHeading": [
    {
      "type": 0,
      "value": "Safety steps"
    }
  ],
  "EmailInput_label": [
    {
      "type": 0,
      "value": "Email"
    }
  ],
  "EnvironmentSafetyLocked_banner": [
    {
      "type": 0,
      "value": "Complete session "
    },
    {
      "type": 1,
      "value": "sessionNum"
    }
  ],
  "EnvironmentSafetyLocked_title": [
    {
      "type": 0,
      "value": "Make your environment safe"
    }
  ],
  "ErrorPresenter_fieldErrorsHeading": [
    {
      "type": 0,
      "value": "**"
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 error"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " errors"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "numErrors"
    },
    {
      "type": 0,
      "value": "** in the following fields:"
    }
  ],
  "FinishRegistering_submitButton": [
    {
      "type": 0,
      "value": "Done"
    }
  ],
  "FinishRegistering_title": [
    {
      "type": 0,
      "value": "Finish registering"
    }
  ],
  "Home_heading": [
    {
      "type": 0,
      "value": "Home"
    }
  ],
  "ImageInput_choosePhotoCameraOption": [
    {
      "type": 0,
      "value": "Take a photo"
    }
  ],
  "ImageInput_choosePhotoCancelOption": [
    {
      "type": 0,
      "value": "Cancel"
    }
  ],
  "ImageInput_choosePhotoGalleryOption": [
    {
      "type": 0,
      "value": "Choose from gallery"
    }
  ],
  "LearnProgress_accessibilityLabel": [
    {
      "type": 0,
      "value": "Learn progress. "
    },
    {
      "type": 1,
      "value": "numCompleted"
    },
    {
      "type": 0,
      "value": " of "
    },
    {
      "type": 1,
      "value": "numTotal"
    },
    {
      "type": 0,
      "value": " completed"
    }
  ],
  "LearnProgress_progressText": [
    {
      "type": 1,
      "value": "numCompleted"
    },
    {
      "type": 0,
      "value": "/"
    },
    {
      "type": 1,
      "value": "numTotal"
    }
  ],
  "Learn_heading": [
    {
      "type": 0,
      "value": "Learn"
    }
  ],
  "Learn_refreshIndicator": [
    {
      "type": 0,
      "value": "Update"
    }
  ],
  "LocalAuthenticationPrompt_acceptButton": [
    {
      "type": 0,
      "value": "Yes, enable"
    }
  ],
  "LocalAuthenticationPrompt_biometricMethod": [
    {
      "type": 0,
      "value": "fingerprint or face unlock"
    }
  ],
  "LocalAuthenticationPrompt_denyButton": [
    {
      "type": 0,
      "value": "Not now"
    }
  ],
  "LocalAuthenticationPrompt_description": [
    {
      "type": 0,
      "value": "Use "
    },
    {
      "type": 1,
      "value": "method"
    },
    {
      "type": 0,
      "value": " to log in securely and easily. You can enable or disable it in your account settings at any time."
    }
  ],
  "LocalAuthenticationPrompt_faceMethod": [
    {
      "type": 0,
      "value": "FaceID"
    }
  ],
  "LocalAuthenticationPrompt_fingerprintMethod": [
    {
      "type": 0,
      "value": "Fingerprint"
    }
  ],
  "LocalAuthenticationPrompt_heading": [
    {
      "type": 0,
      "value": "Enable "
    },
    {
      "type": 1,
      "value": "method"
    },
    {
      "type": 0,
      "value": "?"
    }
  ],
  "LocalAuthenticationPrompt_irisMethod": [
    {
      "type": 0,
      "value": "Iris unlock"
    }
  ],
  "LocalAuthenticationPrompt_pinMethod": [
    {
      "type": 0,
      "value": "PIN"
    }
  ],
  "LocationPicker_chooseButton": [
    {
      "type": 0,
      "value": "Add "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 place"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " places"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "numLocations"
    }
  ],
  "LocationPicker_chooseButtonDisabled": [
    {
      "type": 0,
      "value": "Add places"
    }
  ],
  "LocationPicker_contentPlaceholder": [
    {
      "type": 0,
      "value": "Search for a place"
    }
  ],
  "LocationPicker_label": [
    {
      "type": 0,
      "value": "Places"
    }
  ],
  "LocationPicker_search_placeholder": [
    {
      "type": 0,
      "value": "Search"
    }
  ],
  "Login_description": [
    {
      "type": 0,
      "value": "You've been gone for a bit. For your privacy please re-enter your password."
    }
  ],
  "Login_emailLabel": [
    {
      "type": 0,
      "value": "Email"
    }
  ],
  "Login_forgotPasswordButton": [
    {
      "type": 0,
      "value": "Forgot password?"
    }
  ],
  "Login_heading": [
    {
      "type": 0,
      "value": "Welcome back"
    }
  ],
  "Login_localAuthentication_biometricDescription": [
    {
      "type": 0,
      "value": "Use your fingerprint or face to login"
    }
  ],
  "Login_localAuthentication_biometricFailed": [
    {
      "type": 0,
      "value": "Not recognized"
    }
  ],
  "Login_localAuthentication_faceDescription": [
    {
      "type": 0,
      "value": "Use Face ID to login to app"
    }
  ],
  "Login_localAuthentication_faceFailed": [
    {
      "type": 0,
      "value": "Face not recognized"
    }
  ],
  "Login_localAuthentication_fingerprintDescription": [
    {
      "type": 0,
      "value": "Touch sensor to login to app"
    }
  ],
  "Login_localAuthentication_fingerprintFailed": [
    {
      "type": 0,
      "value": "Fingerprint not recognized"
    }
  ],
  "Login_localAuthentication_irisDescription": [
    {
      "type": 0,
      "value": "Use your irises to login"
    }
  ],
  "Login_localAuthentication_irisFailed": [
    {
      "type": 0,
      "value": "Not recognized"
    }
  ],
  "Login_localAuthentication_pinDescription": [
    {
      "type": 0,
      "value": "Enter PIN to login to app"
    }
  ],
  "Login_localAuthentication_pinFailed": [
    {
      "type": 0,
      "value": "The PIN is invalid"
    }
  ],
  "Login_localAuthentication_promptMessage": [
    {
      "type": 0,
      "value": "Login to app"
    }
  ],
  "Login_loginButton": [
    {
      "type": 0,
      "value": "Login"
    }
  ],
  "Login_logoutButton": [
    {
      "type": 0,
      "value": "Log out"
    }
  ],
  "Login_passwordLabel": [
    {
      "type": 0,
      "value": "Password"
    }
  ],
  "Login_passwordPlaceholder": [
    {
      "type": 0,
      "value": "Password"
    }
  ],
  "Login_passwordResetHint": [
    {
      "type": 0,
      "value": "Password reset successfully. Please log in."
    }
  ],
  "Login_reauthenticate_tryAgain": [
    {
      "type": 0,
      "value": "Try again"
    }
  ],
  "Login_reauthenticate_usePasswordButton": [
    {
      "type": 0,
      "value": "Type password"
    }
  ],
  "Login_reauthenticate_usePinButton": [
    {
      "type": 0,
      "value": "Login with PIN"
    }
  ],
  "Login_reauthenticate_usePinFallbackButton": [
    {
      "type": 0,
      "value": "Login with PIN"
    }
  ],
  "MinimumAppVersion_dismissButton": [
    {
      "type": 0,
      "value": "Not now"
    }
  ],
  "MinimumAppVersion_optionalUpgrade": [
    {
      "type": 0,
      "value": "There's a newer version of "
    },
    {
      "type": 1,
      "value": "appName"
    },
    {
      "type": 0,
      "value": " that's available for download. Please update to continue using "
    },
    {
      "type": 1,
      "value": "appName"
    },
    {
      "type": 0,
      "value": "."
    }
  ],
  "MinimumAppVersion_requiredUpgrade": [
    {
      "type": 0,
      "value": "There's a newer version of "
    },
    {
      "type": 1,
      "value": "appName"
    },
    {
      "type": 0,
      "value": " that's available for download. We recommend updating to ensure you have the best experience possible."
    }
  ],
  "MinimumAppVersion_title": [
    {
      "type": 0,
      "value": "Update Available"
    }
  ],
  "MinimumAppVersion_upgradeButton": [
    {
      "type": 0,
      "value": "Update"
    }
  ],
  "MultipleMediaLibrary_allMediaAlbumTitle": [
    {
      "type": 0,
      "value": "All media"
    }
  ],
  "MyPlanExport_export": [
    {
      "type": 0,
      "value": "Export MyPlan via"
    }
  ],
  "MyPlanExport_exportDownloadButton": [
    {
      "type": 0,
      "value": "Download"
    }
  ],
  "MyPlanExport_exportEmailButton": [
    {
      "type": 0,
      "value": "Email"
    }
  ],
  "MyPlanReview_editMyPlanButton": [
    {
      "type": 0,
      "value": "Edit MyPlan to add"
    }
  ],
  "MyPlanReview_flashcard0_back": [
    {
      "type": 0,
      "value": "Warning signs are thoughts, feelings, or behaviors that serve as cues that you may be going into a crisis."
    }
  ],
  "MyPlanReview_flashcard0_front": [
    {
      "type": 0,
      "value": "What are Warning Signs?"
    }
  ],
  "MyPlanReview_flashcard10_front": [
    {
      "type": 0,
      "value": "What ways are you securing lethal means?"
    }
  ],
  "MyPlanReview_flashcard11_front": [
    {
      "type": 0,
      "value": "Who will help you secure these items?"
    }
  ],
  "MyPlanReview_flashcard1_front": [
    {
      "type": 0,
      "value": "What are your warning signs?"
    }
  ],
  "MyPlanReview_flashcard2_back": [
    {
      "type": 0,
      "value": "Try to calm yourself by doing the things in Step 2 Coping Strategies"
    }
  ],
  "MyPlanReview_flashcard2_front": [
    {
      "type": 0,
      "value": "What should you try first when you recognize you're experiencing your warning signs?"
    }
  ],
  "MyPlanReview_flashcard3_front": [
    {
      "type": 0,
      "value": "What are your Step 2 Coping Strategies?"
    }
  ],
  "MyPlanReview_flashcard4_back": [
    {
      "type": 0,
      "value": "Step 2 Coping Strategies are meant to do alone, whereas Step 3 Social distractions are social activities with or around others."
    }
  ],
  "MyPlanReview_flashcard4_front": [
    {
      "type": 0,
      "value": "What is the difference between Step 2 Coping Strategies and Step 3 Social distractions?"
    }
  ],
  "MyPlanReview_flashcard5_front": [
    {
      "type": 0,
      "value": "What or who are your Step 3 Social distractions?"
    }
  ],
  "MyPlanReview_flashcard6_back": [
    {
      "type": 0,
      "value": "People in Step 3 are not to be used to share or discuss a crisis. People in Step 4 are ones you can reach out to in a crisis."
    }
  ],
  "MyPlanReview_flashcard6_front": [
    {
      "type": 0,
      "value": "What is the difference between Step 3 Social distractions and Step 4 People I can ask for help?"
    }
  ],
  "MyPlanReview_flashcard7_front": [
    {
      "type": 0,
      "value": "Who are your Step 4 People I can ask for help?"
    }
  ],
  "MyPlanReview_flashcard8_back": [
    {
      "type": 0,
      "value": "Call the National Suicide Preventation Lifeline"
    }
  ],
  "MyPlanReview_flashcard8_front": [
    {
      "type": 0,
      "value": "If you're in an escalating crisis and you've tried other steps, what should you do?"
    }
  ],
  "MyPlanReview_flashcard9_back": [
    {
      "type": 0,
      "value": "Making your environment safe means securing items or lethal means that you may use for suicide."
    }
  ],
  "MyPlanReview_flashcard9_front": [
    {
      "type": 0,
      "value": "What does it mean to make your environment safe?"
    }
  ],
  "MyPlanReview_myPlanButton": [
    {
      "type": 0,
      "value": "Full MyPlan"
    }
  ],
  "MyPlanReview_title": [
    {
      "type": 0,
      "value": "MyPlan Review"
    }
  ],
  "MyPlanReview_v2_flashcard0_back": [
    {
      "type": 0,
      "value": "MyPlan is step-by-step guide that helps you manage a crisis and regain control by helping you notice when things start feeling off and giving you specific things to do to stay safe."
    }
  ],
  "MyPlanReview_v2_flashcard0_front": [
    {
      "type": 0,
      "value": "What is MyPlan?"
    }
  ],
  "MyPlanReview_v2_flashcard1_back": [
    {
      "type": 0,
      "value": "Warning signs are thoughts, feelings, or behaviors that serve as cues that you may be going into a crisis."
    }
  ],
  "MyPlanReview_v2_flashcard1_front": [
    {
      "type": 0,
      "value": "What are Warning Signs?"
    }
  ],
  "MyPlanReview_v2_flashcard2_front": [
    {
      "type": 0,
      "value": "What are your Warning Signs?"
    }
  ],
  "MyPlanReview_v2_flashcard3_back": [
    {
      "type": 0,
      "value": "Try to keep things from getting worse by doing the things in ‘Step 2 Things I can do myself’"
    }
  ],
  "MyPlanReview_v2_flashcard3_front": [
    {
      "type": 0,
      "value": "What should you try first when you recognize you’re experiencing your warning signs?"
    }
  ],
  "MyPlanReview_v2_flashcard4_front": [
    {
      "type": 0,
      "value": "What are your ‘Step 2 Things I can do myself’?"
    }
  ],
  "MyPlanReview_v2_flashcard5_front": [
    {
      "type": 0,
      "value": "What are your ‘Step 3 Reasons for living’?"
    }
  ],
  "MyPlanReview_v2_flashcard6_front": [
    {
      "type": 0,
      "value": "Who are your ‘Step 4 People I can talk to’?"
    }
  ],
  "MyPlanReview_v2_flashcard7_back": [
    {
      "type": 0,
      "value": "‘People you can talk to’ are friends and family that can give you support in a crisis. ‘Professional help’ are the 988 Lifeline, doctors, therapists, and other healthcare professionals."
    }
  ],
  "MyPlanReview_v2_flashcard7_front": [
    {
      "type": 0,
      "value": "What is the difference between ‘Step 4 People I can talk to’ and ‘Step 5 Professional help’?"
    }
  ],
  "MyPlanReview_v2_flashcard8_back": [
    {
      "type": 0,
      "value": "Call 988 for National Suicide Prevention Lifeline"
    }
  ],
  "MyPlanReview_v2_flashcard8_front": [
    {
      "type": 0,
      "value": "If you’re in an escalating crisis and you’ve tried other steps, what should you do?"
    }
  ],
  "MyPlan_aidIcon": [
    {
      "type": 0,
      "value": "Aid icon"
    }
  ],
  "MyPlan_editButton": [
    {
      "type": 0,
      "value": "Edit MyPlan"
    }
  ],
  "MyPlan_exportDownloadSuccess": [
    {
      "type": 0,
      "value": "MyPlan downloaded to media library"
    }
  ],
  "MyPlan_exportEmailSuccess": [
    {
      "type": 0,
      "value": "MyPlan sent to "
    },
    {
      "type": 1,
      "value": "email"
    }
  ],
  "MyPlan_heading": [
    {
      "type": 0,
      "value": "MyPlan"
    }
  ],
  "MyPlan_lockedDescription1": [
    {
      "type": 0,
      "value": "This is where you can come look at and edit your MyPlan or safety plan after you complete session 1."
    }
  ],
  "MyPlan_lockedDescription2": [
    {
      "type": 0,
      "value": "This icon "
    },
    {
      "type": 1,
      "value": "icon"
    },
    {
      "type": 0,
      "value": " will always take you to your MyPlan."
    }
  ],
  "MyPlan_lockedUntil": [
    {
      "type": 0,
      "value": "Finish session 1"
    }
  ],
  "MyPlan_refresh": [
    {
      "type": 0,
      "value": "Update"
    }
  ],
  "MyPlan_stepsHeading": [
    {
      "type": 0,
      "value": "Safety steps"
    }
  ],
  "MyPlan_title": [
    {
      "type": 0,
      "value": "MyPlan"
    }
  ],
  "NextSession_header": [
    {
      "type": 0,
      "value": "Next session"
    }
  ],
  "NextSession_sessionProgress": [
    {
      "type": 1,
      "value": "completedCount"
    },
    {
      "type": 0,
      "value": "/"
    },
    {
      "type": 1,
      "value": "totalCount"
    }
  ],
  "NextSession_sessionsDone": [
    {
      "type": 0,
      "value": "Sessions done"
    }
  ],
  "NextSession_sessionsDoneAccessibilityLabel": [
    {
      "type": 0,
      "value": "Session progress: "
    },
    {
      "type": 1,
      "value": "completedCount"
    },
    {
      "type": 0,
      "value": " of "
    },
    {
      "type": 1,
      "value": "totalCount"
    },
    {
      "type": 0,
      "value": " complete"
    }
  ],
  "PatientMyPlanEditContacts_addButton": [
    {
      "type": 0,
      "value": "Add from contacts"
    }
  ],
  "PatientMyPlanEditContacts_addManuallyButton": [
    {
      "type": 0,
      "value": "Or add manually"
    }
  ],
  "PatientMyPlanEditContacts_familyNameLabel": [
    {
      "type": 0,
      "value": "Last name*"
    }
  ],
  "PatientMyPlanEditContacts_familyNamePlaceholder": [
    {
      "type": 0,
      "value": "Smith"
    }
  ],
  "PatientMyPlanEditContacts_givenNameLabel": [
    {
      "type": 0,
      "value": "First name*"
    }
  ],
  "PatientMyPlanEditContacts_givenNamePlaceholder": [
    {
      "type": 0,
      "value": "Sally"
    }
  ],
  "PatientMyPlanEditContacts_phoneLabel": [
    {
      "type": 0,
      "value": "Phone number"
    }
  ],
  "PatientMyPlanEditContacts_placeholder": [
    {
      "type": 0,
      "value": "Add a contact"
    }
  ],
  "PatientMyPlanEditContacts_placeholderAccessibilityLabel": [
    {
      "type": 0,
      "value": "Contact placeholder"
    }
  ],
  "PatientMyPlanEditContacts_removeButton": [
    {
      "type": 0,
      "value": "Remove contact"
    }
  ],
  "PatientMyPlanEditContacts_saveManuallyButton": [
    {
      "type": 0,
      "value": "Add contact"
    }
  ],
  "PatientMyPlanEditContacts_saveManuallyTitle": [
    {
      "type": 0,
      "value": "Contact details"
    }
  ],
  "PatientMyPlanEditPlaces_addButton": [
    {
      "type": 0,
      "value": "Add from map"
    }
  ],
  "PatientMyPlanEditPlaces_addManuallyButton": [
    {
      "type": 0,
      "value": "Or add manually"
    }
  ],
  "PatientMyPlanEditPlaces_locationInputPlaceholder": [
    {
      "type": 0,
      "value": "Location name"
    }
  ],
  "PatientMyPlanEditPlaces_placeholder": [
    {
      "type": 0,
      "value": "Add a location"
    }
  ],
  "PatientMyPlanEditPlaces_placeholderAccessibilityLabel": [
    {
      "type": 0,
      "value": "Location placeholder"
    }
  ],
  "PatientMyPlanEditPlaces_removeButton": [
    {
      "type": 0,
      "value": "Remove place"
    }
  ],
  "PatientMyPlanEditPlaces_saveManuallyButton": [
    {
      "type": 0,
      "value": "Add place"
    }
  ],
  "PatientMyPlanEditPlaces_saveManuallyTitle": [
    {
      "type": 0,
      "value": "Place"
    }
  ],
  "PatientMyPlanIntroduction_answer1": [
    {
      "type": 0,
      "value": "A plan you can use to stop the risk curve getting too high."
    }
  ],
  "PatientMyPlanIntroduction_answer2": [
    {
      "type": 0,
      "value": "During a crisis, often it is hard to think what to do. MyPlan has all the steps you can take to keep you safe."
    }
  ],
  "PatientMyPlanIntroduction_answer3": [
    {
      "type": 0,
      "value": "When your warning signs tell you your risk is rising, you can use the coping steps in the plan. And if you feel the risk still rising, the plan has a number of ways to help you."
    }
  ],
  "PatientMyPlanIntroduction_heading": [
    {
      "type": 0,
      "value": "MyPlan"
    }
  ],
  "PatientMyPlanIntroduction_question1": [
    {
      "type": 0,
      "value": "What is it?"
    }
  ],
  "PatientMyPlanIntroduction_question2": [
    {
      "type": 0,
      "value": "Why is this important?"
    }
  ],
  "PatientMyPlanIntroduction_question3": [
    {
      "type": 0,
      "value": "How does it work?"
    }
  ],
  "PatientMyPlanIntroduction_vignetteCaption": [
    {
      "type": 0,
      "value": "Watching how others make their MyPlan may help you create yours."
    }
  ],
  "PatientMyPlanIntroduction_voiceoverCaption": [
    {
      "type": 0,
      "value": "See how we'll create MyPlan and how it'll keep you safe."
    }
  ],
  "PatientMyPlanReview_heading": [
    {
      "type": 0,
      "value": "MyPlan"
    }
  ],
  "PatientMyPlan_environmentSafety_removalStepsHeading": [
    {
      "type": 0,
      "value": "Ways to secure lethal means"
    }
  ],
  "PatientMyPlan_environmentSafety_removalStepsLabel": [
    {
      "type": 0,
      "value": "Ways to secure lethal means"
    }
  ],
  "PatientMyPlan_environmentSafety_supportContactsHeading": [
    {
      "type": 0,
      "value": "Who will help me secure these"
    }
  ],
  "PatientMyPlan_environmentSafety_title": [
    {
      "type": 0,
      "value": "Making your environment safe"
    }
  ],
  "PatientMyPlan_helpContacts_description": [
    {
      "type": 0,
      "value": "Connecting with others helps you to not think about your problems which allows time for the suicidal thoughts to subside. The people in this step can either help distract you or can help you in a crisis."
    }
  ],
  "PatientMyPlan_helpContacts_title": [
    {
      "type": 0,
      "value": "People I can ask for help"
    }
  ],
  "PatientMyPlan_helpContacts_titleV2": [
    {
      "type": 0,
      "value": "People I can talk to"
    }
  ],
  "PatientMyPlan_professionalHelpContacts_description": [
    {
      "type": 0,
      "value": "These are professionals who can help you in a crisis. We’ve added the 988 Suicide & Crisis Lifeline to help you with this step."
    }
  ],
  "PatientMyPlan_professionalHelpContacts_title": [
    {
      "type": 0,
      "value": "Professional help"
    }
  ],
  "PatientMyPlan_reasonsForLiving_description": [
    {
      "type": 0,
      "value": "With all that has been going on in your life, think about what helps to keep you alive and going on a day-to-day basis. These are your reasons for living."
    }
  ],
  "PatientMyPlan_reasonsForLiving_selectButton": [
    {
      "type": 0,
      "value": "List of common reasons for living"
    }
  ],
  "PatientMyPlan_reasonsForLiving_title": [
    {
      "type": 0,
      "value": "Reasons for living"
    }
  ],
  "PatientMyPlan_selfManagement_description": [
    {
      "type": 0,
      "value": "These are activities you can do and places you can go to alone that are helpful for coping during times of stress."
    }
  ],
  "PatientMyPlan_selfManagement_selectButton": [
    {
      "type": 0,
      "value": "Select from suggested strategies"
    }
  ],
  "PatientMyPlan_selfManagement_title": [
    {
      "type": 0,
      "value": "Coping strategies"
    }
  ],
  "PatientMyPlan_selfManagement_titleV2": [
    {
      "type": 0,
      "value": "Things I can do myself"
    }
  ],
  "PatientMyPlan_socialDistractions_description": [
    {
      "type": 0,
      "value": "Talking to others or going to places with other people helps you not think about your problems which allows time for the suicidal thoughts to subside. People in this step are not to be used to share or discuss a crisis."
    }
  ],
  "PatientMyPlan_socialDistractions_peopleHeader": [
    {
      "type": 0,
      "value": "People that provide distraction"
    }
  ],
  "PatientMyPlan_socialDistractions_placesHeader": [
    {
      "type": 0,
      "value": "Places that provide distraction"
    }
  ],
  "PatientMyPlan_socialDistractions_title": [
    {
      "type": 0,
      "value": "Social distractions"
    }
  ],
  "PatientMyPlan_step": [
    {
      "type": 0,
      "value": "Step "
    },
    {
      "type": 1,
      "value": "number"
    }
  ],
  "PatientMyPlan_warningSigns_description": [
    {
      "type": 0,
      "value": "Warning signs are thoughts, feelings, or behaviors that you have before a suicidal crisis escalates. These serve as a cue to use the MyPlan."
    }
  ],
  "PatientMyPlan_warningSigns_selectButton": [
    {
      "type": 0,
      "value": "Select from your risk curve"
    }
  ],
  "PatientMyPlan_warningSigns_title": [
    {
      "type": 0,
      "value": "Warning signs"
    }
  ],
  "PatientMyStoryIntroduction_heading": [
    {
      "type": 0,
      "value": "MyStory"
    }
  ],
  "PatientMyStoryMyPlanContainer_myPlan": [
    {
      "type": 0,
      "value": "MyPlan"
    }
  ],
  "PatientMyStoryMyPlanContainer_myStory": [
    {
      "type": 0,
      "value": "MyStory"
    }
  ],
  "PatientMyStoryMyPlanContainer_riskCurve": [
    {
      "type": 0,
      "value": "Risk Curve"
    }
  ],
  "PatientMyStoryMyPlanContainer_videoHeading": [
    {
      "type": 0,
      "value": "Videos"
    }
  ],
  "PatientMyStoryMyPlanContainer_watchVideoButton": [
    {
      "type": 0,
      "value": "Watch"
    }
  ],
  "PatientMyStoryMyPlanOverview_goHomeButton": [
    {
      "type": 0,
      "value": "Go to homepage"
    }
  ],
  "PatientMyStoryMyPlanOverview_heading": [
    {
      "type": 0,
      "value": "Overview"
    }
  ],
  "PatientMyStoryMyPlanOverview_headingComplete": [
    {
      "type": 0,
      "value": "That's all for today"
    }
  ],
  "PatientMyStoryMyPlanOverview_myplanDescription": [
    {
      "type": 0,
      "value": "Create a plan to keep you safe during a crisis"
    }
  ],
  "PatientMyStoryMyPlanOverview_myplanDescriptionComplete": [
    {
      "type": 0,
      "value": "Create a plan to keep you safe during a crisis"
    }
  ],
  "PatientMyStoryMyPlanOverview_myplanTitle": [
    {
      "type": 0,
      "value": "MyPlan"
    }
  ],
  "PatientMyStoryMyPlanOverview_mystoryDescription": [
    {
      "type": 0,
      "value": "Tell the story of your recent suicide crisis"
    }
  ],
  "PatientMyStoryMyPlanOverview_mystoryDescriptionComplete": [
    {
      "type": 0,
      "value": "Tell the story of your recent suicide crisis"
    }
  ],
  "PatientMyStoryMyPlanOverview_mystoryTitle": [
    {
      "type": 0,
      "value": "MyStory"
    }
  ],
  "PatientMyStoryMyPlanOverview_riskCurveDescription": [
    {
      "type": 0,
      "value": "Learn how suicide risk rises and falls"
    }
  ],
  "PatientMyStoryMyPlanOverview_riskCurveDescriptionComplete": [
    {
      "type": 0,
      "value": "Learn how suicide risk rises and falls"
    }
  ],
  "PatientMyStoryMyPlanOverview_riskCurveTitle": [
    {
      "type": 0,
      "value": "MyRiskCurve"
    }
  ],
  "PatientMyStoryNarrative_heading": [
    {
      "type": 0,
      "value": "Tell your story"
    }
  ],
  "PatientMyStoryTimeline_description": [
    {
      "type": 0,
      "value": "To check I understand, I'm going to summarize some of the main points from your story."
    }
  ],
  "PatientMyStoryTimeline_heading": [
    {
      "type": 0,
      "value": "Suicide crisis timeline"
    }
  ],
  "PatientNotification_actionDescription_activityAdd": [
    {
      "type": 0,
      "value": "add an activity"
    }
  ],
  "PatientNotification_actionDescription_activityRate": [
    {
      "type": 0,
      "value": "rate an activity"
    }
  ],
  "PatientNotification_actionDescription_copingCardAdd": [
    {
      "type": 0,
      "value": "add a coping card"
    }
  ],
  "PatientNotification_actionDescription_copingCardReview": [
    {
      "type": 0,
      "value": "review your coping cards"
    }
  ],
  "PatientNotification_actionDescription_eatingLogAdd": [
    {
      "type": 0,
      "value": "add to your eating log"
    }
  ],
  "PatientNotification_actionDescription_hopeKitAdd": [
    {
      "type": 0,
      "value": "add to your "
    },
    {
      "type": 1,
      "value": "hopeKitName"
    }
  ],
  "PatientNotification_actionDescription_hopeKitReview": [
    {
      "type": 0,
      "value": "review your "
    },
    {
      "type": 1,
      "value": "hopeKitName"
    }
  ],
  "PatientNotification_actionDescription_myPlanReview": [
    {
      "type": 0,
      "value": "review MyPlan"
    }
  ],
  "PatientNotification_actionDescription_relax": [
    {
      "type": 0,
      "value": "do a relaxation exercise"
    }
  ],
  "PatientNotification_actionDescription_sleepDiaryMorning": [
    {
      "type": 0,
      "value": "complete your morning Sleep Diary"
    }
  ],
  "PatientNotification_actionDescription_sleepDiaryNight": [
    {
      "type": 0,
      "value": "complete your night Sleep Diary"
    }
  ],
  "PatientNotification_actionDescription_thoughtDiarySpot": [
    {
      "type": 0,
      "value": "spot a new thought"
    }
  ],
  "PatientNotification_actionDescription_thoughtDiarySwitch": [
    {
      "type": 0,
      "value": "switch a thought"
    }
  ],
  "PatientNotification_actionDescription_thoughtDiaryTest": [
    {
      "type": 0,
      "value": "test a thought"
    }
  ],
  "PatientRelationPicker_patientRelationLabel": [
    {
      "type": 0,
      "value": "Relation"
    }
  ],
  "PatientRelationPicker_submit": [
    {
      "type": 0,
      "value": "Next"
    }
  ],
  "PatientRiskCurveIntroduction_answer1": [
    {
      "type": 0,
      "value": "Below is a diagram that introduces the 'suicide risk curve'."
    }
  ],
  "PatientRiskCurveIntroduction_answer2": [
    {
      "type": 0,
      "value": "We want to help you to spot your warning signs when your risk starts to go up. That way, we can try stop the risk rising to keep you safe."
    }
  ],
  "PatientRiskCurveIntroduction_heading": [
    {
      "type": 0,
      "value": "Risk curve"
    }
  ],
  "PatientRiskCurveIntroduction_question1": [
    {
      "type": 0,
      "value": "What is it?"
    }
  ],
  "PatientRiskCurveIntroduction_question2": [
    {
      "type": 0,
      "value": "Why is this important?"
    }
  ],
  "PatientRiskCurveIntroduction_videoDescription": [
    {
      "type": 0,
      "value": "The curve shows how over time, risk escalates to the point you want to take your life. Then the risk falls as time passes."
    }
  ],
  "PatientRiskCurveIntroduction_videoLabel": [
    {
      "type": 0,
      "value": "Diagram"
    }
  ],
  "PatientRiskCurveIntroduction_vignetteCaption": [
    {
      "type": 0,
      "value": "Watch how others organize what happened into their suicide risk curve. It may help you think about yours."
    }
  ],
  "PatientRiskCurveIntroduction_voiceoverCaption": [
    {
      "type": 0,
      "value": "Learn more about what the suicide risk curve is and how it applies to you."
    }
  ],
  "PatientRiskCurveReview_heading": [
    {
      "type": 0,
      "value": "My risk curve"
    }
  ],
  "PatientSupporterRelation_clinician": [
    {
      "type": 0,
      "value": "Clinician"
    }
  ],
  "PatientSupporterRelation_other": [
    {
      "type": 0,
      "value": "Other"
    }
  ],
  "PatientSupporterRelation_parentGuardian": [
    {
      "type": 0,
      "value": "Parent/guardian"
    }
  ],
  "PatientSupporterRelation_relative": [
    {
      "type": 0,
      "value": "Relative"
    }
  ],
  "PatientSupporterRelation_socialServices": [
    {
      "type": 0,
      "value": "Social services"
    }
  ],
  "PracticeRating_label_1": [
    {
      "type": 0,
      "value": "Awful"
    }
  ],
  "PracticeRating_label_2": [
    {
      "type": 0,
      "value": "Bad"
    }
  ],
  "PracticeRating_label_3": [
    {
      "type": 0,
      "value": "Ok"
    }
  ],
  "PracticeRating_label_4": [
    {
      "type": 0,
      "value": "Good"
    }
  ],
  "PracticeRating_label_5": [
    {
      "type": 0,
      "value": "Great"
    }
  ],
  "PracticeTile_locked": [
    {
      "type": 0,
      "value": "locked"
    }
  ],
  "PracticeTile_lockedUntilHint": [
    {
      "type": 0,
      "value": "Finish session "
    },
    {
      "type": 1,
      "value": "sessionNum"
    }
  ],
  "PracticeTile_practiceCount": [
    {
      "type": 1,
      "value": "count"
    },
    {
      "type": 0,
      "value": " done"
    }
  ],
  "Practices_xAxisMonthLabel": [
    {
      "type": 0,
      "value": "Week beginning"
    }
  ],
  "Practices_xAxisWeekLabel": [
    {
      "type": 0,
      "value": "Days"
    }
  ],
  "Practices_xAxisYearLabel": [
    {
      "type": 0,
      "value": "Months"
    }
  ],
  "Profile_choosePhotoCameraOption": [
    {
      "type": 0,
      "value": "Take a photo"
    }
  ],
  "Profile_choosePhotoCancelOption": [
    {
      "type": 0,
      "value": "Cancel"
    }
  ],
  "Profile_choosePhotoGalleryOption": [
    {
      "type": 0,
      "value": "Choose from gallery"
    }
  ],
  "Profile_profilePhotoButton": [
    {
      "type": 0,
      "value": "Profile photo"
    }
  ],
  "Profile_settingsButton": [
    {
      "type": 0,
      "value": "Settings"
    }
  ],
  "ReauthenticationModal_submitButton": [
    {
      "type": 0,
      "value": "Submit"
    }
  ],
  "RequestResetPassword_description": [
    {
      "type": 0,
      "value": "Don't worry. Resetting your password is easy. Just tell us the email address you registered with "
    },
    {
      "type": 1,
      "value": "appName"
    },
    {
      "type": 0,
      "value": "."
    }
  ],
  "RequestResetPassword_emailLabel": [
    {
      "type": 0,
      "value": "Email"
    }
  ],
  "RequestResetPassword_error": [
    {
      "type": 0,
      "value": "Something went wrong requesting a password reset email. Please make sure you've entered your email address properly."
    }
  ],
  "RequestResetPassword_heading": [
    {
      "type": 0,
      "value": "Forgot your password?"
    }
  ],
  "RequestResetPassword_submitButton": [
    {
      "type": 0,
      "value": "Request password reset"
    }
  ],
  "RequestResetPassword_success": [
    {
      "type": 0,
      "value": "Please check your email to reset your password."
    }
  ],
  "RequestResetPassword_title": [
    {
      "type": 0,
      "value": "Forgot password"
    }
  ],
  "ResetPassword_description": [
    {
      "type": 0,
      "value": "You're almost there. Enter a new password to update your account."
    }
  ],
  "ResetPassword_error": [
    {
      "type": 0,
      "value": "We couldn't reset your password. Your token may have expired. Please request a new reset password email."
    }
  ],
  "ResetPassword_heading": [
    {
      "type": 0,
      "value": "Choose a new password"
    }
  ],
  "ResetPassword_passwordConfirmationLabel": [
    {
      "type": 0,
      "value": "Confirm password"
    }
  ],
  "ResetPassword_passwordHint": [
    {
      "type": 0,
      "value": "Must be at least 8 characters"
    }
  ],
  "ResetPassword_passwordLabel": [
    {
      "type": 0,
      "value": "New password"
    }
  ],
  "ResetPassword_passwordMismatchError": [
    {
      "type": 0,
      "value": "Your password and password confirmation do not match"
    }
  ],
  "ResetPassword_requestNewEmailButton": [
    {
      "type": 0,
      "value": "Request another email"
    }
  ],
  "ResetPassword_submitButton": [
    {
      "type": 0,
      "value": "Set password"
    }
  ],
  "ResetPassword_title": [
    {
      "type": 0,
      "value": "Reset password"
    }
  ],
  "ReviewCompleteCard_complete": [
    {
      "type": 0,
      "value": "Review complete"
    }
  ],
  "ReviewCompleteCard_doneButton": [
    {
      "type": 0,
      "value": "Done"
    }
  ],
  "ReviewCompleteCard_resetButton": [
    {
      "type": 0,
      "value": "Reset cards"
    }
  ],
  "Signup_confirmPasswordLabel": [
    {
      "type": 0,
      "value": "Confirm password"
    }
  ],
  "Signup_confirmPasswordPlaceholder": [
    {
      "type": 0,
      "value": "Confirm password"
    }
  ],
  "Signup_emailLabel": [
    {
      "type": 0,
      "value": "Email"
    }
  ],
  "Signup_loginButton": [
    {
      "type": 0,
      "value": "Login"
    }
  ],
  "Signup_passwordHint": [
    {
      "type": 0,
      "value": "Must be at least 8 characters"
    }
  ],
  "Signup_passwordLabel": [
    {
      "type": 0,
      "value": "Set a password"
    }
  ],
  "Signup_passwordPlaceholder": [
    {
      "type": 0,
      "value": "Password"
    }
  ],
  "Signup_termsOfService": [
    {
      "type": 0,
      "value": "By creating an account you agree to the "
    },
    {
      "type": 1,
      "value": "appName"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "Terms of Service & Privacy Policy"
        }
      ],
      "type": 8,
      "value": "link"
    },
    {
      "type": 0,
      "value": "."
    }
  ],
  "Signup_title": [
    {
      "type": 0,
      "value": "Create your account"
    }
  ],
  "SoloMyPlan_stepsHeading": [
    {
      "type": 0,
      "value": "Safety steps"
    }
  ],
  "SoloMyPlan_title": [
    {
      "type": 0,
      "value": "MyPlan"
    }
  ],
  "SoloSuicideModeStepBReview_description": [
    {
      "type": 0,
      "value": "You have identified "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 event"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " events"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "numEvents"
    },
    {
      "type": 0,
      "value": " that led up to your recent crisis.\n\nThese events and your baseline risk factors made you vulnerable to suicide crisis."
    }
  ],
  "StaticMyPlan_heading": [
    {
      "type": 0,
      "value": "MyPlan"
    }
  ],
  "StaticSessionCover_back": [
    {
      "type": 0,
      "value": "Back"
    }
  ],
  "StaticSessionCover_next": [
    {
      "type": 0,
      "value": "Next"
    }
  ],
  "SuggestionsHeader_title": [
    {
      "type": 0,
      "value": "Review"
    }
  ],
  "SupportPatientDetails_message": [
    {
      "type": 0,
      "value": "Your account has been linked with"
    }
  ],
  "SupportPatientRegistration_familyNameLabel": [
    {
      "type": 0,
      "value": "Last name"
    }
  ],
  "SupportPatientRegistration_givenNameLabel": [
    {
      "type": 0,
      "value": "First name"
    }
  ],
  "SupportPatientRegistration_phoneNumberLabel": [
    {
      "type": 0,
      "value": "Phone number"
    }
  ],
  "SupporteeMyPlanStatus_header": [
    {
      "type": 1,
      "value": "firstName"
    },
    {
      "type": 0,
      "value": "'s Environment Safety Plan"
    }
  ],
  "SupporteeMyPlanStatus_plan_about": [
    {
      "type": 0,
      "value": "It may help to talk to "
    },
    {
      "type": 1,
      "value": "firstName"
    },
    {
      "type": 0,
      "value": " about their plan to keep their environment safe"
    }
  ],
  "SupporteeMyPlanStatus_plan_update": [
    {
      "type": 1,
      "value": "firstName"
    },
    {
      "type": 0,
      "value": " made changes to their plan to keep their environment safe."
    }
  ],
  "SupporteeMyPlanStatus_review_completed": [
    {
      "type": 0,
      "value": "Review completed environment safety plan"
    }
  ],
  "SupporteeMyPlanStatus_review_updated": [
    {
      "type": 0,
      "value": "Review updated environment safety plan"
    }
  ],
  "SupporteeMyPlan_lockedDescription": [
    {
      "type": 0,
      "value": "This is where you can come review "
    },
    {
      "type": 1,
      "value": "supporteeName"
    },
    {
      "type": 0,
      "value": "'s plan to keep their environment safe after they complete Session 1 in their app."
    }
  ],
  "SupporteeMyPlan_lockedLabel": [
    {
      "type": 0,
      "value": "Not complete"
    }
  ],
  "SwitchInput_offLabel": [
    {
      "type": 0,
      "value": "Off"
    }
  ],
  "SwitchInput_onLabel": [
    {
      "type": 0,
      "value": "On"
    }
  ],
  "Tabs_accountTab": [
    {
      "type": 0,
      "value": "Account"
    }
  ],
  "Tabs_homeTab": [
    {
      "type": 0,
      "value": "Home"
    }
  ],
  "Tabs_learnTab": [
    {
      "type": 0,
      "value": "Learn"
    }
  ],
  "Tabs_myPlanTab": [
    {
      "type": 0,
      "value": "Safety"
    }
  ],
  "Tabs_tabAccessibilityLabel": [
    {
      "type": 1,
      "value": "tabName"
    },
    {
      "type": 0,
      "value": ", "
    },
    {
      "type": 1,
      "value": "count"
    },
    {
      "type": 0,
      "value": " of "
    },
    {
      "type": 1,
      "value": "total"
    }
  ],
  "TreatmentList_avivaSupport_activityPlanning_subtitle": [
    {
      "type": 0,
      "value": "Do activities to feel better"
    }
  ],
  "TreatmentList_avivaSupport_activityPlanning_title": [
    {
      "type": 0,
      "value": "Getting going"
    }
  ],
  "TreatmentList_avivaSupport_copingCards_subtitle": [
    {
      "type": 0,
      "value": "Create reminder flash cards"
    }
  ],
  "TreatmentList_avivaSupport_copingCards_title": [
    {
      "type": 0,
      "value": "Coping cards"
    }
  ],
  "TreatmentList_avivaSupport_hopeKit_subtitle": [
    {
      "type": 0,
      "value": "Create a reminder of your hopes"
    }
  ],
  "TreatmentList_avivaSupport_hopeKit_title": [
    {
      "type": 0,
      "value": "Hope kit"
    }
  ],
  "TreatmentList_avivaSupport_howToHelp_subtitle": [
    {
      "type": 0,
      "value": "How to help"
    }
  ],
  "TreatmentList_avivaSupport_howToHelp_title": [
    {
      "type": 0,
      "value": "Introduction"
    }
  ],
  "TreatmentList_avivaSupport_myPlanReview_subtitle": [
    {
      "type": 0,
      "value": "Tie learnings together"
    }
  ],
  "TreatmentList_avivaSupport_myPlanReview_title": [
    {
      "type": 0,
      "value": "Risk curve & MyPlan"
    }
  ],
  "TreatmentList_avivaSupport_myPlan_subtitle": [
    {
      "type": 0,
      "value": "Make a plan to stay safe"
    }
  ],
  "TreatmentList_avivaSupport_myPlan_title": [
    {
      "type": 0,
      "value": "MyPlan"
    }
  ],
  "TreatmentList_avivaSupport_postAviva_subtitle": [
    {
      "type": 0,
      "value": "How Aviva can keep helping you"
    }
  ],
  "TreatmentList_avivaSupport_postAviva_title": [
    {
      "type": 0,
      "value": "What's next?"
    }
  ],
  "TreatmentList_avivaSupport_reduceRisk_subtitle": [
    {
      "type": 0,
      "value": "Review skills you learned"
    }
  ],
  "TreatmentList_avivaSupport_reduceRisk_title": [
    {
      "type": 0,
      "value": "Skills to reduce risk"
    }
  ],
  "TreatmentList_avivaSupport_relaxation_subtitle": [
    {
      "type": 0,
      "value": "Learn calming exercises"
    }
  ],
  "TreatmentList_avivaSupport_relaxation_title": [
    {
      "type": 0,
      "value": "Relaxation & mindfulness"
    }
  ],
  "TreatmentList_avivaSupport_sleep_subtitle": [
    {
      "type": 0,
      "value": "Develop better sleep habits"
    }
  ],
  "TreatmentList_avivaSupport_sleep_title": [
    {
      "type": 0,
      "value": "Improving sleep"
    }
  ],
  "TreatmentList_avivaSupport_spotIt_subtitle": [
    {
      "type": 0,
      "value": "Link thoughts to how you feel"
    }
  ],
  "TreatmentList_avivaSupport_spotIt_title": [
    {
      "type": 0,
      "value": "Spot it"
    }
  ],
  "TreatmentList_avivaSupport_switchIt_subtitle": [
    {
      "type": 0,
      "value": "Find more helpful thoughts"
    }
  ],
  "TreatmentList_avivaSupport_switchIt_title": [
    {
      "type": 0,
      "value": "Switch it"
    }
  ],
  "TreatmentList_avivaSupport_testIt_subtitle": [
    {
      "type": 0,
      "value": "Challenge your thoughts"
    }
  ],
  "TreatmentList_avivaSupport_testIt_title": [
    {
      "type": 0,
      "value": "Test it"
    }
  ],
  "TreatmentList_aviva_activityPlanning_description": [
    {
      "type": 0,
      "value": "What’s it mean to get going\nLink doing activities to mood\nRecord activities and mood"
    }
  ],
  "TreatmentList_aviva_activityPlanning_subtitle": [
    {
      "type": 0,
      "value": "Do activities to feel better"
    }
  ],
  "TreatmentList_aviva_activityPlanning_title": [
    {
      "type": 0,
      "value": "Getting going"
    }
  ],
  "TreatmentList_aviva_copingCards_description": [
    {
      "type": 0,
      "value": "Remember your skills learned\nHow to create Coping cards\nMake time to practice them"
    }
  ],
  "TreatmentList_aviva_copingCards_subtitle": [
    {
      "type": 0,
      "value": "Create reminder flash cards"
    }
  ],
  "TreatmentList_aviva_copingCards_title": [
    {
      "type": 0,
      "value": "Coping cards"
    }
  ],
  "TreatmentList_aviva_hopeKit_description": [
    {
      "type": 0,
      "value": "What is a Hope Kit & why it’s helpful\nStart building your kit\nMake time to use it"
    }
  ],
  "TreatmentList_aviva_hopeKit_subtitle": [
    {
      "type": 0,
      "value": "Create a reminder of your hopes"
    }
  ],
  "TreatmentList_aviva_hopeKit_title": [
    {
      "type": 0,
      "value": "Hope kit"
    }
  ],
  "TreatmentList_aviva_myPlanReview_description": [
    {
      "type": 0,
      "value": "Reviewing your risk curve\nReviewing MyPlan\nMake updates to MyPlan"
    }
  ],
  "TreatmentList_aviva_myPlanReview_subtitle": [
    {
      "type": 0,
      "value": "Tie learnings together"
    }
  ],
  "TreatmentList_aviva_myPlanReview_title": [
    {
      "type": 0,
      "value": "Risk curve & MyPlan"
    }
  ],
  "TreatmentList_aviva_myPlan_description": [
    {
      "type": 0,
      "value": "How suicide risk rises and falls\nPlot your own risk curve\nCreate a plan to stay safe"
    }
  ],
  "TreatmentList_aviva_myPlan_subtitle": [
    {
      "type": 0,
      "value": "Make a plan to stay safe"
    }
  ],
  "TreatmentList_aviva_myPlan_title": [
    {
      "type": 0,
      "value": "MyPlan"
    }
  ],
  "TreatmentList_aviva_postAviva_description": [
    {
      "type": 0,
      "value": "What happens now\nSeeking more help\nContinue using Aviva"
    }
  ],
  "TreatmentList_aviva_postAviva_subtitle": [
    {
      "type": 0,
      "value": "How Aviva can keep helping you"
    }
  ],
  "TreatmentList_aviva_postAviva_title": [
    {
      "type": 0,
      "value": "What's next?"
    }
  ],
  "TreatmentList_aviva_reduceRisk_description": [
    {
      "type": 0,
      "value": "Skills to change behaviors\nSkills to change thoughts\nWhat was most helpful"
    }
  ],
  "TreatmentList_aviva_reduceRisk_subtitle": [
    {
      "type": 0,
      "value": "Review skills you learned"
    }
  ],
  "TreatmentList_aviva_reduceRisk_title": [
    {
      "type": 0,
      "value": "Skills to reduce risk"
    }
  ],
  "TreatmentList_aviva_relaxation_description": [
    {
      "type": 0,
      "value": "What are relaxation skills\nHow to use them\nMake time to practice"
    }
  ],
  "TreatmentList_aviva_relaxation_subtitle": [
    {
      "type": 0,
      "value": "Learn calming exercises"
    }
  ],
  "TreatmentList_aviva_relaxation_title": [
    {
      "type": 0,
      "value": "Relaxation & mindfulness"
    }
  ],
  "TreatmentList_aviva_sleep_description": [
    {
      "type": 0,
      "value": "Why is sleeping well important\nWhat might disrupt your sleep\nTrack your sleep habits"
    }
  ],
  "TreatmentList_aviva_sleep_subtitle": [
    {
      "type": 0,
      "value": "Develop better sleep habits"
    }
  ],
  "TreatmentList_aviva_sleep_title": [
    {
      "type": 0,
      "value": "Improving sleep"
    }
  ],
  "TreatmentList_aviva_spotIt_description": [
    {
      "type": 0,
      "value": "Why thoughts affect feelings\nHow to spot negative thoughts\nWrite spotted thoughts"
    }
  ],
  "TreatmentList_aviva_spotIt_subtitle": [
    {
      "type": 0,
      "value": "Link thoughts to how you feel"
    }
  ],
  "TreatmentList_aviva_spotIt_title": [
    {
      "type": 0,
      "value": "Spot it"
    }
  ],
  "TreatmentList_aviva_switchIt_description": [
    {
      "type": 0,
      "value": "Why balance your thoughts\nHow to switch it\nWrite switched thoughts"
    }
  ],
  "TreatmentList_aviva_switchIt_subtitle": [
    {
      "type": 0,
      "value": "Find more helpful thoughts"
    }
  ],
  "TreatmentList_aviva_switchIt_title": [
    {
      "type": 0,
      "value": "Switch it"
    }
  ],
  "TreatmentList_aviva_testIt_description": [
    {
      "type": 0,
      "value": "Why test your thoughts\nHow to test negative thoughts\nWrite evidence for & against"
    }
  ],
  "TreatmentList_aviva_testIt_subtitle": [
    {
      "type": 0,
      "value": "Challenge your thoughts"
    }
  ],
  "TreatmentList_aviva_testIt_title": [
    {
      "type": 0,
      "value": "Test it"
    }
  ],
  "Welcome_logoAccessibilityLabel": [
    {
      "type": 0,
      "value": "Aviva Caregiver Logo"
    }
  ],
  "Welcome_message": [
    {
      "type": 0,
      "value": "To activate your account, please contact your child’s clinician for a link"
    }
  ],
  "Welcome_navigateToLogin": [
    {
      "type": 0,
      "value": "I already have an account"
    }
  ],
  "Welcome_title": [
    {
      "type": 0,
      "value": "Welcome to Aviva Caregiver"
    }
  ],
  "patientSocialSupporterRelation_CHEERLEADER": [
    {
      "type": 0,
      "value": "Cheerleader"
    }
  ],
  "patientSocialSupporterRelation_DETRACTOR": [
    {
      "type": 0,
      "value": "Detractor"
    }
  ],
  "patientSocialSupporterRelation_PARTNER": [
    {
      "type": 0,
      "value": "Partner"
    }
  ],
  "patientSocialSupporterRelation_POSITIVE_ENABLER": [
    {
      "type": 0,
      "value": "Positive Enabler"
    }
  ]
}