import { parseISO } from 'date-fns';
import format from 'date-fns/format';
import parse from 'date-fns/parse';

import { GQLDate, GQLDateTime } from './types/scalars';

export function formatGQLDate(date = new Date()): GQLDate {
  return format(date, 'yyyy-MM-dd') as GQLDate;
}

export function parseGQLDate(date: GQLDate): Date {
  return parseISO(date);
}

export function parseGQLTime(time: string): Date {
  return parse(time, 'HH:mm', new Date());
}

export function parseGQLDateAndTime(date: string, time: string): Date {
  return parse(`${date} ${time}`, 'yyyy-MM-dd HH:mm', new Date());
}

export function formatGQLTime(date: Date): string {
  return format(date, 'HH:mm');
}

export function parseGQLDateTime(dateTime: GQLDateTime | string): Date {
  return parseISO(dateTime);
}
