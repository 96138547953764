import { STATIC_REVIEW_CARDS as STATIC_REVIEW_CARDS_OTX202 } from './content/review/otx202';
import { STATIC_REVIEW_CARDS as STATIC_REVIEW_CARDS_OTX207 } from './content/review/otx207';
import { ContentType, ProductVariant, RoleType } from './types/graphql.generated';

export function getStaticReview({
  contentType,
  productSlug,
  productStatic,
  roleType,
}: {
  contentType: ContentType;
  productSlug: ProductVariant;
  productStatic: boolean;
  roleType: RoleType;
}) {
  switch (productSlug) {
    case ProductVariant.AVIVA_ADULT:
      return STATIC_REVIEW_CARDS_OTX202[contentType];
    case ProductVariant.AVIVA_STATIC:
      return STATIC_REVIEW_CARDS_OTX202[contentType];
    case ProductVariant.AVIVA_ADOLESCENT:
      return roleType === 'PATIENT'
        ? STATIC_REVIEW_CARDS_OTX207[contentType]
        : STATIC_REVIEW_CARDS_OTX207[contentType];
  }
  throw new Error(
    'getStaticReview invalid configuration: ' +
      JSON.stringify({ contentType, productSlug, productStatic }),
  );
}
