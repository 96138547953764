import { z } from 'zod';

export const ChecklistItem = z.object({
  key: z.string().default(''),
  completed: z.boolean().default(false),
});

export const ClinicalChecklist = z
  .object({
    checklist: z.array(ChecklistItem).default([]),
  })
  .default({ checklist: [] });

export type ChecklistItem = z.infer<typeof ChecklistItem>;
export type ClinicalChecklist = z.infer<typeof ClinicalChecklist>;
