import { useQuery } from '@apollo/client';
import { useFocusEffect } from '@react-navigation/native';
import { useCallback, useMemo } from 'react';

import { getDataHashFromMyPlanComposition } from '@oui/app-core/src/hooks/useComposition';
import { usePersistedState } from '@oui/app-core/src/hooks/usePersistedState';
import { graphql } from '@oui/lib/src/graphql/tada';
import { ContentType } from '@oui/lib/src/types';
import { GQLDateTime } from '@oui/lib/src/types/scalars';
import { EnvironmentSafetySectionFragment } from '@oui/myplan/src/components/EnvironmentSafetySection';
import { MyPlanFragment } from '@oui/myplan/src/components/MyPlan';

export const SupporteeMyPlanQuery = graphql(
  `
    query SupporteeMyPlan {
      user {
        ID
        role {
          ID
          supportee {
            ID
            supportee {
              ID
              user {
                ID
                givenName
                familyName
              }
              composition(template: "MYSTORYMYPLAN") {
                ID
                json
                sections {
                  ID
                  title
                  createdAt
                  updatedAt
                }
              }
              progress {
                content
                completed
                updatedAt
              }
              ...MyPlan
              ...EnvironmentSafetySection
            }
          }
        }
      }
    }
  `,
  [MyPlanFragment, EnvironmentSafetySectionFragment],
);

export const useSupporteeMyPlan = ({
  updatesLastSeenDate = true,
}: {
  updatesLastSeenDate?: boolean;
}) => {
  const { loading, data, refetch } = useQuery(SupporteeMyPlanQuery);
  const [lastSeenAt, setLastSeenAt] = usePersistedState(
    'supporteeMyPlanLastSeenAt',
    () => new Date(0).toISOString() as GQLDateTime,
  );

  const supportee = data?.user?.role?.supportee;
  const composition = supportee?.supportee.composition ?? undefined;

  useFocusEffect(
    useCallback(() => {
      void refetch();
      return () => {
        if (updatesLastSeenDate) {
          setLastSeenAt(new Date().toISOString() as GQLDateTime);
        }
      };
    }, [setLastSeenAt, refetch, updatesLastSeenDate]),
  );

  const updatedSections = useMemo(() => {
    // don't show updated sections if this is the first time user is looking at this screen
    if (lastSeenAt === (new Date(0).toISOString() as GQLDateTime)) return [];

    return (
      composition?.sections
        .filter((s) => s.updatedAt > lastSeenAt)
        .map((s) => s.title as keyof ReturnType<typeof getDataHashFromMyPlanComposition>) ?? []
    );
  }, [composition, lastSeenAt]);

  const hasCompletedMyPlanDate = useMemo(() => {
    if (lastSeenAt && supportee) {
      const hasCompletedMyPlanDate = supportee.supportee.progress.filter((i) => {
        return i.content === ContentType.MYPLAN && i.completed === true;
      })[0]?.updatedAt;

      return hasCompletedMyPlanDate;
    }
    return null;
  }, [lastSeenAt, supportee]);

  const shouldShowMyPlanStatus = useMemo(() => {
    // If newly completed
    if (hasCompletedMyPlanDate && hasCompletedMyPlanDate > lastSeenAt) return true;
    // If ENVIRONMENT_SAFETY updated since last seen
    if (updatedSections.includes('ENVIRONMENT_SAFETY')) return true;

    return false;
  }, [lastSeenAt, updatedSections, hasCompletedMyPlanDate]);

  return {
    loading,
    updatedSections,
    supportee,
    composition,
    lastSeenAt,
    refetch,
    shouldShowMyPlanStatus,
    hasCompletedMyPlanDate,
  };
};
