import { z } from 'zod';

import { Contact, Place } from './common';

export const SocialDistractionContact = Contact;
export type SocialDistractionContact = Contact;
export const SocialDistractionPlace = Place;
export type SocialDistractionPlace = Place;
export const SocialDistractions = z
  .object({
    contacts: z.array(SocialDistractionContact).default([]),
    places: z.array(SocialDistractionPlace).default([]),
  })
  .default({
    contacts: [],
    places: [],
  });

export type SocialDistractions = z.infer<typeof SocialDistractions>;
