import {
  BottomTabNavigationOptions,
  createBottomTabNavigator,
} from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import { defineMessages } from 'react-intl';
import { TouchableWithoutFeedback, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Icon } from '@oui/app-core/src/components/Icon';
import { Text } from '@oui/app-core/src/components/Text';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { AccountSettings } from '@oui/app-core/src/screens/AccountSettings';
import { useTheme } from '@oui/app-core/src/styles';

import { Home } from '@src/screens/Home';
import { Learn } from '@src/screens/Learn';
import { SupporteeMyPlan } from '@src/screens/SupporteeMyPlan';
import { HomeTabParamList } from '@src/types/navigation';

const Account = () => <AccountSettings tabScreen />;

function TabLabel(props: { focused: boolean; color: string; text: string }) {
  return (
    <View
      style={{
        alignItems: 'center',
        paddingVertical: 2,
        marginTop: -4,
        width: '100%',
      }}
    >
      <Text
        text={props.text}
        color={props.color}
        size={13}
        weight="semibold"
        numberOfLines={1}
        textAlign="center"
      />
      <View
        style={{
          backgroundColor: props.focused ? props.color : 'transparent',
          height: 2,
          width: '70%',
        }}
      ></View>
    </View>
  );
}

const tabLabels = defineMessages({
  home: { id: 'Tabs_homeTab', defaultMessage: 'Home' },
  learn: { id: 'Tabs_learnTab', defaultMessage: 'Learn' },
  myplan: { id: 'Tabs_myPlanTab', defaultMessage: 'Safety' },
  account: { id: 'Tabs_accountTab', defaultMessage: 'Account' },
});

const AccountStack = createStackNavigator();
const AccountNavigator = () => {
  const { theme } = useTheme();
  return (
    <AccountStack.Navigator
      screenOptions={{
        headerTintColor: theme.color.primary100,
        headerTitleStyle: { color: theme.color.dark, fontFamily: 'OpenSansSemiBold' },
      }}
    >
      <AccountStack.Screen name="AccountScreen" component={Account} />
    </AccountStack.Navigator>
  );
};

const Tab = createBottomTabNavigator<HomeTabParamList>();
export const TabNavigator = () => {
  const insets = useSafeAreaInsets();
  const { theme } = useTheme();
  const { $t } = useI18n();

  const tabAccessibilityLabel = (values: { tabName: string; count: number }) => {
    return $t(
      { id: 'Tabs_tabAccessibilityLabel', defaultMessage: '{tabName}, {count} of {total}' },
      { ...values, total: 4 },
    ) as string;
  };

  return (
    <Tab.Navigator
      screenOptions={({ route }) => {
        const options: BottomTabNavigationOptions = {
          headerShown: false,
          tabBarStyle: { height: 60 + insets.bottom },
          tabBarActiveTintColor: theme.color.primary100,
          tabBarInactiveTintColor: theme.color.gray500,
          // Adapted from https://github.com/react-navigation/react-navigation/blob/a484c1d7834f195ac0cfccda6a9f905218bc2274/packages/bottom-tabs/src/views/BottomTabItem.tsx#L156-L164
          // customized accessibilityRole
          tabBarButton: ({ children, style, onPress, to, accessibilityRole, ...rest }) => (
            <TouchableWithoutFeedback {...rest} role="tab" onPress={onPress}>
              <View style={style}>{children}</View>
            </TouchableWithoutFeedback>
          ),
          tabBarIcon: ({ color: tintColor }) => {
            const routeName = route.name;

            let icon = null;
            if (routeName === 'Home') {
              icon = <Icon color={tintColor} name="home" />;
            } else if (routeName === 'Learn') {
              icon = <Icon color={tintColor} name="sessions" />;
            } else if (routeName === 'MyPlan') {
              icon = <Icon color={tintColor} name="my-plan" />;
            } else if (routeName === 'Account') {
              icon = <Icon color={tintColor} name="person" />;
            }

            return <View style={{ paddingTop: 4 }}>{icon}</View>;
          },
          tabBarHideOnKeyboard: true,
        };
        return options;
      }}
      initialRouteName="Home"
    >
      <Tab.Screen
        name="Home"
        component={Home}
        options={{
          headerShown: false,
          tabBarLabel: (p) => <TabLabel {...p} text={$t(tabLabels.home)} />,
          tabBarTestID: 'NAV_Home',
          tabBarAccessibilityLabel: tabAccessibilityLabel({
            tabName: $t(tabLabels.home),
            count: 1,
          }),
        }}
      />
      <Tab.Screen
        name="Learn"
        component={Learn}
        options={{
          tabBarLabel: (p) => <TabLabel {...p} text={$t(tabLabels.learn)} />,
          tabBarTestID: 'NAV_Learn',
          tabBarAccessibilityLabel: tabAccessibilityLabel({
            tabName: $t(tabLabels.learn),
            count: 2,
          }),
        }}
      />
      <Tab.Screen
        name="MyPlan"
        component={SupporteeMyPlan}
        options={{
          tabBarLabel: (p) => <TabLabel {...p} text={$t(tabLabels.myplan)} />,
          tabBarTestID: 'NAV_MyPlan',
          tabBarAccessibilityLabel: tabAccessibilityLabel({
            tabName: $t(tabLabels.myplan),
            count: 3,
          }),
        }}
      />
      <Tab.Screen
        name="Account"
        component={AccountNavigator}
        options={{
          tabBarLabel: (p) => <TabLabel {...p} text={$t(tabLabels.account)} />,
          tabBarTestID: 'NAV_Account',
          tabBarAccessibilityLabel: tabAccessibilityLabel({
            tabName: $t(tabLabels.account),
            count: 4,
          }),
        }}
      />
    </Tab.Navigator>
  );
};
