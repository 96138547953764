import { useMutation, useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { useCallback, useRef } from 'react';
import { FormProvider } from 'react-hook-form';
import { z } from 'zod';

import { AuthScreenContainer } from '@oui/app-core/src/components/AuthScreenContainer';
import { Button } from '@oui/app-core/src/components/Button';
import { View } from '@oui/app-core/src/components/View';
import { Controller, TextInputRender, useZodForm, useZodFormContext } from '@oui/app-core/src/form';
import { useCurrentPatient } from '@oui/app-core/src/hooks/useCurrentUser';
import { usePersistedState } from '@oui/app-core/src/hooks/usePersistedState';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { graphql } from '@oui/lib/src/graphql/tada';
import {
  PatientSupporterRelationInputSchema,
  PatientUpdateInputSchema,
  UUIDSchema,
} from '@oui/lib/src/types';

import { RootStackScreenProps } from '@src/types/navigation';

export const FinishRegisteringQuery = graphql(`
  query FinishRegistering {
    ouiUser {
      __typename
      userID
      user {
        __typename
        ... on Patient {
          ID
          profile {
            patient {
              ID
            }
            supportees {
              __typename
              supporterID
              patientID
              patient {
                __typename
                ID
              }
            }
          }
        }
      }
    }
  }
`);

export const SupportPatientRegistrationMutation = graphql(`
  mutation SupportPatientRegistration(
    $patientID: UUID!
    $update: PatientUpdateInput!
    $patientRelationInput: PatientSupporterRelationInput!
  ) {
    updatePatient(patientID: $patientID, update: $update) {
      patient {
        __typename
        ID
        person {
          givenName
          familyName
          phone
          email
        }
      }
    }
    setPatientSupporterRelation(input: $patientRelationInput) {
      patientID
      supporterID
      relation
    }
  }
`);

const SupportPatientRegistrationMutationSchema = z.object({
  patientID: UUIDSchema,
  update: PatientUpdateInputSchema,
  patientRelationInput: PatientSupporterRelationInputSchema,
});

function SupportPatientRegistration() {
  const { $t } = useI18n();
  const { control } = useZodFormContext(SupportPatientRegistrationMutationSchema);

  return (
    <View spacing={12}>
      <Controller
        control={control}
        render={(props) =>
          TextInputRender(props, {
            label: $t({
              id: 'SupportPatientRegistration_givenNameLabel',
              defaultMessage: 'First name',
            }),
          })
        }
        name="update.person.givenName"
      />
      <Controller
        control={control}
        render={(props) =>
          TextInputRender(props, {
            label: $t({
              id: 'SupportPatientRegistration_familyNameLabel',
              defaultMessage: 'Last name',
            }),
          })
        }
        name="update.person.familyName"
      />

      <Controller
        control={control}
        render={(props) =>
          TextInputRender(props, {
            autoComplete: 'tel',
            inputMode: 'tel',
            label: $t({
              id: 'SupportPatientRegistration_phoneNumberLabel',
              defaultMessage: 'Phone number',
            }),
          })
        }
        name={`update.person.phone`}
      />
    </View>
  );
}

export function FinishRegistering(_props: {}) {
  const { $t } = useI18n();
  const navigation =
    useNavigation<RootStackScreenProps<'FinishPatientRegistration'>['navigation']>();

  const [patientSupporterRelation] = usePersistedState('patientSupporterRelation', 'OTHER');

  const { user } = useCurrentPatient();
  const { data } = useQuery(FinishRegisteringQuery);

  const supportee =
    data?.ouiUser?.user?.__typename === 'Patient' ? data.ouiUser.user.profile.supportees[0] : null;

  const form = useZodForm(SupportPatientRegistrationMutationSchema, {
    defaultValues: {
      patientID: user?.ID,
      update: {
        person: {
          givenName: user?.person.givenName,
          familyName: user?.person.familyName,
          phone: user?.person.phone,
        },
      },
      patientRelationInput: {
        patientID: supportee?.patientID,
        relation: patientSupporterRelation,
      },
    },
  });

  const formRef = useRef(form);
  formRef.current = form;

  const [updateSupportUser, { loading }] = useMutation(SupportPatientRegistrationMutation);

  const onSave = useCallback(async () => {
    await formRef.current.handleSubmit(async (data) => {
      await updateSupportUser({
        variables: data,
      });
      navigation.navigate('Confidentiality');
    })();
  }, [updateSupportUser, navigation]);

  return (
    <AuthScreenContainer
      heading={$t({ id: 'FinishRegistering_title', defaultMessage: 'Finish registering' })}
    >
      <View
        style={{
          marginTop: 20,
        }}
      >
        <FormProvider {...form}>
          {!user || !data ? null : (
            <View spacing={20}>
              <SupportPatientRegistration />
              <Button
                alignSelf="center"
                testID="FinishRegistering_submitButton"
                onPress={onSave}
                disabled={loading}
                text={$t({ id: 'FinishRegistering_submitButton', defaultMessage: 'Done' })}
              />
            </View>
          )}
        </FormProvider>
      </View>
    </AuthScreenContainer>
  );
}
