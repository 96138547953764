import type { SetData } from '../../content/quizSet/quizSetData';
import { getMuxUrlFromGCSUrl } from '../../muxUrl';

//Session 1 - Onboarding
//Text & quizzes here - https://docs.google.com/document/d/1ZVKt92TMEq0-ulWBYiLaFFHsv6EsEP2qDVOoSXeShaI/edit?usp=sharing
//Videos in this folder - https://drive.google.com/drive/folders/1yNIJqqTf_Z8tkaMenNQpxiRTSA2tWGLx?usp=sharing

//Collab Experience

export const MYSTORY_LEARN_VIGNETTE_PRIYANKA: SetData = {
  ID: 'mystory-learn-vignette-priyanka',
  name: 'Priyanka',
  description: 'Priyanka: 35, female, Indian-American, depressed, physical disability',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S1/Priyanka_S1A_220412_720.mp4',
        ),
        title: "Priyanka's Story",
        compulsory: true,
      },
    },
  ],
};

export const RISK_CURVE_LEARN_VIGNETTE_PRIYANKA_COLLAB: SetData = {
  ID: 'risk-curve-learn-vignette-priyanka-collab',
  name: 'Priyanka',
  description: 'Priyanka: 35, female, Indian-American, depressed, physical disability',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S1/Priyanka_S1B_220412_720.mp4',
        ),
        title: 'Making Sense',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S1/Priyanka_S1C_220412_720.mp4',
        ),
        title: 'Risk Curve',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Try to ignore it when she notices a warning sign.',
          '1': 'Avoid all situations that might increase her risk.',
          '2': 'Watch for warning signs that will increase her risk for a crisis',
        },
      },
      dir: {
        prompt: 'How can Priyanka prevent a crisis in the future?',
        feedback: {
          '0': {
            correct: false,
            text: 'It is important for Priyanka to be aware of and work to identify her warning signs.',
          },
          '1': {
            correct: false,
            text: 'It’s not realistic for Priyanka to avoid every stressful situation in her life.',
          },
          '2': {
            correct: true,
            text: '',
          },
        },
        afterAll:
          'Since Priyanka now knows her warning signs, she is better able to realize when she is going up the risk curve. She is also able to try lowering her risk back down before a crisis.',
      },
    },
  ],
};

//Aviva Experience

export const RISK_CURVE_LEARN_VIGNETTE_PRIYANKA: SetData = {
  ID: 'mystory-learn-vignette-priyanka',
  name: 'Priyanka',
  description: 'Priyanka: 35, female, Indian-American, depressed, physical disability',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S1/Priyanka_S1A_220412_720.mp4',
        ),
        title: "Priyanka's Story",
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S1/Priyanka_S1B_220412_720.mp4',
        ),
        title: 'Making Sense',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S1/Priyanka_S1C_220412_720.mp4',
        ),
        title: 'Risk Curve',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Try to ignore it when she notices a warning sign.',
          '1': 'Avoid all situations that might increase her risk.',
          '2': 'Watch for warning signs that will increase her risk for a crisis',
        },
      },
      dir: {
        prompt: 'How can Priyanka prevent a crisis in the future?',
        feedback: {
          '0': {
            correct: false,
            text: 'It is important for Priyanka to be aware of and work to identify her warning signs.',
          },
          '1': {
            correct: false,
            text: 'It’s not realistic for Priyanka to avoid every stressful situation in her life.',
          },
          '2': {
            correct: true,
            text: '',
          },
        },
        afterAll:
          'Since Priyanka now knows her warning signs, she is better able to realize when she is going up the risk curve. She is also able to try lowering her risk back down before a crisis.',
      },
    },
  ],
};

export const MYPLAN_LEARN_VIGNETTE_PRIYANKA: SetData = {
  ID: 'myplan-learn-vignette-priyanka',
  name: 'Priyanka',
  description: 'Priyanka: 35, female, Indian-American, depressed, physical disability',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S1/Priyanka_S1D_220412_720.mp4',
        ),
        title: 'My Plan',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S1/Priyanka_S1E_220412_720.mp4',
        ),
        title: 'Safe Environment',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Because, sometimes, people need help to keep themselves safe.',
          '1': 'It is not important. It is just part of the MyPlan form, so it needs to be filled out.',
          '2': 'To decrease someone’s independence.',
        },
      },
      dir: {
        prompt: 'Why is making the environment safe an important part of MyPlan?',
        feedback: {
          '0': {
            correct: true,
            text: '',
          },
          '1': {
            correct: false,
            text: 'Every step on the Aviva app has a purpose.',
          },
          '2': {
            correct: false,
            text: 'Even though family members might control access to certain things, it is to make sure that there is support around when one needs it.',
          },
        },
        afterAll:
          'Making the environment safe is a way to help reduce risk, because a person has less access to things that they could use to hurt themselves.',
      },
    },
  ],
};

//Session 2 - Hope Kit
//Videos in this folder - https://drive.google.com/drive/folders/1TAfk6RcStbL81_LMk4CamNMLH5U1-j4f?usp=sharing

export const HOPE_KIT_LEARN_VIGNETTE_PRIYANKA: SetData = {
  ID: 'hope-kit-learn-priyanka',
  name: 'Priyanka',
  description: 'Priyanka: 35, female, Indian-American, depressed, physical disability',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S2/Priyanka_S2A_220506_720.mp4',
        ),
        title: 'About Hope Kit',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S2/Priyanka_S2B_220506_720.mp4',
        ),
        title: 'What Include',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'True',
          '1': 'False',
        },
      },
      dir: {
        prompt:
          'You can include anything you want in the Hope Kit that brings you joy or helps you look forward to things in the future. True or false?',
        feedback: {
          '0': {
            correct: true,
            text: 'A great thing about Hope Kit is that you can pick things that are specific to you that might make you feel hopeful. You can also add things to the Hope Kit over time.',
          },
          '1': {
            correct: false,
            text: 'A great thing about Hope Kit is that you can pick things that are specific to you that might make you feel hopeful.',
          },
        },
      },
    },
  ],
};

export const HOPE_KIT_APPLY_VIGNETTE_PRIYANKA: SetData = {
  ID: 'hope-kit-apply-priyanka',
  name: 'Priyanka',
  description: 'Priyanka: 35, female, Indian-American, depressed, physical disability',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S2/Priyanka_S2C_220412_720.mp4',
        ),
        title: 'Making Hope Kit',
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S2/Priyanka_S2D_220412_720.mp4',
        ),
        title: 'Using Hope Kit',
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'It helps you work harder to keep stress down.',
          '1': 'You completely forget about wanting to end your life.',
          '2': 'Reminds you of reasons to keep living.',
        },
      },
      dir: {
        prompt: 'How can the Hope Kit help reduce suicide risk?',
        feedback: {
          '0': {
            correct: false,
            text: 'The Hope Kit doesn’t necessarily provide ways to keep stress down.',
          },
          '1': {
            correct: false,
            text: 'Struggling with suicidal thoughts may still happen, but the Hope Kit helps you look beyond them.',
          },
          '2': {
            correct: true,
            text: '',
          },
        },
        afterAll:
          'The Hope Kit can help you see reasons for living more clearly. It helps Priyanka see the good she’s already had in her life, as well as see the hope in her future.',
      },
    },
  ],
};

//Session 3 - Getting going
//Videos in this folder - https://drive.google.com/drive/folders/1pnSIdlUX_29ZY9dZ96xSv3ce5CF8OmSp?usp=sharing

export const GETTING_GOING_LEARN_VIGNETTE_PRIYANKA: SetData = {
  ID: 'getting-going-learn-vignette-priyanka',
  name: 'Priyanka',
  description: 'Priyanka: 35, female, Indian-American, depressed, physical disability',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S3/Priyanka_S3A_220412_720.mp4',
        ),
        title: 'What Priyanka does to get going',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'It can be helpful to just do something, even if it’s small.',
          '1': 'You have to spend the day doing as many things as possible for it to be helpful.',
          '2': 'It is most helpful if you do new things in exciting places.',
        },
      },
      dir: {
        prompt: 'What is something important to remember about “getting going”?',
        feedback: {
          '0': {
            correct: true,
            text: '',
          },
          '1': {
            correct: false,
            text: 'It is alright if you only get a little bit done, as long as you’re “getting going”.',
          },
          '2': {
            correct: false,
            text: 'It doesn’t matter what you choose to do, as long as you’re doing something.',
          },
        },
        afterAll:
          'Priyanka realized that she didn`t have to do a lot to “get going,” even something simple like getting dressed or doing PT exercises was enough to make her feel better.',
      },
    },

    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S3/Priyanka_S3B_220412_720.mp4',
        ),
        title: 'How Priyanka makes sure to get going',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Thinking about all the things she will do',
          '1': 'Talking to friends about what she is going to do',
          '2': 'Giving herself no option but to do it',
        },
      },
      dir: {
        prompt: 'What helps Priyanka get going?',
        feedback: {
          '0': {
            correct: false,
            text: '',
          },
          '1': {
            correct: false,
            text: '',
          },
          '2': {
            correct: true,
            text: '',
          },
        },
        afterAll:
          'Priyanka creates urgency to get going, and tells herself that she will feel better and more motivated if she gets going. She gives herself no other option.',
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S3/Priyanka_S3C_220412_720.mp4',
        ),
        title: 'How getting going reduces risk',
        compulsory: true,
      },
    },
  ],
};

export const GETTING_GOING_APPLY_VIGNETTE_PRIYANKA: SetData = {
  ID: 'getting-going-apply-vignette-priyanka',
  name: 'Priyanka',
  description: 'Priyanka: 35, female, Indian-American, depressed, physical disability',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S3/Priyanka_S3D_220412_720.mp4',
        ),
        title: 'Getting going example',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S3/Priyanka_S3E_220412_720.mp4',
        ),
        title: 'How use activity diary',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S3/Priyanka_S3F_220412_720.mp4',
        ),
        title: 'How activity diary helps',
        compulsory: true,
      },
    },
  ],
};

//Session 4 - Relaxation & Mindfulness
//Videos in this folder - https://drive.google.com/drive/folders/1shdaLyMQu3RyhHXCE6f8e8ULLSdGGFwv?usp=sharing

export const RELAXATION_MINDFULNESS_LEARN_VIGNETTE_PRIYANKA: SetData = {
  ID: 'relaxation-mindfulness-learn-vignette-priyanka',
  name: 'Priyanka',
  description: 'Priyanka: 35, female, Indian-American, depressed, physical disability',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S4/Priyanka_S4A_220412_720.mp4',
        ),
        title: 'When use it',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S4/Priyanka_S4B_220412_720.mp4',
        ),
        title: 'How it has helped',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'You only should practice these techniques once a week.',
          '1': 'These techniques can reduce stress when you feel worked up.',
          '2': 'It should be obvious how relaxation and mindfulness can help you.',
        },
      },
      dir: {
        prompt: 'Which of these is true about practicing relaxation and mindfulness?',
        feedback: {
          '0': {
            correct: false,
            text: '',
          },
          '1': {
            correct: true,
            text: '',
          },
          '2': {
            correct: false,
            text: '',
          },
        },
        afterAll:
          'It helps to make a habit of practicing relaxation and mindfulness, especially when you’re struggling during the day. It can aid with regulating tough emotions.',
      },
    },
  ],
};

export const RELAXATION_MINDFULNESS_APPLY_VIGNETTE_PRIYANKA: SetData = {
  ID: 'relaxation-mindfulness-apply-vignette-priyanka',
  name: 'Priyanka',
  description: 'Priyanka: 35, female, Indian-American, depressed, physical disability',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S4/Priyanka_S4C_220412_720.mp4',
        ),
        title: 'Apply Relaxation & Mindfulness',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S4/Priyanka_S4D_220412_720.mp4',
        ),
        title: 'Apply Relaxation & Mindfulness',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S4/Priyanka_S4E_220412_720.mp4',
        ),
        title: 'Apply Relaxation & Mindfulness',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'True',
          '1': 'False',
        },
      },
      dir: {
        prompt:
          'It is pointless to try relaxation or mindfulness unless you do it perfectly, without messing up. True or false?',
        feedback: {
          '0': {
            correct: false,
            text: 'You can still get a lot out of relaxation and mindfulness, even if you don’t get the hang of it right away. Trying is the most important thing.',
          },
          '1': {
            correct: true,
            text: 'You can still get a lot out of relaxation and mindfulness, even if you don’t get the hang of it right away. Trying is the most important thing.',
          },
        },
      },
    },
  ],
};

//Session 5 - Improving Sleep
//Videos in this folder - https://drive.google.com/drive/folders/1HzqpffLg0W0sCGZUb5fBmIdoL2LaUAGm?usp=sharing

export const SLEEP_DIARY_LEARN_VIGNETTE_PRIYANKA: SetData = {
  ID: 'sleep-diary-learn-vignette-priyanka',
  name: 'Priyanka',
  description: 'Priyanka: 35, female, Indian-American, depressed, physical disability',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S5/Priyanka_S5A_220412_720.mp4',
        ),
        title: 'Improving sleep',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S5/Priyanka_S5B_220412_720.mp4',
        ),
        title: 'Improving sleep',
        compulsory: true,
      },
    },

    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Watch TV before bed.',
          '1': 'Relax in bed after you have woken up in the morning.',
          '2': 'Implement a healthy bedtime routine.',
        },
      },
      dir: {
        prompt: 'What is something that you can do during the day to improve your sleep?',
        feedback: {
          '0': {
            correct: false,
            text: 'Being plugged into screens before bed can make it harder to fall asleep.',
          },
          '1': {
            correct: false,
            text: 'Spending a lot of time relaxing in bed can make it harder for your body to recognize that it should be sleeping in bed.',
          },
          '2': {
            correct: true,
            text: '',
          },
        },
        afterAll: 'Implementing a healthy bedtime routine can help improve your sleep quality.',
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S5/Priyanka_S5C_220412_720.mp4',
        ),
        title: 'Improving sleep',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Better sleep prevents all bad situations from happening.',
          '1': 'More sleep stops you from thinking negative thoughts.',
          '2': 'With better sleep, you can think clearer and cope better with issues.',
        },
      },
      dir: {
        prompt: 'How can changing sleep habits reduce risk?',
        feedback: {
          '0': {
            correct: false,
            text: 'Better sleep can’t prevent all bad situations.',
          },
          '1': {
            correct: false,
            text: 'More sleep doesn’t directly stop negative thoughts.',
          },
          '2': {
            correct: true,
            text: '',
          },
        },
        afterAll:
          'Better sleep habits improves sleep quality. And when you’re well rested, you will cope better with everyday tasks and problems, lowering your suicide risk.',
      },
    },
  ],
};

export const SLEEP_DIARY_APPLY_VIGNETTE_PRIYANKA: SetData = {
  ID: 'sleep-diary-apply-vignette-priyanka',
  name: 'Priyanka',
  description: 'Priyanka: 35, female, Indian-American, depressed, physical disability',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S5/Priyanka_S5D_220412_720.mp4',
        ),
        title: 'Apply Sleep Diary',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S5/Priyanka_S5E_220412_720.mp4',
        ),
        title: 'Apply Sleep Diary',
        compulsory: true,
      },
    },
  ],
};

//Session 6 - Spot It
//Videos in this folder -https://drive.google.com/drive/folders/1fSh3Vl00FuPVZQaByG1TYoAasx1T5-cb?usp=sharing

export const SPOT_IT_LEARN_VIGNETTE_PRIYANKA: SetData = {
  ID: 'spot-it-learn-vignette-priyanka',
  name: 'Priyanka',
  description: 'Priyanka: 35, female, Indian-American, depressed, physical disability',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S6/Priyanka_S6A_220412_720.mp4',
        ),
        title: 'Improving sleep',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S6/Priyanka_S6B_220412_720.mp4',
        ),
        title: 'Improving sleep',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Thoughts about what you should get done during the day.',
          '1': 'Unhelpful thought patterns that make you feel bad about yourself and the world.',
          '2': 'Every single thought that you have in a day.',
        },
      },
      dir: {
        prompt: 'What kinds of thoughts are good to “spot”?',
        feedback: {
          '0': {
            correct: false,
            text: 'Just thinking about the tasks you have to do might not always lead to unhelpful thoughts or emotions.',
          },
          '1': {
            correct: true,
            text: '',
          },
          '2': {
            correct: false,
            text: 'It may be overwhelming to review every thought that you have. Instead, it may be helpful to identify situations where you tend to have unhelpful thoughts.',
          },
        },
        afterAll:
          'It takes a little practice to figure out which thoughts to ‘spot’. Try to focus on thoughts that are unhelpful or not realistic.',
      },
    },

    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S6/Priyanka_S6C_220412_720.mp4',
        ),
        title: 'Improving sleep',
        compulsory: true,
      },
    },

    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'It helps you ignore your emotions.',
          '1': 'It proves that all negative thoughts are true.',
          '2': 'You can see how your negative thoughts affect how you feel.',
        },
      },
      dir: {
        prompt: 'How does suicide risk go down using `Spot It`?',
        feedback: {
          '0': {
            correct: false,
            text: '',
          },
          '1': {
            correct: false,
            text: '',
          },
          '2': {
            correct: true,
            text: '',
          },
        },
        afterAll:
          'Knowing that difficult feelings can start with unhelpful thoughts, Priyanka can start to avoid the spiral unhelpful thoughts can cause.',
      },
    },
  ],
};

export const SPOT_IT_APPLY_VIGNETTE_PRIYANKA: SetData = {
  ID: 'spot-it-apply-vignette-priyanka',
  name: 'Priyanka',
  description: 'Priyanka: 35, female, Indian-American, depressed, physical disability',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S6/Priyanka_S6D_220412_720.mp4',
        ),
        title: 'Apply Spot it',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S6/Priyanka_S6E_220412_720.mp4',
        ),
        title: 'Apply Spot it',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S6/Priyanka_S6F_220412_720.mp4',
        ),
        title: 'Apply Spot it',
        compulsory: true,
      },
    },
  ],
};

//Session 7 - Test It
//Videos in this folder - https://drive.google.com/drive/folders/1fjGLFkwLY6swOSg5MJyYw46k2ABV0LsV?usp=sharing

export const TEST_IT_LEARN_VIGNETTE_PRIYANKA: SetData = {
  ID: 'test-it-learn-vignette-priyanka',
  name: 'Priyanka',
  description: 'Priyanka: 35, female, Indian-American, depressed, physical disability',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S7/Priyanka_S7A_220412_720.mp4',
        ),
        title: 'Test it example',
        compulsory: true,
      },
    },

    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'They are always true.',
          '1': 'She tends to jump to the worst case conclusion.',
          '2': 'Her unhelpful thoughts are easy to change.',
        },
      },
      dir: {
        prompt: 'What did Priyanka find out about her thoughts?',
        feedback: {
          '0': {
            correct: false,
            text: 'Priyanka started to find through testing that her negative thoughts might not be true or realistic.',
          },
          '1': {
            correct: true,
            text: '',
          },
          '2': {
            correct: false,
            text: 'Priyanka couldn’t change her thoughts right away, but working on testing them has helped her see things differently.',
          },
        },
        afterAll:
          'Priyanka realized her negative thoughts were hurtful to herself. She’d been telling herself many of them for quite a while, and they often didn’t make sense.',
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S7/Priyanka_S7B_220412_720.mp4',
        ),
        title: 'Test it example',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Events in life will stop going wrong.',
          '1': 'It helps you avoid thinking about certain things completely.',
          '2': 'It helps you to test thoughts and remember what’s true and not true.',
        },
      },
      dir: {
        prompt: 'How can using `Test It` lower suicide risk?',
        feedback: {
          '0': {
            correct: false,
            text: 'Things will still go wrong in life.',
          },
          '1': {
            correct: true,
            text: 'It’s not really possible to avoid thoughts.',
          },
          '2': {
            correct: false,
            text: '',
          },
        },
        afterAll:
          'Testing It helps you to think more realistically. Not all thoughts are true or unhelpful. Knowing how to test her thoughts helps Priyanka avoid holding onto these types of thoughts.',
      },
    },
  ],
};

export const TEST_IT_APPLY_VIGNETTE_PRIYANKA: SetData = {
  ID: 'test-it-apply-vignette-priyanka',
  name: 'Priyanka',
  description: 'Priyanka: 35, female, Indian-American, depressed, physical disability',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S7/Priyanka_S7C_220404_720.mp4',
        ),
        title: 'Test it practice',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S7/Priyanka_S7D_220404_720.mp4',
        ),
        title: 'Test it practice',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S7/Priyanka_S7E_220404_720.mp4',
        ),
        title: 'Test it practice',
        compulsory: true,
      },
    },
  ],
};

//Session 8 - Switch It
//Videos in this folder - https://drive.google.com/drive/folders/1-QEsdjDpqS2A58rwNZvgNwEE2sYv_tdT?usp=sharing

export const SWITCH_IT_LEARN_VIGNETTE_PRIYANKA: SetData = {
  ID: 'switch-it-learn-vignette-priyanka',
  name: 'Priyanka',
  description: 'Priyanka: 35, female, Indian-American, depressed, physical disability',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S8/Priyanka_S8A_220412_720.mp4',
        ),
        title: 'Switch it example',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S8/Priyanka_S8B_220412_720.mp4',
        ),
        title: 'Switch it example',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Writing it down.',
          '1': 'Distract yourself from it. ',
          '2': 'Take context into account with your thought.',
        },
      },
      dir: {
        prompt: 'What is one way to “switch” a thought?',
        feedback: {
          '0': {
            correct: false,
            text: 'Just writing a thought down doesn’t help switch it. ',
          },
          '1': {
            correct: false,
            text: 'Distracting yourself doesn’t help you switch your thoughts.',
          },
          '2': {
            correct: true,
            text: '',
          },
        },
        afterAll:
          'Priyanka found that her thinking is often not accurate. But by looking at the broader context, she is able to switch to thoughts which are more holistic and balanced.',
      },
    },

    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S8/Priyanka_S8C_220412_720.mp4',
        ),
        title: 'Switch it example',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'It helps you think more realistically.',
          '1': 'Your thoughts are happier.',
          '2': 'It stops suicidal thoughts completely.',
        },
      },
      dir: {
        prompt: 'How does “switching” thoughts reduce risk for suicide?',
        feedback: {
          '0': {
            correct: true,
            text: '',
          },
          '1': {
            correct: false,
            text: 'It’s okay that thoughts are not always happy.',
          },
          '2': {
            correct: false,
            text: 'Switching thoughts may not completely stop suicdal thoughts. But they help reduce the risk.',
          },
        },
        afterAll:
          'Changing thoughts to be more balanced helps avoid negative emotion spirals. This then helps lower suicide risk.',
      },
    },
  ],
};

export const SWITCH_IT_APPLY_VIGNETTE_PRIYANKA: SetData = {
  ID: 'switch-it-apply-vignette-priyanka',
  name: 'Priyanka',
  description: 'Priyanka: 35, female, Indian-American, depressed, physical disability',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S8/Priyanka_S8D_220412_720.mp4',
        ),
        title: 'Switch it practice',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S8/Priyanka_S8E_220412_720.mp4',
        ),
        title: 'Switch it practice',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S8/Priyanka_S8F_220412_720.mp4',
        ),
        title: 'Switch it practice',
        compulsory: true,
      },
    },
  ],
};

//Session 9 - Coping Cards
//Videos in this folder - https://drive.google.com/drive/folders/1E3T1w3RNAcglpLZkFkY4DadxLFFUIvT6?usp=sharing

export const COPING_CARDS_LEARN_VIGNETTE_PRIYANKA: SetData = {
  ID: 'coping-cards-learn-vigenette-priyanka',
  name: 'Priyanka',
  description: 'Priyanka: 35, female, Indian-American, depressed, physical disability',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S9/Priyanka_S9A_220412_720.mp4',
        ),
        title: 'CC experience',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S9/Priyanka_S9B_220412_720.mp4',
        ),
        title: 'CC reduce SR',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'They help you review every single small detail.',
          '1': 'They give you reminders of ways to cope with tough situations.',
          '2': 'You can write down all of your thoughts.',
        },
      },
      dir: {
        prompt: 'How do Coping Cards help reduce suicide risk?',
        feedback: {
          '0': {
            correct: false,
            text: 'The point isn’t just to review all of the details, but to build coping skills.',
          },
          '1': {
            correct: true,
            text: '',
          },
          '2': {
            correct: false,
            text: 'Coping Cards aren’t the same as a diary or journal.',
          },
        },
        afterAll:
          'Coping Cards help you deal with stressful situations and unhelpful thoughts. They help by giving you quick reminders of how you can handle the problem.',
      },
    },
  ],
};

export const COPING_CARDS_APPLY_VIGNETTE_PRIYANKA: SetData = {
  ID: 'coping-cards-apply-vigenette-priyanka',
  name: 'Priyanka',
  description: 'Priyanka: 35, female, Indian-American, depressed, physical disability',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S9/Priyanka_S9C_220412_720.mp4',
        ),
        title: 'How start CC',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S9/Priyanka_S9D_220412_720.mp4',
        ),
        title: 'CC more eg',
        compulsory: true,
      },
    },
  ],
};

//Session 10 - RC & MyPlan Review
//Videos in this folder - https://drive.google.com/drive/folders/1rLg3xEVDtjc90ukXOVxVyEtTg8-GlZ2Q?usp=sharing

export const RISK_CURVE_MYPLAN_REVIEW_LEARN_VIGNETTE_PRIYANKA: SetData = {
  ID: 'risk-curve-myplan-review-learn-vignette-priyanka',
  name: 'Priyanka',
  description: 'Priyanka: 35, female, Indian-American, depressed, physical disability',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S10/Priyanka_S10A_220412_720.mp4',
        ),
        title: 'RC experi',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S10/Priyanka_S10B_220412_720.mp4',
        ),
        title: 'MP experi',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Skills learned in Aviva were to reduce her suicide risk.',
          '1': 'She should never change her MyPlan.',
          '2': 'Warning signs never change.',
        },
      },
      dir: {
        prompt: 'What did Priyanka realize when reviewing the Risk Curve and MyPlan?',
        feedback: {
          '0': {
            correct: true,
            text: '',
          },
          '1': {
            correct: false,
            text: '',
          },
          '2': {
            correct: false,
            text: '',
          },
        },
        afterAll:
          'The review helped Priyanka see how skills she learned in Aviva directly helped her lower her risk. She remembered that if her warning signs do change, she should update her MyPlan.',
      },
    },
  ],
};

//Session 11 - Reduce Risk
//Videos in this folder - https://drive.google.com/drive/folders/1BVoCMhM1DK2CUmqPbW25BHBMNqNh65RC?usp=sharing

export const REDUCE_RISK_LEARN_VIGNETTE_PRIYANKA: SetData = {
  ID: 'reduce-risk-learn-vignette-priyanka',
  name: 'Priyanka',
  description: 'Priyanka: 35, female, Indian-American, depressed, physical disability',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S11/Priyanka_S11A_220412_720.mp4',
        ),
        title: 'Rev skills exp',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S11/Priyanka_S11B_220412_720.mp4',
        ),
        title: 'Most helpful',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Completely overwhelmed',
          '1': 'Proud of herself and ready to use them',
          '2': 'Not sure how they will help',
        },
      },
      dir: {
        prompt: 'How does Priyanka feel after reviewing the skills?',
        feedback: {
          '0': {
            correct: false,
            text: '',
          },
          '1': {
            correct: true,
            text: '',
          },
          '2': {
            correct: false,
            text: '',
          },
        },
        afterAll:
          'Priyanka knows how she can use what she learned to deal with future challenges in life. If she gets stuck, she can always go back to the app and practice more.',
      },
    },
  ],
};

//Session 12 - Post-Aviva
//Videos in this folder - https://drive.google.com/drive/folders/1h8ZgscxL81063edKsRZhJRXxoMqmI3kq?usp=sharing

export const POSTAVIVA_LEARN_VIGNETTE_PRIYANKA: SetData = {
  ID: 'postaviva-learn-vignette-priyanka',
  name: 'Priyanka',
  description: 'Priyanka: 35, female, Indian-American, depressed, physical disability',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S12/Priyanka_S12A_220412_720.mp4',
        ),
        title: 'How feel',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Continue doing the ‘changing thoughts’ exercises.',
          '1': 'Look into other resources, like individual therapy or support groups.',
          '2': 'All of the above',
        },
      },
      dir: {
        prompt: 'What could be a next step after finishing Aviva?',
        feedback: {
          '0': {
            correct: false,
            text: '',
          },
          '1': {
            correct: false,
            text: '',
          },
          '2': {
            correct: true,
            text: '',
          },
        },
        afterAll:
          'It is a good idea to keep using skills from Aviva, even after you have finished. Looking into other resources after Aviva is a great way to continue making progress as well.',
      },
    },
  ],
};
