import { createElement, FunctionComponent } from 'react';
import { TouchableOpacity } from 'react-native';
import { SvgProps } from 'react-native-svg';

import { Pill } from '../components/Pill';
import { SvgUri } from '../components/SvgUri';
import { Text } from '../components/Text';
import { View } from '../components/View';
import { useI18n } from '../lib/i18n';
import { Shadow, useTheme } from '../styles';

type Props = {
  /**
   * A string if referencing a hosted GCS asset, otherwise an Svg illustration component.
   */
  assetName: string | FunctionComponent<SvgProps & { 'aria-label': string | undefined }>;
  backgroundColor?: string;
  bannerText?: string;
  title: string;
  testID: string;
  shadow?: boolean;
} & (
  | {
      /**
       * If locked, the button is not pressable
       */
      locked: true;
      badgeText?: string;
      onPress?: () => void;
    }
  | {
      locked: false;
      badgeText: string;
      /**
       * Called when the button is pressed
       */
      onPress: () => void;
    }
);

export function ArtifactButton({
  assetName,
  backgroundColor,
  bannerText,
  badgeText,
  locked,
  onPress,
  title,
  testID,
  shadow = true,
}: Props) {
  const { $t } = useI18n();
  const { theme, scheme } = useTheme();
  const unlockedBackgroundColor = backgroundColor ?? (scheme === 'dark' ? 'white' : '#ebf1ed');
  return (
    <TouchableOpacity
      role="button"
      testID={testID}
      onPress={onPress}
      disabled={locked}
      aria-disabled={!!locked}
      style={[
        {
          borderRadius: 20,
          backgroundColor: locked ? theme.color.gray800 : unlockedBackgroundColor,
        },
        locked || !shadow ? null : Shadow.high,
      ]}
    >
      <View
        childFlex={1}
        row
        style={{
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            alignItems: 'flex-start',
            alignSelf: 'flex-end',
            borderBottomStartRadius: 20,
            overflow: 'hidden',
          }}
        >
          <View
            style={{
              borderBottomStartRadius: 20,
              borderTopStartRadius: 20,
              opacity: locked ? 0.1 : undefined,
              overflow: 'hidden',
            }}
          >
            {typeof assetName === 'string' ? (
              <SvgUri
                uri={
                  assetName.startsWith('https://')
                    ? assetName
                    : `https://storage.googleapis.com/asset.oui.dev/static/artifacts/${assetName}.svg`
                }
                height={100}
                preserveAspectRatio="xMinYMax meet"
              />
            ) : (
              createElement(assetName, {
                height: 100,
                preserveAspectRatio: 'xMinYMax meet',
                'aria-label': undefined,
              })
            )}
          </View>
          {bannerText ? (
            <View
              style={{
                backgroundColor: 'white',
                bottom: 10,
                left: 0,
                padding: 5,
                position: 'absolute',
                right: 0,
                alignItems: 'center',
              }}
            >
              <Text text={bannerText} size={13} style={{ textAlign: 'center' }} />
            </View>
          ) : null}
        </View>
        <View
          spacing={8}
          style={{
            paddingHorizontal: 20,
            paddingVertical: 16,
            alignItems: 'center',
            height: 100,
          }}
        >
          <Text
            text={title}
            size={17}
            lineHeight={22}
            weight="semibold"
            style={{ textAlign: 'center' }}
            color={locked ? theme.color.gray400 : undefined}
          />
          {locked ? (
            <Pill
              icon="lock"
              text={$t({ id: 'ArtifactButton_locked', defaultMessage: 'Locked' })}
              color={theme.color.gray400}
            />
          ) : (
            <Pill text={badgeText} />
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
}
