/* eslint-disable */
/* This file is autogenerated by @oui/cli */
function interopDefault(module: any) {
  return module['default'] || module;
}
export default {
  'ai': interopDefault(require('./ai.svg')),
'alcohol': interopDefault(require('./alcohol.svg')),
'almonds': interopDefault(require('./almonds.svg')),
'apple': interopDefault(require('./apple.svg')),
'approve': interopDefault(require('./approve.svg')),
'apps': interopDefault(require('./apps.svg')),
'arrow-down': interopDefault(require('./arrow-down.svg')),
'arrow-left': interopDefault(require('./arrow-left.svg')),
'arrow-right': interopDefault(require('./arrow-right.svg')),
'arrow-up': interopDefault(require('./arrow-up.svg')),
'audio-mute': interopDefault(require('./audio-mute.svg')),
'audio': interopDefault(require('./audio.svg')),
'bad-thought': interopDefault(require('./bad-thought.svg')),
'banana': interopDefault(require('./banana.svg')),
'beaker': interopDefault(require('./beaker.svg')),
'bin': interopDefault(require('./bin.svg')),
'blind-fold': interopDefault(require('./blind-fold.svg')),
'book-head': interopDefault(require('./book-head.svg')),
'book': interopDefault(require('./book.svg')),
'boxing-glove': interopDefault(require('./boxing-glove.svg')),
'breath-hold': interopDefault(require('./breath-hold.svg')),
'breathe': interopDefault(require('./breathe.svg')),
'building-double': interopDefault(require('./building-double.svg')),
'building-single': interopDefault(require('./building-single.svg')),
'bulleted-list': interopDefault(require('./bulleted-list.svg')),
'calendar': interopDefault(require('./calendar.svg')),
'camera': interopDefault(require('./camera.svg')),
'cards': interopDefault(require('./cards.svg')),
'caret-down': interopDefault(require('./caret-down.svg')),
'caret-filled-down': interopDefault(require('./caret-filled-down.svg')),
'caret-filled-right': interopDefault(require('./caret-filled-right.svg')),
'caret-filled-up-down': interopDefault(require('./caret-filled-up-down.svg')),
'caret-left': interopDefault(require('./caret-left.svg')),
'caret-right': interopDefault(require('./caret-right.svg')),
'caret-up': interopDefault(require('./caret-up.svg')),
'chat-double': interopDefault(require('./chat-double.svg')),
'check-in': interopDefault(require('./check-in.svg')),
'check': interopDefault(require('./check.svg')),
'choice': interopDefault(require('./choice.svg')),
'click-check': interopDefault(require('./click-check.svg')),
'clock': interopDefault(require('./clock.svg')),
'close': interopDefault(require('./close.svg')),
'closed-captioning-selected': interopDefault(require('./closed-captioning-selected.svg')),
'closed-captioning': interopDefault(require('./closed-captioning.svg')),
'cloud-offline': interopDefault(require('./cloud-offline.svg')),
'cloud-upload': interopDefault(require('./cloud-upload.svg')),
'coffee': interopDefault(require('./coffee.svg')),
'collapse': interopDefault(require('./collapse.svg')),
'colors': interopDefault(require('./colors.svg')),
'comment': interopDefault(require('./comment.svg')),
'compulsion': interopDefault(require('./compulsion.svg')),
'config': interopDefault(require('./config.svg')),
'connect': interopDefault(require('./connect.svg')),
'content': interopDefault(require('./content.svg')),
'copy': interopDefault(require('./copy.svg')),
'crisis-peak': interopDefault(require('./crisis-peak.svg')),
'curve-falling': interopDefault(require('./curve-falling.svg')),
'curve-peak': interopDefault(require('./curve-peak.svg')),
'curve-rising': interopDefault(require('./curve-rising.svg')),
'dashboard': interopDefault(require('./dashboard.svg')),
'decrease': interopDefault(require('./decrease.svg')),
'diary': interopDefault(require('./diary.svg')),
'distress': interopDefault(require('./distress.svg')),
'download': interopDefault(require('./download.svg')),
'earth': interopDefault(require('./earth.svg')),
'easier': interopDefault(require('./easier.svg')),
'eating-log': interopDefault(require('./eating-log.svg')),
'edit': interopDefault(require('./edit.svg')),
'email': interopDefault(require('./email.svg')),
'exchange': interopDefault(require('./exchange.svg')),
'exercise': interopDefault(require('./exercise.svg')),
'expand': interopDefault(require('./expand.svg')),
'external': interopDefault(require('./external.svg')),
'eye': interopDefault(require('./eye.svg')),
'face-bad': interopDefault(require('./face-bad.svg')),
'face-good': interopDefault(require('./face-good.svg')),
'face-great': interopDefault(require('./face-great.svg')),
'face-okay': interopDefault(require('./face-okay.svg')),
'face-terrible': interopDefault(require('./face-terrible.svg')),
'filter': interopDefault(require('./filter.svg')),
'flag-mountain': interopDefault(require('./flag-mountain.svg')),
'flame': interopDefault(require('./flame.svg')),
'flip': interopDefault(require('./flip.svg')),
'full-size': interopDefault(require('./full-size.svg')),
'goal': interopDefault(require('./goal.svg')),
'good-thought': interopDefault(require('./good-thought.svg')),
'graduation': interopDefault(require('./graduation.svg')),
'grid': interopDefault(require('./grid.svg')),
'group': interopDefault(require('./group.svg')),
'hamburger': interopDefault(require('./hamburger.svg')),
'hand-stop': interopDefault(require('./hand-stop.svg')),
'history': interopDefault(require('./history.svg')),
'home': interopDefault(require('./home.svg')),
'image': interopDefault(require('./image.svg')),
'in-progress': interopDefault(require('./in-progress.svg')),
'increase': interopDefault(require('./increase.svg')),
'info': interopDefault(require('./info.svg')),
'input': interopDefault(require('./input.svg')),
'jumping': interopDefault(require('./jumping.svg')),
'kit': interopDefault(require('./kit.svg')),
'ladder': interopDefault(require('./ladder.svg')),
'later': interopDefault(require('./later.svg')),
'light-blub': interopDefault(require('./light-blub.svg')),
'link': interopDefault(require('./link.svg')),
'location': interopDefault(require('./location.svg')),
'lock': interopDefault(require('./lock.svg')),
'markdown': interopDefault(require('./markdown.svg')),
'meal': interopDefault(require('./meal.svg')),
'mic': interopDefault(require('./mic.svg')),
'minus': interopDefault(require('./minus.svg')),
'mobile-phone': interopDefault(require('./mobile-phone.svg')),
'mood-after': interopDefault(require('./mood-after.svg')),
'mood-before': interopDefault(require('./mood-before.svg')),
'mood': interopDefault(require('./mood.svg')),
'moon': interopDefault(require('./moon.svg')),
'more': interopDefault(require('./more.svg')),
'move-exchanges': interopDefault(require('./move-exchanges.svg')),
'my-plan': interopDefault(require('./my-plan.svg')),
'next': interopDefault(require('./next.svg')),
'notification': interopDefault(require('./notification.svg')),
'obsession': interopDefault(require('./obsession.svg')),
'paste': interopDefault(require('./paste.svg')),
'pause': interopDefault(require('./pause.svg')),
'people': interopDefault(require('./people.svg')),
'person': interopDefault(require('./person.svg')),
'phone': interopDefault(require('./phone.svg')),
'pill': interopDefault(require('./pill.svg')),
'pin': interopDefault(require('./pin.svg')),
'play': interopDefault(require('./play.svg')),
'plus': interopDefault(require('./plus.svg')),
'point': interopDefault(require('./point.svg')),
'post-open': interopDefault(require('./post-open.svg')),
'post-ready': interopDefault(require('./post-ready.svg')),
'practice': interopDefault(require('./practice.svg')),
'print': interopDefault(require('./print.svg')),
'publish': interopDefault(require('./publish.svg')),
'push-notifications': interopDefault(require('./push-notifications.svg')),
'quiz': interopDefault(require('./quiz.svg')),
'quotemarks': interopDefault(require('./quotemarks.svg')),
'refresh': interopDefault(require('./refresh.svg')),
'relax': interopDefault(require('./relax.svg')),
'reorder-1': interopDefault(require('./reorder-1.svg')),
'reorder': interopDefault(require('./reorder.svg')),
'reply': interopDefault(require('./reply.svg')),
'retry': interopDefault(require('./retry.svg')),
'risk-high': interopDefault(require('./risk-high.svg')),
'risk-low': interopDefault(require('./risk-low.svg')),
'risk-med': interopDefault(require('./risk-med.svg')),
'sandwich': interopDefault(require('./sandwich.svg')),
'script': interopDefault(require('./script.svg')),
'search': interopDefault(require('./search.svg')),
'section': interopDefault(require('./section.svg')),
'send': interopDefault(require('./send.svg')),
'sessions': interopDefault(require('./sessions.svg')),
'settings': interopDefault(require('./settings.svg')),
'share': interopDefault(require('./share.svg')),
'shield': interopDefault(require('./shield.svg')),
'skip-in-session': interopDefault(require('./skip-in-session.svg')),
'sleep-bed': interopDefault(require('./sleep-bed.svg')),
'slider': interopDefault(require('./slider.svg')),
'smoking': interopDefault(require('./smoking.svg')),
'specialized-exchange': interopDefault(require('./specialized-exchange.svg')),
'square-cube': interopDefault(require('./square-cube.svg')),
'star-selected': interopDefault(require('./star-selected.svg')),
'star': interopDefault(require('./star.svg')),
'start': interopDefault(require('./start.svg')),
'stats': interopDefault(require('./stats.svg')),
'stop-1': interopDefault(require('./stop-1.svg')),
'stop': interopDefault(require('./stop.svg')),
'sun': interopDefault(require('./sun.svg')),
'sunrise': interopDefault(require('./sunrise.svg')),
'sunset': interopDefault(require('./sunset.svg')),
'surf': interopDefault(require('./surf.svg')),
'switch': interopDefault(require('./switch.svg')),
'tag': interopDefault(require('./tag.svg')),
'teen': interopDefault(require('./teen.svg')),
'test': interopDefault(require('./test.svg')),
'text-card': interopDefault(require('./text-card.svg')),
'text-page': interopDefault(require('./text-page.svg')),
'text': interopDefault(require('./text.svg')),
'thumb-down': interopDefault(require('./thumb-down.svg')),
'thumb-up': interopDefault(require('./thumb-up.svg')),
'transcript': interopDefault(require('./transcript.svg')),
'trigger': interopDefault(require('./trigger.svg')),
'troubleshooting': interopDefault(require('./troubleshooting.svg')),
'ungroup': interopDefault(require('./ungroup.svg')),
'upload': interopDefault(require('./upload.svg')),
'variable': interopDefault(require('./variable.svg')),
'video-card': interopDefault(require('./video-card.svg')),
'video': interopDefault(require('./video.svg')),
'volcano': interopDefault(require('./volcano.svg')),
'warning': interopDefault(require('./warning.svg')),
'weight-scale': interopDefault(require('./weight-scale.svg')),
'widget': interopDefault(require('./widget.svg')),
'zz': interopDefault(require('./zz.svg')),
}