import { YstMentalHealthConcerns } from './ystMentalHealthConcerns';
import { YstOnboardingAvailability, YstRecurringAvailability } from './ystScheduler';

export type CompositionScalars = {
  YstMentalHealthConcerns: YstMentalHealthConcerns;
  YstOnboardingAvailability: YstOnboardingAvailability;
  YstRecurringAvailability: YstRecurringAvailability;
};

export { YstMentalHealthConcerns, YstOnboardingAvailability, YstRecurringAvailability };
