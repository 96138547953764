import { PartialTheme } from './styles';

export const avivaTheme = {
  color: {
    primary100: '#5F277E',
    primary200: '#C077E3',
    primary300: '#EDD7F7',
    dark: '#202E53',
    accent100: '#47BFAF',
    accent200: '#A2DED6',
    accent300: '#DAF2EF',
    accentTwo100: '#B2E6F2',
    accentTwo200: '#FFD856',
    accentTwo300: '#FFF4CD',
    accentThree100: '#FEC344',
    accentThree200: '#FFDD9A',
    accentThree300: '#FFEFCE',
  },
} satisfies PartialTheme;
