import { Component, ComponentProps, ReactNode, Ref, RefCallback, useCallback, useRef } from 'react';
import { Platform, StyleProp, ViewStyle } from 'react-native';

import { useAccessibilityContext } from '../components/AccessibilityContext';
import { Text } from '../components/Text';
import { View } from '../components/View';
import { useTheme } from '../styles';

type AccessibleInputChildProps<T extends Component> = {
  'aria-label': string;
  accessibilityLabel: string;
  ref: RefCallback<T>;
  placeholder?: string;
};

export function useAccessibleInput<T extends Component>({
  error,
  label,
  placeholder,
  ref,
}: {
  error?: string;
  label?: string;
  placeholder?: string;
  ref?: Ref<T | null | undefined>;
}) {
  const innerRef = useRef<T | null | undefined>();
  const { isScreenReaderEnabled } = useAccessibilityContext();

  const refCallback = useCallback(
    (r: T | null) => {
      innerRef.current = r;
      if (ref) {
        if (typeof ref === 'function') {
          ref(r);
        } else if (ref) {
          // eslint-disable-next-line
          (ref as any).current = r;
        }
      }
    },
    [ref],
  );

  const ariaLabel = (label || placeholder || '').replace(/\*/g, '');

  return {
    'aria-label': ariaLabel,
    accessibilityLabel: ariaLabel, // some components haven't fully implemented aria-label support (e.g. iOS TextInput)
    ref: refCallback,
    // https://github.com/facebook/react-native/issues/26739
    placeholder: isScreenReaderEnabled && label && placeholder !== label ? undefined : placeholder,
  };
}

type AccessibleInputProps<T extends Component> = {
  children: (accessibilityProps: AccessibleInputChildProps<T>) => ReactNode;
  error?: string;
  forwardRef?: Ref<T | null | undefined>;
  hint?: string;
  hintTop?: string;
  placeholder?: string;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  labelColor?: string;
  labelSize?: number;
  labelHorizontal?: boolean | 'small';
  labelLineHeight?: number;
  labelAlign?: ComponentProps<typeof Text>['textAlign'];
  labelWeight?: ComponentProps<typeof Text>['weight'];
  required?: boolean;
  disabled?: boolean;
  onLabelLayout?: ComponentProps<typeof View>['onLayout'];
} & (
  | {
      label?: string;
      'aria-label'?: string;
    }
  | {
      label?: () => ReactNode;
      'aria-label': string;
    }
);

export function AccessibleInput<T extends Component>({
  'aria-label': ariaLabel,
  children,
  error,
  forwardRef,
  hint,
  hintTop,
  label,
  labelColor,
  labelSize,
  labelLineHeight,
  labelAlign,
  labelWeight,
  labelHorizontal,
  placeholder,
  style,
  testID,
  required,
  disabled,
  onLabelLayout,
}: AccessibleInputProps<T>) {
  const { theme } = useTheme();

  const accessible = useAccessibleInput({
    error,
    label: ariaLabel ?? (typeof label === 'string' ? label : ''),
    placeholder,
    ref: forwardRef,
  });

  if (labelHorizontal === 'small') {
    labelWeight = theme.typography.small.weight;
    labelColor = theme.typography.small.color ?? theme.color.gray400;
    labelSize = theme.typography.small.size;
  }

  return (
    <View
      style={[
        style,
        disabled
          ? {
              opacity: 0.5,
              cursor: Platform.OS === 'web' ? ('not-allowed' as 'pointer') : undefined, // make types happy
            }
          : undefined,
      ]}
    >
      <View row={!!labelHorizontal}>
        {label && typeof label === 'string' ? (
          <Text
            role="none"
            text=""
            style={[labelHorizontal ? { marginRight: 10 } : { marginLeft: 16, marginBottom: 5 }]}
            weight={labelWeight ?? theme.typography.label.weight}
            color={labelColor ?? theme.typography.label.color}
            size={labelSize ?? theme.typography.label.size}
            lineHeight={labelLineHeight ?? theme.typography.label.lineHeight}
            textAlign={labelAlign}
            onLayout={onLabelLayout}
          >
            {label.replace(/\*$/, '')}
            {label.endsWith('*') || required ? (
              <Text text=" *" color={theme.color.danger} weight="semibold" />
            ) : null}
          </Text>
        ) : typeof label === 'function' ? (
          <View style={{ marginLeft: 16 }} aria-hidden onLayout={onLabelLayout}>
            {label()}
          </View>
        ) : null}
        {hintTop ? (
          <Text
            text={hintTop}
            color={theme.color.gray400}
            size={13}
            style={{ marginLeft: 16, marginBottom: 5 }}
            testID={`${testID}_hintTop`}
          />
        ) : null}
        {children?.(accessible)}
      </View>
      {error ? (
        <Text
          text={error}
          style={{ marginLeft: 16, marginTop: 5 }}
          color={theme.color.danger}
          size={13}
          testID={`${testID}_error`}
        />
      ) : hint ? (
        <Text
          text={hint}
          color={theme.color.gray400}
          size={13}
          style={{ marginLeft: 16, marginTop: 5 }}
          testID={`${testID}_hint`}
        />
      ) : null}
    </View>
  );
}
