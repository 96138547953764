import type { SetData } from '../../content/quizSet/quizSetData';
import { getMuxUrlFromGCSUrl } from '../../muxUrl';

const MYSTORY_LEARN_VIGNETTE_BRIDGET: SetData = {
  ID: 'mystory-learn-vignette-bridget',
  name: 'Bridget',
  description: 'Bridget: 17 y.o. girl, high achiever, thinks she’s failing',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S1/Bridget_Session1_Suicide_Story_200401.mp4',
        ),
        title: "Bridget's Story",
        compulsory: true,
      },
    },
  ],
};

const RISK_CURVE_LEARN_VIGNETTE_BRIDGET_COLLAB: SetData = {
  ID: 'risk-curve-learn-vignette-bridget-collab',
  name: 'Bridget',
  description: 'Bridget: 17 y.o. girl, high achiever, thinks she’s failing',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S1/Bridget_Session1_Making_Sense_RC_200331.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'To relive a very difficult day again',
          '1': 'To learn about your own specific warning signs so that you can keep an eye on them and work to lower your risk',
          '2': 'You probably won’t need to learn about your own Risk Curve. Your warning signs are probably the same as Bridget’s warning signs.',
        },
      },
      dir: {
        prompt: 'Why is it important to learn about your own Risk Curve?',
        feedback: {
          '0': {
            correct: false,
            text: 'Though it may be difficult to spend time thinking about what happened before a suicide attempt, it is important to learn what pushes you up the Risk Curve.',
          },
          '1': {
            correct: true,
            text: 'By understanding what pushes you up the Risk Curve, you can keep an eye on warning signs specific to you and work to lower your risk.',
          },
          '2': {
            correct: false,
            text: 'What goes in a Risk Curve is actually unique to each person. It is important to learn about what things increase your risk and these things might be different than the things that increase Bridget’s risk.',
          },
        },
      },
    },
  ],
};

const RISK_CURVE_LEARN_VIGNETTE_BRIDGET: SetData = {
  ID: 'risk-curve-learn-vignette-bridget',
  name: 'Bridget',
  description: 'Session 1: Risk Curve',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S1/Bridget_Session1_Suicide_Story_200401.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S1/Bridget_Session1_Making_Sense_RC_200331.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'To relive a very difficult day again',
          '1': 'To learn about your own specific warning signs so that you can keep an eye on them and work to lower your risk',
          '2': 'You probably won’t need to learn about your own Risk Curve. Your warning signs are probably the same as Bridget’s warning signs.',
        },
      },
      dir: {
        prompt: 'Why is it important to learn about your own Risk Curve?',
        feedback: {
          '0': {
            correct: false,
            text: 'Though it may be difficult to spend time thinking about what happened before a suicide attempt, it is important to learn what pushes you up the Risk Curve.',
          },
          '1': {
            correct: true,
            text: 'By understanding what pushes you up the Risk Curve, you can keep an eye on warning signs specific to you and work to lower your risk.',
          },
          '2': {
            correct: false,
            text: 'What goes in a Risk Curve is actually unique to each person. It is important to learn about what things increase your risk and these things might be different than the things that increase Bridget’s risk.',
          },
        },
      },
    },
  ],
};

const MYPLAN_LEARN_VIGNETTE_BRIDGET: SetData = {
  ID: 'myplan-learn-vignette-bridget',
  name: 'Bridget',
  description: 'Bridget: 17 y.o. girl, high achiever, thinks she’s failing',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S1/Bridget_S1_How_MP_Helps_GFX.mp4',
        ),
        title: 'Risk Curve',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S1/Bridget_S1_Making_Environ_Safe_GFX.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'To make sure that Bridget has less independence because her parents don’t trust her',
          '1': 'It is not important. It is just part of the MyPlan form, so it needs to be filled out',
          '2': 'Because, sometimes, people need help to keep themselves safe ',
        },
      },
      dir: {
        prompt: 'Why is making the environment safe an important part of Bridget’s MyPlan?',
        feedback: {
          '0': {
            correct: false,
            text: 'Though Bridget’s parents are making sure that someone is always home with her, they are doing this to make sure that she has support around at all times. Making the environment safe is a way to help reduce risk because a teen has less access to things that they could use to hurt themselves and they also have more support around.',
          },
          '1': {
            correct: false,
            text: 'Every step that the Aviva app has you do has a purpose. There is no busy work here. Making the environment safe is a way to help reduce risk because a teen has less access to things that they could use to hurt themselves and they also have more support around.',
          },
          '2': {
            correct: true,
            text: 'Making the environment safe is a way to help reduce risk because a teen has less access to things that they could use to hurt themselves and they also have more support around.',
          },
        },
      },
    },
  ],
};

//Session 2 - Hope Kit
//Text & quizzes here - https://docs.google.com/document/d/1ABP_IuBrME8avt7cnE6G80ad3ZDS2HbTzQh2J3mbitc/edit
//All videos stored here - https://drive.google.com/drive/folders/11jSj5OZ4MowbfmPLSsZZarvfXIR1bfQQ?usp=sharing
const HOPE_KIT_BRIDGET: SetData = {
  ID: 'hope-kit-bridget-learn',
  name: 'Bridget',
  description: 'Bridget: 17 y.o. girl, high achiever, thinks she’s failing',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S2/Bridget_Session2a_How_Does_HK_Reduce_Risk_200401.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S2/Bridget_Session2b_What_Did_I_Include_In_HK_200401.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'True',
          '1': 'False',
        },
      },
      dir: {
        prompt:
          'True or false? You can include anything that you want in the Hope Kit that brings you joy or helps you look forward to things in the future.',
        feedback: {
          '0': {
            correct: true,
            text: 'The great thing about the Hope Kit is that you can pick anything at all that makes you feel hopeful.',
          },
          '1': {
            correct: false,
            text: 'A great thing about Hope Kit is that you can pick things that are specific to you that might make you feel hopeful. You can also add things to the Hope Kit over time!',
          },
        },
      },
    },
  ],
};

const HOPE_KIT_APPLY_BRIDGET: SetData = {
  ID: 'hope-kit-bridget-apply',
  name: 'Bridget',
  description: 'Bridget: 17 y.o. girl, high achiever, thinks she’s failing',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S2/Bridget_Session2c_Making_My_HK_200401.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S2/Bridget_Session2d_Using_My_HK_200401.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'It was a fun activity that she looked at one time',
          '1': 'It reminds her that life is worth living',
          '2': 'It gets rid of her bad thoughts completely',
        },
      },
      dir: {
        prompt: 'How has the Hope Kit helped Bridget?',
        feedback: {
          '0': {
            correct: false,
            text: 'The Hope Kit can be used daily! Looking at things that make her feel hopeful may help Bridget’s mood. Bridget can also add things to the Hope Kit over time.',
          },
          '1': {
            correct: true,
            text: 'When Bridget is having a rough day, it is hard for her to think of positive things in her life. The Hope Kit reminds her that there are things she has done that have helped her feel happy. It also reminds her that there are a lot of things that she is looking forward to.',
          },
          '2': {
            correct: false,
            text: 'Instead, it can help her direct her thoughts to other things. The Hope Kit will help Bridget remember the good things she has in her life, and what she can look forward to.',
          },
        },
      },
    },
  ],
};

//Session 3 - Get Going
//Text & quizzes here - https://docs.google.com/document/d/1SFkWWDGnMVGYlMpddaAK5zB9V55HvfJ49TqIwor3lck/edit?usp=sharing
//Videos store here - https://drive.google.com/drive/folders/1xVuI-RswV4HMZeDmsNmOeBihGVOC6_nV?usp=sharing

const GET_GOING_LEARN_BRIDGET: SetData = {
  ID: 'get-going-bridget-learn',
  name: 'Bridget',
  description: 'Bridget: 17 y.o. girl, high achiever, thinks she’s failing',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S3/Bridget_Session3a_Why_I_Need_To_Get_Going_200405.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S3/Bridget_Session3b_How_I_Make_Sure_I_Get_Going_200405.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Lying in bed and thinking about going on an epic vacation',
          '1': 'Thought about all the things she was missing out on by spending less time with friends',
          '2': 'Made her bed and brushed her hair',
        },
      },
      dir: {
        prompt: 'How did Bridget start to "get going"?',
        feedback: {
          '0': {
            correct: false,
            text: 'Having things to look forward to is great, but "getting going" includes actions and movement (making bed, brushing hair or having dinner with family).',
          },
          '1': {
            correct: false,
            text: 'These are not super helpful thoughts. "Getting going" includes actions and movement (making bed, brushing hair or having dinner with family).',
          },
          '2': {
            correct: true,
            text: 'Bridget "got going" by doing small things like making her bed and doing her hair. Though these are small steps, they are a great place to start!',
          },
        },
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S3/Bridget_Session3c_How_GG_Helped_Reduce_Risk_200405.mp4',
        ),
        compulsory: true,
      },
    },
  ],
};
const GET_GOING_APPLY_BRIDGET: SetData = {
  ID: 'get-going-bridget-apply',
  name: 'Bridget',
  description: 'Bridget: 17 y.o. girl, high achiever, thinks she’s failing',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S3/Bridget_Session3dGFX_How_To_Use_Activity_Diary_GFX_210428.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemMultipleChoiceQuestion',
      props: {
        label: {
          '0': 'Rewarding herself for doing an activity',
          '1': 'Reminding herself that "getting going" helps to improve her mood',
          '2': 'Holding herself accountable by asking a friend to meet her somewhere',
        },
      },
      dir: {
        prompt: 'What helps Bridget to "get going"?',
        feedback: {
          default: {
            correct: false,
            text: 'Giving herself a reward, reminding herself of the positive impact on her mood, and doing things with a friend all help Bridget to "get going."',
          },
          '["0","1","2"]': {
            correct: true,
            text: 'Giving herself a reward, reminding herself of the positive impact on her mood, and doing things with a friend all help Bridget to "get going."',
          },
        },
      },
    },
  ],
};

//Session 4 - Relaxation and Mindfulness
//Text & quizzes here - https://docs.google.com/document/d/1QpE4vOAx1AB3gV315d1RTOcHyNysduWAfOA20OYD9a0/edit?usp=sharing
//Videos store here - https://drive.google.com/drive/folders/1KIgQrA3wFks8TSpxHbvOZX5Z8A_GnF-T?usp=sharing

const RELAX_MINDFUL_LEARN_BRIDGET: SetData = {
  ID: 'relax-mindful-bridget-learn',
  name: 'Bridget',
  description: 'Bridget: 17 y.o. girl, high achiever, thinks she’s failing',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S4/Bridget_Session4a_What_Is_It_How_I_Use_It_200405.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S4/Bridget_Session4b_How_RM_Helped_Reduce_My_Risk_200405.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Bridget practices when she is feeling very emotional',
          '1': 'Bridget tries to practice every day by starting the day with relaxation or mindfulness',
          '2': 'Bridget practices to get rid of all her problems',
        },
      },
      dir: {
        prompt: 'Which of these is true about Bridget’s practice of relaxation and mindfulness?',
        feedback: {
          '0': {
            correct: false,
            text: 'It is really important for Bridget to first practice when she is in a calm place. Once Bridget gets the hang of it, she will be able to use these tools when she is feeling emotional.',
          },
          '1': {
            correct: true,
            text: 'Bridget practices everyday so that she can improve her mindfulness and relaxation skills.',
          },
          '2': {
            correct: false,
            text: 'Though mindfulness and relaxation can be helpful, they don’t get rid of all of Bridget’s problems. These skills do help Bridget to relax her body and focus on the present moment.',
          },
        },
      },
    },
  ],
};

const RELAX_MINDFUL_APPLY_BRIDGET: SetData = {
  ID: 'relax-mindful-bridget-apply',
  name: 'Bridget',
  description: 'Bridget: 17 y.o. girl, high achiever, thinks she’s failing',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S4/Bridget_Session4c_Where_Do_I_Start_200405.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S4/Bridget_Session4d_Where_Do_I_Do_Exercises_200405.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S4/Bridget_Session4e_What_Do_I_Do_Neg_Thoughts_200405.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'True',
          '1': 'False',
        },
      },
      dir: {
        prompt:
          'True or false? Since Bridget is a good student, she was great at relaxation right away.',
        feedback: {
          '0': {
            correct: false,
            text: 'It was hard for Bridget to focus on the exercises at first. She has worked really hard to get better at these skills.',
          },
          '1': {
            correct: true,
            text: 'Though she is a strong student, Bridget still had to practice and figure out how to reduce distractions.',
          },
        },
      },
    },
  ],
};

//Session 5 - Improving Sleep
//Text & quizzes here - https://docs.google.com/document/d/1q2uVuAZLQ7uVqQdjIgL-eck2oqbQjrbItqMsqgDWLBU/edit?usp=sharing
//Videos store here - https://drive.google.com/drive/folders/1IJZWlfT0tXCfs5fJ7JT56MW-gyT7bfDZ?usp=sharing

const IMPROVING_SLEEP_LEARN_BRIDGET: SetData = {
  ID: 'improving-sleep-bridget-learn',
  name: 'Bridget',
  description: 'Bridget: 17 y.o. girl, high achiever, thinks she’s failing',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S5/Bridget_Session5a_How_Do_I_Normally_Sleep_200406.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S5/Bridget_Session5b_What_Have_I_Changed_200406.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'She had to make all of the changes at once',
          '1': 'She had to be perfect at following the sleep recommendations',
          '2': 'She used the Sleep Diary to figure out where she could make small changes',
        },
      },
      dir: {
        prompt: 'What is true about the changes that Bridget made to her sleep routine?',
        feedback: {
          '0': {
            correct: false,
            text: 'It can be really hard to make a lot of changes at once. Bridget picked a few things to work on.',
          },
          '1': {
            correct: false,
            text: 'Making changes to a sleep routine can be really difficult. Some days will be better than others and that is okay!',
          },
          '2': {
            correct: true,
            text: 'The Sleep Diary is a great first step. By looking at her sleep patterns, Bridget was able to make small changes to her sleep routine.',
          },
        },
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S5/Bridget_Session5c_How_Has_Improve_Sleep_Reduced_Risk_200406.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Bridget gets better sleep and is better prepared to tackle challenges',
          '1': 'Bridget uses her sleep diary to track her warning signs',
          '2': 'Bridget has been in bed twice as much, so she gets better sleep',
        },
      },
      dir: {
        prompt: 'How did changing her sleep habits reduce Bridget’s suicide risk?',
        feedback: {
          '0': {
            correct: true,
            text: 'Bridget has focused on improving her sleep habits and that has helped her cope with stress. This lowers her suicide risk.',
          },
          '1': {
            correct: false,
            text: 'Warning signs are kept in Bridget’s MyPlan. Bridget has focused on improving her sleep habits and that has helped her cope with stress. This lowers her suicide risk.',
          },
          '2': {
            correct: false,
            text: 'Being in bed more often doesn’t always mean more or better rest. Bridget has focused on improving her sleep habits and that has helped her cope with stress. This lowers her suicide risk.',
          },
        },
      },
    },
  ],
};

const IMPROVING_SLEEP_APPLY_BRIDGET: SetData = {
  ID: 'improving-sleep-bridget-apply',
  name: 'Bridget',
  description: 'Bridget: 17 y.o. girl, high achiever, thinks she’s failing',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S5/Bridget_Session5d_How_Do_I_Start_200406.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S5/Bridget_Session5e_How_Does_Using_The_SD_Help_200406.mp4',
        ),
        compulsory: true,
      },
    },
  ],
};

//Session 6 - Spot It
//Text & quizzes here - https://docs.google.com/document/d/1jiYRofizn8Ledw5G1rSRXhLnzutZO_z4oo7n_eTidWw/edit?usp=sharing
//Videos store here - https://drive.google.com/drive/folders/1hQqIOFA-__0n0Uw1HbCp04fPO5tY5JAd?usp=sharing

const SPOT_IT_LEARN_BRIDGET: SetData = {
  ID: 'spot-it-bridget-learn',
  name: 'Bridget',
  description: 'Bridget: 17 y.o. girl, high achiever, thinks she’s failing',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S6/Bridget_S6a_What_Is_Spot_It_210412.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S6/Bridget_S6b_When_Do_I_Use_Spot_It_210412.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S6/Bridget_S6c_Whats_My_Experience_With_Spot_It_210412.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'She doesn’t need any clues because she spots the thoughts right away',
          '1': 'She feels her heart racing and her palms start to sweat',
          '2': 'Spotting unhelpful thoughts isn’t important so she doesn’t need clues',
        },
      },
      dir: {
        prompt: 'What are clues that help Bridget spot unhelpful thoughts?',
        feedback: {
          '0': {
            correct: false,
            text: 'Bridget is still learning, so she doesn’t always notice when unhelpful thoughts pop up. Clues that her body gives her (racing heart, sweaty palms) can be helpful signals that remind her to focus on her thoughts.',
          },
          '1': {
            correct: true,
            text: 'Sometimes our bodies give us clues that let us know we aren’t feeling great. This is a good time to look at our thoughts. For Bridget, a racing heart and sweaty palms mean that she is getting nervous. This is a good signal that reminds her to focus on her thoughts.',
          },
          '2': {
            correct: false,
            text: 'Thoughts can really impact our mood. Being aware of unhelpful thoughts can help Bridget understand why sometimes she feels poorly. Clues that her body gives her (racing heart, sweaty palms) can be helpful signals that remind her to focus on her thoughts.',
          },
        },
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S6/Bridget_S6d_How_Has_Spot_It_Reduced_Risk_210412.mp4',
        ),
        compulsory: true,
      },
    },
  ],
};
const SPOT_IT_APPLY_BRIDGET: SetData = {
  ID: 'spot-it-bridget-apply',
  name: 'Bridget',
  description: 'Bridget: 17 y.o. girl, high achiever, thinks she’s failing',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S6/Bridget_S6e_How_Do_I_Start_210412.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S6/Bridget_S6f_How_Was_Spot_It_Helpful_210412.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'She can criticize every single thought that she has',
          '1': 'She realizes all her unhelpful thoughts are true',
          '2': 'She can see the way that her thoughts impact her mood',
        },
      },
      dir: {
        prompt: 'How does using Spot It help Bridget?',
        feedback: {
          '0': {
            correct: false,
            text: 'By using Spot It, Bridget learned what thoughts are helpful to her and what might be unhelpful. In the future, she will learn tools for dealing with unhelpful thoughts.',
          },
          '1': {
            correct: false,
            text: 'Bridget learned that unhelpful thoughts can increase her anxiety. These thoughts aren’t necessarily true.',
          },
          '2': {
            correct: true,
            text: 'Bridget has learned that difficult feelings like feeling stupid and ashamed are sometimes related to unhelpful thoughts.',
          },
        },
      },
    },
  ],
};

//Session 7 - Test It
//Text & quizzes here - https://docs.google.com/document/d/17osrkEuNg9Ev92Eak-nHL0M1mtgDME-5U0lJVK9nhAs/edit?usp=sharing
//Videos store here - https://drive.google.com/drive/folders/1P7NJuNf18abIwx8Zi6uE-qIw9MT0Xbwa?usp=sharing

const TEST_IT_LEARN_BRIDGET: SetData = {
  ID: 'test-it-bridget-learn',
  name: 'Bridget',
  description: 'Bridget: 17 y.o. girl, high achiever, thinks she’s failing',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S7/Bridget_S7a_Whats_My_Experience_With_Test_It_210421.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'She often ignores evidence that helps to disprove unhelpful thoughts',
          '1': 'They are always true',
          '2': 'Her unhelpful thoughts are new',
        },
      },
      dir: {
        prompt: 'What did Bridget find out about her unhelpful thoughts?',
        feedback: {
          '0': {
            correct: true,
            text: 'Bridget realized that when she had an unhelpful thought, she would only look at the proof that supported the unhelpful thought.',
          },
          '1': {
            correct: false,
            text: 'By using Test It, Bridget learned that her unhelpful thoughts are often not fully true.',
          },
          '2': {
            correct: false,
            text: 'By examining her thoughts, Bridget realized that she’d had many of them for a long time.',
          },
        },
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S7/Bridget_S7b_How_Has_Test_It_Helped_Reduce_Risk_210421.mp4',
        ),
        compulsory: true,
      },
    },
  ],
};

const TEST_IT_APPLY_BRIDGET: SetData = {
  ID: 'test-it-bridget-apply',
  name: 'Bridget',
  description: 'Bridget: 17 y.o. girl, high achiever, thinks she’s failing',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S7/Bridget_S7c_How_Do_I_Start_210421.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S7/Bridget_S7d_How_Did_I_Test_My_Thought_210421.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S7/Bridget_S7e_How_Was_Test_It_Helpful_To_Me_210421.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemMultipleChoiceQuestion',
      props: {
        label: {
          '0': 'Looking at the evidence for and against a thought',
          '1': 'Telling yourself that you are silly for having unhelpful thoughts',
          '2': 'Asking yourself "what would you say to a friend"?',
        },
      },
      dir: {
        prompt: 'What are strategies for testing unhelpful thoughts?',
        feedback: {
          default: {
            correct: false,
            text: 'Looking at the evidence for and against thoughts and asking yourself "what would you say to a friend" are good ways to test unhelpful thoughts.',
          },
          '["0","2"]': {
            correct: true,
            text: 'These are great tools for testing unhelpful thoughts.',
          },
        },
      },
    },
  ],
};

//Session 8 - Switch It
//Text & quizzes here - https://docs.google.com/document/d/1oJT7I3uKvacTkwuTrzDNsvKWd1S9Tcx7sFT7d4P7d6k/edit?usp=sharing
//Videos store here - https://drive.google.com/drive/folders/1Cv7rZwD8FZ-LbZ-5SBWeSi-v--3P-04F?usp=sharing

const SWITCH_IT_LEARN_BRIDGET: SetData = {
  ID: 'switch-it-bridget-learn',
  name: 'Bridget',
  description: 'Bridget: 17 y.o. girl, high achiever, thinks she’s failing',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S8/Bridget_S8a_Whats_My_Experience_With_Switch_It_210422.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S8/Bridget_S8b_How_Has_Switch_It_Helped_Reduce_Risk_210422.mp4',
        ),
        compulsory: true,
      },
    },

    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'True',
          '1': 'False',
        },
      },
      dir: {
        prompt:
          'True or false? When switching a thought, it is best to find the most positive thought even if it is not realistic.',
        feedback: {
          '0': {
            correct: false,
            text: 'It is more important to find a balanced and believable thought. For example, it would be more helpful to change the unhelpful thought of  "I always fail" to "There are things that I do well" and not to "I am always the best at everything." This is because the thought "There are things that I do well" is more realistic, balanced and believable.',
          },
          '1': {
            correct: true,
            text: 'It is important to focus on finding a thought that is balanced and believable.',
          },
        },
      },
    },
  ],
};

const SWITCH_IT_APPLY_BRIDGET: SetData = {
  ID: 'switch-it-bridget-apply',
  name: 'Bridget',
  description: 'Bridget: 17 y.o. girl, high achiever, thinks she’s failing',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S8/Bridget_S8c_How_Did_I_Start_210422.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S8/Bridget_S8d_How_Did_I_Switch_My_Thought_210422.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'True',
          '1': 'False',
        },
      },
      dir: {
        prompt:
          'True or false?Every single time that Bridget switches her thoughts she feels automatically 100% better.',
        feedback: {
          '0': {
            correct: false,
            text: 'Switching unhelpful thoughts to more helpful and realistic thoughts can reduce a hard feeling. But it will not fix everything. Bridget will use these skills along with other skills (relaxation, getting going) to improve her mood.',
          },
          '1': {
            correct: true,
            text: 'Switching unhelpful thoughts to more helpful and realistic thoughts can reduce a hard feeling but it will not fix everything. Bridget will use these skills along with other skills (relaxation, getting going). And over time, these can help to improve her mood.',
          },
        },
      },
    },
  ],
};

//Session 9 - Coping Cards
//Text & quizzes here - https://docs.google.com/document/d/1WTKRcxBuQMFQJQcF9GpZuEd70Xfq3bLQqHK2Pfjq0_0/edit?usp=sharing
//Videos store here - https://drive.google.com/drive/folders/1HZAMjVBLO2Badf01We9A_-IuOn-27aur?usp=sharing

const COPING_CARDS_LEARN_BRIDGET: SetData = {
  ID: 'coping-cards-bridget-learn',
  name: 'Bridget',
  description: 'Bridget: 17 y.o. girl, high achiever, thinks she’s failing',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S9/Bridget_S9a_Whats_My_Experience_With_Coping_Cards_210422.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S9/Bridget_S9b_How_Have_Coping_Cards_Helped_Reduce_Risk_210422.mp4',
        ),
        compulsory: true,
      },
    },
  ],
};

const COPING_CARDS_APPLY_BRIDGET: SetData = {
  ID: 'coping-cards-bridget-apply',
  name: 'Bridget',
  description: 'Bridget: 17 y.o. girl, high achiever, thinks she’s failing',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S9/Bridget_S9c_How_Do_I_Start_210422.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S9/Bridget_S9d_Whats_Another_Example_Of_A_Card_210422.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'True',
          '1': 'False',
        },
      },
      dir: {
        prompt:
          'True or false? Coping cards are like a shortcut that connects tough situations with specific coping skills.',
        feedback: {
          '0': {
            correct: true,
            text: 'Bridget can add specific things that are hard for her on one side and coping skills that she knows have worked in the past on the other side.',
          },
          '1': {
            correct: false,
            text: 'They are like shortcuts. Bridget can add specific things that are hard for her on one side and coping skills that she knows have worked in the past on the other side.',
          },
        },
      },
    },
  ],
};

//Session 10 - Risk Curve Review
//Text & quizzes here - https://docs.google.com/document/d/1eI3S4F9LcFDgS4W-vW9MfGhKRyjoWGebc6xaPeyZL5c/edit?usp=sharing
//Videos store here - https://drive.google.com/drive/folders/1ANIw5DmuHvROS1fxSJYn0zGvZRNwzMi6?usp=sharing

const RISK_CURVE_REVIEW_LEARN_BRIDGET: SetData = {
  ID: 'risk-curve-review-bridget-learn',
  name: 'Bridget',
  description: 'Bridget: 17 y.o. girl, high achiever, thinks she’s failing',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S10/Bridget_S10a_Whats_My_Experience_With_Reviewing_RC_210423.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S10/Bridget_S10b_Whats_My_Experience_With_Reviewing_MP_210423.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'It is important to revisit the Risk Curve and MyPlan because warning signs and coping skills change over time',
          '1': 'There is no need to revisit the Risk Curve and MyPlan',
          '2': 'Warning signs never change',
        },
      },
      dir: {
        prompt: 'What did Bridget realize when reviewing the Risk Curve and MyPlan?',
        feedback: {
          '0': {
            correct: true,
            text: 'Bridget has learned new things about what pushes her up the risk curve. She has also learned new tools. She can update her Risk Curve and MyPlan with the things that she has learned.',
          },
          '1': {
            correct: false,
            text: 'Bridget has learned new things about what pushes her up the risk curve. She has also learned new tools. She can update her Risk Curve and MyPlan with the things that she has learned.',
          },
          '2': {
            correct: false,
            text: 'Warning signs can actually change over time. Once in a while, it is important to think about warning signs and update the Risk Curve.',
          },
        },
      },
    },
  ],
};

//Session 11 - Skill Review
//Text & quizzes here - https://docs.google.com/document/d/11ECZSB-i_a1NFLRkDp6np2dkVcfj53irhG2cXOtUj4c/edit?usp=sharing
//Videos store here - https://drive.google.com/drive/folders/1e-wLTCRKUnvwCE4GNoKpVGx6Rt-y6Mgs?usp=sharing

const SKILL_REVIEW_LEARN_BRIDGET: SetData = {
  ID: 'skill-review-bridget-learn',
  name: 'Bridget',
  description: 'Bridget: 17 y.o. girl, high achiever, thinks she’s failing',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S11/Bridget_S11a_Whats_My_Experience_With_Reviewing_Skills_210423.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S11/Bridget_S11b_Whats_Most_Helpful_Reviewing_Skills_210423.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'True',
          '1': 'False',
        },
      },
      dir: {
        prompt:
          'True or false? Now that Bridget has learned all of the skills in Aviva, she needs to make sure that she is practicing them all at all times.',
        feedback: {
          '0': {
            correct: false,
            text: 'It can be pretty hard to practice all the skills at all times. It is best for Bridget to focus on one thing at a time.',
          },
          '1': {
            correct: true,
            text: 'It can be pretty hard to practice all the skills at all times. It is best for Bridget to focus on one thing at a time.',
          },
        },
      },
    },
  ],
};

//Session 12 - What's Next?
//Text & quizzes here - https://docs.google.com/document/d/1T6mCQK0xACH9pQbzpW5pAsXygwidwdbkZNCi7t7-KQU/edit?usp=sharing
//Videos store here - https://drive.google.com/drive/folders/1QoD1XZGOpryOsxkELoWaw986_U-8REiA?usp=sharing

const WHATS_NEXT_LEARN_BRIDGET: SetData = {
  ID: 'whats-next-bridget-learn',
  name: 'Bridget',
  description: 'Bridget: 17 y.o. girl, high achiever, thinks she’s failing',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S12/Bridget_S12_How_Do_I_Feel_Finished_210423.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Stop using all the tools that she learned in Aviva',
          '1': 'Continue to use the skills that she has learned and ask for more help if she needs it',
          '2': 'Delete the app',
        },
      },
      dir: {
        prompt: 'What does Bridget plan to do after finishing Aviva?',
        feedback: {
          '0': {
            correct: false,
            text: 'The tools that she has learned can continue to be helpful.',
          },
          '1': {
            correct: true,
            text: 'Bridget has learned some tools to use in different situations. She will continue to use these tools and will ask a parent for more help if she needs it.',
          },
          '2': {
            correct: false,
            text: 'Even if things feel much better for Bridget, it might be a good idea to keep the app. In the future, Bridget can return to Aviva to review a skill if she needs it!',
          },
        },
      },
    },
  ],
};

export const ADOLESCENT_QUIZ_SET_DATA: Record<string, SetData[] | undefined> = {
  MYSTORY_LEARN_VIGNETTE: [MYSTORY_LEARN_VIGNETTE_BRIDGET],
  RISK_CURVE_LEARN_VIGNETTE: [RISK_CURVE_LEARN_VIGNETTE_BRIDGET],
  RISK_CURVE_LEARN_VIGNETTE_COLLAB: [RISK_CURVE_LEARN_VIGNETTE_BRIDGET_COLLAB],
  MYPLAN_LEARN_VIGNETTE: [MYPLAN_LEARN_VIGNETTE_BRIDGET],
  HOPE_KIT_LEARN_VIGNETTE: [HOPE_KIT_BRIDGET],
  HOPE_KIT_APPLY_VIGNETTE: [HOPE_KIT_APPLY_BRIDGET],
  GETTING_GOING_LEARN_VIGNETTE: [GET_GOING_LEARN_BRIDGET],
  GETTING_GOING_APPLY_VIGNETTE: [GET_GOING_APPLY_BRIDGET],
  RELAXATION_MINDFULNESS_LEARN_VIGNETTE: [RELAX_MINDFUL_LEARN_BRIDGET],
  RELAXATION_MINDFULNESS_APPLY_VIGNETTE: [RELAX_MINDFUL_APPLY_BRIDGET],
  SLEEP_DIARY_LEARN_VIGNETTE: [IMPROVING_SLEEP_LEARN_BRIDGET],
  SLEEP_DIARY_APPLY_VIGNETTE: [IMPROVING_SLEEP_APPLY_BRIDGET],
  SPOT_IT_LEARN_VIGNETTE: [SPOT_IT_LEARN_BRIDGET],
  SPOT_IT_APPLY_VIGNETTE: [SPOT_IT_APPLY_BRIDGET],
  TEST_IT_LEARN_VIGNETTE: [TEST_IT_LEARN_BRIDGET],
  TEST_IT_APPLY_VIGNETTE: [TEST_IT_APPLY_BRIDGET],
  SWITCH_IT_LEARN_VIGNETTE: [SWITCH_IT_LEARN_BRIDGET],
  SWITCH_IT_APPLY_VIGNETTE: [SWITCH_IT_APPLY_BRIDGET],
  COPING_CARDS_LEARN_VIGNETTE: [COPING_CARDS_LEARN_BRIDGET],
  COPING_CARDS_APPLY_VIGNETTE: [COPING_CARDS_APPLY_BRIDGET],
  RISK_CURVE_MYPLAN_REVIEW_LEARN_VIGNETTE: [RISK_CURVE_REVIEW_LEARN_BRIDGET],
  REDUCE_RISK_LEARN_VIGNETTE: [SKILL_REVIEW_LEARN_BRIDGET],
  POSTAVIVA_LEARN_VIGNETTE: [WHATS_NEXT_LEARN_BRIDGET],
};
