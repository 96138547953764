import { v4 as uuid } from 'uuid';
import { z } from 'zod';

import {
  ClinicalChecklist,
  CopingStrategies,
  CrisisTimeline,
  CSSRS,
  DistractingActivities,
  EatingCommitment,
  EatingSchedule,
  EnvironmentSafety,
  HelpContacts,
  MoodPlan,
  MyStoryMyPlanState,
  NumericDiagnostic,
  PreventingRelapse,
  ProfessionalHelpContacts,
  ReasonsForLiving,
  RiskFactors,
  SocialDistractions,
  SocialSupport,
  WarningSigns,
} from './avro';
import { YstMentalHealthConcerns } from '../ystMentalHealthConcerns';
import { YstOnboardingAvailability, YstRecurringAvailability } from '../ystScheduler';

export const SingletonCompositionSectionTemplates = {
  EATING_COMMITMENT: EatingCommitment,
  EATING_SCHEDULE: EatingSchedule,
  TEST: z.object({}).default({}),
  SCREENER_NUMERIC: NumericDiagnostic,
  SCREENER_CSSRS: CSSRS,
  MYSTORYMYPLAN_CLINICAL: ClinicalChecklist,
  YST_MENTAL_HEALTH_CONCERNS: YstMentalHealthConcerns,
  YST_ONBOARDING_AVAILABILITY: YstOnboardingAvailability,
  YST_RECURRING_AVAILABILITY: YstRecurringAvailability,
} as const;

export const CompositionTemplates = {
  // singleton composition templates
  EATING_COMMITMENT: z
    .object({
      EATING_COMMITMENT: SingletonCompositionSectionTemplates.EATING_COMMITMENT,
    })
    .strict()
    .default({
      EATING_COMMITMENT: SingletonCompositionSectionTemplates.EATING_COMMITMENT.parse(undefined),
    }),
  EATING_SCHEDULE: z
    .object({
      EATING_SCHEDULE: SingletonCompositionSectionTemplates.EATING_SCHEDULE,
    })
    .strict()
    .default({
      EATING_SCHEDULE: SingletonCompositionSectionTemplates.EATING_SCHEDULE.parse(undefined),
    }),
  TEST: z
    .object({ TEST: SingletonCompositionSectionTemplates.TEST })
    .strict()
    .default({
      TEST: SingletonCompositionSectionTemplates.TEST.parse(undefined),
    }),
  SCREENER_NUMERIC: z
    .object({
      SCREENER_NUMERIC: SingletonCompositionSectionTemplates.SCREENER_NUMERIC,
    })
    .strict()
    .default({
      SCREENER_NUMERIC: SingletonCompositionSectionTemplates.SCREENER_NUMERIC.parse(undefined),
    }),
  SCREENER_CSSRS: z
    .object({ SCREENER_CSSRS: SingletonCompositionSectionTemplates.SCREENER_CSSRS })
    .strict()
    .default({
      SCREENER_CSSRS: SingletonCompositionSectionTemplates.SCREENER_CSSRS.parse(undefined),
    }),
  MYSTORYMYPLAN_CLINICAL: z
    .object({
      MYSTORYMYPLAN_CLINICAL: SingletonCompositionSectionTemplates.MYSTORYMYPLAN_CLINICAL,
    })
    .strict()
    .default({
      MYSTORYMYPLAN_CLINICAL:
        SingletonCompositionSectionTemplates.MYSTORYMYPLAN_CLINICAL.parse(undefined),
    }),
  YST_MENTAL_HEALTH_CONCERNS: z
    .object({
      YST_MENTAL_HEALTH_CONCERNS: SingletonCompositionSectionTemplates.YST_MENTAL_HEALTH_CONCERNS,
    })
    .strict()
    .default({
      YST_MENTAL_HEALTH_CONCERNS:
        SingletonCompositionSectionTemplates.YST_MENTAL_HEALTH_CONCERNS.parse(undefined),
    }),

  YST_ONBOARDING_AVAILABILITY: z
    .object({ YST_ONBOARDING_AVAILABILITY: YstOnboardingAvailability })
    .strict()
    .default({
      YST_ONBOARDING_AVAILABILITY:
        SingletonCompositionSectionTemplates.YST_ONBOARDING_AVAILABILITY.parse(undefined),
    }),
  YST_RECURRING_AVAILABILITY: z
    .object({ YST_RECURRING_AVAILABILITY: YstRecurringAvailability })
    .strict()
    .default({
      YST_RECURRING_AVAILABILITY:
        SingletonCompositionSectionTemplates.YST_RECURRING_AVAILABILITY.parse(undefined),
    }),

  BINGE_EATING_MY_PLAN: z
    .object({
      APP_STATE: MyStoryMyPlanState,
      DISTRACTING_ACTIVITIES: DistractingActivities,
      MOOD_PLAN: MoodPlan,
      SOCIAL_SUPPORT: SocialSupport,
      PREVENTING_RELAPSE: PreventingRelapse,
    })
    .strict()
    .default({
      APP_STATE: MyStoryMyPlanState.parse(undefined),
      DISTRACTING_ACTIVITIES: DistractingActivities.parse(undefined),
      MOOD_PLAN: MoodPlan.parse(undefined),
      SOCIAL_SUPPORT: SocialSupport.parse(undefined),
      PREVENTING_RELAPSE: PreventingRelapse.parse(undefined),
    }),
  MYSTORYMYPLAN: z
    .object({
      APP_STATE: MyStoryMyPlanState,
      CRISIS_TIMELINE: CrisisTimeline,
      WARNING_SIGNS: WarningSigns,
      COPING_STRATEGIES: CopingStrategies,
      REASONS_FOR_LIVING: ReasonsForLiving.optional(),
      SOCIAL_DISTRACTIONS: SocialDistractions,
      HELP_CONTACTS: HelpContacts,
      PROFESSIONAL_HELP_CONTACTS: ProfessionalHelpContacts,
      ENVIRONMENT_SAFETY: EnvironmentSafety,
      RISK_FACTORS: RiskFactors,
    })
    .strict()
    .default({
      APP_STATE: MyStoryMyPlanState.parse(undefined),
      CRISIS_TIMELINE: CrisisTimeline.parse(undefined),
      WARNING_SIGNS: WarningSigns.parse(undefined),
      COPING_STRATEGIES: CopingStrategies.parse(undefined),
      REASONS_FOR_LIVING: ReasonsForLiving.parse(undefined),
      SOCIAL_DISTRACTIONS: SocialDistractions.parse(undefined),
      HELP_CONTACTS: HelpContacts.parse(undefined),
      PROFESSIONAL_HELP_CONTACTS: ProfessionalHelpContacts.parse(undefined),
      ENVIRONMENT_SAFETY: EnvironmentSafety.parse(undefined),
      RISK_FACTORS: RiskFactors.parse(undefined),
    }),
} as const;

export function getBlankDocument<T extends keyof typeof CompositionTemplates>(
  template: T,
  { product }: { product: { slug: string } },
) {
  const document: z.infer<(typeof CompositionTemplates)[T]> =
    CompositionTemplates[template].parse(undefined);

  if (product.slug == 'AVIVA_INPATIENT' && 'COPING_STRATEGIES' in document) {
    document.COPING_STRATEGIES = [
      {
        ID: uuid(),
        text: 'Do relaxation or mindfulness',
      },
    ];
  }

  return document;
}

export function getBlankSingletonDocument<
  T extends keyof typeof SingletonCompositionSectionTemplates,
>(template: T): z.infer<(typeof SingletonCompositionSectionTemplates)[T]> {
  return SingletonCompositionSectionTemplates[template].parse(undefined);
}
