import type { StaticSessionContent } from '../../content/session/static';
import { ContentType, RoleType } from '../../types/graphql.generated';

const STATIC_SESSION_01 = (roleType: RoleType): StaticSessionContent => {
  const introByVariant: Partial<Record<RoleType, string>> = {
    [RoleType.SUPPORTER]:
      'This app is a companion app to the treatment app that your teen is using. The goal of this app is to give you an insight into the type of content your teen is receiving so they can learn more about suicide and keep themselves safe.',
    [RoleType.PATIENT]:
      'This app is a treatment to help you reduce your suicide risk. We hope by using this app, you will learn more about suicide and how to keep yourself safe.',
  };
  const intro = introByVariant[roleType];

  if (!intro) {
    throw new Error('Invalid ProductVariant in STATIC_SESSION_01');
  }

  return [
    `
## Context

${intro}
  `,
    `
## Context for suicide

Suicide is a major public health problem for teens. It is the second leading cause of death for teens in the United States. Learning more about suicide will help you as part of your treatment. As you know, suicide is an action a person takes to end their own lives.

Typically, it is advised to avoid using terms like "committed suicide" or 'successful suicide". Instead, it is suggested that the phrase 'death by suicide' is more appropriate.
  `,
    `
## Who is at risk of suicide?

Suicide does not discriminate. But some teens are at higher risk than others. Below is a list of the main risk factors of suicide:

* Previous suicide attempt
* Depression and other mental health issues
* Substance use issues
* Family history of suicide
* Family violence or sexual abuse
* Having guns / firearms at home
* Being in prison
* Medical illness
* Being ages 10-18
  `,
    `
## Why do some teens become suicidal but other teens do not?

Most teens who have suicide risk factors do not try to kill themselves. Risk for suicidal behavior is complex.

The research indicates that teens who do try to kill themselves react differently to situations and events due to challenges they're facing in their life, such as:

* Depression
* Substance use
* Anxiety
* And more
  `,
    `
## Use of medications

The use of medications is common among patients at risk of suicide.

Types of medications include:

* Benzodiazepines
* Antidepressants
* Antipsychotics
* Mood-stabilizing drugs

The first two are the most commonly prescribed.
`,
    `
## Benzodiazepines

Benzodiazepines, sometimes called "benzos". Benzos are depressants, which are drugs that lower brain activity. They are prescribed to treat conditions such as anxiety, insomnia, and seizures.

Benzos are often used to help people with heightened levels of anxiety and agitation.
  `,
    `
## Antidepressants

Antidepressants are used to treat major depressive disorder and a number of other conditions, including some anxiety disorders, some chronic pain conditions, and to help manage some addictions.

Antidepressants are often used in combinations with one another.
  `,
    `
## Antipsychotics and mood stabilizers

Both antipsychotics and mood stabilizers are used less frequently for suicidal teens, but do have a role in reducing symptoms.

Clozapine is an antipsychotic used to treat patients with schizophrenia and suicidal behaviors.

Additionally, numerous clinical studies over the years have demonstrated that lithium can reduce the risk for suicide and suicide attempts in teens with bipolar disorder.
  `,
    `
##  Access to a template plan for safety

There is a red cross button in this treatment that links to a PDF copy of something called a safety plan. Don’t worry about printing it out right now. Instead, be prepared to write out responses when you prefer to spend the time and effort.
  `,
    `
## Videos

This treatment includes videos you can watch to help understand what gets covered in each session. In this session we covered an introduction to suicide, who is at risk and what they can do. The short videos in this session will tell you more about groups at risk for death by suicide.
  `,
    { type: 'QuizSet', slug: ContentType.STATIC_01 },
    `
## Finish

Now you have completed the first session of this treatment. You may click finish to return to the home screen. See the Practice session to review the treatment content.
  `,
  ];
};

const STATIC_SESSION_02: StaticSessionContent = [
  `
## Key statistics (1/2)

Teenage years are a stage of rapid physical, emotional, and social development. Suicide attempts among teens may be associated with feelings of sadness, confusion, anger, or problems with attention and hyperactivity.

* 8% of high school students report trying to take their own life in the past 12 months.

* Every day, 5,400 teens in grades 7–12 attempt suicide.

* Each year, around 150,000 teens are treated in emergency rooms for self-inflicted injuries.

* Suicide as a cause of death in ages 15-19 is very similar in both boys and girls: 10% in boys and 8% in girls.
  `,
  `
## Key statistics (2/2)

Suicide kills more teenagers than does cancer, heart disease, AIDS, birth defects, stroke, pneumonia, influenza, and chronic lung disease combined.

*16% of high school students report seriously considering suicide.

*13% of high school students report creating a plan.

* Girls are more likely to report attempting suicide than boys.

*4 out of 5 teens who attempt suicide have given clear warning signs.
  `,
  `
## How does suicide in the US compare to other countries? (1/2)

Over roughly the same period, other countries have seen rates fall, including Japan, China, Russia and most of Western Europe.

Suicides per 100,000 people (2000 to 2012). Data from World Health Organization.

| **Country**    | **2000**      | **2012**      |
| -           | -           | -           |
| Canada      | 11          | 9.8         |
| China       | 29.4        | 7.8         |
| Germany     | 11.1        | 9.2         |
| Japan       | 18.8        | 18.5        |
| Russia      | 35          | 19.5        |
| UK          | 7.8         | 6.2         |
  `,
  `
## How does suicide in the US compare to other countries? (2/2)

Globally, suicide rates have often fallen when living conditions have improved. The reverse is also true. Suicide rates have increased when living conditions have gotten worse. As life has worsened for certain groups of teens, suicide has increased.
  `,
  `
## Where do suicides occur?

In the US, about three-quarters of suicide incidents occur at home. Most (85%) die at the scene and never make it to the hospital.

In terms of geography, suicide rates are highest in rural areas, in the west (excluding California) and, to a lesser extent, in parts of the south and northern New England.
  `,
  { type: 'QuizSet', slug: ContentType.STATIC_02 },
  `
## Finish

Now you have completed the second session of this treatment. You may click finish to return to the home screen. See the Practice session to review the treatment content.
  `,
];

const STATIC_SESSION_03: StaticSessionContent = [
  `
## Introduction

In this treatment it is also important that you understand a little about stress, and its role in suicide. It is not surprising that suicide attempts often happen when teens are very stressed.

In this session, we're going to look at what stress is, and what it does to our bodies and minds.

  `,
  `
## What is stress?

Stress is the physical or mental response to an external cause, such as having a lot of homework, failing exams or having an illness. A stressor may be a one-time or short-term thing, or it can happen repeatedly over a long time. Once a problem is resolved, typically the stress goes away.

Stress is interesting in that it can be positive or negative. For example, it may inspire you to meet a deadline. Or it may cause you to lose sleep.
  `,
  `
## What are some signs of stress? (2/2)

Stress can affect your mind and body. You may experience symptoms such as:

* Excessive worry
* Uneasiness
* Tension
* Headaches or body pain
* High blood pressure
* Loss of sleep

It's important to manage your stress. Stress can affect your enjoyment of things you like to do. It can get in the way of you doing your everyday tasks.
  `,
  `
## What happens to your body during stress? (1/2)

When you get stressed, your body enters a state called 'fight or flight'. Your body is on full alert and is ready to fight to survive or run away to safety.

Either way, your heart may beat faster, you may tense up and you may sweat.
  `,
  `
## What happens to your body during stress? (2/2)

These physical reactions are normal. But they also tire you out because your body is on high alert. So being stressed all the time is likely to also lead to you feeling tired all the time.

Having tense muscles or headaches is normal when you are stressed. Understanding your stress and how you react will help.
  `,
  `
## Stress & Suicide

Stress is the most common cause of suicide – that act of someone taking their own life. Causes of stress can include homework, bullying , exam pressure, relationships, or losing a loved one.

Witnessing or coping with physical, mental or emotional abuse can also  lead to stress, all of which put pressure on the body and mind which makes it harder to cope with other issues.
  `,
  { type: 'QuizSet', slug: ContentType.STATIC_03 },
  `
## Finish

Now you have completed the third session of this treatment. You may click finish to return to the home screen. See the Practice session to review the treatment content.
`,
];

const STATIC_SESSION_04: StaticSessionContent = [
  `
## Introduction

In this treatment it is important that you understand a little about depression, and its role in suicide. When you are depressed, problems might seem really overwhelming.

And you may not have the energy to feel like doing anything. This might lead to a sudden decision that you might not choose to make if you were not depressed.
  `,
  `
## What is depression? (1/2)

Depression is a common and serious medical illness that negatively affects how you feel, the way you think and how you act.

Depression is a low mood that lasts for a long time, and affects your everyday life.
  `,
  `
## What is depression? (2/2)

In its mildest form, depression can mean just being in low spirits. It doesn't stop you leading your normal life but makes everything harder to do and seem less worthwhile.

At its most severe, depression can be life-threatening because it can make you feel suicidal.
  `,
  `
## What are signs of depression? (1/2)

Depression symptoms can vary from mild to severe and can include:

* Feeling sad or having a depressed mood
* Loss of interest or pleasure in activities once enjoyed
* Changes in appetite — weight loss or gain unrelated to dieting
* Trouble sleeping or sleeping too much

  `,
  `
## What are signs of depression? (2/2)

Below are some other common signs of depression:

* Loss of energy or increased fatigue
* Increase in purposeless physical activity (e.g., inability to sit still, pacing, handwringing) or slowed movements or speech (these actions must be severe enough to be observable by others)
* Feeling worthless or guilty
* Difficulty thinking, concentrating or making decisions
* Thoughts of death or suicide

  `,
  `
## How widespread is depression? (1/2)

One in six people (16.6%) will experience depression at some time in their life. Severe depression can occur at any time, but on average, first appears during the late teens.

Girls are more likely than men to experience depression. Some studies show that one-third of girls will experience a major depressive episode in their lifetime.
  `,
  `
## How widespread is depression? (2/2)

There is a high degree of heritability (approximately 40%) when first-degree relatives (parents/children/siblings) have depression.

The main thing to note is that depression is quite common and that it affects many teens around us. This data is of course general. But hopefully it gives you a sense of how widespread depression is.

  `,
  `
## Depression and suicide (1/2)

Research has consistently shown a strong link between suicide and depression. Studies that have followed depressed teens over long periods of time suggests that even teens who are treated for depression in an outpatient setting may die by suicide.

Among those ever treated for depression in an inpatient hospital setting, the rate of death by suicide is twice as high.
  `,
  `
## Depression and suicide (2/2)

Around 3.2 million teens ages 13 to 17 had one major depressive episode in 2017.

In 2021 children ages 11 to 17 were the most likely age group across the lifespan to score in the moderate-to-severe depression categories when screened for mental health conditions.
  `,
  { type: 'QuizSet', slug: ContentType.STATIC_04 },
  `
## Finish

Now you have completed the fourth session of this treatment. You may click finish to return to the home screen. See the Practice session to review the treatment content.
  `,
];

const STATIC_SESSION_05: StaticSessionContent = [
  `
## Introduction

In this treatment it is also important that you understand a little about anxiety, and its role in suicide. When you are anxious, problems can seem really big and impossible to fix.

And, that might lead to spur of the moment decisions that you might not choose to make if you weren't anxious.
  `,
  `
## What is anxiety? (1/2)

People with generalized anxiety disorder (GAD) find it difficult to control their anxiety and stay focused on daily tasks.

They often feel extremely worried or feel nervous about  everyday life events, even when there is little or no reason to worry about them.
  `,
  `
## What is anxiety? (2/2)

This anxiety is associated with persistent and excessive worry about any number of different things. People with GAD may anticipate disaster and may be overly concerned about money, health, family, school, or other issues.

Individuals with GAD find it difficult to control their worry. They often worry more than seems warranted about actual events or may expect the worst even when there is no apparent reason for concern.
  `,
  `
## What are signs of anxiety? (1/2)

Below are some common signs of anxiety:

* Feel restless and have trouble relaxing
* Have a hard time concentrating
* Be easily startled
* Have trouble falling asleep or staying asleep
* Feel easily tired or tired all the time
  `,
  `
## What are signs of anxiety? (2/2)

Below are more some common signs of anxiety:

* Have headaches, muscle aches, stomach aches, or unexplained pains
* Have a hard time swallowing
* Tremble or twitch
* Be irritable or feel “on edge”
* Sweat a lot, feel light-headed or out of breath
* Must go to the bathroom a lot
  `,
  `
## How widespread is anxiety?

More than 30% of teenagers have an anxiety disorder.

Among teens diagnosed with any anxiety disorder, an estimated 8.3% are severely affected. The prevalence of any anxiety among teens is higher for girls (38.0%) than for boys (26.1%).

Although the exact cause of anxiety is unknown, there is evidence that biological factors, family background, and life experiences, particularly stressful ones, play a role.
  `,
  `
## Anxiety and suicide

Suicidal thoughts and actions are typically discussed in relation to depression. But several studies have suggested suicide may also be linked to anxiety disorders.

Anxiety disorders often co-occur with depression, and this may be masking a risk of suicide specifically related to anxiety.
  `,
  { type: 'QuizSet', slug: ContentType.STATIC_05 },
  `
## Finish

Now you have completed the fifth session of this treatment. You may click finish to return to the home screen. See the Practice session to review the treatment content.
  `,
];

const STATIC_SESSION_06: StaticSessionContent = [
  `
## Introduction

In this treatment it is important for you to understand  that for many people, suicide is linked with an underlying chronic illness. Receiving bad news about health can be very upsetting.

Sometimes it is not clear exactly what the news means, and what the future will hold. Going through difficult health issues makes things hard and can trigger suicidal tendencies.
  `,
  `
## What are chronic illnesses?

Chronic illnesses are those that last a long time or are ongoing. Chronic diseases are defined broadly as conditions that last 1 year or more and require ongoing medical attention or limit activities of daily living or both.

Chronic diseases such as heart disease, cancer, and diabetes are the leading causes of death and disability in the United States. And they are also leading drivers of the nation’s $3.8 trillion in annual health care costs.
  `,
  `
## What causes chronic illnesses?

Many chronic diseases are caused by a short list of risky behaviors:

* Tobacco use and exposure to secondhand smoke
* Poor nutrition, including diets low in fruits and vegetables and high in sodium and saturated fats
* Lack of physical activity
* Excessive alcohol use
* Obesity
  `,
  `
## Chronic Illness & Suicide (1/2)

People with chronic health problems seem to have a higher risk of suicide. And, for certain conditions, such as traumatic brain injury, the risk is much higher. Having more than one chronic health problem also substantially increases the risk of suicide.
  `,
  `
## Chronic Illness & Suicide (2/2)

Teens with chronic diseases like asthma and diabetes are more than 4 times as likely to attempt suicide compared to their healthy peers.

In addition to the heightened risk of suicidal attempts, teens with a chronic illness are 28% more likely to have suicidal thoughts and 134% more likely to have suicidal plans.
  `,
  { type: 'QuizSet', slug: ContentType.STATIC_06 },
  `
## Finish

Now you have completed the sixth session of this therapy. You may click finish to return to the home screen. See the Practice session to review the treatment content.
  `,
];

const STATIC_SESSION_07: StaticSessionContent = [
  `
## Introduction

In this treatment, it is important for you to understand that for many people, suicide is linked with a diagnosis of cancer. Receiving a diagnosis of cancer can be devastating. Sometimes it is hard to figure out exactly what the diagnosis will mean.

That uncertainty is definitely difficult. And then perhaps having to go through painful treatments also makes things hard. Uncertainty about the future can be very difficult to accept.
  `,
  `
## Some context about cancer

Cancer is the second leading cause of death in the US. There are many types of cancers, but the types that lead to the most deaths include lung, breast, prostate  and colon cancer.

Cancers develop in stages, but some are only found very late when the cancer growth is advanced.
  `,
  `
## What are some cancer specific risk factors for suicide? (1/2)

There are many cancer specific risk factors linked with suicide. Below are the most common to be aware of:

* time before death is less than a year
* advanced or terminal cancer
* loss of physical ability
  `,
  `
## What are some cancer specific risk factors for suicide? (2/2)

Below are more of the most common to be aware of:

* Loss of meaning, dignity or status
* Feeling like a burden
* Survivor of childhood cancer
* Treatment is very painful
  `,
  `
## What is the link between cancer and suicide? (1/2)

Thoughts of suicide while dealing with cancer are exceedingly common.

It is thought that there are certain feelings that folks with cancer have that lead to suicide, such as:
* Pain
* Rage
* Hostility
* Depression
* Shame
* Guilt
* Hopelessness
  `,
  `
## What is the link between cancer and suicide? (2/2)

Suicide rates among cancer patients are generally thought to be twice as high as that of the general population.

Cancer types with a higher rate of physical suffering, such as head and neck, lung, and advanced prostate cancer, have significantly higher rates of suicide.
  `,
  `
## What is the desire for hastened death?

It is thought that suicidal thinking in some patients with cancer is based on the desire for hastened death, or the longing for death to occur more rapidly than it otherwise would.

The most common reasons for this desire include:
* Pain
* Depression
* Hopelessness
* Perception of being a burden to others
* Loss of independence
  `,
  { type: 'QuizSet', slug: ContentType.STATIC_07 },
  `
## Finish

Now you have completed the seventh session of this treatment. You may click finish to return to the home screen. See the Practice session to review the treatment content.
  `,
];

const STATIC_SESSION_08: StaticSessionContent = [
  `
## Introduction

In this treatment, it is important for you to understand that for many people, suicide is linked with schizophrenia.
  `,
  `
## What is schizophrenia?

Schizophrenia is a serious condition in which people interpret reality abnormally.

Schizophrenia may result in some combination of:

* Hallucinations
* Delusions
* Extremely disordered thinking
* Behavior that impairs daily functioning and can be disabling
  `,
  `
## What is the link between schizophrenia and suicide?

Suicide and schizophrenia are closely linked.

About 10% of people with schizophrenia die by suicide.
  `,
  `
## What are the risk factors of suicide for people with schizophrenia? (1/3)

Risk factors of suicide in schizophrenia include being:
* Male
* Unemployed
* Unmarried and living alone
* Intelligent or well-educated
  `,
  `
## What are the risk factors of suicide for people with schizophrenia? (2/3)

People who suffer delusions tend to exhibit more suicidal behavior than those who do not. Also people who do not follow their treatment plan with antipsychotic drugs are at a greater a risk of death from suicide.
  `,
  `
## What are the risk factors of suicide for people with schizophrenia? (3/3)

People with other coexisting conditions such as depression, a history of suicidal behavior, substance abuse, and other neurological or medical conditions have been shown to have an increased risk for suicide. Feelings of hopelessness, demoralization, and panic are other comorbid symptoms associated with a higher suicide risk for this population.
  `,
  `
## When are people with schizophrenia most at risk for death by suicide?

People with schizophrenia tend to have a significantly higher risk for suicide during the first 10 years of living with the condition. 44% of people with schizophrenia who die by suicide, die within this time period.
  `,
  `
## Finish
Now you have completed the eighth session of this treatment. You may click finish to return to the home screen. See the Practice session to review the treatment content.
  `,
];

const STATIC_SESSION_09: StaticSessionContent = [
  `
## Introduction

In this treatment it is important for you to understand that for many people, suicide is linked with drug addiction (sometimes called substance use disorder).

Being addicted to alcohol or other drugs is often linked with other issues going on in a person's life as people often take the drugs to escape. This addiction in turn can create further difficulties in a person's life.
  `,
  `
## Some context about addiction

Addiction is defined as not having control over doing, taking or using something to the point where it could be harmful to you.

Addiction is most commonly associated with gambling, drugs, alcohol and nicotine, but it's possible to be addicted to many other things, including work, shopping, internet usage and solvents.
  `,
  `
## What causes addiction?

There are lots of reasons that explain how addictions start.

For example, substances like drugs, alcohol and nicotine can affect the way you feel, physically, and mentally.

These feelings can be enjoyable and make you want to keep using them but  they often require ever increasing amounts to give you the same enjoyment or positive feeling and,used chronically can cause permanent damage to your body and mind.
`,
  `
# What is the link between addiction and suicide? (1/2)

Suicide and addiction are linked often by a third issue - depression.

Suicide, addiction, and depression have a very close and interconnected relationship.

More than half of people who die by suicide suffer from depression, have a substance abuse disorder, or both. Depression and substance abuse combine to form a vicious cycle that all too often leads to suicide.
  `,
  `
# What is the link between addiction and suicide? (2/2)

Many who experience severe depression (as a result of Major Depression, Bipolar Disorder, Obsessive Compulsive Disorder, and other conditions) can turn to drugs, alcohol, gambling, and other risky behaviors to numb their pain and/or alleviate their negative feelings.
  `,
  { type: 'QuizSet', slug: ContentType.STATIC_09 },
  `
## Finish

Now you have completed the ninth session of this treatment. You may click finish to return to the home screen. See the Practice session to review the treatment content.
  `,
];

const STATIC_SESSION_10: StaticSessionContent = [
  `
## Use of medications

The use of medications is common among patients at risk of suicide.

Types of medications include:

* Benzodiazepines
* Antidepressants
* Antipsychotics
* Mood-stabilizing drugs.

The first two are the most commonly prescribed.
  `,
  `
## Benzodiazepines

Benzodiazepines, sometimes called "benzos". Benzos are depressants, which are drugs that lower brain activity. They are prescribed to treat conditions such as anxiety, insomnia, and seizures.

Benzos are often used to help people with heightened levels of anxiety and agitation.
`,
  `
## Antidepressants

Antidepressants are used to treat major depressive disorder and a number of other conditions, including some anxiety disorders, some chronic pain conditions, and to help manage some addictions.

Antidepressants are often used in combinations with one another.
`,
  `
## Antipsychotics and mood stabilizers

Both antipsychotics and mood stabilizers are used less frequently for suicidal patients but do have a role in reducing symptoms causing emotional dysphoria. Clozapine is an antipsychotic used to treat patients with schizophrenia and suicidal behaviors.

Additionally, numerous clinical studies over the years have demonstrated that lithium can reduce the risk for suicide and suicide attempts in people with bipolar disorder.
`,
  { type: 'QuizSet', slug: ContentType.STATIC_10 },
  `
## Finish

Now you have completed the tenth session of this treatment. You may click finish to return to the home screen. See the Practice session to review the treatment content.
  `,
];

const STATIC_SESSION_11: StaticSessionContent = [
  `
## Who is at risk of suicide?

Suicide does not discriminate. But some people are at higher risk than others.

Below is a list of the main risk factors of suicide:
* Previous suicide attempt
* Depression and other mental health issues
* Substance use issues
* Family history of suicide
* Family violence or sexual abuse
* Having guns / firearms at home
* Being in prison
* Medical illness
* Being ages 10-18
  `,
  { type: 'QuizSet', slug: ContentType.STATIC_11 },
  `
## Finish

Now you have completed the eleventh session of this treatment. You may click finish to return to the home screen. See the Practice session to review the treatment content.
  `,
];

const STATIC_SESSION_12: StaticSessionContent = [
  `
## Key statistics (1/2)

As discussed, teenage years are a stage of rapid physical, emotional, and social development.

Suicide attempts among teens may be associated with feelings of sadness, confusion, anger, or problems with attention and hyperactivity.

* 8% of high school students report trying to take their own life in the past 12 months
* Every day, 5,400 teens in grades 7–12 attempt suicide
* Each year, around 150,000 teens are treated in emergency rooms for self-inflicted injuries
  `,
  `
## Key statistics (2/2)

Suicides rank as the 10th leading cause of death in the United States.

And for certain age groups, it ranks even higher.

Suicide is the 2nd leading cause of death for those ages 10-18.
  `,
  `
## How does suicide in the US compare to other countries? (1/2)

Over roughly the same period, other countries have seen rates fall, including Japan, China, Russia and most of Western Europe.

Suicides per 100,000 people (2000 to 2012). Data from World Health Organization.

| **Country**    | **2000**      | **2012**      |
| -           | -           | -           |
| Canada      | 11          | 9.8         |
| China       | 29.4        | 7.8         |
| Germany     | 11.1        | 9.2         |
| Japan       | 18.8        | 18.5        |
| Russia      | 35          | 19.5        |
| UK          | 7.8         | 6.2         |
  `,
  `
## How does suicide in the US compare to other countries? (2/2)

Globally, suicide rates have often fallen when living conditions have improved. But the reverse is also true.

Suicide rates have increased when living conditions have gotten worse.

As life has worsened for certain groups of people, because of changing economic and social factors, suicide has increased.
  `,
  `
## Where do suicides occur?

In the US, about three-quarters of suicide incidents occur at home. Most (85%) die at the scene and never make it to the hospital.

In terms of geography, suicide rates are highest in rural areas, in the west (excluding California) and, to a lesser extent, in parts of the south and northern New England.
  `,
  { type: 'QuizSet', slug: ContentType.STATIC_12 },
  `
## Finish

You have now completed the last session of this treatment. You may click finish to return to the home screen.
  `,
];

export const STATIC_SESSIONS: Record<
  string,
  StaticSessionContent | ((roleType: RoleType) => StaticSessionContent)
> = {
  [ContentType.STATIC_01]: STATIC_SESSION_01,
  [ContentType.STATIC_02]: STATIC_SESSION_02,
  [ContentType.STATIC_03]: STATIC_SESSION_03,
  [ContentType.STATIC_04]: STATIC_SESSION_04,
  [ContentType.STATIC_05]: STATIC_SESSION_05,
  [ContentType.STATIC_06]: STATIC_SESSION_06,
  [ContentType.STATIC_07]: STATIC_SESSION_07,
  [ContentType.STATIC_08]: STATIC_SESSION_08,
  [ContentType.STATIC_09]: STATIC_SESSION_09,
  [ContentType.STATIC_10]: STATIC_SESSION_10,
  [ContentType.STATIC_11]: STATIC_SESSION_11,
  [ContentType.STATIC_12]: STATIC_SESSION_12,
};
