import type { SetData } from '../../content/quizSet/quizSetData';
import { getMuxUrlFromGCSUrl } from '../../muxUrl';

//Session 1 - MyPlan

//Collab Experience

export const MYSTORY_LEARN_VIGNETTE_SUE: SetData = {
  ID: 'mystory-learn-vignette-sue',
  name: 'Sue',
  description: 'Sue: 50, female, from small town, married with kids but feels alone',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S1/Oui_Sue_S1A_RC01_720_220315.mp4',
        ),
        title: "Sue's Story",
        compulsory: true,
      },
    },
  ],
};

export const RISK_CURVE_LEARN_VIGNETTE_SUE_COLLAB: SetData = {
  ID: 'risk-curve-learn-vignette-sue-collab',
  name: 'Sue',
  description: 'Sue: 50, female, from small town, married with kids but feels alone',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S1/Oui_Sue_S1B_RC01_720_220315.mp4',
        ),
        title: 'Making Sense',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'To relive a very difficult day again',
          '1': 'To learn about your own specific warning signs so that you can keep an eye on them and work to lower your risk',
          '2': 'You probably won’t need to learn about your own Risk Curve. Your warning signs are probably the same as Sue’s warning signs.',
        },
      },
      dir: {
        prompt: 'Why is it important to learn about your own Risk Curve?',
        feedback: {
          '0': {
            correct: false,
            text: 'Though it may be difficult to spend time thinking about what happened before a suicide attempt, it is important to learn what pushes you up the Risk Curve.',
          },
          '1': {
            correct: true,
            text: 'By understanding what pushes you up the Risk Curve, you can keep an eye on warning signs specific to you and work to lower your risk.',
          },
          '2': {
            correct: false,
            text: 'What goes in a Risk Curve is actually unique to each person. It is important to learn about what things increase your risk and these things might be different than the things that increase Sue’s risk.',
          },
        },
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S1/Oui_Sue_S1C_RC01_720_220315.mp4',
        ),
        title: 'Risk Curve',
        compulsory: true,
      },
    },
  ],
};

//Aviva Experience

export const RISK_CURVE_LEARN_VIGNETTE_SUE: SetData = {
  ID: 'risk-curve-learn-vignette-sue',
  name: 'Sue',
  description: 'Sue: 50, female, from small town, married with kids but feels alone',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S1/Oui_Sue_S1A_RC01_720_220315.mp4',
        ),
        title: "Sue's Story",
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S1/Oui_Sue_S1B_RC01_720_220315.mp4',
        ),
        title: 'Making Sense',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'To relive a very difficult day again',
          '1': 'To learn about your own specific warning signs so that you can keep an eye on them and work to lower your risk',
          '2': 'You probably won’t need to learn about your own Risk Curve. Your warning signs are probably the same as Sue’s warning signs.',
        },
      },
      dir: {
        prompt: 'Why is it important to learn about your own Risk Curve?',
        feedback: {
          '0': {
            correct: false,
            text: 'Though it may be difficult to spend time thinking about what happened before a suicide attempt, it is important to learn what pushes you up the Risk Curve.',
          },
          '1': {
            correct: true,
            text: 'By understanding what pushes you up the Risk Curve, you can keep an eye on warning signs specific to you and work to lower your risk.',
          },
          '2': {
            correct: false,
            text: 'What goes in a Risk Curve is actually unique to each person. It is important to learn about what things increase your risk and these things might be different than the things that increase Sue’s risk.',
          },
        },
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S1/Oui_Sue_S1C_RC01_720_220315.mp4',
        ),
        title: 'Risk Curve',
        compulsory: true,
      },
    },
  ],
};

export const MYPLAN_LEARN_VIGNETTE_SUE: SetData = {
  ID: 'myplan-learn-vignette-due',
  name: 'Sue',
  description: 'Sue: 50, female, from small town, married with kids but feels alone',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S1/Oui_Sue_S1D_RC01_720_220315.mp4',
        ),
        title: 'My Plan',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S1/Oui_Sue_S1E_RC01_720_220315.mp4',
        ),
        title: 'Safe Environment',
        compulsory: true,
      },
    },
  ],
};

//Session 2 - Hope Kit

export const HOPE_KIT_LEARN_VIGNETTE_SUE: SetData = {
  ID: 'hope-kit-learn-sue',
  name: 'Sue',
  description: 'Sue: 50, female, from small town, married with kids but feels alone',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S2/Oui_Sue_S2A_RC01_720_220315.mp4',
        ),
        title: 'About Hope Kit',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': "It reminds her why it's worth it to keep living",
          '1': 'It lets her think about her good memories from the past',
          '2': 'It gets rid of her bad thoughts completely',
        },
      },
      dir: {
        prompt: 'How has the Hope Kit helped Sue?',
        feedback: {
          '0': {
            correct: true,
            text: ``,
          },
          '1': {
            correct: false,
            text: 'The Hope Kit helps Sue think about her future, not her past.',
          },
          '2': {
            correct: false,
            text: "Instead, it can help her direct her thoughts to other things, so that bad thoughts don't hurt her so much.",
          },
        },
        afterAll:
          'The Hope Kit reminds Sue that there are lots of good things in her life and that life is worth living. Let’s now see how the Hope Kit is made and used.',
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S2/Oui_Sue_S2B_RC01_720_220315.mp4',
        ),
        title: 'What Include',
        compulsory: true,
      },
    },
  ],
};

export const HOPE_KIT_APPLY_VIGNETTE_SUE: SetData = {
  ID: 'hope-kit-apply-sue',
  name: 'Sue',
  description: 'Sue: 50, female, from small town, married with kids but feels alone',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S2/Oui_Sue_S2C_RC01_720_220315.mp4',
        ),
        title: 'Making Hope Kit',
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S2/Oui_Sue_S2D_RC01_720_220315.mp4',
        ),
        title: 'Using Hope Kit',
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'It can help you do more hobbies',
          '1': 'It can make you completely forget about wanting to end your life',
          '2': 'It can help you remember reasons to keep living',
        },
      },
      dir: {
        prompt: 'How does the Hope Kit help lower risk?',
        feedback: {
          '0': {
            correct: false,
            text: 'Even though hobbies could be included in the kit, doing more hobbies isn’t the reason for creating the kit.',
          },
          '1': {
            correct: false,
            text: 'It is okay to still struggle with suicidal thoughts, but the Hope Kit helps you focus on things that you may be looking forward to.',
          },
          '2': {
            correct: true,
            text: '',
          },
        },
        afterAll:
          'The Hope Kit can help you to see reasons for living more clearly. With her hope kit, Sue can see the good she’s already had in her life, as well as see the hope in her future.',
      },
    },
  ],
};

//Session 3 - Getting going

export const GETTING_GOING_LEARN_VIGNETTE_SUE: SetData = {
  ID: 'getting-going-learn-vignette-sue',
  name: 'Sue',
  description: 'Sue: 50, female, from small town, married with kids but feels alone',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S3/Oui_Sue_S3A_RC01_720_220315.mp4',
        ),
        title: 'What Sue does to get going',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S3/Oui_Sue_S3B_RC01_720_220315.mp4',
        ),
        title: 'How Sue makes sure to get going',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'It can be helpful to just do something, even if it’s small',
          '1': 'You have to spend the day doing as many things as possible for it to be helpful',
          '2': 'It is most helpful if you do new things in exciting places',
        },
      },
      dir: {
        prompt: 'What is something important to remember about “getting going”?',
        feedback: {
          '0': {
            correct: true,
            text: '',
          },
          '1': {
            correct: false,
            text: 'It doesn’t matter if you only get a little bit done, as long as you’re “getting going”.',
          },
          '2': {
            correct: false,
            text: 'It doesn’t matter what you choose to do, as long as you make a start.',
          },
        },
        afterAll:
          'Sue realized that she didn’t have to do a lot to “get going,” even something simple like taking out the trash or getting the mail was enough to make her feel better.',
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S3/Oui_Sue_S3C_RC01_720_220315.mp4',
        ),
        title: 'How getting going reduces risk',
        compulsory: true,
      },
    },
  ],
};

export const GETTING_GOING_APPLY_VIGNETTE_SUE: SetData = {
  ID: 'getting-going-apply-vignette-sue',
  name: 'Sue',
  description: 'Sue: 50, female, from small town, married with kids but feels alone',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S3/Oui_Sue_S3D_RC01_720_220315.mp4',
        ),
        title: 'Getting going example',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S3/Oui_Sue_S3E_RC01_720_220315.mp4',
        ),
        title: 'How use activity diary',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S3/Oui_Sue_S3F_RC01_720_220315.mp4',
        ),
        title: 'How activity diary helps',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Punishing herself for not doing an activity',
          '1': 'Ignoring that “getting going” helps to improve her mood',
          '2': 'Holding herself accountable by telling her friend about what she’s cooking',
        },
      },
      dir: {
        prompt: 'What helps Sue to “get going”?',
        feedback: {
          '0': {
            correct: false,
            text: 'Instead of using a negative approach of punishing herself, Sue focuses on rewarding herself when she does something positive.',
          },
          '1': {
            correct: false,
            text: 'Just by remembering that getting going does help improve her mood helps motivate Sue to get going more often.',
          },
          '2': {
            correct: true,
            text: '',
          },
        },
        afterAll:
          'Giving herself a reward, reminding herself of the positive impact on her mood, and texting her plans to do things with a friend all help Sue to “get going.”',
      },
    },
  ],
};

//Session 4 - Relaxation & Mindfulness

export const RELAXATION_MINDFULNESS_LEARN_VIGNETTE_SUE: SetData = {
  ID: 'relaxation-mindfulness-learn-vignette-sue',
  name: 'Sue',
  description: 'Sue: 50, female, from small town, married with kids but feels alone',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S4/Oui_Sue_S4A_RC01_720_220315.mp4',
        ),
        title: 'When use it',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S4/Oui_Sue_S4B_RC01_720_220315.mp4',
        ),
        title: 'How it has helped',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'You only should practice these techniques once a week',
          '1': 'These techniques can reduce stress when you feel worked up',
          '2': 'It should be obvious how relaxation and mindfulness can help you',
        },
      },
      dir: {
        prompt: 'Which of these is true about practicing relaxation and mindfulness?',
        feedback: {
          '0': {
            correct: false,
            text: '',
          },
          '1': {
            correct: true,
            text: '',
          },
          '2': {
            correct: false,
            text: '',
          },
        },
        afterAll:
          'It helps to make a habit of practicing relaxation and mindfulness, especially when you’re struggling during the day. It can help you cope with tough emotions.',
      },
    },
  ],
};

export const RELAXATION_MINDFULNESS_APPLY_VIGNETTE_SUE: SetData = {
  ID: 'relaxation-mindfulness-apply-vignette-sue',
  name: 'Sue',
  description: 'Sue: 50, female, from small town, married with kids but feels alone',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S4/Oui_Sue_S4C_RC01_720_220315.mp4',
        ),
        title: 'Apply Relaxation & Mindfulness',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S4/Oui_Sue_S4D_RC01_720_220315.mp4',
        ),
        title: 'Apply Relaxation & Mindfulness',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S4/Oui_Sue_S4E_RC01_720_220315.mp4',
        ),
        title: 'Apply Relaxation & Mindfulness',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'True',
          '1': 'False',
        },
      },
      dir: {
        prompt:
          'True or false? It is pointless to try relaxation or mindfulness unless you do it perfectly, without messing up. ',
        feedback: {
          '0': {
            correct: false,
            text: 'You can still get a lot out of relaxation and mindfulness, even if you don’t get the hang of it right away. Trying is the most important thing.',
          },
          '1': {
            correct: true,
            text: 'You can still get a lot out of relaxation and mindfulness, even if you don’t get the hang of it right away. Trying is the most important thing.',
          },
        },
      },
    },
  ],
};

//Session 5 - Improving Sleep

export const SLEEP_DIARY_LEARN_VIGNETTE_SUE: SetData = {
  ID: 'sleep-diary-learn-vignette-sue',
  name: 'Sue',
  description: 'Sue: 50, female, from small town, married with kids but feels alone',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S5/Oui_Sue_S5A_RC01_720_220315.mp4',
        ),
        title: 'Improving sleep',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S5/Oui_Sue_S5B_RC01_720_220315.mp4',
        ),
        title: 'Improving sleep',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Create a bedtime routine',
          '1': 'Stop thinking about stressful things entirely',
          '2': 'Watch TV before bed',
        },
      },
      dir: {
        prompt: 'What is something that you can do during the day to improve your sleep?',
        feedback: {
          '0': {
            correct: true,
            text: '',
          },
          '1': {
            correct: false,
            text: 'It might not be possible to stop thinking about stressful things.',
          },
          '2': {
            correct: false,
            text: 'Watching TV before bed can make it harder to fall asleep.',
          },
        },
        afterAll:
          'Creating a bedtime routine that works for you can help calm your thoughts. Rather than worrying and thinking back on the day, a routine can prepare you for better sleep.',
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S5/Oui_Sue_S5C_RC01_720_220315.mp4',
        ),
        title: 'Improving sleep',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Getting better sleep prevents all risky situations from happening',
          '1': 'Getting more sleep stops you from thinking negative thoughts',
          '2': 'When you get more and better sleep, you can think clearer and cope better with issues',
        },
      },
      dir: {
        prompt: 'How can changing sleep habits reduce risk?',
        feedback: {
          '0': {
            correct: false,
            text: 'Simply getting better sleep can’t prevent all risky situations.',
          },
          '1': {
            correct: false,
            text: 'Simply getting more sleep doesn’t directly stop all negative thoughts. ',
          },
          '2': {
            correct: true,
            text: '',
          },
        },
        afterAll:
          'When you focus on improving bedtime habits, your sleep quality will be better. If you struggle less with problems after being well-rested, your risk can be lowered.',
      },
    },
  ],
};

export const SLEEP_DIARY_APPLY_VIGNETTE_SUE: SetData = {
  ID: 'sleep-diary-sue-vignette-sue',
  name: 'Sue',
  description: 'Sue: 50, female, from small town, married with kids but feels alone',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S5/Oui_Sue_S5D_RC01_720_220315.mp4',
        ),
        title: 'Apply Sleep Diary',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S5/Oui_Sue_S5E_RC01_720_220315.mp4',
        ),
        title: 'Apply Sleep Diary',
        compulsory: true,
      },
    },
  ],
};

//Session 6 - Spot It

export const SPOT_IT_LEARN_VIGNETTE_SUE: SetData = {
  ID: 'spot-it-learn-vignette-sue',
  name: 'Sue',
  description: 'Sue: 50, female, from small town, married with kids but feels alone',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S6/Oui_Sue_S6A_RC01_720_220315.mp4',
        ),
        title: 'Improving sleep',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S6/Oui_Sue_S6B_RC01_720_220315.mp4',
        ),
        title: 'Improving sleep',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'She doesn’t need any clues because she spots the thoughts right away',
          '1': 'She feels tense and that it’s hard to breathe',
          '2': 'Spotting unhelpful thoughts isn’t important so she doesn’t need clues',
        },
      },
      dir: {
        prompt: 'What are clues that help Sue spot unhelpful thoughts?',
        feedback: {
          '0': {
            correct: false,
            text: '',
          },
          '1': {
            correct: true,
            text: '',
          },
          '2': {
            correct: false,
            text: '',
          },
        },
        afterAll:
          'Thoughts can impact our mood. Clues that her body gives her (being tense, trouble breathing, racing heart, sweaty palms) can be signals that remind her to focus on her thoughts.',
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S6/Oui_Sue_S6C_RC01_720_220315.mp4',
        ),
        title: 'Improving sleep',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'It helps you ignore your emotions',
          '1': 'It proves that negative thoughts are true',
          '2': 'You can see how your unhelpful thoughts affect how you feel',
        },
      },
      dir: {
        prompt: 'How can suicide risk go down using Spot It?',
        feedback: {
          '0': {
            correct: false,
            text: '',
          },
          '1': {
            correct: false,
            text: '',
          },
          '2': {
            correct: true,
            text: '',
          },
        },
        afterAll:
          'Learning that difficult feelings can start with unhelpful thoughts means you can try to change your thoughts. This will help you to feel better about the future and lower your risk.',
      },
    },
  ],
};

export const SPOT_IT_APPLY_VIGNETTE_SUE: SetData = {
  ID: 'spot-it-apply-vignette-sue',
  name: 'Sue',
  description: 'Sue: 50, female, from small town, married with kids but feels alone',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S6/Oui_Sue_S6D_RC01_720_220315.mp4',
        ),
        title: 'Apply Spot it',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S6/Oui_Sue_S6E_RC01_720_220315.mp4',
        ),
        title: 'Apply Spot it',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S6/Oui_Sue_S6F_RC01_720_220315.mp4',
        ),
        title: 'Apply Spot it',
        compulsory: true,
      },
    },
  ],
};

//Session 7 - Test It

export const TEST_IT_LEARN_VIGNETTE_SUE: SetData = {
  ID: 'test-it-learn-vignette-sue',
  name: 'Sue',
  description: 'Sue: 50, female, from small town, married with kids but feels alone',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S7/Oui_Sue_S7A_RC01_720_220315.mp4',
        ),
        title: 'Test it example',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S7/Oui_Sue_S7B_RC01_720_220315.mp4',
        ),
        title: 'Test it example',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'She often jumps to unrealistic conclusions',
          '1': 'They are always true',
          '2': 'Her unhelpful thoughts are new',
        },
      },
      dir: {
        prompt: 'What did Sue find out about her unhelpful thoughts?',
        feedback: {
          '0': {
            correct: true,
            text: 'Sue realized that when she had an unhelpful thought, she would only look at the proof that supported the unhelpful thought.',
          },
          '1': {
            correct: false,
            text: '',
          },
          '2': {
            correct: false,
            text: '',
          },
        },
        afterAll:
          'Sue realizes that her negative thoughts were hurtful to her. She’d been telling herself many of them for quite a while but they often didn’t make sense or were not true.',
      },
    },
  ],
};

export const TEST_IT_APPLY_VIGNETTE_SUE: SetData = {
  ID: 'test-it-apply-vignette-sue',
  name: 'Sue',
  description: 'Sue: 50, female, from small town, married with kids but feels alone',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S7/Oui_Sue_S7C_RC01_720_220315.mp4',
        ),
        title: 'Test it practice',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S7/Oui_Sue_S7D_RC01_720_220315.mp4',
        ),
        title: 'Test it practice',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S7/Oui_Sue_S7E_RC01_720_220315.mp4',
        ),
        title: 'Test it practice',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Look at the evidence supporting the unhelpful thought',
          '1': 'Tell yourself that you are silly for having unhelpful thoughts',
          '2': 'Ask yourself: “what would a friend say about this?”',
        },
      },
      dir: {
        prompt: 'What are ways to test for unhelpful thoughts?',
        feedback: {
          '0': {
            correct: false,
            text: '',
          },
          '1': {
            correct: false,
            text: '',
          },
          '2': {
            correct: true,
            text: '',
          },
        },
        afterAll:
          'Looking at the evidence for and against thoughts, and asking yourself “what would a friend say about this?” are excellent ways to test unhelpful thoughts.',
      },
    },
  ],
};

//Session 8 - Switch It

export const SWITCH_IT_LEARN_VIGNETTE_SUE: SetData = {
  ID: 'switch-it-learn-vignette-sue',
  name: 'Sue',
  description: 'Sue: 50, female, from small town, married with kids but feels alone',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S8/Oui_Sue_S8A_RC01_720_220315.mp4',
        ),
        title: 'Switch it example',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S8/Oui_Sue_S8B_RC01_720_220315.mp4',
        ),
        title: 'Switch it example',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S8/Oui_Sue_S8C_RC01_720_220315.mp4',
        ),
        title: 'Switch it example',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'True',
          '1': 'False',
        },
      },
      dir: {
        prompt:
          'True or false? When switching a thought, it is best to find the most positive thought even if it is not realistic.',
        feedback: {
          '0': {
            correct: false,
            text: 'It is important to find a balanced and believable thought. Eg: Switch “I always fail” to “There are some things that I do well”. This is because this thought is more realistic, balanced and believable.',
          },
          '1': {
            correct: true,
            text: 'It is important to find a balanced and believable thought. Eg: Switch “I always fail” to “There are some things that I do well”. This is because this thought is more realistic, balanced and believable.',
          },
        },
      },
    },
  ],
};

export const SWITCH_IT_APPLY_VIGNETTE_SUE: SetData = {
  ID: 'switch-it-apply-vignette-sue',
  name: 'Sue',
  description: 'Sue: 50, female, from small town, married with kids but feels alone',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S8/Oui_Sue_S8D_RC01_720_220315.mp4',
        ),
        title: 'Switch it practice',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S8/Oui_Sue_S8E_RC01_720_220315.mp4',
        ),
        title: 'Switch it practice',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S8/Oui_Sue_S8F_RC01_720_220315.mp4',
        ),
        title: 'Switch it practice',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'True',
          '1': 'False',
        },
      },
      dir: {
        prompt:
          'True or false? Every single time that Sue switches her thoughts she feels 100% better straight away.',
        feedback: {
          '0': {
            correct: false,
            text: 'Switching unhelpful thoughts to more helpful and realistic thoughts can reduce a hard feeling, but it will not fix everything. Sue will use all she’s learned with this app to improve her mood.',
          },
          '1': {
            correct: true,
            text: 'Switching unhelpful thoughts to more helpful and realistic thoughts can reduce a hard feeling, but it will not fix everything. Sue will use all she’s learned with this app to improve her mood.',
          },
        },
      },
    },
  ],
};

//Session 9 - Coping Cards

export const COPING_CARDS_LEARN_VIGNETTE_SUE: SetData = {
  ID: 'coping-cards-learn-vigenette-sue',
  name: 'Sue',
  description: 'Sue: 50, female, from small town, married with kids but feels alone',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S9/Oui_Sue_S9A_RC01_720_220315.mp4',
        ),
        title: 'CC experience',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S9/Oui_Sue_S9B_RC01_720_220315.mp4',
        ),
        title: 'CC reduce SR',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'True',
          '1': 'False',
        },
      },
      dir: {
        prompt:
          'True or false? Coping cards are like a shortcut that connects tough situations with specific coping skills.',
        feedback: {
          '0': {
            correct: true,
            text: 'Sue can add specific things that are hard for her on one side and coping skills that she knows have worked in the past on the other side.',
          },
          '1': {
            correct: false,
            text: 'Sue can add specific things that are hard for her on one side and coping skills that she knows have worked in the past on the other side.',
          },
        },
      },
    },
  ],
};

export const COPING_CARDS_APPLY_VIGNETTE_SUE: SetData = {
  ID: 'coping-cards-apply-vigenette-sue',
  name: 'Sue',
  description: 'Sue: 50, female, from small town, married with kids but feels alone',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S9/Oui_Sue_S9C_RC01_720_220315.mp4',
        ),
        title: 'How start CC',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S9/Oui_Sue_S9D_RC01_720_220315.mp4',
        ),
        title: 'CC more eg',
        compulsory: true,
      },
    },
  ],
};

//Session 10 - RC & MyPlan Review

export const RISK_CURVE_MYPLAN_REVIEW_LEARN_VIGNETTE_SUE: SetData = {
  ID: 'risk-curve-myplan-review-learn-vignette-sue',
  name: 'Sue',
  description: 'Sue: 50, female, from small town, married with kids but feels alone',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S10/Oui_Sue_S10A_RC01_220315.mp4',
        ),
        title: 'RC experi',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S10/Oui_Sue_S10B_RC01_220315.mp4',
        ),
        title: 'MP experi',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'It is important to revisit the Risk Curve and MyPlan because warning signs and coping skills can change',
          '1': 'She should never change her MyPlan',
          '2': 'Warning signs never change',
        },
      },
      dir: {
        prompt: 'What did Sue realize when reviewing the Risk Curve and MyPlan?',
        feedback: {
          '0': {
            correct: true,
            text: '',
          },
          '1': {
            correct: false,
            text: '',
          },
          '2': {
            correct: false,
            text: '',
          },
        },
        afterAll:
          'Reviewing MyPlan and the Risk Curve helped Sue see how skills she learned in Aviva helped her lower her risk. She knows her warning signs can change and that she should update her MyPlan as a result.',
      },
    },
  ],
};

//Session 11 - Reduce Risk

export const REDUCE_RISK_LEARN_VIGNETTE_SUE: SetData = {
  ID: 'reduce-risk-learn-vignette-sue',
  name: 'Sue',
  description: 'Sue: 50, female, from small town, married with kids but feels alone',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S11/Oui_Sue_S11A_RC01_220315.mp4',
        ),
        title: 'Rev skills exp',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S11/Oui_Sue_S11B_RC01_220315.mp4',
        ),
        title: 'Most helpful',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'True',
          '1': 'False',
        },
      },
      dir: {
        prompt:
          'True or false? Now that Sue has learned all of the skills in Aviva, she needs to always be using every one of them.',
        feedback: {
          '0': {
            correct: false,
            text: 'It can be pretty hard to use all the skills at all times. It is best for Sue to focus on one or two at a time, depending on what will help her most.',
          },
          '1': {
            correct: true,
            text: 'It can be pretty hard to use all the skills at all times. It is best for Sue to focus on one or two at a time, depending on what will help her most.',
          },
        },
      },
    },
  ],
};

//Session 12 - Post-Aviva

export const POSTAVIVA_LEARN_VIGNETTE_SUE: SetData = {
  ID: 'postaviva-learn-vignette-sue',
  name: 'Sue',
  description: 'Sue: 50, female, from small town, married with kids but feels alone',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S12/Oui_Sue_S12A_RC01_220315.mp4',
        ),
        title: 'How feel',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': "Continue doing the 'switch it' exercises",
          '1': 'Look into other resources like therapy or support groups.',
          '2': 'All of the above',
        },
      },
      dir: {
        prompt: 'What could be a next step after finishing Aviva?',
        feedback: {
          '0': {
            correct: false,
            text: 'All of the above is right.',
          },
          '1': {
            correct: false,
            text: 'All of the above is right.',
          },
          '2': {
            correct: true,
            text: '',
          },
        },
        afterAll: [
          'It is a good idea to keep using Aviva, even after you have finished. Looking into other resources is also a great way to maintain skills and keep making progress.',
        ].join('\n\n'),
      },
    },
  ],
};
