import { ComponentType, ReactNode } from 'react';
import { ScrollViewProps } from 'react-native';
import { SystemBars } from 'react-native-edge-to-edge';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import LoginFlower from '../assets/Login_flower.svg';
import LoginPlant from '../assets/Login_plant.svg';
import { useAppContext } from '../components/AppContext';
import { ScrollView } from '../components/ScrollView';
import { Heading } from '../components/Text';
import { View } from '../components/View';
import { manifest } from '../constants';
import { useWindowDimensions } from '../hooks/useWindowDimensions';
import { useI18n } from '../lib/i18n';
import { useTheme } from '../styles';

export function AuthScreenContainer(props: {
  testID?: string;
  heading: string | null;
  children: ReactNode;
  _scrollView?: ComponentType<ScrollViewProps>;
  graphic?: ReactNode;
  logo?: ReactNode;
}) {
  const { height } = useWindowDimensions();
  const { scheme } = useTheme();
  const ScrollViewComponent = props._scrollView ?? ScrollView;
  const { Logo, onboardingGraphic } = useAppContext();
  const { $t } = useI18n();
  const insets = useSafeAreaInsets();

  return (
    <View flex={1} style={{ backgroundColor: 'white' }}>
      <SystemBars style={scheme === 'dark' ? 'light' : 'dark'} />
      <ScrollViewComponent
        style={{ zIndex: 1 }}
        contentContainerStyle={{
          paddingTop: insets.top + height / 12,
          flexGrow: 1,
          paddingBottom: insets.bottom + 100,
        }}
        testID={`${props.testID}_scrollView`}
      >
        {props.logo ? (
          props.logo
        ) : (
          <View style={{ alignItems: 'center' }}>
            <Logo
              height={height / 8}
              aria-label={$t(
                {
                  id: 'AuthScreenContainer_logoLabel',
                  defaultMessage: '{appName} Logo',
                },
                { appName: manifest.name },
              )}
              role="img"
            />
          </View>
        )}
        <View
          style={{
            paddingHorizontal: 20,
            flex: 1,
            marginTop: 20,
          }}
          testID={props.testID}
        >
          {props.heading ? (
            <Heading
              text={props.heading}
              level={1}
              textAlign="center"
              style={{ marginTop: 20, marginBottom: 10 }}
            />
          ) : null}
          {props.children}
        </View>
      </ScrollViewComponent>
      {props.graphic ?? onboardingGraphic ?? (
        <View
          style={{
            position: 'absolute',
            right: 0,
            left: 0,
            bottom: 0,
            flexDirection: 'row',
            alignSelf: 'stretch',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            paddingRight: 40,
          }}
        >
          <>
            <LoginPlant height={height / 6} aria-label={undefined} />
            <LoginFlower height={height / 6} aria-label={undefined} />
          </>
        </View>
      )}
    </View>
  );
}
