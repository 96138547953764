import { useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { StyleProp, ViewStyle } from 'react-native';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { SegmentedProgressBar } from '@oui/app-core/src/components/SegmentedProgressBar';
import { SessionCard } from '@oui/app-core/src/components/SessionCard';
import { Heading, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useCurrentPatient } from '@oui/app-core/src/hooks/useCurrentUser';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { LearnSessionsFragment } from '@oui/app-core/src/screens/Learn';
import { useTheme } from '@oui/app-core/src/styles';
import { useNavigateToStaticSessionHook } from '@oui/app-static/src/hooks/useNavigateToStaticSessionHook';
import { graphql } from '@oui/lib/src/graphql/tada';
import { GQLUUID } from '@oui/lib/src/types/scalars';

import { RootStackScreenProps } from '@src/types/navigation';

export const NextSessionQuery = graphql(
  `
    query NextSession {
      user {
        ID
        role {
          ID
          ...LearnSessions
        }
      }
    }
  `,
  [LearnSessionsFragment],
);

export function NextSession({ style }: { style?: StyleProp<ViewStyle> }) {
  const { user } = useCurrentPatient();
  const { $t } = useI18n();
  const navigation = useNavigation<RootStackScreenProps<'home'>['navigation']>();
  const { navigateToStaticSession } = useNavigateToStaticSessionHook({ navigation });
  const { theme } = useTheme();

  const { data, loading } = useQuery(NextSessionQuery, {
    skip: !user,
    variables: {
      patientID: user?.ID as GQLUUID,
    },
  });

  const activeItem =
    data?.user?.role?.sessions.find((s) => s.status === 'IN_PROGRESS') ||
    data?.user?.role?.sessions.find((s) => s.status === 'UNLOCKED');

  const completedCount =
    data?.user?.role?.sessions.filter((s) => s.status === 'COMPLETED').length ?? 0;
  const totalCount = data?.user?.role?.sessions.length ?? 0;
  const allCompleted = completedCount === totalCount;

  return (
    <>
      {loading ? <ActivityIndicator testID="NextSession_activityIndicator" /> : null}
      {!allCompleted ? (
        <View style={style}>
          <Heading
            text={$t({
              id: 'NextSession_header',
              defaultMessage: 'Next session',
            })}
            level={2}
            style={{ marginBottom: 12 }}
          />
          {activeItem ? (
            <View
              style={{
                backgroundColor: 'white',
                borderRadius: 20,
                paddingHorizontal: 20,
                paddingVertical: 15,
                gap: 10,
              }}
              testID={`NextSession`}
            >
              <SessionCard
                testID={`SessionCard_${activeItem.session.contentType}`}
                state="unlocked"
                session={{
                  num: activeItem.session.order,
                  details: [], // TODO
                  title: activeItem.session.name,
                  subtitle: activeItem.session.description,
                  illustrationUrl: activeItem.session.illustration?.gcsPath,
                }}
                onPressTop={() => {
                  navigateToStaticSession(
                    { contentType: activeItem.session.contentType, name: activeItem.session.name },
                    'home',
                  );
                }}
              />

              <View
                row
                spacing={10}
                style={{ marginLeft: 6 }}
                accessible
                aria-label={$t(
                  {
                    id: 'NextSession_sessionsDoneAccessibilityLabel',
                    defaultMessage: 'Session progress: {completedCount} of {totalCount} complete',
                  },
                  {
                    completedCount,
                    totalCount,
                  },
                )}
              >
                <Text
                  text={$t({ id: 'NextSession_sessionsDone', defaultMessage: 'Sessions done' })}
                  weight="semibold"
                  size={15}
                  color={theme.color.gray300}
                />
                <Text
                  text={$t(
                    {
                      id: 'NextSession_sessionProgress',
                      defaultMessage: `{completedCount}/{totalCount}`,
                    },
                    {
                      completedCount,
                      totalCount,
                    },
                  )}
                  size={15}
                  color={theme.color.gray300}
                  testID="NextSession_sessionProgress"
                />
                <SegmentedProgressBar length={totalCount} progress={completedCount} height={6} />
              </View>
            </View>
          ) : null}
        </View>
      ) : null}
    </>
  );
}
