export type ChatTextProps = { backgroundColor?: string; text: string[] };
export type ChatArtifactPreviewProps = {
  artifactName:
    | 'ActivityDiary'
    | 'BingeEatingMyPlan'
    | 'CopingCards'
    | 'EatingLog'
    | 'EditEatingSchedule'
    | 'HopeKit'
    | 'MyPlan'
    | 'MyPlanReview'
    | 'MyProgress'
    | 'QuizSet'
    | 'Relaxation'
    | 'RiskCurve'
    | 'SleepDiary'
    | 'SoloMyPlan'
    | 'SoloRiskCurve'
    | 'SoloSuicideMode'
    | 'SuicideMode'
    | 'ThoughtDiary';
  linkText: string;
  params?: object;
  testID?: string;
};
export type ChatTableProps = { data: string[][]; markdown?: boolean };
export type ChatAssetProps = { uri: string; linkText?: string };
