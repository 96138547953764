import { IntlShape } from '@formatjs/intl';

import { PatientSupporterRelation } from './types';

export function getPatientSupporterRelation({
  $t,
  patientSupporterRelation,
}: {
  $t: IntlShape['$t'];
  patientSupporterRelation: PatientSupporterRelation;
}) {
  switch (patientSupporterRelation) {
    case PatientSupporterRelation.CLINICIAN:
      return $t({ id: 'PatientSupporterRelation_clinician', defaultMessage: 'Clinician' });
    case PatientSupporterRelation.PARENT_GUARDIAN:
      return $t({
        id: 'PatientSupporterRelation_parentGuardian',
        defaultMessage: 'Parent/guardian',
      });
    case PatientSupporterRelation.RELATIVE:
      return $t({ id: 'PatientSupporterRelation_relative', defaultMessage: 'Relative' });
    case PatientSupporterRelation.SOCIAL_SERVICES:
      return $t({
        id: 'PatientSupporterRelation_socialServices',
        defaultMessage: 'Social services',
      });
    case PatientSupporterRelation.OTHER:
    default:
      return $t({ id: 'PatientSupporterRelation_other', defaultMessage: 'Other' });
  }
}
