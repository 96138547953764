import { z } from 'zod';

export const PeakMethod = z.enum([
  'gun',
  'poison',
  'drugs',
  'prescriptions',
  'suffocation',
  'hanging',
  'cutting',
  'other',
]);
export type PeakMethod = z.infer<typeof PeakMethod>;

/**
 * Stores a single warning sign
 */
export const CrisisTimelineEvent = z
  .object({
    ID: z.string().default(''),
    isWarningSign: z.boolean().default(false),
    text: z.string().default(''),
    // To support AVIVA_MILITARY variant, we need to know what section of the suicide mode the item comes from
    suicideModeSection: z
      .enum(['afterEvents', 'beforeEvents', 'feelings', 'thoughts', 'behaviors', 'physiological'])
      .optional(),
    peakMethods: z.array(PeakMethod).optional(),
  })
  .describe('Stores a single warning sign');
export type CrisisTimelineEvent = z.infer<typeof CrisisTimelineEvent>;

/**
 * A patient crisis timeline
 */
export const CrisisTimeline = z
  .object({
    crisisPeakID: z.string().default(''),
    /**
     * A patient crisis timeline sequence
     */
    timeline: z.array(CrisisTimelineEvent).default([]),
  })
  .default({
    crisisPeakID: '',
    timeline: [],
  })
  .describe('A patient crisis timeline');
export type CrisisTimeline = z.infer<typeof CrisisTimeline>;
