import type { Contact } from 'expo-contacts';

import { SocialDistractionContact } from './types/avro/socialDistractions';

export type V1ResponseContact = {
  id?: string;
  email?: string | null;
  imageBase64?: string | null;
  name_first?: string | null;
  name_last?: string | null;
  phone?: string | null;
};

export type MyStoryMyPlanContact = SocialDistractionContact;

export function transformExpoContactToMyStoryMyPlanContact(
  contact: Readonly<Contact>,
): MyStoryMyPlanContact {
  const hasNameParts = !!(contact.firstName || contact.lastName);
  const nameFields = hasNameParts
    ? {
        firstName: contact.firstName ?? '',
        lastName: contact.lastName ?? '',
      }
    : {
        firstName: contact.name ?? '',
        lastName: '',
      };

  return {
    ...nameFields,
    ID: contact.id!, // we only use existing contacts which are guaranteed to have an id
    phone: contact.phoneNumbers?.[0]?.number ?? '',
    localDeviceID: contact.id!,
    imageBase64: '',
    imageAssetKey: '',
  };
}

export function transformExpoContactToResponseContact(contact: Contact): V1ResponseContact {
  const hasNameParts = !!(contact.firstName || contact.lastName);
  const nameFields = hasNameParts
    ? {
        name_first: contact.firstName,
        name_last: contact.lastName,
      }
    : {
        name_first: contact.name,
      };

  const value: V1ResponseContact = {
    ...nameFields,
    id: contact.id,
    phone: contact.phoneNumbers?.[0]?.number,
    email: contact.emails?.map((e) => e.email!)?.[0],
  };

  if (contact.image?.base64) {
    value.imageBase64 = contact.image?.base64;
  }
  return value;
}

export type ApiContact = {
  name: {
    first: string | null;
    last: string | null;
    nick: string | null;
  };
  phone: string[] | null;
  email: string[] | null;
  image: {
    url: string | null;
    data: string | null;
  } | null;
};

export function transformExpoContactToApiContact(contact: Contact): ApiContact {
  const hasNameParts = !!(contact.firstName || contact.lastName);

  return {
    email: contact.emails?.map((e) => e.email!) ?? null,
    name: hasNameParts
      ? { first: contact.firstName ?? null, last: contact.lastName ?? null, nick: null }
      : { first: contact.name ?? null, last: null, nick: null },
    phone: contact.phoneNumbers?.map((p) => p.number!) ?? null,
    image: contact.image?.base64 ? { url: null, data: contact.image.base64 } : null,
  };
}

export function transformApiContactToResponseContact(contact: ApiContact): V1ResponseContact {
  const transformed: V1ResponseContact = {
    name_first: contact.name.first,
    name_last: contact.name.last,
  };

  if (contact.email?.[0]) {
    transformed.email = contact.email[0];
  }

  if (contact.phone?.[0]) {
    transformed.phone = contact.phone[0];
  }

  if (contact.image?.data) {
    transformed.imageBase64 = contact?.image?.data;
  }

  return transformed;
}
