import { NativeModules, Platform } from 'react-native';
var LINKING_ERROR = "The package '@oui/native-utils' doesn't seem to be linked. Make sure: \n\n" + Platform.select({
    ios: "- You have run 'pod install'\n",
    default: ''
}) + '- You rebuilt the app after installing the package\n' + '- You are not using Expo managed workflow\n';
var NativeUtils = NativeModules.NativeUtils ? NativeModules.NativeUtils : new Proxy({}, {
    get: function get() {
        throw new Error(LINKING_ERROR);
    }
});
export function clearFrescoMemoryCache() {
    if (Platform.OS === 'web') return;
    return NativeUtils.clearFrescoMemoryCache();
}
export function clearFrescoDiskCache() {
    if (Platform.OS === 'web') return;
    return NativeUtils.clearFrescoDiskCache();
}
export function isCaptionServiceEnabledAsync() {
    if (Platform.OS === 'web') return Promise.resolve(false);
    return NativeUtils.isCaptionServiceEnabledAsync();
}
