'use client';

import { Children, cloneElement, forwardRef, Fragment, isValidElement, ReactNode } from 'react';
import { View as RNView, StyleSheet, ViewProps } from 'react-native';

type Props = ViewProps & {
  childFlex?: number;
  flex?: number;
  spacing?: number;
  row?: boolean;
  children?: ReactNode;
};

export const View = forwardRef<RNView, Props>(
  ({ flex, row, style, children, spacing, childFlex, ...props }: Props, ref) => {
    return (
      <RNView
        ref={ref}
        {...props}
        style={[
          // If the row has childFlex, we're likely trying to align items horizontally to take up the same space
          // In the case of form fields, if one has an error, we want them to align top instead of center
          row ? [styles.row, childFlex ? { alignItems: 'flex-start' } : null] : null,
          flex ? { flex } : null,
          style,
        ]}
      >
        {spacing || typeof childFlex === 'number'
          ? Children.toArray(children)
              .filter((child) => child !== null)
              .map((child, i) => {
                return (
                  <Fragment
                    key={
                      child
                        ? // eslint-disable-next-line
                          (child as any).key || i
                        : i
                    }
                  >
                    {i > 0 && child ? (
                      <RNView style={row ? { width: spacing } : { height: spacing }} />
                    ) : null}
                    {typeof childFlex === 'number' && isValidElement(child)
                      ? cloneElement(child, {
                          // @ts-ignore we pass along style always but aren't actually guaranteed
                          // the children support a style prop
                          style: [child.props.style, { flex: childFlex }],
                        })
                      : child}
                  </Fragment>
                );
              })
          : children}
      </RNView>
    );
  },
);

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
