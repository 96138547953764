import { Platform, Pressable, Switch } from 'react-native';

import { Text } from '../components/Text';
import { View } from '../components/View';
import { useI18n } from '../lib/i18n';
import { useTheme } from '../styles';

export function SwitchInput(props: {
  'aria-label'?: string;
  disabled?: boolean;
  showOnOff?: boolean;
  onChangeValue?: (value: boolean) => void;
  value?: boolean | null;
  testID?: string;
}) {
  const { theme } = useTheme();
  const { $t } = useI18n();
  return (
    <Pressable
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
        opacity: props.disabled ? 0.5 : 1,
      }}
      accessible
      role="switch"
      aria-label={props['aria-label']}
      aria-checked={!!props.value}
      aria-disabled={!!props.disabled}
      onAccessibilityTap={() => {
        props.onChangeValue?.(!props.value);
      }}
      onPress={() => {
        props.onChangeValue?.(!props.value);
      }}
    >
      {props.showOnOff ? (
        <Text
          role="none"
          text={
            props.value
              ? $t({ id: 'SwitchInput_onLabel', defaultMessage: 'On' })
              : $t({ id: 'SwitchInput_offLabel', defaultMessage: 'Off' })
          }
          color={theme.color.gray400}
        />
      ) : null}
      <View
        style={{
          backgroundColor: props.value ? 'white' : theme.color.gray700,
          borderWidth: 2,
          paddingHorizontal: 1,
          borderRadius: 20,
          borderColor: props.value ? theme.color.primary100 : theme.color.gray600,
        }}
        aria-hidden
      >
        <Switch
          value={!!props.value}
          thumbColor={props.value ? theme.color.primary100 : 'white'}
          trackColor={{ false: theme.color.gray700, true: 'white' }}
          onValueChange={props.onChangeValue}
          ios_backgroundColor="white"
          {...(Platform.OS === 'web' ? { activeThumbColor: theme.color.primary100 } : undefined)}
          testID={props.testID}
        />
      </View>
    </Pressable>
  );
}
