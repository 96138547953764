import { useMutation, useQuery } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import pickBy from 'lodash/pickBy';
import { PropsWithChildren, useEffect, useLayoutEffect } from 'react';
import { Linking } from 'react-native';
import { z } from 'zod';

import { getPatientSupporterRelation } from '@oui/lib/src/getPatientSupporterRelation';
import { graphql } from '@oui/lib/src/graphql/tada';
import {
  PatientSupporterRelation,
  PatientSupporterRelationSchema,
  UpdateOuiUserInputSchema,
  UpdateUserInputSchema,
} from '@oui/lib/src/types';

import { AddressFormInput } from '../components/AddressFormInput';
import { AuthenticationSettings } from '../components/AuthenticationSettings';
import { Button } from '../components/Button';
import { ErrorPresenter } from '../components/ErrorPresenter';
import { HeaderButtons, HeaderItem } from '../components/HeaderButtons';
import { PhoneFormInput } from '../components/PhoneInput';
import { ScrollView } from '../components/ScrollView';
import { TabHeader } from '../components/TabHeader';
import { Heading, Label, Text } from '../components/Text';
import { UserAddress } from '../components/UserAddress';
import { View } from '../components/View';
import { manifest } from '../constants';
import {
  Controller,
  Form,
  PickerFormInput,
  SwitchInputRender,
  TextFormInput,
  useZodForm,
} from '../form';
import { useLogout } from '../hooks/useLogout';
import { useI18n } from '../lib/i18n';
import { useTheme } from '../styles';
import { StackScreenProps } from '../types/navigation';

const IFU_URL =
  'https://storage.googleapis.com/asset.oui.dev/static/InstructionsforUse_23July2021.pdf';
export const AccountSettingsQuery = graphql(
  `
    query AccountSettings {
      user {
        ID
        email
        givenName
        phone
        familyName
        addresses {
          city
          line
          postalCode
          state
          use
        }
        birthDate
        role {
          ID
          supportee {
            ID
            relation
            supportee {
              ID
              user {
                ID
                givenName
                familyName
              }
            }
          }
          organization {
            ID
            isTrialOrganization
          }
        }
      }
      ouiUser {
        userID
        pushNotificationsDisabled
      }
    }
  `,
  [],
);

export const SaveAccountSettingsMutation = graphql(`
  mutation SaveAccountSettings($input: MutationUpdateUserInput!) {
    updateUser(input: $input) {
      ID
      email
      givenName
      phone
      familyName
      addresses {
        city
        line
        postalCode
        state
        use
      }
      birthDate
    }
  }
`);

export const UpdatePasswordMutation = graphql(`
  mutation UpdatePassword($input: UpdateOuiUserInput!) {
    updateOuiUser(input: $input) {
      ... on BaseError {
        message
      }
      ... on CurrentOuiUser {
        userID
      }
    }
  }
`);

export const SetPushNotificationsDisabledMutation = graphql(`
  mutation SetPushNotificationsDisabled($pushNotificationsDisabled: Boolean!) {
    setPushNotificationsDisabled(pushNotificationsDisabled: $pushNotificationsDisabled) {
      userID
      pushNotificationsDisabled
    }
  }
`);

const SaveAccountSettingsMutationSchema = UpdateUserInputSchema;

function Subheading(props: { text: string }) {
  const { theme } = useTheme();
  return (
    <Text
      text={props.text}
      color={theme.color.gray300}
      style={{ marginBottom: 6 }}
      size={15}
      weight="semibold"
    />
  );
}

function Section(props: PropsWithChildren<{ title: string; testID?: string }>) {
  return (
    <View style={{ gap: 10 }} testID={props.testID}>
      <Heading text={props.title} level={2} />
      {props.children}
    </View>
  );
}

const UpdatePasswordMutationSchema = UpdateOuiUserInputSchema.schema
  .extend({
    password: z
      .string()
      .refine((val) => val === '' || val.length >= 8, {
        message: 'Must contain at least 8 character(s)',
      })
      .optional(),
    confirmPassword: z
      .string()
      .refine((val) => val === '' || val.length >= 8, {
        message: 'Must match new password',
      })
      .optional(),
  })
  .refine(
    (data) => {
      return data.password === data.confirmPassword;
    },
    {
      message: 'Must match new password',
      path: ['confirmPassword'],
    },
  );

function AccountSection() {
  const route = useRoute<StackScreenProps<'AccountSettings'>['route']>();
  const { data } = useQuery(AccountSettingsQuery, { fetchPolicy: 'cache-only' });
  const isEditing = route.params?.isEditing === 'true';
  const { $t } = useI18n();

  return isEditing ? (
    <>
      <Section
        title={$t({ id: 'AccountSettings_accountHeading', defaultMessage: 'Account' })}
        testID="AccountDetailsSection"
      >
        <Form
          mutate={SaveAccountSettingsMutation}
          schema={SaveAccountSettingsMutationSchema}
          prepareVariables={(formData) => ({ input: { userID: data!.user!.ID, user: formData } })}
          getDefaultValues={() => ({
            givenName: data?.user?.givenName,
            familyName: data?.user?.familyName,
            email: data?.user?.email,
          })}
          buttons={{ submit: { text: 'Save', variant: 'contained' } }}
        >
          {(form) => (
            <>
              <TextFormInput
                control={form.control}
                label={$t({
                  id: 'AccountSettings_givenNameLabel',
                  defaultMessage: 'First name',
                })}
                name="givenName"
              />
              <TextFormInput
                control={form.control}
                label={$t({
                  id: 'AccountSettings_familyNameLabel',
                  defaultMessage: 'Last name',
                })}
                name="familyName"
              />
              <TextFormInput
                control={form.control}
                label={$t({
                  id: 'AccountSettings_email',
                  defaultMessage: 'Email',
                })}
                name="email"
              />
            </>
          )}
        </Form>
      </Section>
      <Section
        title={$t({ id: 'AccountSettings_passwordHeading', defaultMessage: 'Password' })}
        testID="AccountPasswordSection"
      >
        <Form
          mutate={UpdatePasswordMutation}
          schema={UpdatePasswordMutationSchema}
          prepareVariables={(formData) => ({
            input: {
              currentPassword: formData.currentPassword,
              password: formData.password,
            },
          })}
          getDefaultValues={() => ({
            currentPassword: '',
            password: '',
            confirmPassword: '',
          })}
          buttons={{ submit: { text: 'Save', variant: 'contained' } }}
        >
          {(form) => (
            <>
              <TextFormInput
                control={form.control}
                label={$t({
                  id: 'AccountSettings_currentPasswordLabel',
                  defaultMessage: 'Current password',
                })}
                placeholder="********"
                secureTextEntry={true}
                autoComplete="current-password"
                name="currentPassword"
              />
              <TextFormInput
                control={form.control}
                label={$t({
                  id: 'AccountSettings_newPasswordLabel',
                  defaultMessage: 'New password',
                })}
                placeholder="********"
                secureTextEntry={true}
                autoComplete="new-password"
                name="password"
              />
              <TextFormInput
                control={form.control}
                label={$t({
                  id: 'AccountSettings_confirmPasswordLabel',
                  defaultMessage: 'Confirm new password',
                })}
                placeholder="********"
                secureTextEntry={true}
                autoComplete="new-password"
                name="confirmPassword"
              />
            </>
          )}
        </Form>
      </Section>
    </>
  ) : (
    <Section title={$t({ id: 'AccountSettings_accountHeading', defaultMessage: 'Account' })}>
      <View style={{ gap: 20 }}>
        <View>
          <Subheading text={$t({ id: 'AccountSettings_nameLabel', defaultMessage: 'Name' })} />
          <Text
            testID="AccountSettings_name"
            text={data?.user ? `${data.user.givenName} ${data.user.familyName}` : ''}
          />
        </View>
        <View>
          <Subheading text={$t({ id: 'AccountSettings_emailLabel', defaultMessage: 'Email' })} />
          <Text testID="AccountSettings_email" text={data?.user?.email ?? ''} />
        </View>
        <AuthenticationSettings />
      </View>
    </Section>
  );
}

const ContactSectionSchema = z.preprocess((unknownValue) => {
  const value = unknownValue as z.output<typeof SaveAccountSettingsMutationSchema>;

  // Filter out empty addresses
  value.addresses = value.addresses?.filter((a) => {
    return a.city || a.postalCode || a.state || a.line?.some((l) => l);
  });

  return value;
}, SaveAccountSettingsMutationSchema);

function ContactSection() {
  const route = useRoute<StackScreenProps<'AccountSettings'>['route']>();
  const { data } = useQuery(AccountSettingsQuery, { fetchPolicy: 'cache-only' });
  const isEditing = route.params?.isEditing === 'true';
  const { $t } = useI18n();

  return (
    <Section
      title={$t({ id: 'AccountSettings_contactHeading', defaultMessage: 'Contact information' })}
      testID="ContactSection"
    >
      {isEditing ? (
        <Form
          mutate={SaveAccountSettingsMutation}
          schema={ContactSectionSchema}
          prepareVariables={(formData) => ({ input: { userID: data!.user!.ID, user: formData } })}
          getDefaultValues={() => ({
            phone: data?.user?.phone,
            addresses: data?.user?.addresses ?? [],
          })}
          buttons={{ submit: { text: 'Save', variant: 'contained' } }}
        >
          {(form) => (
            <View testID="ContactSection_edit" spacing={8}>
              <AddressFormInput
                control={form.control}
                name="addresses.0"
                requiresMailingAddress={false}
              />
              <PhoneFormInput
                control={form.control}
                label={$t({
                  id: 'AccountSettings_phoneLabel',
                  defaultMessage: 'Phone number',
                })}
                placeholder={$t({
                  id: 'AccountSettings_phonePlaceholder',
                  defaultMessage: 'Phone number',
                })}
                name="phone"
              />
            </View>
          )}
        </Form>
      ) : data && data.user ? (
        <View spacing={20}>
          {data?.user.addresses && Object.keys(pickBy(data.user.addresses)).length ? (
            <>
              <Subheading
                text={$t({ id: 'AccountSettings_addressLabel', defaultMessage: 'Home address' })}
              />
              <UserAddress {...data.user.addresses[0]} />
            </>
          ) : null}
          <>
            <Subheading
              text={$t({ id: 'AccountSettings_phoneLabel', defaultMessage: 'Phone number' })}
            />
            <Text
              testID="ContactSection_phone"
              text={
                data.user.phone ||
                $t({ id: 'AccountSettings_unknownValue', defaultMessage: 'Unknown' })
              }
            />
          </>
        </View>
      ) : null}
    </Section>
  );
}

const AllowPushSchema = z.object({
  allowPush: z.boolean(),
});

function NotificationSection() {
  const { $t } = useI18n();
  const { data } = useQuery(AccountSettingsQuery, { fetchPolicy: 'cache-only' });
  const [setPush] = useMutation(SetPushNotificationsDisabledMutation);
  const form = useZodForm(AllowPushSchema, {
    defaultValues: { allowPush: !data?.ouiUser?.pushNotificationsDisabled },
  });
  const { control, watch } = form;

  const allowPush = watch('allowPush');

  useEffect(() => {
    if (
      typeof allowPush === 'boolean' &&
      typeof data?.ouiUser?.pushNotificationsDisabled === 'boolean'
    ) {
      // prevent sending unecessary mutations
      if (data?.ouiUser?.pushNotificationsDisabled !== !allowPush) {
        setPush({ variables: { pushNotificationsDisabled: !allowPush } });
      }
    }
  }, [setPush, allowPush, data?.ouiUser]);

  return (
    <Section
      title={$t({
        id: 'AccountSettings_notificationsHeading',
        defaultMessage: 'Notification settings',
      })}
    >
      <View row style={{ justifyContent: 'space-between' }}>
        <Label
          text={$t({
            id: 'AccountSettings_pushNotificationsLabel',
            defaultMessage: 'Allow push notifications',
          })}
          role="none"
        />
        <Controller
          control={control}
          render={(props) =>
            SwitchInputRender(props, {
              'aria-label': $t({
                id: 'AccountSettings_pushNotificationsLabel',
                defaultMessage: 'Allow push notifications',
              }),
            })
          }
          name="allowPush"
        />
      </View>
    </Section>
  );
}

export const AccountSettingsRelationMutation = graphql(`
  mutation AccountSettingsRelation($relation: PatientSupporterRelationInput!) {
    setPatientSupporterRelation(input: $relation) {
      supporterID
      patientID
      relation
      roleSupportee {
        ID
        relation
      }
    }
  }
`);

function ConnectedSection() {
  const route = useRoute<StackScreenProps<'AccountSettings'>['route']>();
  const { data } = useQuery(AccountSettingsQuery, { fetchPolicy: 'cache-only' });
  const isEditing = route.params?.isEditing === 'true';
  const { $t } = useI18n();
  const supportee = data?.user?.role?.supportee;

  if (!supportee) return null;

  const patient = (
    <View>
      <Subheading text={$t({ id: 'Account_patientLabel', defaultMessage: 'Patient' })} />
      <Text
        testID="ConnectedSection_patientName"
        text={[
          supportee?.supportee.user.givenName ?? '',
          supportee?.supportee.user.familyName ?? '',
        ].join(' ')}
      />
    </View>
  );
  return (
    <Section
      title={$t({ id: 'Account_connectedHeading', defaultMessage: 'Connected' })}
      testID="ConnectedSection"
    >
      {isEditing ? (
        <Form
          mutate={AccountSettingsRelationMutation}
          schema={z.object({ relation: PatientSupporterRelationSchema })}
          prepareVariables={(formData) => ({
            relation: { patientID: supportee.supportee.ID, relation: formData.relation },
          })}
          getDefaultValues={() => ({ relation: supportee?.relation })}
          buttons={{ submit: { text: 'Save', variant: 'contained' } }}
        >
          {(form) => (
            <View style={{ gap: 20 }}>
              {patient}
              <PickerFormInput
                control={form.control}
                name="relation"
                items={Object.values(PatientSupporterRelation).map((v) => ({
                  label: getPatientSupporterRelation({ $t, patientSupporterRelation: v }),
                  value: v,
                }))}
                label={$t({
                  id: 'Account_patientRelationLabel',
                  defaultMessage: 'Relation',
                })}
              />
            </View>
          )}
        </Form>
      ) : (
        <View style={{ gap: 20 }}>
          {patient}
          <View>
            <Subheading
              text={$t({
                id: 'Account_patientRelationHeading',
                defaultMessage: 'Your relation',
              })}
            />
            <Text
              testID="ConnectedSection_patientRelation"
              text={
                supportee?.relation
                  ? getPatientSupporterRelation({
                      $t,
                      patientSupporterRelation: supportee.relation,
                    })
                  : ''
              }
            />
          </View>
        </View>
      )}
    </Section>
  );
}

function LegalSection() {
  const navigation = useNavigation<StackScreenProps<'AccountSettings'>['navigation']>();
  const route = useRoute<StackScreenProps<'AccountSettings'>['route']>();
  const isEditing = route.params?.isEditing === 'true';
  const { $t } = useI18n();
  const { data } = useQuery(AccountSettingsQuery, { fetchPolicy: 'cache-only' });

  return isEditing ? null : (
    <Section title={$t({ id: 'AccountSettings_legalHeading', defaultMessage: 'Legal' })}>
      <>
        <View style={{ gap: 8 }}>
          <Button
            variant="text"
            text={$t({
              id: 'AccountSettings_termsOfServiceLink',
              defaultMessage: 'Terms of service & privacy policy',
            })}
            onPress={() => {
              navigation.navigate('TermsAndPrivacy');
            }}
          />
        </View>
        {data?.user?.role?.organization.isTrialOrganization ? (
          <>
            <Button
              variant="text"
              text={$t({
                id: 'AccountSettings_instructionsForUseLink',
                defaultMessage: 'Instructions for use',
              })}
              onPress={() => {
                return Linking.openURL(IFU_URL);
              }}
            />
            <Text
              text={$t({
                id: 'AccountSettings_investigationalDevice',
                defaultMessage:
                  'CAUTION—Investigational device. Limited by Federal law to investigational use.',
              })}
            />
            <Text
              text={$t(
                {
                  id: 'AccountSettings_investigationalDeviceVersion',
                  defaultMessage: 'Version: {version}',
                },
                { version: manifest.version },
              )}
            />
          </>
        ) : null}
      </>
    </Section>
  );
}

export function AccountSettings(props: { tabScreen?: boolean }) {
  const navigation = useNavigation<StackScreenProps<'AccountSettings'>['navigation']>();
  const route = useRoute<StackScreenProps<'AccountSettings'>['route']>();
  const { $t } = useI18n();
  const { error } = useQuery(AccountSettingsQuery);
  const logout = useLogout();
  const isEditing = route.params?.isEditing === 'true';
  const { theme } = useTheme();

  useLayoutEffect(() => {
    if (isEditing) {
      navigation.setOptions({
        header: undefined,
        headerTitle: $t({
          id: 'AccountSettings_editHeading',
          defaultMessage: 'Edit account settings',
        }),
        headerRight: undefined,
        headerLeft: ({ tintColor }) => (
          <HeaderButtons left>
            <HeaderItem
              testID="AccountSettings_cancelButton"
              aria-label={$t({
                id: 'AccountSettings_cancelButton',
                defaultMessage: 'Cancel',
              })}
              title=""
              onPress={() => {
                navigation.setParams({ isEditing: 'false' });
              }}
              iconName="arrow-left"
              color={tintColor}
            />
          </HeaderButtons>
        ),
      });
    } else {
      const getHeaderRight = ({ tintColor }: { tintColor?: string }) => (
        <HeaderItem
          aria-label={$t({ id: 'AccountSettings_editButton', defaultMessage: 'Edit' })}
          title=""
          onPress={() => navigation.setParams({ isEditing: 'true' })}
          iconName="edit"
          color={tintColor}
          testID="AccountSettings_editButton"
        />
      );
      if (props.tabScreen) {
        navigation.setOptions({
          header: ({ options: { headerTintColor: tintColor } }) => (
            <TabHeader
              borderBottom
              heading={$t({ id: 'AccountSettings_heading', defaultMessage: 'Account' })}
              headerRight={getHeaderRight({ tintColor })}
            />
          ),
        });
      } else {
        navigation.setOptions({
          headerTitle: 'Account settings',
          headerLeft: undefined,
          headerRight: ({ tintColor }) => (
            <HeaderButtons>{getHeaderRight({ tintColor })}</HeaderButtons>
          ),
        });
      }
    }
  }, [navigation, isEditing, $t, theme, props.tabScreen]);

  return (
    <ScrollView testID="AccountSettings">
      <View style={{ padding: 20, gap: 60 }}>
        <ErrorPresenter error={error} />
        <AccountSection />
        <ConnectedSection />
        <ContactSection />
        {isEditing ? null : <NotificationSection />}
        <LegalSection />
        {isEditing ? null : (
          <Button
            style={{ marginTop: 40 }}
            text={$t({
              id: 'AccountSettings_logOutLink',
              defaultMessage: 'Log out',
            })}
            variant="text"
            onPress={logout}
          />
        )}
      </View>
    </ScrollView>
  );
}
