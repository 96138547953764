{
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S1/Bridget_S1_How_MP_Helps_GFX.mp4": {
    "assetId": "cAwUbQHZIcIdv01x3QXp01MPCNeOWGYEdro02DxhM1TiLs",
    "playbackId": "swfyU01pWrG602MRlrXBZY2jnA00Y2Lw3k02XqRerleSV00s"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S1/Bridget_S1_Making_Environ_Safe_GFX.mp4": {
    "assetId": "26CM01YLFNAj00nx93v47FxpJZsI3mUAkHCC200L5vAcEs",
    "playbackId": "mxIMzAqFZ4tTrJ016hoRXSi29AU02012t24IQTd01geR7VA"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S1/Bridget_Session1_How_MP_Helps_RC_200331.mp4": {
    "assetId": "hQi5IS02L6SHupyykr200bRnLe01xuQDjjOLg00sek7ixUQ",
    "playbackId": "incQcgPZwLmedUmKwGx2g1EHmI4emd8kYocJ49yUNqM"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S1/Bridget_Session1_Making_Environ_Safe_RC_200331.mp4": {
    "assetId": "9q9XgZcFI3QhE6MllhhTXs02S00uxTIjr9K71Zxnna2yM",
    "playbackId": "IjA2kTxqUB6d21KBGm9pN1bOojy7tZ1DB5cN6K6PszY"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S1/Bridget_Session1_Making_Sense_RC_200331.mp4": {
    "assetId": "iASlZ01r374QZUmngGlFpuOUSzuJQiCPnTn85lT5Jh4M",
    "playbackId": "dq02bEdkTKONYa84LOjj3yw7llBWAL2Gaqt008lLoDfu00"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S1/Bridget_Session1_MyPlan_and_SafeEnvironment.mp4": {
    "assetId": "EVIi01S1IwIDqkFLC9TIHUG00Tv9XiM0000LZWGA3ykN2pk",
    "playbackId": "11Nt85YpKaVQDy6YtV841XX800Qw9o0115oVyro9e8sdY"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S1/Bridget_Session1_Suicide_Story_200401.mp4": {
    "assetId": "24dLfFXNhstpOxITHKF102pokQ3zelvh21rh00hN00FTUs",
    "playbackId": "7MMRHtSO1HiqE01ZWK02BApWoHcs5C6aQs8r6qiIZxW02M"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S10/Bridget_S10a_Whats_My_Experience_With_Reviewing_RC_210423.mp4": {
    "assetId": "1w02DCO8JJ4X26kIaioow3tG9LuM02y2N6Mil4yQnuoa00",
    "playbackId": "sKDHOr600avZYHRaR8k2OIY24qMwb2IWA011W6wIzKvlY"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S10/Bridget_S10b_Whats_My_Experience_With_Reviewing_MP_210423.mp4": {
    "assetId": "afXyh3iLPt3QlKJQgDwGtYxf022qZGjmBk8M5s01ivI7c",
    "playbackId": "DabVTyANPhIz75qOJdI42CD4Ada600OVv00NEi02UyGB4k"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S11/Bridget_S11a_Whats_My_Experience_With_Reviewing_Skills_210423.mp4": {
    "assetId": "GHNC2sUuV3PdKkiR3sNgnQyoLaVoUS02KkYplKZlha2g",
    "playbackId": "yGGthdLn00Dai8Vch01ix01ibGScHEEUa100x4XQuCZ00kok"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S11/Bridget_S11b_Whats_Most_Helpful_Reviewing_Skills_210423.mp4": {
    "assetId": "E99AwXbBfUHzmyLPW3b1ISBxL9Jb5Mba6S00NBu9aJXc",
    "playbackId": "EsAPJVUesP02BuYN88y01dkKmkCiHBFP5CLEKlBszbM44"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S12/Bridget_S12_How_Do_I_Feel_Finished_210423.mp4": {
    "assetId": "ndIY111h02vYwkBZxWn6jOhTz0001ixIk6TrRrXK674Qt00",
    "playbackId": "VD5PRhEmYWqRtevoIEN8kCX85HlONWkdnz3CMwsqhBg"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S2/Bridget_Session2a_How_Does_HK_Reduce_Risk_200401.mp4": {
    "assetId": "jowPcl9HZE16y101HLKC2YvyLJ01jzzWP2TcATht01yZOg",
    "playbackId": "0002iv21x3A52r3alscGpVMGScsFHYH5vS024bY2kHhlIw"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S2/Bridget_Session2a_How_Does_HK_Reduce_Risk_210525.mp4": {
    "assetId": "edxnraJtu00hwlyY4PE01bHJ4ZKzRewF022FwNf02CHd3gg",
    "playbackId": "XYokfLbZfqC3l008QJM8RrNA8W2mM78Fvt9C3M9JOtPc"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S2/Bridget_Session2b_What_Did_I_Include_In_HK_200401.mp4": {
    "assetId": "d5cSn1FmKlOJIC9TDUzXEn6HxPMB4RSf7YS4XdWneSQ",
    "playbackId": "baTCzZuoeqw4AnWZXXZ2Ka02q3MDwjXRgs6aZrIU9AN4"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S2/Bridget_Session2c_Making_My_HK_200401.mp4": {
    "assetId": "a3fsI3I3EMQhlzFZNzPAVXv9culRdL02c3jEJqY00fq1w",
    "playbackId": "bicmLkgiX3Z7htgUzLo2a23bh6brMm00NEspVeHG6xAc"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S2/Bridget_Session2d_Using_My_HK_200401.mp4": {
    "assetId": "3yuWkodOLRwPw7En2ZECQDfgEHxlVZ00GWpKyR014xb4E",
    "playbackId": "AVkxJiYWeBZUggvkJVSVmwssRkLBx9XAqKZslsz5os4"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S3/Bridget_Session3a_Why_I_Need_To_Get_Going_200405.mp4": {
    "assetId": "1gSWJKAKjgfjJcNwXCha9loAUw5C7HZChY3vXObaH8A",
    "playbackId": "UY4HbTnn5zeg02qLtRA017YYzwetjcXQn02SNhogs16Qrs"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S3/Bridget_Session3a_Why_I_Need_To_Get_Going_210525.mp4": {
    "assetId": "f00R00c6R9AGt91Rk02K4ODrN02lS017ipaXupNm6SKmISvs",
    "playbackId": "SR4vPn01y4YBUVkopn2BCsBRrAPfFBn63ZAGGNZh7qfM"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S3/Bridget_Session3b_How_I_Make_Sure_I_Get_Going_200405.mp4": {
    "assetId": "V02Vxeb01ANLnjU3yvhLaoOU3ohzUR01UcEZuxYNvNuqzc",
    "playbackId": "f36anaVfUcYmi9aaqE00tge01MKh3yjYZgxUUvd1PmRWk"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S3/Bridget_Session3b_How_I_Make_Sure_I_Get_Going_210525.mp4": {
    "assetId": "KPIR51hV01qUlau501lixbw00A1YF5V8XIOF021SGLlwCr00",
    "playbackId": "8WqGQSdlWIDBznxrrw02q4MRFW48jrmZGiFm00WnmGwlI"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S3/Bridget_Session3c_How_GG_Helped_Reduce_Risk_200405.mp4": {
    "assetId": "ruIef59IMce3njZkzWgHZWN3wW8f2tUKdPotwiYp6sw",
    "playbackId": "C00AsV4ft5h4KHqpn5shOsBnhBnp4m7Gv9hubcmIf4yE"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S3/Bridget_Session3dGFX_How_To_Use_Activity_Diary_GFX_210428.mp4": {
    "assetId": "eA00YsoJge94CdlZv9YlZJPVctiE1gslPP4RVoLoQ02ag",
    "playbackId": "2g4eFLmKl02NQ8B00pZ2c66WLMxPaYh4I8oGsOi8UGqFo"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S3/Bridget_Session3d_How_To_Use_Activity_Diary_200405.mp4": {
    "assetId": "1zaG7BbT9bRw400feFGmTMdC4DyOSHGTyQe5600sspztc",
    "playbackId": "MtM00pO6es2Z024AQztlzG00MoJUN7ESf8tDquuaPK1uzQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S4/Bridget_Session4a_What_Is_It_How_I_Use_It_200405.mp4": {
    "assetId": "5CHnfjdj2yJwk4zkR2OEdi02eAfqxd00jehBcgdeJiR00A",
    "playbackId": "D8K7PTbXgASrYbLQk00mPR602hevouHbA2Ltdway7RtRc"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S4/Bridget_Session4b_How_RM_Helped_Reduce_My_Risk_200405.mp4": {
    "assetId": "zIfApPNJtI01cXlFtDuXzm00802XGBQXgBd8BoiimGptUw",
    "playbackId": "KKJoDlbZNrDY71m02YJpjN2Zd7WbPvU5rBAKEqMELAYo"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S4/Bridget_Session4c_Where_Do_I_Start_200405.mp4": {
    "assetId": "nWF8KclKlx47UXVx4fPDwdAfyU01gpIT00pw007Xxl9Mto",
    "playbackId": "jySlovTz02kxIPJe2fV2siEFYkoHQAj9nwrkX00Ai00Y5Q"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S4/Bridget_Session4d_Where_Do_I_Do_Exercises_200405.mp4": {
    "assetId": "9mEsF42gNPY8WNpfQo00leLEL00pTZG2cpMoi1fDO5ZXY",
    "playbackId": "1G7G02Zl75avou7WsTvjJGGdhgPU2nrPVL9O4ha01Cj5A"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S4/Bridget_Session4e_What_Do_I_Do_Neg_Thoughts_200405.mp4": {
    "assetId": "2ZQ1P33dwIfeV029D7SGCMbCjrbtVwxSub7iouD004KF00",
    "playbackId": "6hzV8tZqzcuKvCGtD7fSbRBkT102lyS641OFKqA18uYw"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S5/Bridget_Session5a_How_Do_I_Normally_Sleep_200406.mp4": {
    "assetId": "JG61jd1VPG3ZWPjmtqAdlslEmOhlMJBy7e4h8ThOaFI",
    "playbackId": "QpkvhG01CYrKwLux67wU1nmmfIpb1ri8ZnGv3IThFdLY"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S5/Bridget_Session5b_What_Have_I_Changed_200406.mp4": {
    "assetId": "zBSJc00txXmHG02PNfgwahJdCPT01N000202RVjZkyBgQuYJ4",
    "playbackId": "mwXU7C7YfG400G023fHWv6FCTKtpBDpeJy01VNJs9YHabY"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S5/Bridget_Session5c_How_Has_Improve_Sleep_Reduced_Risk_200406.mp4": {
    "assetId": "cTa023vROptySU700oAwzXAzD02Kc017uPbs5J900EKDvIPw",
    "playbackId": "UU01WacDWb1WEQwXQIzRzVbNnR6ywEpst00AYFH8KP7s00"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S5/Bridget_Session5d_How_Do_I_Start_200406.mp4": {
    "assetId": "5xAEc6sgsjiysHFg8AWtyrBQKtzjgJRYKKMTmgXNbVw",
    "playbackId": "Gq9B1aDtiOMYVuQ2S2zzC2DJ5nlZklQgO8o5tIEgtO00"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S5/Bridget_Session5e_How_Does_Using_The_SD_Help_200406.mp4": {
    "assetId": "01824pRBQ7LAmi1y1Q5fXqTi8tzCfagmDPwm4YMeDdpg",
    "playbackId": "BbWlnum26KRHlegYZ7qrkFu4NKf3C2eLnB6QMS8Tg8Y"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S6/Bridget_S6a_What_Is_Spot_It_210412.mp4": {
    "assetId": "029puApz700k8omVPTBpYCScTixbe6Vpwmv8mYE02L37dQ",
    "playbackId": "5bTWjnK6yb3dXb15jkHPIr02Ux02Cr22EGASVcdBx500cE"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S6/Bridget_S6b_When_Do_I_Use_Spot_It_210412.mp4": {
    "assetId": "dKx5d5N4FKaIp4sq00EkRlUP7f7UcGrmz498HOgZ1aKY",
    "playbackId": "QeBvoCAAvOIySjElDO4FHoPF1vo200uE8rRlgmziPpr4"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S6/Bridget_S6c_Whats_My_Experience_With_Spot_It_210412.mp4": {
    "assetId": "jSasdFvntOwko68PproPTiu8xqxhgdivVhI7Da01q234",
    "playbackId": "W8yF7R2aJWaIo02wn8KA8kuPuGKyGR51C3uVpFRehfEo"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S6/Bridget_S6d_How_Has_Spot_It_Reduced_Risk_210412.mp4": {
    "assetId": "yXhvA5VqX7l8ygkh8FG2iFUCPWkjRixQU1U86LcLseg",
    "playbackId": "QdtutYtfTkCQr4CE3F6iu00atATuRRksnVHZwBKYl9ws"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S6/Bridget_S6e_How_Do_I_Start_210412.mp4": {
    "assetId": "GqDVoWhh3Cnbz1BZp9HaIHxf02SpmBl2Ok00c3NAvRtTI",
    "playbackId": "lOiyhVwiftNVz6tyBZwyAjtytRBTM1s2t3oVBDvaxzA"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S6/Bridget_S6f_How_Was_Spot_It_Helpful_210412.mp4": {
    "assetId": "ASNklodh43SfBg228JteaWVxTQ02Sp6019CYfEIf5qsls",
    "playbackId": "R9X3XtS5ASNZsyoPP9TBdkuaYzOrl600RVMYchvGlmO00"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S7/Bridget_S7a_Whats_My_Experience_With_Test_It_210421.mp4": {
    "assetId": "c7C61dfcxa4qsGzLYIqHsNHELjUAgukvsNwnHYqcupQ",
    "playbackId": "7RKjAJJrBnHJvrbVt68BhXlJZoa1ehJWp7Rg78CxBjk"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S7/Bridget_S7b_How_Has_Test_It_Helped_Reduce_Risk_210421.mp4": {
    "assetId": "9KOu01hvqEoTTT5YEHILwdYsv7Dz5vJYp7ukyl73n4iA",
    "playbackId": "edKOTKUMLMQfdy1uKvJK1KSXT6qhu2TvUiXqdv01CZ6g"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S7/Bridget_S7c_How_Do_I_Start_210421.mp4": {
    "assetId": "v0102l01uyr9SZMJFpDIQE2b4xdlDP5kMpqArVO6SvjgnY",
    "playbackId": "PopPVQEUZVCA22ADc14uZ02zCLuCpSLB1weni9IjcPoM"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S7/Bridget_S7d_How_Did_I_Test_My_Thought_210421.mp4": {
    "assetId": "W4hxs02JKKxh9VXniHFDpq79mY4wWwZRBcyH9xq71zew",
    "playbackId": "QVnTnMOFdKMmHVKLxXybdzo01Sxu6ldyDRoVgMcO7H6A"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S7/Bridget_S7e_How_Was_Test_It_Helpful_To_Me_210421.mp4": {
    "assetId": "cZFol02BmMM01pMExjX02Rq8dasnNzWVwHHwCwHlHQ9wnI",
    "playbackId": "60102iQmig01cnS54e9oFdAceQld6g6R9FoFTRApof9vnA"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S8/Bridget_S8a_Whats_My_Experience_With_Switch_It_210422.mp4": {
    "assetId": "LZpyxOeK38lNhNiRiNeeGEeuNRHKa68bT00RQVfKLUCs",
    "playbackId": "A025Qddw2WlvpMNkX3LjinTvcl7PFM7fXNKq3VIFtTPU"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S8/Bridget_S8b_How_Has_Switch_It_Helped_Reduce_Risk_210422.mp4": {
    "assetId": "OPRQ8zyhNtwiyVthG1rz16B02012FtOp9h02YADc6fS1EE",
    "playbackId": "oVhuIVag8TTN3uxhOFizeXwmHGkZgE44Ee9002ndSXog"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S8/Bridget_S8c_How_Did_I_Start_210422.mp4": {
    "assetId": "aAGoUp6NpdGTnCgl4tCzdXo00y6VdHh3SNHRNlixMwG00",
    "playbackId": "JhaBkm011Kub56I9Y02dMoURrJRfl00sZHH9reYMsJwE02s"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S8/Bridget_S8d_How_Did_I_Switch_My_Thought_210422.mp4": {
    "assetId": "FamMP9oDY01CA6QRSpUiixVs02h7lbS025zN3VZezmPSYw",
    "playbackId": "fmvWlGXHLFQBruRhEE2plpFJPCh00sKnAmEwn1dD01301Y"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S9/Bridget_S9a_Whats_My_Experience_With_Coping_Cards_210422.mp4": {
    "assetId": "JTsMXsJ5D0102wj1v9r00N8nvBihHVSiVLkveZAi81xdEs",
    "playbackId": "yjgMSDQ11naSkD2k6UdeHrYXt2kzyoWLxKZTajr3utA"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S9/Bridget_S9b_How_Have_Coping_Cards_Helped_Reduce_Risk_210422.mp4": {
    "assetId": "lHVpj88iVLZucM02jF02UOrb4GCKNs8wXZi01pQcRQGSOI",
    "playbackId": "C902UqfFARn2c9NM02EpDgpsQaN6osCrhwwxvr1UQfsK4"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S9/Bridget_S9c_How_Do_I_Start_210422.mp4": {
    "assetId": "VcrFcYp8Uwf4n006nTQrhgS96cffVfgl8vXjv500KC01kE",
    "playbackId": "6OlSw02ZPxKNzspwTbtwtlEYgGiXV96TbaBuRM7ku1h8"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adolescent/bridget/S9/Bridget_S9d_Whats_Another_Example_Of_A_Card_210422.mp4": {
    "assetId": "00rJ8029fKlMGAdY3eG6UMKvhN9W1AEwDhyTcVQ8R00s6k",
    "playbackId": "NvDpiSRTjt93voqd1AyOySMPFUj2UrPrNEFoV011ySns"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S1/Oui_Frank_S1A_720_220228.mp4": {
    "assetId": "2pltvGXxZCVTyD00jOg18yMRCC2eADQMz7UrDpkNdR00c",
    "playbackId": "k32AatufFWgre1oNL900twVWEd00M00Xe01SCpMN7ciCxms"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S1/Oui_Frank_S1A_RC01_720.mp4": {
    "assetId": "6OSk602Y7vx02fSIcBCc6zkhpHYqyF7ff3WltN3nRepQI",
    "playbackId": "STH6On5BPX91EuCnYUaeqC735vefiS171cxmjui402OE"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S1/Oui_Frank_S1B_720_220228.mp4": {
    "assetId": "AtUgQuztq1ZVlVahUHudhyopZkAflv2Hx01PTiqoYm2A",
    "playbackId": "P004VXZb0200Wtt500wVj9G6cSn7mFHPlYRpGlcZAzicFWA"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S1/Oui_Frank_S1B_RC01_720.mp4": {
    "assetId": "701ZQgkwQpVOtsob9nwN02Elb458TT01p2Lik01cBfre5M8",
    "playbackId": "qPmioUwdQD6x6mHSSnLBnNnYLIft5EI3iE1mkkbuQzw"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S1/Oui_Frank_S1C_720_220228.mp4": {
    "assetId": "RimqA01MdanuugCgUdkt00LudySPsAwymh7Mx6MmDxklQ",
    "playbackId": "Fc5KloZ418LwKl8AnkCXYVHuHLVllP1s435daswmfIY"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S1/Oui_Frank_S1C_RC01_720.mp4": {
    "assetId": "3Irx027wsXKzhURcXJqvE6T029AyVkjqdwE02S00apNKJac",
    "playbackId": "1BB9EPnmCeiLhGWkebDLTupEPiLGUKxOBfJIqi5byaQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S1/Oui_Frank_S1D_720_220228.mp4": {
    "assetId": "eqpieXq75FJrWVGotG1siXAQXjwBALRpjT6xhHxlyCA",
    "playbackId": "AwXlcW00WPLVgl4UbVSI2edNDGdouao02eMnookx02wZiM"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S1/Oui_Frank_S1D_RC01_720.mp4": {
    "assetId": "OrNK602kaSFvlgLpZdHzsYk2jr7lcri01oJufFPF013dy00",
    "playbackId": "ijuG8A1xmjlxlFmlA00DYcEvAtwyNU8sqdXhtEQEzypQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S1/Oui_Frank_S1E_720_220228.mp4": {
    "assetId": "g1M9BX01t302g3FJe2pxd9owDS008EQqTf8kbXNk015ZY9Q",
    "playbackId": "021BsppPEEDJya00lkbzencGw5OZ1mtaQhb1fnDQv4DUk"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S1/Oui_Frank_S1E_RC01_720.mp4": {
    "assetId": "Gb01o9xTG02N00jp71dMPvkufQ926yAKn6UEjh7zS3TFjw",
    "playbackId": "6Jk9ObsvbboabcqyaHi00nZmPhvPEWCOFRsxldWGJfoE"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S10/Oui_Frank_S10A_720_220301.mp4": {
    "assetId": "LdyUyjMxHrSHJrnCEVYgs9tuGEcStjJv6zXU2mCS6sk",
    "playbackId": "tmpqJakrs5P34rgjKYoK01y5ZoZfx55NhfLB86RTz01xg"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S10/Oui_Frank_S10A_RC01_720.mp4": {
    "assetId": "00wmEVWOFh2oOJroL8lqCz3uqo4301QotuC01W0258q6vW8",
    "playbackId": "UQH6Cyx0001401z4KJPmlHb5xX7lJd13LEh00aEM5ejOAC8"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S10/Oui_Frank_S10B_720_220301.mp4": {
    "assetId": "ddMdHS335dg45GauIUhNpJaIgYC66P3JR1UGbU019y8o",
    "playbackId": "kyYn2snnF005wf017sQDBAXPIgox8LHhtcXLPA59mbJSI"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S10/Oui_Frank_S10B_RC01_720.mp4": {
    "assetId": "x1FGNlFONq012W00uUz64wei02fbQDz9GtX6g2XupL935E",
    "playbackId": "6caOFc66dq3hhOzVEUkdQ4JtfuGX200nsFItO3OBGrMs"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S11/Oui_Frank_S11A_720_220301.mp4": {
    "assetId": "aqjF36HKfkG4rjS02FrfLUeK1c02GUdn5Ab601aoBjuVik",
    "playbackId": "wBtZ3PfFbFPIbfOTNEClz2kRCmhaqudTrHMyRAlaMj00"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S11/Oui_Frank_S11A_RC01_720.mp4": {
    "assetId": "qDBttke3400reaKAjJrGRA02KMPf01KrJEk1BRS5g015bV8",
    "playbackId": "Ftu7n400Jv4J8JpHkck7WPAvhc6oUeOIeLTvIqeuHicQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S11/Oui_Frank_S11B_720_220301.mp4": {
    "assetId": "YYgOZZBhgMRcTJXiz2ApjEqrRlGD00AP3mqsdMyiyszI",
    "playbackId": "iSbLB00mqf9xaLvFsXSBb3uk9Tygs7M9QZPK69ggSz6k"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S11/Oui_Frank_S11B_RC01_720.mp4": {
    "assetId": "v2BABAAkK2xvsoGgstfUrxoCohvEgmAXrf3VaZcir3w",
    "playbackId": "8TZ8AdPpHYLziHnQmh02BplI02Nqo1VIdPD01GqMz5b5oM"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S12/Oui_Frank_S12A_720_220301.mp4": {
    "assetId": "QVv1rkrrL300SMHgXYjhdX8pbsm017Z01kf9qwob3Zl00LY",
    "playbackId": "lNTvwr5qzBX2XURbAFU00xcG02Etf02eW7wqMFA7R9vKn8"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S12/Oui_Frank_S12A_RC01_720.mp4": {
    "assetId": "DDl1n9T1IpJLWrkiSPC8T7f7SLOiBV8spH01YywcsR3c",
    "playbackId": "e7sLXNnVGfqkA8CONSTTC82ndlEPN00xtbkAqXsUcmew"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S2/Oui_Frank_S2A_720_220301.mp4": {
    "assetId": "Z2K57BRa8Jgbito9WcJYL9KO5H19wj3zxG2i5ho00EkE",
    "playbackId": "cGgWn1Jhm01U0201W02IvfYXGWLpONFgIT2Tts601tAojAR8"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S2/Oui_Frank_S2A_RC02_720.mp4": {
    "assetId": "dF486klsd6ExNEuUS00fhKTZ65jvhfgOi4YHtvbKQBnU",
    "playbackId": "VqpsT5rK2RppbOoJx8RerdxcwykOri73tVfXys2CLdU"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S2/Oui_Frank_S2B_720_220301.mp4": {
    "assetId": "hr8eS51eQLSK9caigBmQqSKIByxPmb8DtZz02QQNhllE",
    "playbackId": "sno4hBu00QGCoaWD00usI9PxKzVXyWmp38C5kbHcWg015Q"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S2/Oui_Frank_S2B_RC02_720.mp4": {
    "assetId": "dlBX01AndKto7JZOV0100qgbMcP2qR02ZzysnnKtOlYoiQo",
    "playbackId": "ugVH23xxhjsyim012AT7e023gOrgLA1rJuNKUb4pABYQc"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S2/Oui_Frank_S2C_720_220301.mp4": {
    "assetId": "6GQtYRWCdSaAK029w4iaOC8PerPIjut702tXF9LUP2kSg",
    "playbackId": "kV9DCrzyA4b29ExehqjRLSBZbQJq5ojze00cWAu3fxA8"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S2/Oui_Frank_S2C_RC02_720.mp4": {
    "assetId": "2xIi02s009pgZLhr202lQVUYbYhzL7znNfIUsZTuVrlcng",
    "playbackId": "J00uGuuGU00h013bBovlbX9YhI02Q8003HUnbIx1a2kT01Hks"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S2/Oui_Frank_S2D_720_220301.mp4": {
    "assetId": "2sjHykj9XhbMunaqcSG800p84PGpZsxYgGQ67eiel02Lw",
    "playbackId": "zT5XDMHeX7HACc02gjC3iwim402YR3202vYLVHuRsB8xGQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S2/Oui_Frank_S2D_RC02_720.mp4": {
    "assetId": "7K2fceC3oSFSIOnJGAR01uXBzFVSLJrXvI6x82Ewm2yk",
    "playbackId": "4RcYccCCzq4SxMVbenPQIzdGd6E00EiWWHO5jzOhBwCA"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S3/Oui_Frank_S3A_720_220301.mp4": {
    "assetId": "UJbemdmVpaziuAyinCpNWpFOocDxansWrnpc79r3WoU",
    "playbackId": "mLOrT7hbBYoCjVwakC4aXQOo02iJbCGN00ibnLLBxw7p8"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S3/Oui_Frank_S3A_RC01_720.mp4": {
    "assetId": "BweTEOoGH5M4Ck6D3UiX4xZOqj7jzI69XhtQvang9wY",
    "playbackId": "aMF1PzGPXmW9a00ALvRCiI01Tgd402CtJlR9JwdEGT5Kk00"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S3/Oui_Frank_S3B_720_220301.mp4": {
    "assetId": "nsSIPgG1NB6jn00m973pRB23IBD4htkzxejKsqcADtUc",
    "playbackId": "C5GsA5xIjYzs8y01ZGcGwKOaRCbtJKzOtikkmxKOOSCs"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S3/Oui_Frank_S3B_RC01_720.mp4": {
    "assetId": "vsGDIEJL26cdXRuENdo2iKYw4W8wl26wxa9g5w7sE88",
    "playbackId": "nv02OZMyRX6XQCuY027TsoawwnKJ4NQyXH6y01kiaDrNSQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S3/Oui_Frank_S3C_720_220301.mp4": {
    "assetId": "f2HmwgIeNhIVn2vtsy1tFIKldvBTkRg1tlVee1niwOc",
    "playbackId": "nZpkQ97GrTOm7GI1liynO01z74Q7Xs3uNBG00esfpsOrw"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S3/Oui_Frank_S3C_RC01_720.mp4": {
    "assetId": "geD2wg00gGDNtEldQzz00rYC4xD00uJ9300vOm3cqa3e3wA",
    "playbackId": "500hiQ4QYeky6vMTlK6SauqER1G26H011l02sc9upNqUpo"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S3/Oui_Frank_S3D_720_220301.mp4": {
    "assetId": "yIH02LFITWMemujWHaedLhPEepXYabn75HE7hML002nvw",
    "playbackId": "1ZVU9anYwCwLEv4UrRGz017h2lNZRos1WV9OzbwMb02KM"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S3/Oui_Frank_S3D_RC01_720.mp4": {
    "assetId": "01MrAtn3tm00MVO02aAs00vI1WSJINDJ2bdb00SzvWmUNpko",
    "playbackId": "XMkYd00tGQUl2NhhcwumUskGeeDKf00jooVcjLaV8S32M"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S3/Oui_Frank_S3E_720_220301.mp4": {
    "assetId": "C4W02oxI4GxevokOGGcv25uitbUvThMBZ5FdRKkswOc4",
    "playbackId": "01DQerg00G00lhPd00BA8eCYBqczc1rRO02pRuzPn902d01pk8"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S3/Oui_Frank_S3E_RC01_720.mp4": {
    "assetId": "QJADW02Fnu5IwxL9PLeMdzg2n00MOfXrZmfJOoYPGUt100",
    "playbackId": "Kyo5v2BrW9Yp003HxIG02FL9upqPePh3nQzNN0095wuguI"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S3/Oui_Frank_S3F_720_220301.mp4": {
    "assetId": "SwKZF1nLwKO7025rVoo2irAuKm5r9i17bDL48KTq7hzU",
    "playbackId": "is91zGvzla99t3JphSzmdKMRaKVE02apg8AOsdvQ02BE4"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S3/Oui_Frank_S3F_RC01_720.mp4": {
    "assetId": "X76RZUYe4sT2ye19ka3WPD3qICJFJ1L01NYPyZYnodAU",
    "playbackId": "4WW8007RjShCiqcwyTMAye02crKuxnWhCH700fVzDcAEn00"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S4/Oui_Frank_S4A_720_220301.mp4": {
    "assetId": "WBHrkk2iZNarFLbBmnrEHU02JAS3hKMM9gW78PIzFH1I",
    "playbackId": "BT97SKjGJgxEWHaS3xFZmQeBDEVoCdEweUVP2lC5JV8"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S4/Oui_Frank_S4A_RC01_720.mp4": {
    "assetId": "3ZMhWGzAXmGHPqkaF401Z9z7LkTXitXD4z8JJ01uvKwPE",
    "playbackId": "Qx02cfPFv98LEjwLWxiS6XLPiOHrjnfSlAe700iagIcB00"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S4/Oui_Frank_S4B_720_220301.mp4": {
    "assetId": "jck4jCaaCKrc102H9Q3RLNXSnAswQUb00qsCfgtCy9WrY",
    "playbackId": "C8xdM02S00bGYc02VlMBKT8gbFbCdoWlzh8F3c016V5V11M"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S4/Oui_Frank_S4B_RC01_720.mp4": {
    "assetId": "VFJ1601VKANv3QSyfhM3Ispdcsyg02X5J4JLKd8KmITjo",
    "playbackId": "fuEjtnRGIeruh800CSWyYhTOhkAdsR01Wr3z02hWcTOhCg"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S4/Oui_Frank_S4C_720_220301.mp4": {
    "assetId": "1IoPkpI6ysQnBi7mR01U00Wp8EosY5H6lD02c3PMxW2qnM",
    "playbackId": "gMW9XV02hgjlVbAxz01lF6z1XuMH8dGduSO00TYkYXz702c"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S4/Oui_Frank_S4C_RC01_720.mp4": {
    "assetId": "NZpGNMVXLDR24M2HNSETJlvxtKlrcgF1v01QEFw7PUZg",
    "playbackId": "D79GqOZdY2IrKu4rP00CEUFNJo2Y39SwU5db9pRZQxxg"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S4/Oui_Frank_S4D_720_220301.mp4": {
    "assetId": "aasjYG012u3jmAAH5T02TCR1rb5wVskwee00U4fdljyA1g",
    "playbackId": "gm00ezqzbXq4j200LsUg2JGxDKgm1UkopBrD02acbOhtlE"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S4/Oui_Frank_S4D_RC01_720.mp4": {
    "assetId": "ISdXwH6ay3hj01UGu1du01QZCcuHVNtQEf6VCM1jTB8YE",
    "playbackId": "euC763TsNQShL38lLLVv8zzE1ZczmQJepSOnHGxb02VE"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S4/Oui_Frank_S4E_720_220301.mp4": {
    "assetId": "26wpBMn5FoM5Qpr6VKCC26Hg004taDMqQ1PVTbZ131XU",
    "playbackId": "Y4xT7YEx78rj5iRNe2Enq4ryR9XOaURBeBE4sAgvNKg"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S4/Oui_Frank_S4E_RC01_720.mp4": {
    "assetId": "fLrZaLk71l8B6Hp9BL7MeZhoSZOUhst8jBFHdrZ52dY",
    "playbackId": "aIKRjXN4looQUfDddm7mtMsEYuTQ7fookqffnf01s47k"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S5/Oui_Frank_S5A_720_220301.mp4": {
    "assetId": "20201dfuGwhIvT4V68K7nmmvL9yihHH5s00KGTyvXpeTZU",
    "playbackId": "HQ02iZ13R6npAd7ERW3O9bPoD01EDCvK4YjL1xC00Huw1Q"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S5/Oui_Frank_S5A_RC01_720.mp4": {
    "assetId": "dAQtz9gQsi6PB8GvrMBOYFhTPuW7R6C3OrBxH66n016w",
    "playbackId": "jvXSA6X4Yx00bc2jD5lS302MWCWcNCqyQTZkNhBQIVn2U"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S5/Oui_Frank_S5B_720_220301.mp4": {
    "assetId": "ldj8pmGorLi00WTYCncREyPGCuYVArOtXYm8iCGnol02M",
    "playbackId": "Oyh8vTyvEUqxpmeqfIBQtv01tmaRQD3sTVyzrpoDXF8Y"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S5/Oui_Frank_S5B_RC01_720.mp4": {
    "assetId": "K02WXnboqwvFSXA69SBR7Xe101otCoDXhKjVckb8sSg5o",
    "playbackId": "1Y6eGNpSAhgLHEK4tWw5RMInBTmx01cV31nsiV4PlB4o"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S5/Oui_Frank_S5C_720_220301.mp4": {
    "assetId": "2Ld02HaLTIoZXuX00qsqPV7hqklpPyDW28lo6gbipvKhU",
    "playbackId": "k6TQ6501T0100m47A72i3hNtRyJoPQmGLTWUsI01ZrYB1HQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S5/Oui_Frank_S5C_RC01_720.mp4": {
    "assetId": "4IDHyQ1AQqriHO00PVRi44YD1FgyS7ZETWEgnO162UNs",
    "playbackId": "5llbagXo6LPOE4013NQieQBVDbX46SA7u44DsAa6pl28"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S5/Oui_Frank_S5D_720_220301.mp4": {
    "assetId": "mwi9y7Ck1vpjoxWeVHHYjF008fj2SmhxFZTALdePRGV8",
    "playbackId": "f2hjjQJdMsiOAsTF01jDGAX2ZT01qTZCFblfj16E7TGzU"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S5/Oui_Frank_S5D_RC01_720.mp4": {
    "assetId": "XsvDNrqA4Gz01STFHZH3ZmwTqm02018Efky78Qn36Hhb3I",
    "playbackId": "bzU5TuFDc01Hr5N00gL00HLL9AbLfzQFjXliSDG428L3hE"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S5/Oui_Frank_S5E_720_220301.mp4": {
    "assetId": "027QKWry8gplKW501GkS9POxcSGhpIH6UKGcJ4Q01Iy4LU",
    "playbackId": "Iu402E5N25TTph6800Z01ksie00FtNHQ9ohumVL8YmNb7bU"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S5/Oui_Frank_S5E_RC01_720.mp4": {
    "assetId": "AIQPTW9pl1XBkRCNu4IGP021eFnB12MB12G8z001dtaCM",
    "playbackId": "Rc0001J48KLEHkQ28NYF4zN02XhK01fO5tdIfAoza28V54E"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S6/Oui_Frank_S6A_720_220301.mp4": {
    "assetId": "mJGgk4MyANrELpcqUAW5XIF005Cxm6UD8m7xRfbVm78Q",
    "playbackId": "blY6pmS6YYQHipOppLsdfCBKxijuN5fNpqbF301VaMl8"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S6/Oui_Frank_S6A_RC01_720.mp4": {
    "assetId": "1E52s2NEGTtjV8zRq8A8k102YuHLRdGm900tvG00fx00uEs",
    "playbackId": "a9k022kSwQIjLC027MEePN8xmYuXy6K8a6dW800vQULInM"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S6/Oui_Frank_S6B_720_220301.mp4": {
    "assetId": "gB2CN3sc9eXADMAbbZHG3yPXOha7301p8obLv4ZgueHA",
    "playbackId": "KE8zUA24UkkhU5C6oBVV3raHdoTtV2hMsZE01P1W6VYQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S6/Oui_Frank_S6B_RC01_720.mp4": {
    "assetId": "8552hje9CTSeDEAuZph39cndY00Xnv1ZN3qNhpnnC8xQ",
    "playbackId": "8zpZ2cxIWcbgmrck4AGef8ufdCIEhPM2wKuA6sonE4c"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S6/Oui_Frank_S6C_720_220301.mp4": {
    "assetId": "tnF02ltcWcF01v4LoC8A022h9sAjAyRUI02Z01rxLTDWU86s",
    "playbackId": "1n4N00CThcpnf3rLuh5MmwPUoyUB7c4364z6wh4kMlrI"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S6/Oui_Frank_S6C_RC01_720.mp4": {
    "assetId": "Ijhy0000H02025PoZm021HXYgXcpLdX9Q02icRR2yuJnroDRI",
    "playbackId": "NCOF7jDIc02ktp801HdZXYUD025mTVa9l01rFjRRziLLspM"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S6/Oui_Frank_S6D_720_220301.mp4": {
    "assetId": "IyFWZLhGp6q2kBgRXIdMYmBiDRH8VR02Gl2ueKynMVwA",
    "playbackId": "bKmStLg75fBoezGAQfxHKvIo8Kg5RE8atJrePqETDvU"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S6/Oui_Frank_S6D_RC01_720.mp4": {
    "assetId": "vu2hS7b1M02ewnpmwXYeo45dJQTX3y5KEsJ01QFql3xnE",
    "playbackId": "uNiGmZYlKdMYq5bmTpsn64S01JqAH1HNRthwbsJB1pDg"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S6/Oui_Frank_S6E_720_220315_rev.mp4": {
    "assetId": "OKocfyeToSXnklmWQQDaJqMoC53k6rZy1nMlzp1N1xk",
    "playbackId": "bKnNpAvxx02Rxzc02OTivOklt1flrh1cqWxEQyq8D02R6E"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S6/Oui_Frank_S6E_RC01_720.mp4": {
    "assetId": "UPqAQLty3Zf2uZNIFWMONCDWCKAval6iXNUAhGN1PO8",
    "playbackId": "hobq6C2Lt3WhgRaTx3z02IQPf4YARcRQnKAp7mq00401uA"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S6/Oui_Frank_S6F_720_220315_rev.mp4": {
    "assetId": "NGx024t16hbq1zuvlkBX6slgTFt6UpKcQ2qhQwmlBbwc",
    "playbackId": "9mueF6THOw5o7biA6F5bc00WfmUw3ttE026WAg1jr9zjg"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S6/Oui_Frank_S6F_RC01_720.mp4": {
    "assetId": "3yw7a1V1TxKKWCb5tD2szPXZG01mTOeYqUtFoR1etKuo",
    "playbackId": "6ZZBWdajPjMm2OD00qNL7ijANB9PPlM8EQPw01b5fUs8M"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S7/Oui_Frank_S7A_720_220301.mp4": {
    "assetId": "Bnc4X7ACetmfS3NXqP2pba01Z02qDryG8AuJHpPH5xPuI",
    "playbackId": "yKqxcaAmOB8nTtIvcyaed01Kw902Db8WPu3Nc8MdGUvwU"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S7/Oui_Frank_S7A_RC01_720.mp4": {
    "assetId": "8Js02QREtBU8g85IOJIbnoJRn3BBEZxKXy4jG00bxrO8o",
    "playbackId": "h24mxC000202dNWhi8a01o56rimcEZSoC00dns01jIi8VU022c"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S7/Oui_Frank_S7B_720_220301.mp4": {
    "assetId": "sPN954023A7vKBc5QPdXHY2nQgZ6YRY8ZJRxvwafDzAY",
    "playbackId": "xK9ZYWd75tYouKkQYhaq9v6wu300ikVXUfHTnkBojwYk"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S7/Oui_Frank_S7B_RC01_720.mp4": {
    "assetId": "NmO602hfxPOhWVURV8ecJklmEdwWzW78xZ0100hijNL9yU",
    "playbackId": "z97oHdR5tiacXvkY02hh39yJ5imPmb02J4tBPPoJFG1pQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S7/Oui_Frank_S7C_720_220315_rev.mp4": {
    "assetId": "dImneBalizP00zvMiy4mlpy8EoPtd009RifivGyaEkNc4",
    "playbackId": "15uuDx01SNRpSTyTDZZrUOEPgK01TnC2IvpzUI93ZuwL4"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S7/Oui_Frank_S7C_RC01_720.mp4": {
    "assetId": "z8Jh00vpn78uaQGW02qGEdzNi501vG01ba01Is4ldU1GfYXg",
    "playbackId": "aFYZ00HTl01Ok5uycckk201kRiCnB9Np9d00kpSH5N31ioY"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S7/Oui_Frank_S7D_720_220315_rev.mp4": {
    "assetId": "Ngj1zbyNIe2QHxo01SkDMVKCfMEqAb1bPwC9AwSxV4Fk",
    "playbackId": "sqYS6enwuqjA2K201dVG01cmUIjhMK9IOGiL1XsIafCTg"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S7/Oui_Frank_S7D_RC01_720.mp4": {
    "assetId": "uGbue024tsdbvuMftlGawiCP1Uj9GF9aGICduaoins5w",
    "playbackId": "YQmZIMTWyu02QI1JmMK5hX02knYH3fWeBbMrLjbYZWOwE"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S7/Oui_Frank_S7E_720_220315_rev.mp4": {
    "assetId": "R2CSeN007Q00QNTCb95PLzSsaNxobrJCN34a4tkOhIosU",
    "playbackId": "WGypT1kVQbrQodIn2Ikv8pZY5gNuFu7mgYOrAUZt4j00"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S7/Oui_Frank_S7E_RC01_720.mp4": {
    "assetId": "piz5qA00Z902fuyzoppX9rwwp3G5H023DiVESQbf00801gD00",
    "playbackId": "9T12wv7b2D9pRjxAwCQxwE4jYwKXvz00wPfrtiCew28w"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S8/Oui_Frank_S8A_720_220301.mp4": {
    "assetId": "6Lo01cigJWtwBs00Sir6Kv44JHv2PR02JAnHIL001asMBBI",
    "playbackId": "B5PqUqCoQS02J52Q33CG5ui302FZ01A00Z6iX7Lwz6Yb1W00"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S8/Oui_Frank_S8A_RC01_720.mp4": {
    "assetId": "FslFAlHM4z1Pp7bSy2Mqq6Y7ac0200JWJXepbDHsGJrEI",
    "playbackId": "t6qSVcrIbze5u3ncUeiIrAcjVBrGmpsTFdW1hRaEG4E"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S8/Oui_Frank_S8B_720_220301.mp4": {
    "assetId": "qr693HFzq5G01HMHMN3LZ1022nuYCuVKDCfQcEFfAOvhA",
    "playbackId": "drWacoD33mC3119e3uN00ogRLUzRACE7ebBnns15q5Ko"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S8/Oui_Frank_S8B_RC01_720.mp4": {
    "assetId": "OhgspPHJpFdNK8Y93JgXlfTxoSpy1UZWxCslRq2B02qg",
    "playbackId": "npSRwClkSrglX9Ntc7YixWJJPz601qBfJhdcd6O8zyaU"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S8/Oui_Frank_S8C_720_220301.mp4": {
    "assetId": "yDMrvGFxAMdED1bAtJs01ktvXfRidBQ6ZhLIMjNuJ02Uk",
    "playbackId": "iVsneiZ4kUxDGDGRBUcPcZMbtIbER3UdEWmMSiGfq1k"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S8/Oui_Frank_S8C_RC01_720.mp4": {
    "assetId": "G01vW8pktZq8jbxrpgebIaj71w9be8yCUSrQ02DzEDJoA",
    "playbackId": "7DVd02vDg1ijgxkIYOxR2EaXlPo7zomtiIOEL6CrD027A"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S8/Oui_Frank_S8D_720_220315_rev.mp4": {
    "assetId": "xhzKDEpDH8W02jnsfsELYLEZh3LY8802REVXfnObZHnck",
    "playbackId": "aI81Mk018u02IseNrT2XydDF3gFvSd6QVV3HgU3udR01ns"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S8/Oui_Frank_S8D_RC01_720.mp4": {
    "assetId": "cMDwjp01f9t00PUO102MzFOaTyCmGg14QhC400ExPhvv5VE",
    "playbackId": "HKezrcEtvWS6EoAhgCIWcwuJi501Bdq2a02cwzfraBJ4I"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S8/Oui_Frank_S8E_720_220301.mp4": {
    "assetId": "uL1pPwBcE6J7901UqLtJOKLV2QxACwU022MRlcSakdosU",
    "playbackId": "ZY7qgb006vMXRDK14aOosLbvG9GXB8813niPZN00EM02Mk"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S8/Oui_Frank_S8E_RC01_720.mp4": {
    "assetId": "65A8MG00UCQWxrBrYINQyfc202HupvTW4Rl2mdw5x3tNk",
    "playbackId": "ExWgYSkYLamijQmlzrTQT8D82mdMjvBEPk8Y4Eqo8Cs"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S8/Oui_Frank_S8F_720_220301.mp4": {
    "assetId": "ScD8E34D01zuS2T02k02YCaQa3Pu3cLRBQhXqB8QVJBW1M",
    "playbackId": "gSxZ02pRJ2VizDYGVUJHjK012401upjCs4OTbh7Ve29BN8"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S8/Oui_Frank_S8F_RC01_720.mp4": {
    "assetId": "yuPQE6MlKLPgmJPRv5v00ba9GcyyM5qcCBaZq3OpkL01Q",
    "playbackId": "qNi00bQDOQkXE00DVWG5TIbMcHSYHAV00Khy29LB5VUqc00"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S9/Oui_Frank_S9A_720_220301.mp4": {
    "assetId": "V8SLnnEjNoAIpP1014JUlJERWaV1GagU2giAfMbZUWR00",
    "playbackId": "HlvRzdH12G6iwxz00Z5bpf2epW7KnXqxNFkZ00jIUIa004"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S9/Oui_Frank_S9A_RC01_720.mp4": {
    "assetId": "SKn9201CikBisyd0002TAC7cDQ00BH9cu00U5VlfRmsKQYsI",
    "playbackId": "Vbd3UZUYYvxtgOQ00VZbykCQKU4dwBFU009fWU6amnoTc"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S9/Oui_Frank_S9B_720_220301.mp4": {
    "assetId": "4W00601yKnVsC002TivYKaa3FAIceThnE6HB02lg6ehY00Fc",
    "playbackId": "1mfmfZOjdjb5shtpAYVALZKK9UDVy01Xmk87TU2NW6lk"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S9/Oui_Frank_S9B_RC01_720.mp4": {
    "assetId": "tO1LuT1p7nuZb3wp85wShZgcWpyv0163enOwPczeR8ng",
    "playbackId": "HKtUgu3QD801MEG8EoQC5YnsAF71i5MfOxCGidXeKiVU"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S9/Oui_Frank_S9C_720_220315_rev.mp4": {
    "assetId": "PUDe4TDEmY5P01eR02MkTt7DmGIEoG3VXsDvNZlLAT00TA",
    "playbackId": "L001TTNG01pQG01HL2IJqnQNoHaHsaUcvrMDeTasmhCung"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S9/Oui_Frank_S9C_RC01_720.mp4": {
    "assetId": "RKnBmLlyVChEPgw4201xW02RqmNNp8SYAJg5W6tKaaGnQ",
    "playbackId": "UeHd0102DVkOuUnBAStPS2eqkhetsHBVBwJEpaskfG974"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S9/Oui_Frank_S9D_720_220315_rev.mp4": {
    "assetId": "76002AS02Csjwsho8IUp5nHQo26QCPq6q00az0000RXFEG4I",
    "playbackId": "9kjT7b1rIePuXOlgpsTElSaW3WGDAi01JFBUdpSWXU5c"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/frank/S9/Oui_Frank_S9D_RC01_720.mp4": {
    "assetId": "kbkcxTnbuiWEj5veqsFrxbR5CQsDcdAuPrcHlpxkgLI",
    "playbackId": "vY022uC5vltOEz02OytfvdLBLdk02rLeXzmvn5QwUNdot00"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S1/Jeff_S1a_Jeffs_Story_200331.mp4": {
    "assetId": "Wnm5gM81smJbCo1sB02i00qFKDXrEmNp5wbU5XwkaxPW00",
    "playbackId": "R01lsmEcAnmcHL8nbyTd4ig005TF3lnw5To6Y9M02ju4Zo"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S1/Jeff_S1b_Making_Sense_200331.mp4": {
    "assetId": "ReDsx2vyGAPFqkYFBliOnzMh1hVCH6FTz6FSbPD6JSc",
    "playbackId": "AA7yxdN8E2urjtFJPOZ9CfAyXylzJ00yqG3NXowaUyQM"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S1/Jeff_S1c_How_Did_RC_Help_Explain_200331.mp4": {
    "assetId": "IwXsVufI00iurzduKS73iplEcGmwItU7dBWWc6bmigBQ",
    "playbackId": "j5WAPWY2VIps9F1FHkWDf2CEC9TIoMbhmZG5rbMY2rU"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S1/Jeff_S1d_How_MP_Helps_Me_210407.mp4": {
    "assetId": "YrunpED2YsqtezV02PQrFpMWbwVOu01iFMzeY2CmylY7U",
    "playbackId": "f00cpuU00gWWAzHym00NuJ3LShjrqPrUDqARcFuXMODgdI"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S1/Jeff_S1e_Making_My_Environ_Safe_210407.mp4": {
    "assetId": "yOeIZNZ26G7l2ymT02e15jXgDwZzIgektYiFqQE4UUp8",
    "playbackId": "ysNLJBFb02PJxEvbKX9K2hl02szbAihPmj1lbZlr36dC8"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S10/Jeff_S10a_Whats_My_Experience_Review_RC_210527.mp4": {
    "assetId": "auit8KSRKhK9GKXe6U44nIDWoHSyjPFoPC00R8eZponA",
    "playbackId": "jjzE2YTtxq6sYnYJpFwI9CoERd7rZCSkPqsiQkf6HKg"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S10/Jeff_S10b_Whats_My_Experience_Review_MP_210527.mp4": {
    "assetId": "QXBaUWA8wCev4YLMa1rEMefH0102fHuYT3NQyPk5jQDLg",
    "playbackId": "01PjiPlO2et0201v3hezRlIKrBX8B00dXWAnb98G00Voy5qI"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S11/Jeff_S11a_Whats_My_Experience_Reviewing_Skills_210527.mp4": {
    "assetId": "h2cZhk2PBWEg6JVxBk4OXK13G3YBcEsV7j01MNNuWhAY",
    "playbackId": "GVIXm8jLLEm5fSbSJVDywT02MRzMb7JcmCQCOiRz1bUY"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S11/Jeff_S11b_Whats_Most_Helpful_Reviewing_Skills_210527.mp4": {
    "assetId": "WOhA02KQIAUsdVnmxrV1pfoAkHOmORn00FabqlEb01Cl8E",
    "playbackId": "TbBPxyfDxpv38DVd00WRgnPDrgztFm8zPQqpBMwbAcig"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S12/Jeff_S12a_How_Do_I_Feel_Finished_210527.mp4": {
    "assetId": "iRcrhufAQyReRaclIFgukP9KVBuvW02O00fLlfZCYi6Kc",
    "playbackId": "GlFvCqOL4DMdVGrZf5WKxKsw7UjqTbFUEOJKx93Docw"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S2/Jeff_S2a_How_HK_Reduces_Risk_210407.mp4": {
    "assetId": "BvbvS1l3L12D7jI6Uz2GhKZF00Nri3HN9956WSFVe7bk",
    "playbackId": "NZveObQm5iZhHVTawnv01NeldykGMW2QM8tsHRZoubMA"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S2/Jeff_S2b_What_Did_I_Include_210407.mp4": {
    "assetId": "ER1g02RWIA82Bp4F5S9qMXG5g1lfSm8wsQdxUtrNdKbM",
    "playbackId": "GKKcldFK8HtlO001TzrWVZ7efvEd7RtVKkgE6BjF1AbY"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S2/Jeff_S2c_Making_My_HK_210407.mp4": {
    "assetId": "7JDx3s02y8pGPjtI8envYAwbMkRD8sWhCh8Fxpty81HM",
    "playbackId": "4jRtXoj25QAGIRmIXXMXQQQa5omdcbaAhl3hgEyOzso"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S2/Jeff_S2c_Making_My_HK_210623.mp4": {
    "assetId": "pJpTj6NnhCKzPGqMYPMawiy3OzgSwhMVRaT6b023dbmQ",
    "playbackId": "6oZKOcHxi6srrg3fRT9wWWo8qfA9jm28gGXKRD4JAP00"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S2/Jeff_S2d_Using_My_HK_210407.mp4": {
    "assetId": "qfxX01RdZ7KVWcmlCUow2GkQ7A0201I00CLl61KhslDDtvY",
    "playbackId": "EIskmlxHSx7hT5Ho2HF02h02zza1bahtDoIIM5A8YVuf00"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S3/Jeff_S3a_What_Do_I_Do_To_GG_210520.mp4": {
    "assetId": "BbSPS6aL1caKBGYXN8U022lHbE3t4SVcKv8kHOa6eFnw",
    "playbackId": "VwX27SFFk7M2A1FBTijr00kbUPbci01xI00eWtf6Pl302z8"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S3/Jeff_S3b_How_Do_I_Make_Sure_GG_210520.mp4": {
    "assetId": "o7m4x3mOBTzqjGxcRYQ15TKWQQJ7jHWSj02VqlyIMOo4",
    "playbackId": "3THyz6ZHswccEleEyH3bydWDx88NwyNRlk02u7rX3BYk"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S3/Jeff_S3c_How_Has_GG_Reduced_Risk_210520.mp4": {
    "assetId": "02Jeq2gi0138u4U7E3oGovCAgjP00MklnxXrndmt5xIqdc",
    "playbackId": "ySu6CokQe1jE70032nVeUVqr02VviNzSJrQcM3wyUwC3Y"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S3/Jeff_S3d_Immersive_Apply_Vignette_210520.mp4": {
    "assetId": "Ity019GCEwY01tHTfY7fomwUJ1eSI8v1aLxKKWsMpXkA8",
    "playbackId": "fNbZVB4s9vMUaEbLTGG012GBdc5ywZyYza8vdfu9RuzU"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S3/Jeff_S3e_How_Do_I_Use_AD_210520.mp4": {
    "assetId": "8EZ37JviGDMowz9lCNm1kmlIUPo8L00gicBlpZIWdEcA",
    "playbackId": "LlOHYLIjtYuDU5KLw00XYiGaFEG3VXT72wixzvrS301Og"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S3/Jeff_S3f_How_Does_AD_Help_Overall_210520.mp4": {
    "assetId": "T5EDjDqNSXRWF02tLwbFikAReDZaexBC702XGWXyvsJiY",
    "playbackId": "Wt5RaOJmOv5J7mEaOBJCfDqaRUeZBX4ojGIXjCvsX8U"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S4/Jeff_S4a_When_Do_I_Use_It_210520.mp4": {
    "assetId": "dIs2cNO0085Wl029Rq9x0100I2kZoh02fnvf2PkUr7OmavgQ",
    "playbackId": "6C6K1P37dbY02rzeym7AF8phOaywJ00HOFPoTCZeXDPks"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S4/Jeff_S4b_How_Has_RM_Helped_210520.mp4": {
    "assetId": "qtQpwPR7GYizVl00uMQkeYdgx02bm4jHiNUUnvjj48FrA",
    "playbackId": "VXOn02JvPmukOkxQtem01tMAUQiM2J1Gv01BA1ynIj00Vjg"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S4/Jeff_S4c_Where_Do_I_Start_210520.mp4": {
    "assetId": "00El00DAU3XwHb6DMb02flbqDBMmtoP4nvluodSPAmPkYU",
    "playbackId": "hIKJ82HOJ6g00pbMdLN1FCh3oX4cXBxCk00zbqg5qVitQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S4/Jeff_S4d_Where_Do_I_Do_The_Exercises_210520.mp4": {
    "assetId": "etDgog1OHNQejWscvtXOzvs01SYMqtHnadh02IBCLAkAM",
    "playbackId": "FVNUwQhjfkC7v102EUxhJ2v9GuaLpQ5Xj9fGYIoLvSGM"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S4/Jeff_S4e_What_Do_I_Do_If_Neg_Thoughts_210520.mp4": {
    "assetId": "00E22bU85Me00pyXtLkb4U3KE02qiF9RjCaK2KvqeP01Wtk",
    "playbackId": "cE011QIzYIAzuei69DNvaAoag00dssyKZx4rLL1301thXo"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S5/Jeff_S5a_How_Do_I_Normally_Sleep_210520.mp4": {
    "assetId": "xV9qM3qkqWQTjzCRpEXVtWfCA02LJ01POXIriTG01hY8VI",
    "playbackId": "fezJTsqngIzHjhhAUO3Cd00T79VW026IEc7kMDT1QGmr8"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S5/Jeff_S5b_What_Have_I_Changed_210520.mp4": {
    "assetId": "klxhahEgOg8UUGQQ01f5vn9DjWXcs3yuedglQ00w4WdIA",
    "playbackId": "MXLcneRCEQFaslc2dLvfi400ZJgV1kp01AK00FVKLulUhw"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S5/Jeff_S5c_How_Has_Improving_Sleep_Reduced_Risk_210520.mp4": {
    "assetId": "QL02dsa7wc5NuZEbOivSUGaG502GLnNdb02LqYFZHxCE3o",
    "playbackId": "BKkSn00DuStFqE3gVvXvN3MzLemdObQNO2wXYezT02nFM"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S5/Jeff_S5d_How_Do_I_Start_210520.mp4": {
    "assetId": "ssN01GjUO1WJS1U026x7oAq0200apou29CcXBmVVxfTmt00w",
    "playbackId": "01CjmT4004O4WtCv8CDR3C8Vcswh0002ofERbpsB00LdsMR8"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S5/Jeff_S5e_How_Does_Using_SD_Help_210520.mp4": {
    "assetId": "PZ9aDJXA02szkBOQhFotTo01czJgCmr23NUQtddkpqxTI",
    "playbackId": "HXUkMus6cmyUB500fCuPPuHlDYhb8D4E3etAg5Q7ejwQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S6/Jeff_S6a_When_Do_I_Use_SI_210522.mp4": {
    "assetId": "bzv355JPLeMQn9gOyGljTibmKsSU82QcNc2023oqDndc",
    "playbackId": "lU2XFQcUaQbjAu5q5f8ulnTxDtmkQ477caPy2TTkJBs"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S6/Jeff_S6b_Whats_My_Experience_SI_210522.mp4": {
    "assetId": "K8BDpa00ROQOiqvoyBkvRV3G3SAlX47PKLpOaovdQf1U",
    "playbackId": "QIzyyOr2fsSI3zrrkNYk1OBY01tnYqEf3HuLAABZytj8"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S6/Jeff_S6c_How_Has_SI_Helped_Reduce_Risk_210522.mp4": {
    "assetId": "oqPqmrYJ4ZbsAu1kwv1mVoTMnvLxxI4TXoDl7r1j2Uo",
    "playbackId": "vCo7Yu9vTOs1c6602tUzs6yRil7MdrvLPj21hS7rt9900"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S6/Jeff_S6d_How_Do_I_Start_210522.mp4": {
    "assetId": "zMa00td2VCydnp9NMCJdS8uEDtkq8OL172LKDHtLLZ02E",
    "playbackId": "YhRt02CJYJqYmnsX0101qV422F900p3S26KtKuJcdHcpP2M"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S6/Jeff_S6e_What_Was_First_Thought_210522.mp4": {
    "assetId": "8Ju02n02pw111kRGKG1zwJjTVJFCrL005qfHAIalwAS5P00",
    "playbackId": "AaUplxmNkLLeiMpfaWwozKeKVwQ75FtIzkyvdGvB5hQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S6/Jeff_S6f_How_Was_SI_Helpful_210522.mp4": {
    "assetId": "xnPQq5tWEOYwCzvBIB9UwIji01129MhIHZEZTdkaC2pE",
    "playbackId": "SWWZTAuC21wMvGtRCOUNd01o00nkCj3bVWhAAB7GaqqWk"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S7/Jeff_S7a_Whats_My_Experience_TI_210524.mp4": {
    "assetId": "QGTxOB21Q3xkSr02aHNHhmjqYRM9Soj3INxTjUZXv51c",
    "playbackId": "IbpxQIXKCdga7cwBZFlpKMdRvxeoZ3WnViDPnk302KKU"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S7/Jeff_S7b_How_Has_TI_Helped_Reduce_SR_210524.mp4": {
    "assetId": "grYyxyyk02i02rvGzEyA00DJl3FYoJ2mNGDIBTm901lkSrU",
    "playbackId": "iQZX2QHsJGU7MbKK5AHucj77b2oraKYrJIeF7d5Fkyo"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S7/Jeff_S7b_How_Has_TI_Helped_Reduce_SR_210623.mp4": {
    "assetId": "evYVa01K8CFoTVAMDOarNajrZiRi92fS7CRTPHuttsSw",
    "playbackId": "01f4dlIuCSV01xPjwo3gEXNdAOqOK7q1C7cuLJ2OrlYLQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S7/Jeff_S7c_How_Do_I_Start_210524.mp4": {
    "assetId": "M1ah802f1bVqsNZop01KcnEgMXZ2v7qEgYgfiDfglFsvQ",
    "playbackId": "f9HHCXUH6pUgUfbddARIyz5e01YJ99pwJo1WT9oqoWAk"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S7/Jeff_S7d_How_Did_I_Test_Thought_210524.mp4": {
    "assetId": "M02YNxWYtxpbU2h9hPEFbXz00xIdiQkPK1LpjKmx01XN2k",
    "playbackId": "rHFTplTWuMv1JZVrVncQqcSb7I4ot1qOrNnduwWd00Ec"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S7/Jeff_S7e_How_Was_TI_Helpful_210524.mp4": {
    "assetId": "xb8wVeD5OI901GP7Wt4Pq5hvosbr5hOjzx2eCi43OUzM",
    "playbackId": "gSAOnLfskLE8regjL5B3rO01YZqbEenzKPQeF8Qcxoeo"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S8/Jeff_S8a_Whats_My_Experience_SI_210525.mp4": {
    "assetId": "rGjPvr5uCsWHviSaTr85gW3YouSz9rgarNfXwBKMZ9Q",
    "playbackId": "C3eU6nfgKnH00cvudd78vxkeExZcmCqtI00sXc41iZJOQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S8/Jeff_S8b_Has_SI_Helped_Me_210525.mp4": {
    "assetId": "tN600gDr7bi9P466lw8J7ODlTdWdkItg0000pNjBCiyQNo",
    "playbackId": "fP00nTQ9XybVt26LOVh8HXq9L01lFB4WXTkFMGIpVPp1o"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S8/Jeff_S8c_How_Has_SI_Helped_Reduce_SR_210525.mp4": {
    "assetId": "40000y9rsIVi02nuZwk7DVIvxEG4BW2ZGob3ddj3DWkw8o",
    "playbackId": "02YOYfob4vj5301y02d01MOqfYf02ONMsSs2O5P7D78k101DM"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S8/Jeff_S8d_How_Do_I_Start_210525.mp4": {
    "assetId": "seGXeSdwKQvV1GH1AGLT79prkyx5EFBmn501j2jcuDjM",
    "playbackId": "VuaNjveuOKflB3M8vwIv0184qdR013iiRHEjvNR02R4GnQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S8/Jeff_S8e_How_Do_I_Switch_My_Thought_210525.mp4": {
    "assetId": "vJ7yWtqQcVZmPpTNKYNbWbklRYVZcEQxgQB4hLwbIXI",
    "playbackId": "oiNanYqs6e0002yAE00u0022FaLwak9Qa2WUdM00xeCarIL4"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S8/Jeff_S8f_How_Was_SI_Helpful_210525.mp4": {
    "assetId": "I9jgMmR01pya00tqS02m00PaGcrh1QECHKwTaH4ZP02UeWLg",
    "playbackId": "S45mT4GW47Sq4GzWZXbQU22MJrwH2dbG7g9ZK44jDRM"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S9/Jeff_S9a_Whats_My_Experience_CC_210527.mp4": {
    "assetId": "s02wSW02hFSFIIZ00sAC3MViPCUtw79vXKaTRDycBDaS8U",
    "playbackId": "Gnkm9eidxf1FjM4X81EOuiK48T004Xonqwe01uZpINr1s"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S9/Jeff_S9b_How_Have_CC_Reduced_SR_210527.mp4": {
    "assetId": "HpJeWoQiQgX83OWXGqv4wVrc9om00iWnoZrlTLqRz4jY",
    "playbackId": "ddDHTf5BZHRJProINGEkMOyrSSYtc6PbcdAUVDbVq5Y"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S9/Jeff_S9c_How_Do_I_Start_210527.mp4": {
    "assetId": "QzBKvD9bgH8zitePpyoUm4ngdgJDLL1ZQRjKrIvTyH00",
    "playbackId": "EJRV2R01mP00KDRKlw01Wi2Lehw5IY1dFug9K5MbUbqhw8"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S9/Jeff_S9d_Whats_Another_Example_210527.mp4": {
    "assetId": "lFnP9KIlse7RwErczXwUyQ00n3JyT2k7oqeqnhM2mXv8",
    "playbackId": "tudjipBxw9m400qhCYTSR4IGKtecvulHi100ZEm8ubMIg"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S1/Mia_S1a_Suicide_Story_210527.mp4": {
    "assetId": "o1J2VFALylRwv4HftNCKy1ua43tOFuF2hw2CSqnuh64",
    "playbackId": "WiIWSQbjGRtejtFt72bMXhiLroa0037fkIqdqhLw4sjA"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S1/Mia_S1b_Making_Sense_210527.mp4": {
    "assetId": "D02KNyJCrT9gWPv4uvbM4DTsC1ghJppBb1O72QL8lp2s",
    "playbackId": "B2XQTCYmsXYDzwRx8YouHKlMLbxXMm01PL019TPCqIjxs"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S1/Mia_S1c_How_Did_RC_Explain_210527.mp4": {
    "assetId": "01HqPS2jILYhLB01AWOT8dp87fC4LdZw02RHjPpD00TU8A00",
    "playbackId": "kDNHzIQzyCUAdU7lpA2Ycoj02GYIFDksxHzCNRiqA5tc"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S1/Mia_S1d_How_MyPlan_Helps_Me_210527.mp4": {
    "assetId": "cZRO7BQ3Qp02kYePLIvxBVPbkRm02kusurqrQYEtTwJRk",
    "playbackId": "P01O02XC900n4GhN1voOhOCIaXWcHZwrRrPvilaczD02US00"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S1/Mia_S1e_Making_My_Environment_Safe_210527.mp4": {
    "assetId": "vCas48KqqwqIPqXDouwuYEMv1XbwohatLdKn00mhE9XE",
    "playbackId": "dFtMyQWWnnAnoeZpnQAwaYBWsuXZGqFczgjLo1puVY4"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S10/Mia_S10a_Whats_My_Experience_Reviewing_RC_210527.mp4": {
    "assetId": "rNUMkg845GJiyzKDgbzN7G1vKoezVueZ8cwKvVwNg0200",
    "playbackId": "YP1XIgeDsz1zwPyf6sCUrM1l8yaWkWC49r2R5TbJmpA"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S10/Mia_S10b_Whats_My_Experience_Reviewing_MP_210527.mp4": {
    "assetId": "jYeDjeGi9p1pIteLJkoccLbj1nhHHjCVFs5ZaydhXjk",
    "playbackId": "XfuWgbQyhLRDE8uT01TRXiFHA1hUTyVrjnRzy151AhLs"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S11/Mia_S11a_Whats_My_Experience_Reviewing_Skills_210524.mp4": {
    "assetId": "01Slm33SBZPeDvxfHs02iygBHzecXFivxzlHcOLtb00hck",
    "playbackId": "B42ifvMgq2eCzhFgh5i5E91XpZZTcIZJI02kZxXljgFQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S11/Mia_S11b_What_Was_Most_Helpful_210524.mp4": {
    "assetId": "uEF6PN4hpi4soMsMb4OQTOzNFGlnLOVz5GtiL3DrQo8",
    "playbackId": "napCSxgALHpWk1CNtZkkukWSN5KyWQYeWo012d02E8raY"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S12/Mia_S12a_How_Do_I_Feel_210525.mp4": {
    "assetId": "F7eyW0202vokZCO47102PGD5wDU1v93EgvT9IftyT6YJxY",
    "playbackId": "Fo7b8OUn3d67wOmEDuU3YGvFqGSUAlYXaN5x5WcQj8o"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S2/Mia_Session2_How_HK_Reduces_Risk_210407.mp4": {
    "assetId": "9umXo3WaottS2xvh02XHFkL02jy402Eq00InHeJVx01pKidY",
    "playbackId": "TLW60200idiqUKj2NEB5LjU8apJ9Rohv2bRZcizcLxpvo"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S2/Mia_Session2_Making_My_HK_210407.mp4": {
    "assetId": "wScJ2pYI01J4b3kg1gJ6FEAqUWR5dd1R3v1vtSLLOXsI",
    "playbackId": "XozHR7bLKYu1z01VncWDNZ01HDlBZFqOn88GoS9p2gKNA"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S2/Mia_Session2a_How_Does_HK_Help_Me_210407.mp4": {
    "assetId": "1601F6WMVqcX4REikp8PkA00t02UAthepAD3W3bB3LCWRo",
    "playbackId": "C005S2w2Mva8pWEJQWsxk300ZcUFSVAD1hWVRP5b7RCcg"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S2/Mia_Session2b_Using_My_HK_210407.mp4": {
    "assetId": "CjRNFigfsEF2PZ1vvmMb3BagshwPp8ba1vO62Z5PEMw",
    "playbackId": "l3d43m3BiOG4OkOuM008Xn027qMjaaVRBaOW6s02Ro01yFE"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S3/Mia_Session3a_What_Do_I_Do_To_GG_200405.mp4": {
    "assetId": "LkrXjJHhSWtQDhjpj00mMwkMY02Zkyy43r1TTGkpkVfnQ",
    "playbackId": "TH4wTBRKw6zlCZAV9bbtlmjZh8t47o4sOErkOowGeVg"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S3/Mia_Session3b_How_Do_I_Make_Sure_I_GG_200405.mp4": {
    "assetId": "00zGxxvsuZWtKDCjLu51w6XaVv7H6DBelft1TRwmlUW4",
    "playbackId": "79ItBC6oB6Mz9JZiwl6NuqOQAgR2d02tfQu3o01zPdWYw"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S3/Mia_Session3c_How_Has_GG_Helped_To_Reduce_Risk_200405.mp4": {
    "assetId": "AUfPY9t00H02trn33eYoNzvrWog5MAmlMwLU8VOalCgrY",
    "playbackId": "BpEvA1I8r3z5UJukGWZlNtvTCCSLcvAufBMVLgxWq3s"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S3/Mia_Session3d_Activity_Diary_200405.mp4": {
    "assetId": "4Hnymr81XyA8yBA007OhPtxlA1NzyUkpBPzyi3ddpp7Q",
    "playbackId": "2V500lM1S5qbrhib3b02p8Rxou7dE8zBTOQUQmTRp8Gdk"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S3/Mia_Session3e_How_Do_I_Use_Activity_Diary_200405.mp4": {
    "assetId": "3xsnF1s02UI2VLuZsLEPuO2sb8ERZV004E9b4E5UFEokE",
    "playbackId": "OMNgDRuEDCSpImeacDg5w01lYaPRniUrhWd4pg3jDkuw"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S3/Mia_Session3f_How_Does_Activity_Diary_Help_Me_200405.mp4": {
    "assetId": "DkOk1Xuw200MAzWb4jvSVRZpxe2sBw2brCugEHjh7HcU",
    "playbackId": "JeHeLDVjUoA02cJyVS537JAN3bwLyn3he027Nxfygrrvk"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S4/Mia_Session4a_When_Do_I_Use_210407.mp4": {
    "assetId": "Zrrg5dmo402WeulD5RCPdibCRr7prJ3o1J5VD41l5TF00",
    "playbackId": "YIKyZHf2z6AK2YNueg8tAQz009WJpkgSMuHpOfIdv01rQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S4/Mia_Session4b_How_Have_RM_Reduce_Risk_210407.mp4": {
    "assetId": "01aBpGMME55JG3yjeOKx0202AZ00MsrbDuJ4cCnrfi00xx1g",
    "playbackId": "gF00LkGaFIHKVe02DABWZ95A01MBOQPXADu00qwdoyQiEmQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S4/Mia_Session4c_Where_Do_I_Start_210407.mp4": {
    "assetId": "RPHXJoShX7M6a6xwHBQ5WCVDFNiZboWO9az96oNhd02Y",
    "playbackId": "7IiEDW102GFOs9TLbJRXTMWKxROOBm9qT9dcNcGj8nK4"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S4/Mia_Session4d_Where_Do_I_Do_Exercises_210407.mp4": {
    "assetId": "VbW02Udlmfo200m76ijVwwvKyxfVRxoOHvgkv9jx1UivE",
    "playbackId": "k13KypU3nN129d57DCro2G1dveDrkzFJRZDAfAIx59I"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S4/Mia_Session4e_What_Do_I_Do_Neg_Thoughts_210407.mp4": {
    "assetId": "x01yveeulq01mNiKyc8he002GmZCDKuGBuq00zlb1OtKBwc",
    "playbackId": "00ZFJLsJv01Ht7y6vZbw02kQrKOG7tBQJi6I87004EcGjy00"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S5/Mia_S5a_How_Is_My_Sleep_210411.mp4": {
    "assetId": "l8TqM2iRqopp00T5adaRc6eOmGTL1nhWqr01QsJ00wCHp4",
    "playbackId": "dy0202sYgQ2Vh8W004e3c5lGeq2pUWYcwCgVPhykG9Ue6U"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S5/Mia_S5b_What_Have_I_Changed_210411.mp4": {
    "assetId": "RVwRHHDMg2AstYt4RSZStVzbIioQmCLTTkmjLp1LoSU",
    "playbackId": "XgJiw6iBSI00IYfpBO3e4Jw2j4D2eYRA68Po7PN1LOOs"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S5/Mia_S5c_How_Has_Improving_Sleep_Reduced_Risk_210411.mp4": {
    "assetId": "010202dWVo914yFNJ00NifJl2UAX99sY9FvVnoswZI57NP00",
    "playbackId": "00EGXF49py01kJ8T9djEhL007jYw00ckl6yTBXnnmXNARzw"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S5/Mia_S5d_How_Do_I_Start_210411.mp4": {
    "assetId": "yCiPKjlQUeIbF31v5PT8WIZmd3csrJjtlyEVFc0102tK4",
    "playbackId": "rj00LJZtBMAoAEwFFmsipxRR01JGg2Z01uVo6Kg01GmjQXk"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S5/Mia_S5e_How_Does_Using_Sleep_Diary_Help_210411.mp4": {
    "assetId": "eB015WT1mMlQ2zFaNutwKcBMCl00IkKbq5B9VF14AWpBk",
    "playbackId": "oCg28Oxz5VIufjJG004aQaFh2bS4T6KGDTMH00BI01NCJI"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S6/Mia_S6a_When_Do_I_Use_Spot_It_210411.mp4": {
    "assetId": "BB4PNefhJZ302eUzGXDuvW4EU011u02BAXZH01o99S3SdUE",
    "playbackId": "003KaQcvkHVdiwbgVBQUHW2z21D5Q9SeKfHP6qXTAvTA"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S6/Mia_S6a_When_Do_I_Use_Spot_It_211027.mp4": {
    "assetId": "tmo701Zq6Y02mQjvUfz00QCHadsY8a5dGZizDVnJ4sOA00E",
    "playbackId": "35cagH3TX192A33hGS96h9659S4lUqTxJ9uOBaPUIpk"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S6/Mia_S6b_Whats_Experience_Spot_It_Been_Like_210411.mp4": {
    "assetId": "202FQiDQlsNY00S3qu5RyCgcEpW8pzyiwr6z7b9Wo01N02c",
    "playbackId": "gy00MFDL00JkHFV23cu00XsIsxS02kV4o628ILEonTvKgP4"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S6/Mia_S6c_How_Has_Spot_It_Helped_Reduce_Risk_210411.mp4": {
    "assetId": "f46onPADeV47cj00h003yN701XJYxyaINtQhtCaCVbQi2w",
    "playbackId": "cVFQ3m2zFtUmpx5sbgdwxKS1DbjJ1Ie01fnWUVoaaXN4"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S6/Mia_S6d_How_Do_I_Start_210411.mp4": {
    "assetId": "nR6mbd01813wmzI01CE0088FTBNsFoqZ29HN4TtQxSZhNI",
    "playbackId": "wg6m3kgRl3q00i4lENfDuEgJgjaanN0102izejGaU01MzYw"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S6/Mia_S6e_What_Was_First_Thought_Spotted_210411.mp4": {
    "assetId": "LvGM006qDMssY9BlfWxTxsgw3pEbkmhVbmA7XYnk01CBA",
    "playbackId": "w00HmeepC22MPLlxBkzX2ZtfBQy9qtCu1m01DOLABW7M00"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S6/Mia_S6f_How_Was_Spot_It_Helpful_210411.mp4": {
    "assetId": "v9Mp99i4omMOMLp026XTVQ00kg7pbyK6u5bIAXZ9CKbhE",
    "playbackId": "vFpy01nroV6Gw02xXOlLzBJ45634x65tW01OvBJIi01Rt9k"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S7/Mia_S7a_Whats_My_Experience_With_Test_It_210412.mp4": {
    "assetId": "HJrcR44b5k6hXnDdGlF5mvYYNPm400xTJyqyWGClY2dk",
    "playbackId": "phGFwQ9b7jgJbzy01ZVnvbNUEw00iwjKFQ01OQuNjC02Ypg"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S7/Mia_S7b_How_Has_Test_It_Reduced_Risk_210412.mp4": {
    "assetId": "J7lyHHpQ00RdnC00HIavJnY3YCmSbAKNZNGdDd8MQ85Fc",
    "playbackId": "u01cV26RIUYXb6n6hCD02x13hlo8eb7MVbJ4b01O9cHwhc"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S7/Mia_S7c_How_Do_I_Start_210412.mp4": {
    "assetId": "eJ02kjxZf02vXfMUR44U9SW6BwwsRK7yuic4sZBnv2hQY",
    "playbackId": "CupNON7A49INIGSLUM00xRJr72EPvJbn02FnHzICVdUww"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S7/Mia_S7d_How_Did_I_Test_My_Thought_210412.mp4": {
    "assetId": "yBN4TdXuFgKxNpK00EzB4zUZNSNpRS58UET6FbVhmQbg",
    "playbackId": "jCt1PihowNBZVm01LZds02uuDwiJwcDTOvTbKLtJJFjYw"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S7/Mia_S7e_How_Was_Test_It_Helpful_210412.mp4": {
    "assetId": "xEyNI00FXWWUZpZPCD7kAWp5gmq0001TD8pHBiHDuNj02KY",
    "playbackId": "y3GS1L9cfR53gdNTUn5Jq00O2Lzbs9iPHBRETbnevurk"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S8/Mia_S8A_Whats_My_Experience_With_Switch_It_210518.mp4": {
    "assetId": "vGPQNYOeWtZe6yt1zQCD1iFc5hyGFjbuvwMSaR1GLW00",
    "playbackId": "KTcymtdWgh1lR00ipdHe902JucpsCmm00gLC02LC5gbnTIo"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S8/Mia_S8B_Has_Switch_It_Helped_210518.mp4": {
    "assetId": "YgbE8kt67gfExE1TE01xmT02WGUzyoeEp4JsUnaw62oIM",
    "playbackId": "Rij1PCa24FNYQvIxdvm02SRAxVwkwhBgXR02SaZO3qrsA"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S8/Mia_S8C_How_Has_Switch_It_Helped_Reduce_SR_210518.mp4": {
    "assetId": "00jJOuthbSZnZ201zEdnQEbBfTjr00JHOceWhQ3aGvKri8",
    "playbackId": "01mxbiKBKDB5sZUvwzff01U4aWoM43b6ihFu4QqQQ6Lko"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S8/Mia_S8D_How_Did_I_Start_210518.mp4": {
    "assetId": "kWNyX1GHpUwZEAcE4VcOBWNDs3faxRtKbnAfcQzlVbQ",
    "playbackId": "Lr1bgrHiT0000Y16wgyi02G00Elb3Tk00ztiDvcoItfCeNFM"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S8/Mia_S8E_How_Did_I_Switch_Thought_210518.mp4": {
    "assetId": "H3on2XBhxWY2fuJSgw1fdZKg6wN501u72BSPNclzyaW8",
    "playbackId": "ZOfyKCFwzT5bdfJ01wkrOzg1TR00CuEn34rlYgCiTl3us"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S8/Mia_S8F_How_Was_Switch_It_Helpful_210518.mp4": {
    "assetId": "DVLz13zjfX6ehzNfJEJbEDeJAF1a6VmO00400w72Wc7lM",
    "playbackId": "vcmuyrx02kyT00kog7I7UnUphPYYDDac1UJY9I6NyG011k"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S9/Mia_S9a_Whats_My_Exp_With_CC_210522.mp4": {
    "assetId": "btkV7iLHUsw4XcR00jSnXOGO6lqDXGJ4SlctQ7sP9t01g",
    "playbackId": "008xmmDAdPrabYq6l78bbKfTwAd386apY401OKGpHmX01M"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S9/Mia_S9b_How_Have_CC_Helped_Reduce_Risk_210522.mp4": {
    "assetId": "A6TTbNDx79jbfMubiFalOxNaKlYS87crcapZknxIuPo",
    "playbackId": "n9xOlUpJqC48V3W17s8hZ7cEpt01UHC5WnfNhTY011qEQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S9/Mia_S9c_How_Do_I_Start_210522.mp4": {
    "assetId": "Y02TbpNOpelkVsF3RV64x01y02mAPmetjbXp29zHf3xWe00",
    "playbackId": "ao2uPSGBH4ToY38LGvsNN4cPouX9SWwavcnW15HqozI"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S9/Mia_S9d_Whats_Another_Example_210522.mp4": {
    "assetId": "cjMBts7dWoJxHB7JZiJmUzwJYnWefAUC4hORUVhCWZM",
    "playbackId": "wIto6BT3yr29gKV9bnSQ4DxTGqexFzaDtoCMtOK8DGw"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S1/Priyanka_S1A_220412_720.mp4": {
    "assetId": "LPuTfiIOqxuXeFxoMD00FL98vCa3XQC5vVr5EVsSzpLM",
    "playbackId": "M2QRFiT7Ate4hmhxsS00019yMbUAxs0101ziX3hwGC8OGh8"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S1/Priyanka_S1B_220412_720.mp4": {
    "assetId": "01Dzaw6yfwio3FXycXF02YNQi7r9y1bVXOMJMPFNJKjI8",
    "playbackId": "skYtPNko01MS1FJ8jprzAP125hx5XbL7003ziNZstU8KM"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S1/Priyanka_S1C_220412_720.mp4": {
    "assetId": "AmExqcdl0283CW9ABzhqNHm4r4kvHWjHxb01yEeVwIrGg",
    "playbackId": "bN1DyIlg5KTE00dqvZV73ZS4hAZTAHe32Mq67THnKL6k"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S1/Priyanka_S1D_220412_720.mp4": {
    "assetId": "5xJJ2t2b4J3fy4YhVXvN6VazgXsb01KpewM57yyyB023A",
    "playbackId": "HhWsuDjV019qJ1btpKwNUAbVUCk00ky9Zc01mA7BZq6VZQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S1/Priyanka_S1E_220412_720.mp4": {
    "assetId": "Yr3xGuB5IH0001YfDnYZq22wbkB7jzetixuDdeE007eMoI",
    "playbackId": "Xb8KMctIkOGvE00qinbOeSVMA2O01F5ppUigEa3mE6lLI"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S10/Priyanka_S10A_220412_720.mp4": {
    "assetId": "Pb3po800HynVZ02EX8NR502mBRh00ffYZiN602l589hcaJdA",
    "playbackId": "J02wEP9GovwBd37bLZkrlH5p892SPULdY3SAgBmzt8Xs"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S10/Priyanka_S10B_220412_720.mp4": {
    "assetId": "yC6NJaBpvH9ipArNyNpK37VYC6Z4iaX0117xTvUXHxXM",
    "playbackId": "801K9yVTH7WmJOLpCFMdN9To93d01vzwNnSwhr004U9a8Q"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S11/Priyanka_S11A_220412_720.mp4": {
    "assetId": "uN02YLKxiebcrMpIZN1Tl3vwWBl01M8OEazES3RQQr7Cc",
    "playbackId": "R5WpGW7IZDfMew01fTFSKm78FGVoWaG5JLNQx1eB01ecc"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S11/Priyanka_S11B_220412_720.mp4": {
    "assetId": "7myE7z012v4nDPxf00B00n6SBdBDrKrtT00Ss2ZmL8027Qw00",
    "playbackId": "wRM5aMyjbM01h1t4HEnVLxaJfq7HtWh9uZke8c9sKfjg"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S12/Priyanka_S12A_220412_720.mp4": {
    "assetId": "QcbojPgO2v01u7RYNkoBWcEt601aic00ZPU4PrYrQphAAE",
    "playbackId": "f8QEUugwjJWCq1SPat1QzFPj3ppbHpfGkFbiEVqEu01w"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S2/Priyanka_S2A_220412_720.mp4": {
    "assetId": "e02Me01MEkF6mFgm2D00vAP1n02frpMWl01WhjTQgQSEAJzY",
    "playbackId": "iduoV7cGY1j1Glz02yOcl3prhN6erxWU00ccNYvra015ww"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S2/Priyanka_S2A_220506_720.mp4": {
    "assetId": "oBeTj7BW02aIE3nnYMqs00OeRFSuo402DBSu6ukynzXrvc",
    "playbackId": "1YeyuGWoRUoKpLbYXFXxurtKPs00Vd9htWjOjPxFh6mA"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S2/Priyanka_S2B_220412_720.mp4": {
    "assetId": "DwJGXUCCIkwJC9d8A9hHk51mj1fH00zytVBgvPLFTNqA",
    "playbackId": "GOSiNZgwxUEQi2P1XzrhPTMpzE02KuAwx7yCfmdEcSh4"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S2/Priyanka_S2B_220506_720.mp4": {
    "assetId": "ptsaZrxiMyRrurGeZnaF00bYU6zr4ElcWYjfbFdexEbw",
    "playbackId": "oY5weq8DLgfWOj00Dls01eUU6Jw00JAQtRbqPNvoWhrD02o"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S2/Priyanka_S2C_220412_720.mp4": {
    "assetId": "I02XvUfZsfs9lV9vHS5EBxz1hZ4Zqm00VrD2LcOZrheL4",
    "playbackId": "SftZwMvqpnCNulueml1Imivc2C9MO02OXC4kdmmdDW8M"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S2/Priyanka_S2D_220412_720.mp4": {
    "assetId": "Zk32LBI4WhWVThpl9nwHBLDMrn2Sndq4Mb5zAh8AWD8",
    "playbackId": "GFG7ZwaMQ45SrnVdF7ETJG77hA56I8NBO97KfWs02v00k"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S3/Priyanka_S3A_220412_720.mp4": {
    "assetId": "mtd0002kcn00Ik9NqNRH1u4f4AcxrZh3TaBN302ATuO00Omk",
    "playbackId": "Rhy902xxIPdMMCbZpnkiC5zwDzadITvamI57HLbmKiFY"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S3/Priyanka_S3B_220412_720.mp4": {
    "assetId": "BJiunQL02AsGBncXcjMzcP84DC3OzIEIxNjFf7X91400Y",
    "playbackId": "RSWhQTpeDj02LeqyXtvWNbnEDXnDP2B00O1J67k016VbEw"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S3/Priyanka_S3C_220412_720.mp4": {
    "assetId": "wJ1cdZBy00HhzFn9gfj5d7vsrOiFCK6xO1lrVX2nWD6Q",
    "playbackId": "4ppMKlH009Jd4sp8sV1n8cWielOA9ZKZkb416N013zrnc"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S3/Priyanka_S3D_220412_720.mp4": {
    "assetId": "P00XNx01ZhUjFcx5ynDxSMqrKYntXrMa1Ws68yk01inQCw",
    "playbackId": "viINa2hObbNXmQQ4x01EfFizgas5PJN7M9WcyAifvnMM"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S3/Priyanka_S3E_220412_720.mp4": {
    "assetId": "adRz00rf004TLruj73JHbGVPb2jBVVGD3zxk1yoHSM2fk",
    "playbackId": "9hSydZZnhZKA8008NclFZd3K00JEoGnNIKnpkuMWl5cuk"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S3/Priyanka_S3F_220412_720.mp4": {
    "assetId": "eK01EkFE00YmXuJBH3rYgi7p3yokJAx002MrpYsAN1F4sQ",
    "playbackId": "ds8PzRDRZv7KWZjt5kEuCFKayiN00WjVt3xRJr26snRM"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S4/Priyanka_S4A_220412_720.mp4": {
    "assetId": "GTyzh7uaQY1ruO02rMND9qPhz4BCOaTGcXQ6UeNY8Wc00",
    "playbackId": "FwlIeddbJOOX2Cfw29F021FVgjF02IFNg01gQOHqpVFz8o"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S4/Priyanka_S4B_220412_720.mp4": {
    "assetId": "yxa6kNUj9Al36rc9QOwWDcLZOSnjt9jcyey47XjxTPI",
    "playbackId": "MQHk01vL2HvGijmxi813K2lYYCcOjJIsIhHTfrJJ6uso"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S4/Priyanka_S4C_220412_720.mp4": {
    "assetId": "AIW24HWfIoKj01tefthAbBEiQNf4tKFI9cCAKqS51P300",
    "playbackId": "Q6nXa02Xiw6X01EmoeACHq6xzBLD023tDCiaLl7tvmMbNA"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S4/Priyanka_S4D_220412_720.mp4": {
    "assetId": "puF1Aip3yODpMatXjzu00SwzsiX99UeAjyJlkS6qkmmw",
    "playbackId": "l02vB1wiQhIOCwjXaUXf02LCADO7XUdnQqxAQSYLjSPCo"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S4/Priyanka_S4E_220412_720.mp4": {
    "assetId": "GlUFfFTaqbk8z01bZWzdc01jPYT6M9A027KMKQy4Gde85M",
    "playbackId": "gryV4102HS8zvoR7S3qQpSuy02myeYkD00pht36ACXlgeY"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S5/Priyanka_S5A_220412_720.mp4": {
    "assetId": "BeDkBM7MXEmgeuqqzkhInegKf2BMQawAxYu02tm6MK5E",
    "playbackId": "iIZI9z27ZlB9019x01wJPPOBv02ZAX4syYTrd9vh00XMqpc"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S5/Priyanka_S5B_220412_720.mp4": {
    "assetId": "aay02QNIcQ01M00502IyA6ZcUcBI602d81Ca33riEsqUVhCQ",
    "playbackId": "MTyDrShJAdusPIl8K57f2SGWWBb3IOL9wiS7Kz2Mb01g"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S5/Priyanka_S5C_220412_720.mp4": {
    "assetId": "701tVyuaXqtsP12QIrHKP5sxzRme1IaE8HP00RQMBGsaE",
    "playbackId": "8aH01wd025PSatpZ01Vsv01gMXKvkSlZ02YIwh5hf0101ewVrk"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S5/Priyanka_S5D_220412_720.mp4": {
    "assetId": "Y4MYicYANnvpECB6jM4RTWOXTPLaY5yoMgrrymIU9mw",
    "playbackId": "FrTKItwolEn5Trh017hlbYCNn7coKVju01MPAkiaz01Ql00"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S5/Priyanka_S5E_220412_720.mp4": {
    "assetId": "C2rPtZAswH201ixJYXcSY81rFI005sQE7DWTRYyIvMS1A",
    "playbackId": "lIoXoyqr100OPUfnSFdFAF7BVV00HpP8tyKH02iITrM3HQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S6/Priyanka_S6A_220412_720.mp4": {
    "assetId": "DzFFE1KsV3Vv4jn02IxyS8GDhAPRiW02gb9hI9wbJd5yg",
    "playbackId": "spNoIwnjaJ9SwUjVJb00401nbzpNpILSu5Ko1ad00kDe1g"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S6/Priyanka_S6B_220412_720.mp4": {
    "assetId": "TOQNPeddxVcXnMrFrLxS15oeSsP6wzz3b02rV01Ih2Yyk",
    "playbackId": "W716TKLjRfmAJtNpzo2r59jEAhM6hR02roAGqdXTExts"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S6/Priyanka_S6C_220412_720.mp4": {
    "assetId": "kftYjI2O5lVRHpWBxhhnm00WVJj013pPmXwu1sYobxW028",
    "playbackId": "LuaPD54kWVX3CzkZRpXgMQvgIolChw01aHifKm4801o4U"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S6/Priyanka_S6D_220412_720.mp4": {
    "assetId": "ajE6eGrba3X6or023SWrsUwQA00oDu6UJqMn4dQ55tpIE",
    "playbackId": "Bnqu8S3Yt5GOJdtQosbR22sqoHWoxvbG45Mz1AbGWrc"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S6/Priyanka_S6E_220412_720.mp4": {
    "assetId": "QLy01ZSoxJkhAoHfAodUfolBqurpsBO2ZJhw5YZucxfM",
    "playbackId": "fUhWWdijfEmvqGe2TSiEu4C4C02nBQWlY9oacn9bb7wY"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S6/Priyanka_S6F_220412_720.mp4": {
    "assetId": "7fsk01piky39lntUNJhqi8doogtCJ8Cl005EQ8f8NrHRE",
    "playbackId": "hw7km94fyAd7JMoX02WChmzgQvdj9jIrYmBHmGMZ00v01g"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S7/Priyanka_S7A_220412_720.mp4": {
    "assetId": "015e83tO00A2xoJoZkB7UUpaRQhhcwx3Hu2eLrrGwuLR8",
    "playbackId": "eiy4gT1gxXvJ001hVtMwyRlhUpWxQ02KbnjwcaMyO3Oc00"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S7/Priyanka_S7B_220412_720.mp4": {
    "assetId": "Af00FSMAbaTnowahwEyLar4QbLnZSoNH1dX8TaL5TIxs",
    "playbackId": "ezVxhH9k00QxKHXSn4STC64SYnA00o2eDLfupzFo9yIxg"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S7/Priyanka_S7C_220404_720.mp4": {
    "assetId": "qGulO1IwZqccaYe7GvTB02B01PbtI015eC5vBQmyU1KT2U",
    "playbackId": "FtVqSdN02wgfnyvri6kGTF6YdoYnLRTZY82wSY7S4m1g"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S7/Priyanka_S7D_220404_720.mp4": {
    "assetId": "9kKUwfIuPCxzKjunsqOzT7ui4V7M771NrD79cJiRN5Y",
    "playbackId": "5aokyTSUAq8nJmdP02gogZlobIl8XylNrCjhNwtiCkn00"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S7/Priyanka_S7E_220404_720.mp4": {
    "assetId": "zQ5S00kmev9wbxtnRU9ag9rz1apLPY2lOkuP02ndpcSeU",
    "playbackId": "ulahVZCheGk2wr87MfwcxPBTVntApa00P9frNop027Q5s"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S8/Priyanka_S8A_220412_720.mp4": {
    "assetId": "1BodkNzoTyaj011XFXoSF01bWwzFto01Z2yBd7021qtUobU",
    "playbackId": "k8IX9O2HUetFYdibVu4hnNzABXqYNydTstdMxQTXYIo"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S8/Priyanka_S8B_220412_720.mp4": {
    "assetId": "eSUkP02PixLL01XG01it6BoifZkGLWfJ22QOLyIDKDXfe00",
    "playbackId": "vu02zeGpmu82BipVHLf4rqgxzbjUZZijia00r5bRGtOM4"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S8/Priyanka_S8C_220412_720.mp4": {
    "assetId": "IrBc80263ZmTW4mTH00Irfh9sMD2mYsjqxOZN3tRf2vfU",
    "playbackId": "dFVBfI00inDhp2OOQ6EKVrbMREASCV6pZLUkKEZlIXpM"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S8/Priyanka_S8D_220412_720.mp4": {
    "assetId": "xPFDH4M68gKsVZsjwjNdG8CnWVc63lEYmcd9DS8QG9M",
    "playbackId": "WkBSevN01gKxPrnPT5Ifpk02ppF7101OdrqK4I28zrvXHY"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S8/Priyanka_S8E_220412_720.mp4": {
    "assetId": "XMYaXNJ4xuWduy9541q6eAVeTuBnlB00OeyieMNuMW5A",
    "playbackId": "7OuY6bb2j1zK7XXTA00pqp4GOpsJLgWUYBrfEcb1Lf1Y"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S8/Priyanka_S8F_220412_720.mp4": {
    "assetId": "MhChfQFs54unTH2Iyu003rT5jEl7dRgxSeU1PoncMrtg",
    "playbackId": "oHI3ZF6CFAmR4ADxxOyzzZYCBc478sKsQ92E4OQrwEw"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S9/Priyanka_S9A_220412_720.mp4": {
    "assetId": "7701Ro3tjvYoGeQQCj00e22Jb01Tq9jaJzZVqeUF020214700",
    "playbackId": "xtR3uW7qN2zIYWh7CxM00iYokHZah6MkiFTC5RQ6yUdc"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S9/Priyanka_S9B_220412_720.mp4": {
    "assetId": "kQ1JvuvQ1JM005k22aFYAGlqEDz2pe7SVL6mClCIrBcQ",
    "playbackId": "hGeNLknECjjAOkIfz6Vcho3HhbIBOWmHRlwDzm1MtAQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S9/Priyanka_S9C_220412_720.mp4": {
    "assetId": "waa8JUvNhHHXzk5mkiJAE0202k27U2Ycu49TuLslKPoCM",
    "playbackId": "ooXhoMBLf02odlev3yctKDTw6mGBCq89zpnKRdT02Va7A"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/priyanka/S9/Priyanka_S9D_220412_720.mp4": {
    "assetId": "pZVzQsCWPXfj3LvGMRax5vKmk9F3cE3HeojHypsO3SM",
    "playbackId": "dPBNNG00v5Ri73HCDoRLMzmpcBn3qNjHQ5UbHfhHFvUs"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S1/Oui_Sue_S1A_RC01.mp4": {
    "assetId": "n1fx602Mz00Lu5HMDEITKS5G5jj3OoqZfHmeqNFSU00x01U",
    "playbackId": "NxvCKG5oaDTHboZpQtbEepM8o4KCV2teSFBXSJXHQJo"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S1/Oui_Sue_S1A_RC01_720_220315.mp4": {
    "assetId": "k3fbluCmYLNZOofOpyjahBKp4nL9BD9p3ZfGlfxWLNM",
    "playbackId": "WRzQktVx4uDdX5wjxuvwup1wOxU28n01wsQJyJOsg4Rk"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S1/Oui_Sue_S1B_RC01.mp4": {
    "assetId": "pI8SAldxitjy01FWFsht02TA2OQY3UX1cUlwV36VTe5YU",
    "playbackId": "Cs02jeOmh5T9uAlimAs931HOw2Pjafp4yeU6FL3haaAA"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S1/Oui_Sue_S1B_RC01_720_220315.mp4": {
    "assetId": "a2okNJatyTxoTNw3PUJQDZ9ynQZX00zChFbk01hCuFsDU",
    "playbackId": "6qVCdTd025sW01H5blGf00AovA4ClN7802STf014JOuNT9D4"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S1/Oui_Sue_S1C_RC01.mp4": {
    "assetId": "aSSU02017yJtPu02f5t02zd1aTgMHNzRf4DbxC01ARQE9f6E",
    "playbackId": "RCfR1LmzYGj4Rv3Xu9dSfN00fY4p2x2400VTN6pePFKcI"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S1/Oui_Sue_S1C_RC01_720_220315.mp4": {
    "assetId": "uy2xQR00fxvXNpMTG00xydFK00gig0001Wv4MB4pBV4Si6YQ",
    "playbackId": "L5xq9IbP7Epb7ExzYLfBRG6T7QeBYdAFO7krCpwLwfc"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S1/Oui_Sue_S1D_RC01.mp4": {
    "assetId": "qnNyEYpUSWpalWU2hY3TdqfYc01wamkJ1tOl9nFm15T00",
    "playbackId": "8Hac00ffI7Phb31oJ501ok4do00hMkPm4Kug00d6ptPyZls"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S1/Oui_Sue_S1D_RC01_720_220315.mp4": {
    "assetId": "nc02CLj02fEaxGrAuUDU24QdOu8bQWGimw4u6elUnxNe00",
    "playbackId": "Vnxy01d02A4XOd6CZgB02kcunxZ8UUki7MP9Ll1uk635uM"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S1/Oui_Sue_S1E_RC01.mp4": {
    "assetId": "2RbE01x8Y7nqJRqIfiA01cjqxKSqESn0258vTqWiMmDwLw",
    "playbackId": "fIIqgeaqjn01mYGlRO01K4cmFfbhg01Ho7IESM1NuO00aAQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S1/Oui_Sue_S1E_RC01_720_220315.mp4": {
    "assetId": "U69pJd6Z9sIw9f00pViB00bj4ddLJvojjT2fkItDFiEdo",
    "playbackId": "KoQa0298sUHE02rSJy9umZv6SAQw5ISTJm4jMusWmEOIU"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S10/Oui_Sue_S10A_RC01.mp4": {
    "assetId": "sgA9ve6yIYTVSV8QqlzrFvKG4fRSkHeELREIdJqIvqQ",
    "playbackId": "6i02T00nAE2IAsgC16BBlZsrVdEGaQ1AfKDlCdN5RCDgk"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S10/Oui_Sue_S10A_RC01_220315.mp4": {
    "assetId": "CuftsMKQiPHF8hjOOBI5PeFX6cf7OeXqhnG01C4pMQXs",
    "playbackId": "01trgziMdbT201Vp00Hc98SoZzzc01EZGmjxCGM3201r1OsE"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S10/Oui_Sue_S10B_RC01.mp4": {
    "assetId": "hB5gb01i1FUiThJ2aHnjXe33k325YgTAOu2CbcxD4D88",
    "playbackId": "RfMppbi3OPKvM3Iy1LdrWoxz7sl6JkPLDklcm1BLG600"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S10/Oui_Sue_S10B_RC01_220315.mp4": {
    "assetId": "ccjq1WaX6SDSuPILs6VJng9K4JEOgThS2nk6SYZQZH4",
    "playbackId": "572b02HXa1otkEdQ9T6h02lmK3YUM4E7201CuQ9801QVXh8"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S11/Oui_Sue_S11A_RC01.mp4": {
    "assetId": "rs00GhPx02EUIQjvHtgTjdYO1kZRwPU6IVxPzAJx8gM01I",
    "playbackId": "tJtlE00N6zPVZ9ils8krSyP7UDZ9rqk3B44WTTmDnenE"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S11/Oui_Sue_S11A_RC01_220315.mp4": {
    "assetId": "4V9XfSD7DEoybbZmcvkI8epHJVfjZHadoLjD9IqWazY",
    "playbackId": "ZGrLtXKB9014DwknARsJpr8A19A00ykLXgL43Y01jfGlfY"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S11/Oui_Sue_S11B_RC01.mp4": {
    "assetId": "d014lOs00bcDThikeLAP1wkE5bcjya3q55HlrKsUUC3aI",
    "playbackId": "ptxvVJGkMZPax029OUUmkYh4A3QjYTxZ9LLVXQW8TNvA"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S11/Oui_Sue_S11B_RC01_220315.mp4": {
    "assetId": "7UY02TLh3Be7q012wuSe9cKO3DWygDm1mShRs3xGBRoCA",
    "playbackId": "FLuG000202X9HjepreOAP7ZhEt5tFfwlB9cf02kqwO800kzQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S12/Oui_Sue_S12A_RC01.mp4": {
    "assetId": "4xoXQGpSQqJ3gs6gpskO2nJmyhQLm7PXyVFkarS7Mts",
    "playbackId": "nPASGWISQr02qLRigqBViuUpyTOL008JplIA2Ck1nv2EY"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S12/Oui_Sue_S12A_RC01_220315.mp4": {
    "assetId": "9jtL8gyEtHXFbcb5bqR02oAOInZoUfvSjhWOdjiQEBUY",
    "playbackId": "n14Xjn75cmbJVzLymj26SZ7a02pnbSGBuVP5sDBjWZlM"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S2/Oui_Sue_S2A_RC01.mp4": {
    "assetId": "k2lO66p01Fo013HDCbL3Iz02t1RNjs9rNJoZ1ZtKLObBWQ",
    "playbackId": "63yyhRWyVPw4LPnFTuHMpi29TrYJ5rTpvMOEgqgvP1Y"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S2/Oui_Sue_S2A_RC01_720_220315.mp4": {
    "assetId": "UEEFHKJBy5HNdxZCqQCVqwhs01Rdr5LvcKY7hC02Vn400Y",
    "playbackId": "U6iUCrOgu02kUnXyWKbmDv7RIUiOhiHd4MkZWP78JXSU"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S2/Oui_Sue_S2B_RC01.mp4": {
    "assetId": "8tI7pBiXhsvhWr7aE22Zso9DqlvbguT300AMiIIdc1WE",
    "playbackId": "00Z02bVg6NJ23K7Hu3vy601pHil01HLFWsJmTMeufOSUIHg"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S2/Oui_Sue_S2B_RC01_720_220315.mp4": {
    "assetId": "018amQdO848nYJNX9ze00o4Nog9mwUfwWDVVM4MeBpXmo",
    "playbackId": "bKv02PMSlSesHOe00gIVPVPLvH2xzrr2jK4s8lDcZAkv8"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S2/Oui_Sue_S2C_RC01.mp4": {
    "assetId": "kGWXQ5680101Rt7ukVmoVsYvNe3Mm6GTLX474P01makiPY",
    "playbackId": "jvjq1V1v98w8iMlzCTXnHMAHjttVcaeZw0001oBUCWNsg"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S2/Oui_Sue_S2C_RC01_720_220315.mp4": {
    "assetId": "cEJDFTbw2zqNqOS77kLIa02wNL2ZSp294Ts9v5KPM01yY",
    "playbackId": "800Lzs7q01IgbGtcvMLr2x00po1LsQvMulnmpK86PsZfWY"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S2/Oui_Sue_S2D_RC01.mp4": {
    "assetId": "8gU00qybljJHXPFUwCnPnhBTXu5o9SJkSxOPtyjYRmwI",
    "playbackId": "XvQr1tPO2aZR2RDDvRB19suaHCtskQfLORMaQBoV73w"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S2/Oui_Sue_S2D_RC01_720_220315.mp4": {
    "assetId": "L9fSTV2LF1C8kNlhshiWkU3ro7NNjPDfwA01aP9TPfuw",
    "playbackId": "G5dDTwcp2IY9zPGw0201wtsYX14zhn01zxfhBEJdnpFW5w"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S3/Oui_Sue_S3A_RC01.mp4": {
    "assetId": "kq3qATGm6uq8JwSBlB36CL01SiDs1D3k1E6Ug02H9thi00",
    "playbackId": "CteIFhtcs59d3wwJblKskTuzcm01FSHReeE1IScTEaf4"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S3/Oui_Sue_S3A_RC01_720_220315.mp4": {
    "assetId": "7dC7hTgsZ2kK5a7CWn3IqAQQIZcwsY4phZLIxzETAvQ",
    "playbackId": "auIzN8XJ2VeQfONrk17c00i7bGuy4ev7vk5tQ3tpHZ9s"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S3/Oui_Sue_S3B_RC01.mp4": {
    "assetId": "ypbsz6VUtqwRwf01pwPkACFxWZ600mvnheJvgvyWQiD6Y",
    "playbackId": "O35xQFV6NOgBdx1SHmf01PUBl9gBp2p9Aw00fjcUP00sUo"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S3/Oui_Sue_S3B_RC01_720_220315.mp4": {
    "assetId": "WzW1oSq2kmu1CxPmKVYe9JeLInuBItsuiF4XMphuIVE",
    "playbackId": "1EuUv7NIe2f5pz8iM01JhWaDU3i12cQAl02RtTQ01nOdfw"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S3/Oui_Sue_S3C_RC01.mp4": {
    "assetId": "Dzz58x4FhFEJSPpXX02C7ar5hPTYnBKYkpU2B97fp6mU",
    "playbackId": "eX4476u3SbjC1oYl8P01ocxDe4uSfhxE301ov7aUv01Npo"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S3/Oui_Sue_S3C_RC01_720_220315.mp4": {
    "assetId": "uV7lGTeHD01to602HjZLMu1mIDD2ZVJtPyhff4T02IbWhk",
    "playbackId": "Kuq01eBW025m02qfPomiWBYgik8Jx5UwJmcBFExeGWoMKA"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S3/Oui_Sue_S3D_RC01.mp4": {
    "assetId": "BvsIA2z5zHyPLBrEK021hPnyd66DRU7jljlZ9hvahcaQ",
    "playbackId": "01BKliGxwN9iB00ql2U02PEFIJklgFLxeJUg5SrZMMTWYQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S3/Oui_Sue_S3D_RC01_720_220315.mp4": {
    "assetId": "6QXHZCWkPf00Bi2wXXtt1kbR02S7P02Dg7nmb2Z56EvDE00",
    "playbackId": "ACAcfAdu01ZVpwharftCtI4ODVa3yZQlP8O1a6ipb96w"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S3/Oui_Sue_S3E_RC01.mp4": {
    "assetId": "vY8S1tr02zMKkqwA2CRffBHbrp1sppgtZ8MH7Z4TSaus",
    "playbackId": "G02aY56zdjsLP5SAsnOrhAeOSKhkzVTXl02uIjwHCrZGY"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S3/Oui_Sue_S3E_RC01_720_220315.mp4": {
    "assetId": "8DuTMdjyKy2P1wgCa200SHd00O3JwacpebatjnEwfavLs",
    "playbackId": "1Xoatx41e7kLSKOBWJ5Is7SVGfc2Fk8D1byAChq0238g"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S3/Oui_Sue_S3F_RC01.mp4": {
    "assetId": "CUeL8gHr02dY9i2k8XmWFWOHYGU01iyBiK5fbpc02mhZ9c",
    "playbackId": "600h1vR4txX02Tg02P02QxPrIZyzgmblWk6smVgbHRBWHHg"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S3/Oui_Sue_S3F_RC01_720_220315.mp4": {
    "assetId": "ke8Sh026NFKqwI01RQOZpGmbPTwKdXXcz00y02Eg8DwS6IA",
    "playbackId": "00SvkDDeUVGs2xYFchcoi22OlRuwTQcGrpB00ot1G48Tw"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S4/Oui_Sue_S4A_RC01.mp4": {
    "assetId": "cAMub2s00Ggnqszo02801YRPSQREXWMM5W0200DasPmavk900",
    "playbackId": "evPNxlBZ2zYqhnvfwrjeJF00ZblAcjzk2YWxy013tzQfg"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S4/Oui_Sue_S4A_RC01_720_220315.mp4": {
    "assetId": "9IYj4R00LiSJZdfHqWhaiAj5vBZ02hBu00902naWG02t95bo",
    "playbackId": "82mRUb9b6NnCtasNEXLsrLfx001GtiqODsnQeJV02Cq3Y"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S4/Oui_Sue_S4B_RC01.mp4": {
    "assetId": "xC8mFJJxCxrKth1u4iBP5ZepVEaxD1LVw01sDB2UTg01Y",
    "playbackId": "R7ippSR02OaxduI89dGQSbfC7zuLRI8DTfXFRgzi5syo"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S4/Oui_Sue_S4B_RC01_720_220315.mp4": {
    "assetId": "xgVE00oHvUjgNCg02Tg5XOtiWpVcKpwROp9EXDKtdKj3o",
    "playbackId": "U88KlKhe4blMA02sQdtXT5VSq7019KfGtai101Ry5R7HsE"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S4/Oui_Sue_S4C_RC01.mp4": {
    "assetId": "I02dKp1foPns21KrPRwZe97Lg02DIojyBKJuZZ02Zqpp00U",
    "playbackId": "1vXeE14ptcSnsEkstghQg1krOAVF5FV400Mh9nj6BbR4"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S4/Oui_Sue_S4C_RC01_720_220315.mp4": {
    "assetId": "K4TkTyartVdwi9QYlEAlVDu2EjaJbAEJc016MfvsnFsk",
    "playbackId": "CWcBn6CcqiUFB4ev01g1F3KtNzpoPvtfAfdBKv02rkg01o"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S4/Oui_Sue_S4D_RC01.mp4": {
    "assetId": "vlDIdC5PPXBx87Y00vMLrBPeQfYLXXV2GwBRfsWDzTEY",
    "playbackId": "7K61bJYQ02MlqWfoOoInZZovej02opugDDpUMMa3pnPpU"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S4/Oui_Sue_S4D_RC01_720_220315.mp4": {
    "assetId": "iP2WId2G013wVPPs8VbXemxVLFo1VqzopfAFpHVeUD02U",
    "playbackId": "B8ZjPGyk1MHYvyUvnqCBlRBBbx02Oz4r9reeoo1qu9iE"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S4/Oui_Sue_S4E_RC01.mp4": {
    "assetId": "y8kTfHwJrGJH6yfG67pl56thrZexnqg01PQ3SDvCJu02Y",
    "playbackId": "IlNrXm00AElc2LRQiIMwGUFlMMSpKqUkO302Ja02SVjsNg"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S4/Oui_Sue_S4E_RC01_720_220315.mp4": {
    "assetId": "4ZY4gD8sYfiFqhQDIc9qYcc8mmiDgRgMxBRKeiLaZCM",
    "playbackId": "b4B8Cv6Vo7k500NtrAY00TecSb9fYPbLkOERs3UhKZQGs"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S5/Oui_Sue_S5A_RC01.mp4": {
    "assetId": "RVydqTS6H44cdzDNs4kTGuEO2ixt8sOP7CYXJLNUAiA",
    "playbackId": "ABpwOxAXmYpk9JglNUIHYjkrqlWveUZMgxsoZMSdJKY"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S5/Oui_Sue_S5A_RC01_720_220315.mp4": {
    "assetId": "1LiR00FRumtUkIH25AkOT01sAS5RIBoCVfo4mN1c02YgFU",
    "playbackId": "V00svkNHuYYkMe02aX2wELCwX2SaceaW5QOoh4a1ip02eU"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S5/Oui_Sue_S5B_RC01.mp4": {
    "assetId": "7OwISBT01eO1ZS8HbvbjgB7TVxJdMbziiCm3R9et4qVk",
    "playbackId": "xff6CoadKh02J028lAu602UinmsS401PLzkG6oUTCvyDFPE"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S5/Oui_Sue_S5B_RC01_720_220315.mp4": {
    "assetId": "Qmq6hoCHeBJeq3GhhxF7LknQptvvEFXAE7XO1124i1Y",
    "playbackId": "g024yc7GQ6B7Ja7Radp8BdipZoU01RPvurfZOIhmD1M94"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S5/Oui_Sue_S5C_RC01.mp4": {
    "assetId": "hdozPZPa0200hX2oJHzpI7c7CZXo4IT2wFM01R027018eDh00",
    "playbackId": "d7xQY8LPZUPB7gIKQ1hO7ns1Rc101R8AmxpVBg6ojOKM"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S5/Oui_Sue_S5C_RC01_720_220315.mp4": {
    "assetId": "55TvHOdf1p9is5SUEdCqWI1zQnwvNpraOSgf31T9FgM",
    "playbackId": "aVbVIy02Of4dr1NY3BMnxGmF00XNT6UTE2Aujay7fXIGk"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S5/Oui_Sue_S5D_RC01.mp4": {
    "assetId": "ue1JsX9kE28cih7nt7R6OiU8n02wHOJFBS5LfdAIsz1A",
    "playbackId": "63wMHw5zQDvKvWmlg7vr43b00APNkaKzV02DMS008k9O02Q"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S5/Oui_Sue_S5D_RC01_720_220315.mp4": {
    "assetId": "A6k7iGRHXyyNsD6OseBgTvEHtrvFt6aWzv2jloc6ic4",
    "playbackId": "MENl02oyp1WDu02UoydnLVprhC1giGKFicpPv16qA02bqA"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S5/Oui_Sue_S5E_RC01.mp4": {
    "assetId": "lltXA01IwrGUDJ022DAjZLzeHcl4jdJg39CgsKcHYKh6w",
    "playbackId": "Ncs02RDxMdpRj3BZZ6AJCJakxh2BS7B7QoSuSIt02gUlU"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S5/Oui_Sue_S5E_RC01_720_220315.mp4": {
    "assetId": "wxuC6TqOMeUdjk4liJ00BEHD02VSTda0101YQWRi2uLR02A8",
    "playbackId": "OMjqB7yW300LMhwYgD02vM2Nfwcy7aCurgjYlqYtJ6iKc"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S6/Oui_Sue_S6A_RC01.mp4": {
    "assetId": "eNnbfXx1uk00uAWxQWiPPPK5BzvQs9sOhGrYPwHR9yU00",
    "playbackId": "m0202dXBbl38PFKzQPIMxeyQjvy2WzImE1y200oYsHfP24"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S6/Oui_Sue_S6A_RC01_720_220315.mp4": {
    "assetId": "Q00xE02HKqmlwLFmrctooJ6ehmBCf1n4WFr02TQlpDzTlE",
    "playbackId": "4zkrgUjDE7yBrMkaEm7eHa3H9dsPEfZppf6R00xXW8kk"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S6/Oui_Sue_S6B_RC01.mp4": {
    "assetId": "PmNvqe9AnNOzC4H6uEcp5Bynmz01Zj3li6j4DVMWJq3w",
    "playbackId": "pn21pvOO300J1AxCfC44xfbVntAuvydPbrA02WUFxT6Lk"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S6/Oui_Sue_S6B_RC01_720_220315.mp4": {
    "assetId": "hNhiYD100Pbc015bKrmYYhuuy201jZ7I00Wv2mQpDC45j8E",
    "playbackId": "5d7ET7kmRPjYxvXH013UmL5c00cu00IHKA1k5FOy1id8wE"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S6/Oui_Sue_S6C_RC01.mp4": {
    "assetId": "CjDoEuGWwKwF6hZcmVqxTu5nY02C6PMFoORFGMRI9x3g",
    "playbackId": "bHfKAQp6UJXdtWKp1lDnjWnUBq57WEmqsItDv1A98Nw"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S6/Oui_Sue_S6C_RC01_720_220315.mp4": {
    "assetId": "eKy4D1OzxUOm6dcWWLw8WKwoWVAfdE4yB77YolRJ2BU",
    "playbackId": "1PxTbTXFFwKeKusETBlPl5iODOyiNpf8G78wjB8fOWM"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S6/Oui_Sue_S6D_RC01.mp4": {
    "assetId": "300ej1hm7y8NiYzS5FeFwhC99qGELcpF01hJ1cruip02Us",
    "playbackId": "XKF7dpeQi01KlsSmODojyBgbbLRqLYUqhNPqGnPMh8mc"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S6/Oui_Sue_S6D_RC01_720_220315.mp4": {
    "assetId": "300N1DivS74v00vJlf4fHAn16Ua1ugqyddTRN7FZExXzE",
    "playbackId": "026RKyRtpAkdqCyz6GPg8hhd6RdTG7trxXfhWTb00kXzM"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S6/Oui_Sue_S6E_RC01.mp4": {
    "assetId": "FTZ8kg1mvzcgYGu5grBmBx42xVmUh8h3HuG935RqDXI",
    "playbackId": "Ii9SR01CweeC8mk1MKUSXevE5t028AtpronYypZ00GlgYk"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S6/Oui_Sue_S6E_RC01_720_220315.mp4": {
    "assetId": "tX4y02SJQqReei6aLfUrLv3xNYC5Nt8xv7lxjdWLUsXE",
    "playbackId": "tAVqXwxMpbmAkVgg00Q61MHnZbtyhkUp7YsV002vUmUnI"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S6/Oui_Sue_S6F_RC01.mp4": {
    "assetId": "AQ02fqXYTJXt43SBSBgvMlRYPMy1EH84fgdbNKmpWXos",
    "playbackId": "LN8tu38O6Onk6p3jJMXMrnx8sW28Iv1u94nHRJ01dRB8"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S6/Oui_Sue_S6F_RC01_720_220315.mp4": {
    "assetId": "drgTvUl4UeDTTLuvhCTc24iLaDAbFRFB9cXTfVBIJIo",
    "playbackId": "DSyK00wEWjIC3OZZGm9cvLguq1BrRyvcobgB2SrpG102M"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S7/Oui_Sue_S7A_RC01.mp4": {
    "assetId": "2aN00003s6aQFHeyfIxCQLwH1p301J2XwJSIoRTBY6dnxA",
    "playbackId": "C00j600CIPlw6DJSCB4MTGH26w2Ut02QQdbEMD800nFpClI"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S7/Oui_Sue_S7A_RC01_720_220315.mp4": {
    "assetId": "vZwDSNbffB101hvWiAnQx7HGJ01xQ9oBW7TDpCADqDpXA",
    "playbackId": "1KBpqRAOMEhZ15Yf3Ar7jcr4qeodz02WtY8wgWkBPhlE"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S7/Oui_Sue_S7B_RC01.mp4": {
    "assetId": "02c1w02YO0200IOGioJpoIn7wWLaBDGmZm2VW4fV15WT7Kg",
    "playbackId": "EZPr15BzBq02qG00201jX01eZKSbQFoEljZj9DyAysWLQ5c"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S7/Oui_Sue_S7B_RC01_720_220315.mp4": {
    "assetId": "J7FVg7y6JB00F02mYgipHCLF4APm02nwgGHJu02SadyWN0000",
    "playbackId": "qvI5B2T51WZESkUln00FKTuX02WDdfTwJA003exqH5cgjQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S7/Oui_Sue_S7C_RC01.mp4": {
    "assetId": "cDTFw3dUlspwnrP2RAHg01xVBe9Z01AVfpg9T009vaI7qQ",
    "playbackId": "jUTToa01WhAC6IwwUxtTv674MmU1Coty4ShG5MKbqndc"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S7/Oui_Sue_S7C_RC01_720_220315.mp4": {
    "assetId": "ZWymzSVedz7iJfKqWRc7YdiNkOFSUjT5VUU282YLBTw",
    "playbackId": "7U3c5gKMXto7iYz00lDGxfOQYa6snejSFbzZpCZnH2zc"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S7/Oui_Sue_S7D_RC01.mp4": {
    "assetId": "Pxc5336kadq02Or4Si4zTJjmluFAt02CmX4hz5FR8V7Pk",
    "playbackId": "xqr00YexhQHMJA6vYygfUFzGzIYSogrAh61c0200DA1a01c"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S7/Oui_Sue_S7D_RC01_720_220315.mp4": {
    "assetId": "iZMas2ESqZFkFHp01gU01NgRkp5k1CKpZaitXFNVB9Nqg",
    "playbackId": "KdzdBTe02v01Pe3R9ZXoAJCQc527NNfpb9ABIjoMEmF01g"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S7/Oui_Sue_S7E_RC01.mp4": {
    "assetId": "Hrzal02jWX24Qm5ePPHoO02sWSaLEPk9p1k97gpWQlSpo",
    "playbackId": "YqqFQy5RnOwJ2iHZlBmZ5Ledgptb8QR5j6LonZGYX1s"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S7/Oui_Sue_S7E_RC01_720_220315.mp4": {
    "assetId": "YIzuxnPpcotz1lWs6XrIRsxp02OIW6V8D5EWRZt5nS2k",
    "playbackId": "MCTa3snzE00sMXnk02mmTJHbR00gmwXJGU3EmbC3dytGxI"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S8/Oui_Sue_S8A_RC01.mp4": {
    "assetId": "201xMlFohZZLCENLButpoomvM5uMcW1lS2sYbpPrIueU",
    "playbackId": "ugowh32JvEdRQ8izZZj7PqS2fLRxwBcpOmxIuxmez00k"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S8/Oui_Sue_S8A_RC01_720_220315.mp4": {
    "assetId": "mBrvOfSLBlugzuloFE1Wyd89qdLEW316Q9eOfhRZylQ",
    "playbackId": "kwzN00wVbrnqQvGJ8EztuZji029B2fC8HmT01QcRIxxhzw"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S8/Oui_Sue_S8B_RC01.mp4": {
    "assetId": "IaLMG9FeKwXg008wyUH4KZ5AQTzhZbZU0115OathFDZ8k",
    "playbackId": "t00CjnxucvXOGBjT01p4vsNxcp701DfYcfoZG00KJc6nNoU"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S8/Oui_Sue_S8B_RC01_720_220315.mp4": {
    "assetId": "AdFFB6aZyRxRHNL4ZlR02zdUgmbvtNDS602Esd9WLZUes",
    "playbackId": "ivUW9oCAiN8P02XHlgn02AovXZW73W01nYufcYTD6GrOVk"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S8/Oui_Sue_S8C_RC01.mp4": {
    "assetId": "UEN00Bay028Ssi8e7YZOc024WAW6P02xlAbHsw2zVQqNYgA",
    "playbackId": "cCf00NieiGY15GTx3grXokqYBqR3Y01Kv8vTre02JuusS8"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S8/Oui_Sue_S8C_RC01_720_220315.mp4": {
    "assetId": "T00b9OHBqK00EWavG01YGeD02HOH8vv7vjAlBU1ELHdw02ik",
    "playbackId": "HCzf2ueoJ02S1GYGdLTaTpUz01js601oHwsmRmyjFbgJh8"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S8/Oui_Sue_S8D_RC01.mp4": {
    "assetId": "gA6VP02Eg025AezhCRWpZnKaOsKM4B9rL6AgQhCH9oNzs",
    "playbackId": "oiPHyC1gCSgmgy7n5Nmx1bKwFlJ6ydBqpUt9FvzOCdk"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S8/Oui_Sue_S8D_RC01_720_220315.mp4": {
    "assetId": "mDis02XQJliBgcIrSNXaITfh5Ra1AGD3TgtbqzSqgOxk",
    "playbackId": "k6NVKD001gGyLRHwcKFiSjSh9dHR81QTTvWQh8SIkOwk"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S8/Oui_Sue_S8E_RC01.mp4": {
    "assetId": "EU4XZSq2Jxsls02zNijanXdIg01SeV008eWL58OsLFesIE",
    "playbackId": "H2pjVYWhZyFFUFdiyrqFx4r1uSPqyQ025dMmnQuR9zes"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S8/Oui_Sue_S8E_RC01_720_220315.mp4": {
    "assetId": "H4SxtL3r00iRKDHWdewKwc5AJ00QMzwUS7JENrbhvqhmo",
    "playbackId": "Okc2xXhuSi9dQ5Ddq9QSyNiDsxOcZGziM6TaxgdP00XI"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S8/Oui_Sue_S8F_RC01.mp4": {
    "assetId": "B027ZStPEiz1xfzSbxL4aXyD01rIQuYukGqB5CNOJQNdY",
    "playbackId": "00x00drueiqywgnE01m6oBFoBsLyKbMbUdFthd202A5qMO8"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S8/Oui_Sue_S8F_RC01_720_220315.mp4": {
    "assetId": "8gXnSIDaQT99u1DEnz6OZW8xmrrh01bc79rlaoOvd4qU",
    "playbackId": "l2rKXPmRKojb9gudQrfIBn5voTA7GP301xZRTkiha6c4"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S9/Oui_Sue_S9A_RC01.mp4": {
    "assetId": "aBTbbmaxyYtQCsyhQLLvzm8PXV01mHKeZ4HWtUTQh02xA",
    "playbackId": "NOaS2oC4TCWwSHJ2wIoQYIzbXJZGN101wz402ZMFxHknA"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S9/Oui_Sue_S9A_RC01_720_220315.mp4": {
    "assetId": "FKr00xL9uCdB02NdLs238bFAiV02t7iaHJ01cIw014O00OGFo",
    "playbackId": "M00PyjA9nrWV2RCX32SlW02Kj3OrZ5FJIFeaKjIjnRrLE"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S9/Oui_Sue_S9B_RC01.mp4": {
    "assetId": "xwLePkpYMARgdZ9bmOn700SpNACIPHqh2nOaLJU2wEbo",
    "playbackId": "YuS2BZ2Vo999rCf9hb3DeeoH4hGZ1as5u99Zvk3PY3U"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S9/Oui_Sue_S9B_RC01_720_220315.mp4": {
    "assetId": "djUV500kNj51fYtZ14IplAbcx00U00VCYTnqVPaSK8IpJM",
    "playbackId": "Er1IxnYuw1MUUJI01VaQXHW02VKiZeiilJUtFBm6vjJUg"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S9/Oui_Sue_S9C_RC01.mp4": {
    "assetId": "00oxFSqumXNGwaIwX55K2U201TNctHYrPxFJLaRiL5iDM",
    "playbackId": "T14Oz302Lg02ZULGPuECMwpvcFsRawtUDwQxIHlcfEvBE"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S9/Oui_Sue_S9C_RC01_720_220315.mp4": {
    "assetId": "WXECkVPgLJBZPIOVOCKAmwlNW1To9vhLKy009vC02DF02A",
    "playbackId": "NPPm71SdTlTkqRo9cWCmJJZNrF7w02uZcpyu2v2FWLek"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S9/Oui_Sue_S9D_RC01.mp4": {
    "assetId": "H4rQxP19UrBoOPYYT6pNeJSKGDeXDjoRo01F81F5301II",
    "playbackId": "cmQWfKfohm6vlRBg9M02EcU8oGf8Jt9kEG4CK00x2tnZ8"
  },
  "https://storage.googleapis.com/asset.oui.dev/vignette/adult/sue/S9/Oui_Sue_S9D_RC01_720_220315.mp4": {
    "assetId": "025YZUaKQ701gxaNk4YHZaclNej010100DNdTxfelsqoBASg",
    "playbackId": "XZLiQsKas3qBzgrdgPU2yg8AG3nN5F2q7cK4Hi9qa48"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S1/Video_1_1.mp4": {
    "assetId": "Ve45u2cbdL3M3NeuRIO582yUHWFUHfRO502vZrHazKyc",
    "playbackId": "lgLrAH01iSVgz01t4zn00o5mhsmomwwAO64NyM01uSUlhAo"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S1/Video_1_1_211203.mp4": {
    "assetId": "xSr4rTQ31D23inMZkScNlTRCDfo00LoCvnCJ7Csws1CI",
    "playbackId": "3QSGvNQkxv01pbZ01s2Ca95jwcLMw68F00Onggr2mYwJ4o"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S1/Video_1_2.mp4": {
    "assetId": "PYwvSkQYtz99kqrMtiYtR6S15rKO3glY02ZUH01lqCCUg",
    "playbackId": "wAuYYqxOhkyb8DnDvWKAtwgKbZX48HP01D7qDFHRiNmY"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S1/Video_1_2_211203.mp4": {
    "assetId": "2R9nkE2MLVq4GmmTMhetWukK6yZv014BtHfFpxQ13yRo",
    "playbackId": "lPk02nptQ00uq1DJ1yGFFTWuJWfqDgZcIJH4702F6F41Fg"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S1/Video_1_3.mp4": {
    "assetId": "tEKqabcLnBkvjlFnjXtSUdHwFJp01HecCvgjMcf006fck",
    "playbackId": "IDKq2K87VA402bFS01ZGPwnGYYhyK11K9nAD01adxayjx8"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S1/Video_1_3_211203.mp4": {
    "assetId": "01ccwCBMWXmNqFHEUuHJ02FzWKn02uQCSgHESc00ps54HZ4",
    "playbackId": "3z00uWDeCtlga6aeSbnjbvqi1uO6ipGFWT00RaLT4500i4"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S1/Video_1_4.mp4": {
    "assetId": "7FDMQYHmk3zrNcKumdwTTdL2MiC6hpkoCWUT9DVqNf8",
    "playbackId": "p1Zmq02EHWP2YqHuJMmwtmm02dsvHc7yVrAXQA3GiJmsI"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S1/Video_1_4_211203.mp4": {
    "assetId": "01quXE01hBwrZMSR4QzhT01YEihFKBy7yI00gZl02bglS3PY",
    "playbackId": "m1PV29AEQl6YLD7C00tELb5T01B60102CYKS4G1jcc8xw28"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S1/Video_1_5.mp4": {
    "assetId": "MqoM8iYTu47LSI3qxQFdCtM7NrrPcokzRS3k6jXKvFY",
    "playbackId": "D3l5y01i6zTDnN3txjVLRxLLVrkAsASgUefmhib29SKQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S1/Video_1_5_211203.mp4": {
    "assetId": "NphkglG3ZtY02024BgCz02JKGyEEbu3NARs022bJu00YfOBw",
    "playbackId": "01by1FkR9Vc3xhcO01Vu3eUFLZgvI015HkRBPBeRzxQIUQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S1/Video_1_5_Revised.mp4": {
    "assetId": "D8KHiRf7KbVHNmuoGaob24H1qQ00cbgn68h4jajbhArM",
    "playbackId": "wHVdFf2zN02oXLRFopRBOdVJA5e4Rta5djW4yHY2rHVc"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S1/Video_1_6.mp4": {
    "assetId": "VccKEhNOZL6UjEZcb01MDhBmfK4CzHzasfQlAAgddhkI",
    "playbackId": "COky1O342TDsm6pi9v3R2RfdH87qzH4DxSK7RzQUgTU"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S1/Video_1_6_211203.mp4": {
    "assetId": "xR8prdQbprUaGjGekxAsiRhH3sPMVexKfUuGG6BK4W4",
    "playbackId": "nqXvTfiJJ1DLXaSZ5P00tNg91a17iHMeliAOQX01P4zS4"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S1/Video_1_6_Revised.mp4": {
    "assetId": "Kb015Vr02KXJZHDDqgu2fXi401S2XqkdD5q4tmALy9iQwY",
    "playbackId": "DRQd00QXTxL42C0101iGcfpcUZYl6bCNayqOrcGDoGDa100"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S10/Video_10_1.mp4": {
    "assetId": "02C600I4J1xJdzj01EFUNlLoeC8UpWL37W5dyT6FnzzQ8U",
    "playbackId": "jbmvlzFnVwmQ5JW002OKnVXYIWqx9Sz026kqaUWKX4bF4"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S10/Video_10_1_211207.mp4": {
    "assetId": "OPrB01h301DUtoui5IiYedhH6zwCNNm6oWykhoUGXErxg",
    "playbackId": "wewczuFIwsM2Apwq00HN0101ookHQFPD2xATwWkRQfZ00ug"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S10/Video_10_2_211207.mp4": {
    "assetId": "LyGJYDIumtEtZ7F89kDC14vve9Urb8KQaO9ES1mlx01Y",
    "playbackId": "E313mKXgbkFQfjfBKsha00SkEELClvlK2c1lVaONYXF4"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S11/Video_11_1.mp4": {
    "assetId": "QiT0102t4jsaHLxdOSDjs1KyrgMT01Grkp00qfm202tkwRx8",
    "playbackId": "vsX6PSRszJucrtBiAkN3KvpB1xt6usjElhBphaJZLjI"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S11/Video_11_1_211207.mp4": {
    "assetId": "ER200iVHpO019FXFQwpiR7wjfEv00U7QOgOvWeGjjBvZhQ",
    "playbackId": "y02elZCdf5iZmillDPBYXpYdClfS3d024PHGG53QppRrE"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S12/Video_12_1.mp4": {
    "assetId": "02kRDcGGmy9w8s1GEH01EfCZ0101XyIExgaxFyMW3hiSAr00",
    "playbackId": "79GGVfvkaaI01x6vKF01sP6NZEGPWCAbE02dWchPpKyYl4"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S12/Video_12_1_211207.mp4": {
    "assetId": "Y4DrqkuB3Wxzv02As1Gx8stDj4J025RzCDv1aNPs863UE",
    "playbackId": "LgwY8dbRKXge008nm029CvZYFTOPuqubmG7F8K0100lct4o"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S2/Video_2_1.mp4": {
    "assetId": "Y9KdSVrnceMnW7QZsRsiZSuRml3YmRJ63KBBoy9bkTE",
    "playbackId": "tR1KdF01WwjNZEyd00xSQFlR7Rod01XIZMCRAR400UYn4lk"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S2/Video_2_1_211206.mp4": {
    "assetId": "NUgMqq02D2VMw3qxokz700SVj715AeMqEhSTz3WrlwUas",
    "playbackId": "E3LZgOjY7JgNHqPTMPVlVT025woySzGElF1WRm4N7FXc"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S2/Video_2_1_220110.mp4": {
    "assetId": "L1f53DbLZ34m500YdvBwe6Vj01pVlXU01127mb01RpxHqw00",
    "playbackId": "rgLqONBhg02kgkkcqVZ8RQXBSauUjni200UAMUbIIqO7M"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S2/Video_2_2.mp4": {
    "assetId": "ZVRkOtxLy2fZ8HHH18V1AkfunoyCUF64XdEG3vTxWUU",
    "playbackId": "CANehxiFHPiGH3Au6xQM3n5cpXmYzFUCc83MBjFDHUo"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S2/Video_2_2_211206.mp4": {
    "assetId": "MDyn7tKvX6xvc02Bv6coX3e01gNpKfahYiNJDYVwCEJUo",
    "playbackId": "571xf7BOLXRX3SDMEcRHdb00YmnOrCHc5MrKK2e7RU6c"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S2/Video_2_3.mp4": {
    "assetId": "dSor2EbRVE4GH2Ynfiq01PN3NSDoRdEQMU9HNPgYBszY",
    "playbackId": "00M5avIgxBQ02Tq6d4rangy5ZJgtxHOLnmUrxU9f6Mmac"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S2/Video_2_3_211206.mp4": {
    "assetId": "8vKEoRtLfMGZ9Uiacc37C2rA7GOsWU17GXGEO00ViIWI",
    "playbackId": "8kVINuM2F5VEbDKoK02xzLgKSwx546omsFZdGjD9rItI"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S2/Video_2_4.mp4": {
    "assetId": "gDNcnw8L1mdFvBNXy4FF9mZOrGj8ISGgYYmx1z02eoLQ",
    "playbackId": "5xCnfkEY1xP4XVU7i8FQMjp8mXaSO5MMQKsUbwFQ4g00"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S3/Video_3_1.mp4": {
    "assetId": "QrBcCpsrconwVHXscVo9K64GIJXLSJX5XvHxwdUwwaY",
    "playbackId": "ODlMzXYJwRLQdpx9TMdH00DX02Huw2qBsHLBngYqX701sI"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S3/Video_3_1_211206.mp4": {
    "assetId": "RUxIV00ebYAiQCLdihrZ7jgg5BzoDz00vdjoULGkZdIEU",
    "playbackId": "01AmVa02isUnKE9KfdG25Wss7Wy9Cw4a102r0202CytSLuvQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S3/Video_3_2.mp4": {
    "assetId": "ZpLraKa3lLNZmPLN02k025G1B4Iw8p9Kc01yvbyI02wu1Es",
    "playbackId": "pqbvV1EoIIK01s6vlp1KRvPgSFaIAl1nmWMMvHHNN61s"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S3/Video_3_2_211206.mp4": {
    "assetId": "noO442TZdWNa58FmAfqnGW438yiV2q9xt9bUyku3cuc",
    "playbackId": "4pjLR9IZMSAwfu01U6YkeI01rqqnEXLJgLwt52e00rtZOQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S3/Video_3_3.mp4": {
    "assetId": "7RoXVIlhxNJFNMUMrUZ8i6GAl8XJakiaTQe01DW9KvMg",
    "playbackId": "SiEU501aOFabm00fvIbI5kj5029Hvpj3qctWkf300pyjnBc"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S4/Video_4_1.mp4": {
    "assetId": "Gi2Yvgf48Ic02tXKWwhRHlxpi00NBGQvGzLHRknqhV43Q",
    "playbackId": "FhS00TZeP5SIytv9G02OnN1rsFuqkbkEf0260002iAt3eAB00"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S4/Video_4_1_211206.mp4": {
    "assetId": "K21zPIZaMVnIlPSSbW7UovmTz3YMqVBByrq8updWiYc",
    "playbackId": "qjPCgrlwpPr006C5Z202jTYDfVQYrLNf00tj3JQfY9AmBo"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S4/Video_4_2.mp4": {
    "assetId": "UzAdXv29QOb5Ngw8Ee9e9LWRLsyRXkSYKcKpGZY7J8g",
    "playbackId": "pOK3kzoG739gGJZF00lGaUBbDUhFYOjDzShNhrwHaxHs"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S4/Video_4_2_211206.mp4": {
    "assetId": "3oekepb3IEKvPT700b7DSQk01aQnD5q5CIK7x56vvVgh00",
    "playbackId": "9RpNxzyY5mcf00Ecj7EOo6Tk4RkYk89nj4PamKdiQN3c"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S5/Video_5_1.mp4": {
    "assetId": "02f7Ag9wbz1sDeahWKZnydYUdOJW6SN2yxiQ5WSOB6tw",
    "playbackId": "LNES5DzXAVgVkxGwZ3beugE8CGSTSeho1XFEdndWyUg"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S5/Video_5_1_211207.mp4": {
    "assetId": "G4biJag0034OzAZtwhEvsVeFxHZkbZD94G41hj7oQdwQ",
    "playbackId": "xx8LfDRS4grIrF1L8t1T5LK23yZnHSiTmIkCcWebIZk"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S5/Video_5_2.mp4": {
    "assetId": "iTePRYHEMtSBEBkp8DRzky8kiTyOBiak00Sy7xxWDYkk",
    "playbackId": "9gAMfK5JNJZp1A9ASIz029B3jVyaIO902yunmq8FnB00DQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S5/Video_5_2_211207.mp4": {
    "assetId": "X1AyL9vJ8Pgl3t4kQHDgeU6nZD5mE7LfvCKJqyi4ojc",
    "playbackId": "ksNUPe6muNfGGVcDSI01iobedGGHl1taQrS2CAR0200V98"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S6/Video_6_1.mp4": {
    "assetId": "HmSHwHk6ryAXUtPC9wCh2UV4S1WyQZkKHo6OIRkohYo",
    "playbackId": "S0201M2EHzeeHyua4PFGfd02atzu9tsUztZMYyCRDfsjxM"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S6/Video_6_1_211207.mp4": {
    "assetId": "JoSirzRj9yPqIAQMa00R01ugoSO453W201DToBYuZNF8t8",
    "playbackId": "Zal9eTF6HDb01APHMI1CxiyU3c8EZfVbDswrKMQgx4ME"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S6/Video_6_2.mp4": {
    "assetId": "bPTiOreON7ghxCEeN4w29clqrWux8S6Ms74C9bjbfNY",
    "playbackId": "ruUiIBPeA4NCfdMcFihvGr00R82h9uueViHPXFnt2qpI"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S6/Video_6_2_211207.mp4": {
    "assetId": "02WQg4URWk01S1001xb802RxfLj2MdBT1gDNyZoWphrAvm8",
    "playbackId": "yLXr1XhDrmp002CSGuX01jn02QOG02SJE9J2kjg4bsboF3w"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S7/Video_7_1.mp4": {
    "assetId": "ZWzRCoGe58V6XVAjQkVLLM2TTSIAA1nB8vGkv802f9UM",
    "playbackId": "t00agsvX3006102LJeBlav5ABjMiVS02XYkGOyywV4zTOlM"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S7/Video_7_1_211207.mp4": {
    "assetId": "wLzezLHzkitxmiHZ62jC8EgFHcIXv01KMIgQ2oa300acw",
    "playbackId": "w005uAhYr101QbEI5fZcWBty00KkyT7wHcB7aHjbqGPV2I"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S7/Video_7_2.mp4": {
    "assetId": "wkUQoTSmzT0000E1luX8TGtpP2VOaTcafW2Jbn0112BOVM",
    "playbackId": "Po3z8QYes02cGbwR6jnf1f5nuPK6UnGy7seQHwoOBKP8"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S7/Video_7_2_211207.mp4": {
    "assetId": "eZgRabJC6m8AK00R8oYXuPstSVebU9T7U9xR00mjBxXfw",
    "playbackId": "M005025ZIvsNye6LrUBciHZz3xNY1J3OYXavquOZqrlsg"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S8/Video_8_1.mp4": {
    "assetId": "GT1zQMHOr6G9ffdw01J1JszT7x9ldGMHd64uilrQEJtk",
    "playbackId": "zA69yh02nRe02RCD5RG4KwnMDIVqPeY8pKkD3ajPGUKMM"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S8/Video_8_1_211207.mp4": {
    "assetId": "nQGFMhFF7gSlVuwXAjO3eYt3Xu7l02eGiDpK1n5YFT4w",
    "playbackId": "4tQUae00ZouwLctCvZwjqcQvcDyoQvTxUyDl6c3XfXyg"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S8/Video_8_1_Revised.mp4": {
    "assetId": "MlpF1y02ILkXfUiO2XNP5Z1cfzmCYvflH1dBz4YksaNU",
    "playbackId": "02YMXxZSwotBbchknKw7AypKODMGsr00DthIwovNR007dw"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S8/Video_8_2.mp4": {
    "assetId": "1YqkBl02DiS9UxjB9NyZtElciqRsdtbl9HSHoKcSrqTA",
    "playbackId": "8lS2d97S8kSXOuPXx00g3tMBmNDCvDlMz1sumB3knR5g"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S8/Video_8_2_211207.mp4": {
    "assetId": "3nkaiZNCKQkTQILMgf7BMdkxEXsoQcMGsfC0111HRzu00",
    "playbackId": "VbaqpaKivfZnjOM1ej2rhabnZePAN501yFMcpyEcUAyM"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S8/Video_8_2_Revised.mp4": {
    "assetId": "c2fMfUJQ6ez3OIUVDaCgVIT1wELGrO6vshM3xvHwIVE",
    "playbackId": "7bLafQ7onlKcZFnBm02LbJFhFPIVSCF018uAuZvx02DVS4"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S9/Video_9_1.mp4": {
    "assetId": "00zEl00x5BWjGS00fVQz6fMbK3nXFRCKzCaZYWYWafpQvo",
    "playbackId": "wCAXwelqxkO1nXelRVgSu01YnXWyt44zZdu9fmulXw6w"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S9/Video_9_1_211207.mp4": {
    "assetId": "yHKAy5SikuzIULEiwYYnsn5027GwVO7xI1wN00btDJQT4",
    "playbackId": "485yBDUH2p02E1brGTK6Al800AeZZmFXnxi4ns54sFNZg"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S9/Video_9_2.mp4": {
    "assetId": "jnxrJ9apIomcr00mOerdR79hITqLtiZMLuBOG3TgAmiQ",
    "playbackId": "k00pWad2j2HPHJ02XGC1ZAWoUSZ61vCb6ceHAMYpJF02tU"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/static/S9/Video_9_2_211207.mp4": {
    "assetId": "d02da5o9IT4MSQpsFMs91urA7GS5WbpzRtlZlmOuoRqQ",
    "playbackId": "WCmxjqazd3EKmp2lDZqIRR016IDYT1WSrttwpzQBb02Is"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/v2/01_SRC.mp4": {
    "assetId": "3aQjMLxRiVn02hiF00r9FfBnwr47d5A4ZehY4H01P7Bfvg",
    "playbackId": "XZ011jVdrupOiFGiYThLVJocEmN701kvGT7syyxxit600c"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/v2/02_MyPlan.mp4": {
    "assetId": "xbqxc02ASdxkCPAC74gKk02IBJoOEmnp7HErQlBq01ZThw",
    "playbackId": "MjdUZE02v00HmtcR28MkutDb02VF2kv8AtUmw5KbUHKPVM"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/v2/02_MyPlan_211018.mp4": {
    "assetId": "nVk1lH9WRHDMqods012IXpEULvtw1cxPnCceNINoBfOI",
    "playbackId": "xrUSzdCjxSD1oIEpn01ILj01ZuxjZX02A4CngBz3DiRDSM"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/v2/03_Hope_kit.mp4": {
    "assetId": "TdpFm2Xgw4Y00Opr4gQoD4TiGczLeTaPt4MQBmV71WvM",
    "playbackId": "6B1FDEE022Ee00vPUyJ681a8GITK3e00YgFVZOD2SWmwXI"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/v2/04_Getting_going.mp4": {
    "assetId": "lCPy3vORiXboyYQBZhqcv9DO00PRlw2ncPXc01U1NpOCQ",
    "playbackId": "2nbFMazUJWBYHXHoRrEPq17L8cFmy6BvTzcB9xOiAbE"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/v2/05_Relaxation_Skills.mp4": {
    "assetId": "RiceXUx602X4BqrGWXBkSd7XNdkjhAl6aONDjlzSJ4Oo",
    "playbackId": "5j6O18kVgDRer7WvOpyV99IMvrc9m2t8Uuty5CEXObU"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/v2/06_Mindfulness.mp4": {
    "assetId": "8rKdaWuZuh31Mc9RyBvyAN8pFvgCwpBTEUXcROAVQvc",
    "playbackId": "EZXU2MU9jNiZBIwPx1p6xhhNwnTmEIhLo9vqyCawJ01o"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/v2/07_Improving_sleep.mp4": {
    "assetId": "sby01XicaCxAOcm022vHMiAr56BABbnoAMOSJZZZmdCas",
    "playbackId": "n7e00qo8q6TR1wePC02tfr5xuHFLKsi02iS61O3dk01Vy7U"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/v2/08_Spot_it.mp4": {
    "assetId": "BOXfjoLFgjGDiF9obZkHM1A00s6pIp4JM4x01Lq5BPrD4",
    "playbackId": "tw4oQEqQbryRpNLJJn01auB3EjBdjRaTQy4iYHuBLFl00"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/v2/09_Test_it.mp4": {
    "assetId": "b301Sx00Ooy02q4jiKP1ucQc5zYgP9U8FwfoUOMfOxF00HA",
    "playbackId": "AUGR7y28r30201E18HaGNbyRdqAwDff01lHz9tQmEUk65M"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/v2/10_Switch_it.mp4": {
    "assetId": "rxT8GgzadeqtpzNvUEHUm00VoH4vTRz1fD4nt9p00kcw00",
    "playbackId": "ixNEAwqxoFEmvc02oshJXYlU8S02gI900uX4sH6cCTcF2w"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/v2/11_Coping_cards.mp4": {
    "assetId": "Dj3yqwuvJBEBGieZgFW00kBigKI2uf5PxjksLq00E01bac",
    "playbackId": "iyp3oQEk4hgsf57fneEBwczZ4kCQaqgOs02hAc5N00DOw"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/v2/12_Review_risk_curve_and_MyPlan.mp4": {
    "assetId": "3JaPfcBymxSDSrZxr00jJ5SP1PAhKcmqEV4OYVkpFmu00",
    "playbackId": "R5EK4oL5mqyeB367SAM01itp4q00RTXaH772IbZR9eaGQ"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/v2/13_Review_skills_to_reduce_risk.mp4": {
    "assetId": "02pRYWgszxBGNEQyMdvgLjOD8k7oYbEj00tN5HGzvZVgU",
    "playbackId": "fKtg702p01HiU14OeaqWdDJF9ubsA8oZEoUUjM8a5l4Jw"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/v2/14_Whats_next.mp4": {
    "assetId": "CaWQpM9Ce02JWxuEHexCJqrDtXuttnedV2i3q53btkx00",
    "playbackId": "IAoXpirKVxDBfr6wNPURcXPG01w9El6grfU01ySNebGGo"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/v3/01_RiskCurve.mp4": {
    "assetId": "ZRkpj5yM4QKUX01GknhbCcIplDYym01FjuWZTf01pHcItY",
    "playbackId": "lBh4DBIVQhUeVwSLWSNtbnrYCGOr3AU02ISXOJCtXO1s"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/v3/02_MyPlan.mp4": {
    "assetId": "QzmVcXP3k00qHTsi96U4Kbmi68JK01eFb7Uxjwg5mqQ8Q",
    "playbackId": "eGxgJlTx79UY8op02b00T6J8S3BhhksaajPr4Ushe3zVo"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/v3/03_Hopekit.mp4": {
    "assetId": "KabQafmGDXy8QKN2qSlFc01cPbvvp7XkOOhBbOy5iYKU",
    "playbackId": "jXOh01Ifq4WgslcXLysnv6a5lj0233cLxrXml5900nt01Q4"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/v3/04_Getting Going.mp4": {
    "assetId": "qcsKdVZZOVwSKSs4cGIEQm27ual69flNB6svIp3Y9qk",
    "playbackId": "ms2XtqC02CDqFvNtpZGlynfdic00Qy2dLp00EgYYygduPE"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/v3/05_Relaxation skills.mp4": {
    "assetId": "IMf5ZZQysLH5pjMZwuqhr02WIDOtT7EsHpNfA8S8ZotY",
    "playbackId": "HleD5Yr7uRpLPfYYropxv83EpjW8kzFL4VVelf01WVqw"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/v3/06_Mindfulness.mp4": {
    "assetId": "ltUreU01VtPDmC00R1itkpyLPTacdNdhCc7L3DS01XuPKE",
    "playbackId": "YhoqYyMZbEexG01RnBzhekr919f4NkLjbfZlwiBmbvQs"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/v3/07_improving sleep.mp4": {
    "assetId": "FwF5m01RPh9fMaokw1GSdqGDGJa02BsFOhn9BiYc2WGAA",
    "playbackId": "00F4fyChd9FyNse02KEkFiDJrUadjYFW00nzpE27wmQjns"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/v3/08_Spot It.mp4": {
    "assetId": "T8NseZmRM01E2H02SBIK5Sf9HC4A5sxOrAism01DfZ656c",
    "playbackId": "wA01qYuecMfxdJffWYGCvzAEZaw7sDdBjd1ByHPGt2UY"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/v3/09_Test it.mp4": {
    "assetId": "5AZ022qoD00Q71qhEORG702993jrn6qgHCqLjn38ke01jwg",
    "playbackId": "3lIYYT4a009CEvoosJyRYEZGzhpjyJEe2pTX023btO00lo"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/v3/10_Switch it.mp4": {
    "assetId": "lh79j7bjRByvNKUhxTjfgUZTIlchqYYp8SpfMyK4UMg",
    "playbackId": "5MbfzcoyY6f6GG7Z1jbzWxTPU01X6X8ayWW2HWTJxNNM"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/v3/11_Coping Cards.mp4": {
    "assetId": "R1ZHKGw6bXs01gOGYy4ksrzFM33aAljtl4hN9QyO9Wag",
    "playbackId": "WYNDc7z2NHcUl5iH4Vo6IZKu6JOJWewqRUBKRy600FO8"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/v3/12_Review SRC and MyPlan.mp4": {
    "assetId": "C0086DEErf4x9VmS3HTFbLu9HJhPK00xAU8nI5Pviut02E",
    "playbackId": "W5i9PVVlisyc2J00nRq014ifTNGFNH01iO7IeiVHpIT32Y"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/v3/13_Review Skills.mp4": {
    "assetId": "s015e00kGaqNn02KldPYH2mq1hpiMNUrC2fC3GCKWwVC00w",
    "playbackId": "y8CsaHAlfrgC01X2SdM02IrSRKtVKC902f5e7S9Y9CeuIA"
  },
  "https://storage.googleapis.com/asset.oui.dev/voiceover/v3/14_After Aviva.mp4": {
    "assetId": "rGZT2dT6zQ02WyNXQLTfrDnoa4JMJbvstpjmNLvoDThA",
    "playbackId": "8ZQGeMuYz9aDOVbqOR8xWZPkyTv01uBm00ZrwkRhoX01JA"
  }
}
