import { z } from 'zod';

export const MyStoryMyPlanStep = z.enum([
  'OVERVIEW',
  'MYSTORY__INTRODUCTION',
  'MYSTORY__TIMELINE',
  'MYSTORY__TIMELINE_REVIEW',
  'MYSTORY__TIMELINE_FINAL',
  'RISK_CURVE__INTRODUCTION',
  'RISK_CURVE__REVIEW',
  'MY_PLAN__INTRODUCTION',
  'MY_PLAN__REVIEW',
  'COMPLETE',
]);
export type MyStoryMyPlanStep = z.infer<typeof MyStoryMyPlanStep>;

export const CurrentEditorEnum = z.enum([
  'CLINICIAN',
  'PATIENT__SOCIAL_CONTACT_PICKER',
  'PATIENT__SOCIAL_PLACE_PICKER',
  'PATIENT__HELP_CONTACT_PICKER',
  'PATIENT__PROFESSIONAL_CONTACT_PICKER',
  'PATIENT__SUPPORT_CONTACT_PICKER',
]);
export type CurrentEditorEnum = z.infer<typeof CurrentEditorEnum>;

export const MyStoryMyPlanState = z
  .object({
    currentStep: MyStoryMyPlanStep.default('OVERVIEW'),
    currentEditor: CurrentEditorEnum.default('CLINICIAN'),
  })
  .default({
    currentStep: 'OVERVIEW',
    currentEditor: 'CLINICIAN',
  });
export type MyStoryMyPlanState = z.infer<typeof MyStoryMyPlanState>;
