import { GQLUUID } from './types/scalars';

type HostedQuizSetSlug = string & { _quizSetSlug: true };

export function isHostedQuizSetSlug(slug: string): slug is HostedQuizSetSlug {
  return slug.startsWith('quizSetCollection:');
}

export function encodeHostedQuizSetSlug({
  quizSetCollectionID,
}: {
  quizSetCollectionID: string;
}): HostedQuizSetSlug {
  return `quizSetCollection:${quizSetCollectionID}` as HostedQuizSetSlug;
}

export function decodeHostedQuizSetSlug(slug: HostedQuizSetSlug) {
  const [_, quizSetCollectionID] = slug.split(':') as GQLUUID[];
  return { quizSetCollectionID };
}
