import { z } from 'zod';

import { Contact, TextItem } from './common';

export const SupporterTypeEnum = {
  PARTNER: 'PARTNER',
  CHEERLEADER: 'CHEERLEADER',
  POSITIVE_ENABLER: 'POSITIVE_ENABLER',
} as const;
export type SupporterTypeEnum = (typeof SupporterTypeEnum)[keyof typeof SupporterTypeEnum];

export const SupportActivity = TextItem;
export const Supporter = z.object({
  contact: Contact,
  type: z.nativeEnum(SupporterTypeEnum),
  activities: z.array(SupportActivity),
});

export const DetractorStrategy = TextItem;
export const Detractor = z.object({
  contact: Contact,
  strategies: z.array(DetractorStrategy),
});

export const SocialSupport = z
  .object({
    supporters: z.array(Supporter),
    detractors: z.array(Detractor),
  })
  .default({
    supporters: [],
    detractors: [],
  });

export type SupportActivity = z.infer<typeof SupportActivity>;
export type Supporter = z.infer<typeof Supporter>;
export type DetractorStrategy = z.infer<typeof DetractorStrategy>;
export type Detractor = z.infer<typeof Detractor>;
export type SocialSupport = z.infer<typeof SocialSupport>;
