import { z } from 'zod';

import { Contact, TextItem } from './common';

export const EnvironmentSafetyStep = TextItem;
export type EnvironmentSafetyStep = z.infer<typeof EnvironmentSafetyStep>;

export const SupportContact = Contact;
export type SupportContact = z.infer<typeof SupportContact>;

export const EnvironmentSafety = z
  .object({
    /**
     * A list of removal steps
     */
    removalSteps: z.array(EnvironmentSafetyStep).default([]),
    /**
     * A list of environment safety support contacts
     */
    supportContacts: z.array(SupportContact).default([]),
  })
  .default({
    removalSteps: [],
    supportContacts: [],
  });
export type EnvironmentSafety = z.infer<typeof EnvironmentSafety>;
