import { z } from 'zod';

import { TextItem } from './common';

export const PreventingRelapseStrategy = TextItem;
export type PreventingRelapseStrategy = TextItem;

export const PreventingRelapseReasonEnum = {
  LOSS: 'LOSS',
  FINANCIAL: 'FINANCIAL',
  CONFLICT: 'CONFLICT',
  STRESS: 'STRESS',
  CHANGES: 'CHANGES',
  HOLIDAYS: 'HOLIDAYS',
  VACATIONS: 'VACATIONS',
  OTHER: 'OTHER',
} as const;
export type PreventingRelapseReasonEnum =
  (typeof PreventingRelapseReasonEnum)[keyof typeof PreventingRelapseReasonEnum];

export const PreventingRelapse = z
  .object({
    relapsePlans: z.array(
      z.object({
        reason: z.nativeEnum(PreventingRelapseReasonEnum),
        reasonLabel: z.string().optional(),
        strategies: z.array(PreventingRelapseStrategy),
      }),
    ),
  })
  .default({
    relapsePlans: [],
  });
export type PreventingRelapse = z.infer<typeof PreventingRelapse>;
