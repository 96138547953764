import { ContentType } from './types/graphql.generated';

const SUGGESTIONS_SESSION_00 = `
You learned about the aim of this app and how it relates to your teen’s app. You also got a list of ways to have helpful talks with your teen about their thoughts and feelings that others have found helpful.
`;
const SUGGESTIONS_SESSION_01 = `
You saw that in MyPlan your teen is learning how to make a plan to reduce suicide risk by listing their warning signs, ways to distract themselves from harmful thoughts, key people they can talk to for support and ways to keep their environment safe. 
`;
const SUGGESTIONS_SESSION_02 = `
You learned that the Hope Kit reminds people that there are lots of good things in life and that life is worth living.
`;
const SUGGESTIONS_SESSION_03 = `
You learned that Get Going helps your teen become more active which improves mood. 
`;
const SUGGESTIONS_SESSION_04 = `
You learned that relaxation and mindfulness exercises can help your teen reduce stress, and let go of negative thoughts. 
`;
const SUGGESTIONS_SESSION_05 = `
You learned that improving sleep can help your teen’s reduce suicide risk. Things tend to look less bleak and hopeless when they’re well rested.
`;
const SUGGESTIONS_SESSION_06 = `
You learned that your teen will practice spotting their own patterns of thinking that can increase suicide risk. 
`;
const SUGGESTIONS_SESSION_07 = `
You learned that your teen will practice challenging their thoughts because not all their thoughts are true, or helpful.
`;
const SUGGESTIONS_SESSION_08 = `
You learned that your teen will practice switching untrue and unhelpful ways of thinking to more neutral or helpful ones. 
`;
const SUGGESTIONS_SESSION_09 = `
You learned that your teen will review their Coping Cards which help remind them of skills they've acquired already and help them deal with negative thoughts.
`;
const SUGGESTIONS_SESSION_10 = `
You learned that your teen will review the importance of knowing their warning signs and the use of MyPlan as a series of steps your teen can take when they feel their risk is rising. 
`;
const SUGGESTIONS_SESSION_11 = `
You learned that your teen will review the skills they have been taught to use to avoid another suicidal crisis
`;
const SUGGESTIONS_SESSION_12 = `
You learned that your teen was encouraged to keep using the app’s tools to reduce their suicide risk.
`;

const SUPPORTER_SUGGESTIONS: Record<string, string> = {
  [ContentType.HOW_TO_HELP]: SUGGESTIONS_SESSION_00,
  [ContentType.MYPLAN]: SUGGESTIONS_SESSION_01,
  [ContentType.HOPE_KIT]: SUGGESTIONS_SESSION_02,
  [ContentType.ACTIVITY_PLANNING]: SUGGESTIONS_SESSION_03,
  [ContentType.RELAXATION]: SUGGESTIONS_SESSION_04,
  [ContentType.SLEEP]: SUGGESTIONS_SESSION_05,
  [ContentType.SPOT_IT]: SUGGESTIONS_SESSION_06,
  [ContentType.TEST_IT]: SUGGESTIONS_SESSION_07,
  [ContentType.SWITCH_IT]: SUGGESTIONS_SESSION_08,
  [ContentType.COPING_CARDS]: SUGGESTIONS_SESSION_09,
  [ContentType.MYPLAN_REVIEW]: SUGGESTIONS_SESSION_10,
  [ContentType.REDUCE_RISK]: SUGGESTIONS_SESSION_11,
  [ContentType.POST_AVIVA]: SUGGESTIONS_SESSION_12,
};

export const getSupporterSuggestionsForSession = ({
  contentType,
}: {
  contentType: ContentType;
}) => {
  return SUPPORTER_SUGGESTIONS[contentType];
};
