import { zodResolver } from '@hookform/resolvers/zod';
import equals from 'fast-deep-equal';
import merge from 'lodash/merge';
import { Controller, FormProvider, useForm, useFormContext, UseFormProps } from 'react-hook-form';
import { z } from 'zod';

// TODO i18n
const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === z.ZodIssueCode.invalid_type) {
    if (issue.expected && issue.received === 'undefined') {
      return { message: 'Must be present' };
    }
  }
  return { message: ctx.defaultError };
};
z.setErrorMap(customErrorMap);

export function useZodForm<T extends z.Schema>(schema: T, props?: UseFormProps<z.infer<T>>) {
  const resolver = zodResolver(schema);
  const form = useForm<z.infer<T>>({
    resolver: async (...args) => {
      const context = args[1];
      // First trigger built-in validation handling that normally is disabled when resolver is used
      // This function relies on the react-hook-form patch
      await context.executeBuiltInValidation();
      // Then trigger the zod resolver
      const { errors, values } = await resolver(...args);
      // Combine errors to report all errors at once. otherwise executeBuiltInValidation won't show
      // until the next change event
      const mergedErrors = merge({}, form.formState.errors, errors) as any; // eslint-disable-line
      return { errors: mergedErrors, values: Object.keys(mergedErrors).length ? {} : values };
    },
    ...props,
  });
  // access this property so we always "subscribe" to dirty changes in case of call to reset
  // https://github.com/react-hook-form/react-hook-form/issues/8341
  form.formState.dirtyFields;

  // This field is used by inputs to create a mapping of form value -> labels globally available
  // in the form context. This is useful for rendering errors apart from their individual fields
  if (!form.labels) {
    form.labels = {};
  }

  // by default, react-hook-form only cares about the defaultValues passed in at mount. A more
  // ergonomic hook is one that updates the forms defaults when the specified default values change.
  if (props?.defaultValues && !equals(props?.defaultValues, form.formState.defaultValues)) {
    form.reset(props?.defaultValues, { keepDirtyValues: true });
  }

  return form;
}

export function useZodFormContext<T extends z.Schema>(schema: T) {
  return useFormContext<z.infer<T>>();
}

export { Controller, FormProvider };
