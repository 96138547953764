body.detox-puppeteer > #root {
  height: calc(100vh - 50px); /* 50px offsets the chrome "automated browser" toolbar */
}

a {
  transition-property: opacity;
  transition-duration: 150ms;
}
a:hover {
  opacity: 0.5;
}
a[data-tablerow]:hover {
  opacity: 1;
  background-color: rgba(206, 106, 107, 0.15) !important;
}

[data-no-pointer-events] * {
  pointer-events: none !important;
}

[data-nofocusoutline] {
  outline: none;
}

/* https://stackoverflow.com/questions/16603979/how-to-remove-the-default-arrow-icon-from-a-dropdown-list-select-element */
select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}
/* For IE10 */
select::-ms-expand {
  display: none;
}

/* ViewPager */
[data-snap-container-horizontal] {
  -webkit-scroll-snap-type: mandatory;
  scroll-snap-type: x mandatory;
  -webkit-scroll-snap-points-x: repeat(100%);
  scroll-snap-points-x: repeat(100%);
}
[data-snap-container-vertical] {
  -webkit-scroll-snap-type: mandatory;
  scroll-snap-type: y mandatory;
  -webkit-scroll-snap-points-y: repeat(100%);
  scroll-snap-points-y: repeat(100%);
}
[data-snap-child] {
  scroll-snap-align: start;
  overflow: hidden;
}
/* ViewPager */

[data-button] {
  transition:
    background-color,
    opacity 100ms;
}
[data-link-button--text]:hover:not([disabled]) > *,
[data-button--text]:hover:not([disabled]) > * {
  text-decoration-line: underline;
}
