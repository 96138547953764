import type { NotificationPayloadRegistry as NPR } from '@oui/lib/src/types';

/* eslint-disable no-var */
declare global {
  var e2e: boolean | undefined;
  var fontsLoaded: boolean | undefined;
  var pauseAppState: boolean | undefined; // when asking for permissions we probably want to ignore updates to appstate

  // The AppCore namespace allows us to reference base types in @oui/app-core while letting consuming
  // apps extend their definitions. Approach taken from:
  // https://github.com/react-navigation/react-navigation/blob/55da7c9b72076c98a65eb4b1b338e990a2f5a21c/packages/core/src/types.tsx#L12-L18
  // eslint-disable-next-line @typescript-eslint/no-namespace
  export namespace AppCore {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    export interface Flags {
      allowDarkTheme: boolean;
      showEnvelopeIDInChat: boolean;
      showI18NFlags: boolean;
      showI18NKeys: boolean;
      useIOS14DatePicker: boolean;
      useMediaLibraryAlbums: boolean;
    }

    // eslint-disable-next-line
    export interface IconGlyphs {}

    // https://github.com/microsoft/TypeScript/issues/28078#issue-373025524
    // eslint-disable-next-line
    export interface NotificationPayloadRegistry extends NPR {}
    export type NotificationPayload =
      NotificationPayloadRegistry[keyof NotificationPayloadRegistry];
  }

  interface Window {
    OUI_AVIVA_DOMAIN?: string; // used in iframeUtils and set by consuming app
  }
}

/* eslint-enable no-var */
export {};
