import type { ActionEnvelope } from '../types/actionEnvelopes';
import type { ChatEnvelope } from '../types/chatEnvelopes';
import type { EmptyEnvelope } from '../types/emptyEnvelope';
import type { InputEnvelope } from '../types/inputEnvelopes';
import type { SideEffectEnvelope } from '../types/sideEffectEnvelopes';

export type { ActionEnvelope, ChatEnvelope, InputEnvelope, SideEffectEnvelope };

type History = { history?: boolean };
export type EnvelopeHistory = History;
// Need to apply history to each Envelope category so generation with json:envelops works properly
export type Envelopes =
  | (EmptyEnvelope & History)
  | (ChatEnvelope & History)
  | (InputEnvelope & History)
  | (SideEffectEnvelope & History);
