import { z } from 'zod';

export const TextItem = z.object({
  ID: z.string().default(''),
  text: z.string().default(''),
});
export type TextItem = z.infer<typeof TextItem>;

export const Contact = z.object({
  ID: z.string().default(''),
  firstName: z.string().default(''),
  lastName: z.string().default(''),
  localDeviceID: z.string().default(''),
  imageBase64: z.string().default(''),
  imageAssetKey: z.string().optional(),
  phone: z.string().default(''),
});
export type Contact = z.infer<typeof Contact>;

export const Place = z.object({
  ID: z.string().default(''),
  name: z.string().default(''),
  latitude: z.number().default(0),
  longitude: z.number().default(0),
});
export type Place = z.infer<typeof Place>;
