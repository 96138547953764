import forEach from 'lodash/forEach';
import isPlainObject from 'lodash/isPlainObject';

export const flattenErrorMessages = (
  errors = {},
  labels: Record<string, string | undefined> = {},
) => {
  const result: {
    [key: string]: {
      label: string;
      message: string;
    };
  } = {};

  const flatten = (collection: object | undefined, prefix = '') => {
    forEach(collection, (value: object | string | undefined, key: string) => {
      let path = `${prefix}${key}`;

      if (isPlainObject(value)) {
        flatten(value as object, `${path}.`);
      } else if (Array.isArray(value)) {
        for (let i in value) {
          flatten(value[i] as object, `${path}.${i}.`);
        }
      } else {
        // I had to use a slightly unconventional approach to extract all the nested error
        // messages from react-hook-form. The list of errors uses a naming pattern
        // like "address.city.message" to identify the corresponding input field.
        // To simplify things, I removed the ".message" portion from the pattern and used
        // the resulting value to create a flat list of inputs and
        // their respective error messages.
        if (key === 'message') {
          path = path.replace(/.message/, '');
          result[path] = {
            label: labels[path] ?? 'Field',
            message: value as string,
          };
        }
      }
    });
  };

  flatten(errors);

  return result;
};
