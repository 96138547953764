import { ArtifactButton } from '@oui/app-core/src/components/ArtifactButton';
import { View } from '@oui/app-core/src/components/View';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { card } from '@oui/app-core/src/styles';
import { FragmentOf, graphql, readFragment } from '@oui/lib/src/graphql/tada';
import { CompositionTemplates } from '@oui/lib/src/types/compositionTemplates';

import { EnvironmentSafety } from '../components/PatientMyPlan';
import {
  IsEnvironmentSafetyLockedFragment,
  useIsEnvironmentSafetyLocked,
} from '../hooks/useIsEnvironmentSafetyLocked';

function EnvironmentSafetyLocked({ unlockingSession }: { unlockingSession: number }) {
  const { $t } = useI18n();

  return (
    <ArtifactButton
      testID="ArtifactButton_EnvironmentSafety__locked"
      bannerText={$t(
        {
          id: 'EnvironmentSafetyLocked_banner',
          defaultMessage: 'Complete session {sessionNum}',
        },
        {
          sessionNum: unlockingSession,
        },
      )}
      assetName="MeansSafetyPlan"
      title={$t({
        id: 'EnvironmentSafetyLocked_title',
        defaultMessage: 'Make your environment safe',
      })}
      locked
    />
  );
}

export const EnvironmentSafetySectionFragment = graphql(
  `
    fragment EnvironmentSafetySection on Role {
      myPlanComposition: composition(template: "MYSTORYMYPLAN") {
        ID
        json
      }
      sessions(contentType: "ENVIRONMENT_SAFETY") {
        session {
          sessionID
          ordinal
        }
      }
      ...IsEnvironmentSafetyLocked
    }
  `,
  [IsEnvironmentSafetyLockedFragment],
);

export function EnvironmentSafetySection({
  data: _data,
  cardUI,
  highlightedSections,
}: {
  data: FragmentOf<typeof EnvironmentSafetySectionFragment> | undefined;
  cardUI?: boolean;
} & Pick<React.ComponentProps<typeof EnvironmentSafety>, 'highlightedSections'>) {
  const data = readFragment(EnvironmentSafetySectionFragment, _data);
  const isEnvironmentSafetyLocked = useIsEnvironmentSafetyLocked(data ?? null);
  const compositionData = data?.myPlanComposition
    ? CompositionTemplates.MYSTORYMYPLAN.parse(data.myPlanComposition.json)
    : undefined;

  return !data || !compositionData ? null : isEnvironmentSafetyLocked ? (
    <EnvironmentSafetyLocked unlockingSession={data.sessions[0].session.ordinal} />
  ) : cardUI ? (
    <View style={[card, { padding: 20 }]}>
      <EnvironmentSafety
        data={compositionData}
        isEditing={false}
        onEdit={() => {}}
        highlightedSections={highlightedSections}
      />
    </View>
  ) : (
    <EnvironmentSafety
      data={compositionData}
      isEditing={false}
      onEdit={() => {}}
      highlightedSections={highlightedSections}
    />
  );
}
