import { ComponentProps, useMemo } from 'react';
import { Pressable } from 'react-native';

import { OldHeading, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { Shadow, useTheme } from '@oui/app-core/src/styles';
import { CrisisTimeline, CrisisTimelineEvent } from '@oui/lib/src/types/avro/crisisTimeline';

import { Icon } from '../components/Icon';
import { getColorForX, getXPositionsForItems } from '../components/RiskCurve';

export function CrisisTimelineSectionHeading(props: {
  icon: ComponentProps<typeof Icon>['name'];
  text: string;
  alignSelf?: 'center';
  backgroundColor?: string;
}) {
  return (
    <View
      row
      style={{ alignSelf: props.alignSelf, backgroundColor: props.backgroundColor }}
      spacing={8}
    >
      <Icon name={props.icon} aria-label={undefined} size={30} />
      <OldHeading text={props.text} textAlign="center" />
    </View>
  );
}

export function CrisisTimelineListItem({
  isCrisisPeak,
  item,
  color,
}: {
  isCrisisPeak: boolean;
  item: CrisisTimelineEvent;
  color?: string;
}) {
  return (
    <View row spacing={20} style={{ alignItems: 'flex-start' }}>
      <View style={{ height: 23 }} row>
        <View
          style={[
            Shadow.low,
            {
              width: 10,
              height: 10,
              backgroundColor: color ?? '#dee0e5',
              borderRadius: 5,
              shadowOffset: { width: 0, height: 1 },
              shadowRadius: 2,
            },
          ]}
        />
      </View>
      <Text
        text={item.text}
        size={17}
        style={{ lineHeight: 23, flex: 1 }}
        weight={isCrisisPeak ? 'bold' : undefined}
      />
    </View>
  );
}

export function getCrisisTimelineSections(props: CrisisTimeline) {
  const peakIndex = props.timeline.findIndex((e) => e.ID === props.crisisPeakID);
  if (peakIndex === -1) {
    return { before: props.timeline, peak: [], after: [] };
  }

  return {
    before: props.timeline.slice(0, peakIndex),
    peak: props.timeline.slice(peakIndex, peakIndex + 1),
    after: props.timeline.slice(peakIndex + 1),
  };
}

export function CrisisTimelineList(
  props: CrisisTimeline & {
    showRiskColor: boolean;
    onPress?: (item: CrisisTimeline['timeline'][0], index: number) => void;
    testID?: string;
  } & (
      | {
          /**
           * If splitSections is true, breaks out the timeline items into groups
           * based on the crisis peak and allows selectively rendering the groups
           */
          splitSections?: true;
          skipBeforePeak?: boolean;
          skipAfterPeak?: boolean;
          backgroundColor?: string;
        }
      | {
          splitSections?: false;
          skipBeforePeak?: never;
          skipAfterPeak?: never;
          backgroundColor?: never;
        }
    ),
) {
  const { theme } = useTheme();
  const xPositions = getXPositionsForItems(props);

  const { before, peak, after } = useMemo(() => {
    return getCrisisTimelineSections({
      timeline: props.timeline,
      crisisPeakID: props.crisisPeakID,
    });
  }, [props.crisisPeakID, props.timeline]);

  function renderItems(collection: CrisisTimeline['timeline'], baseIndex: number) {
    return collection.map((item, i) => {
      return (
        <Pressable
          key={item.ID}
          onPress={() => props.onPress?.(item, baseIndex + i)}
          testID={props.testID ? `${props.testID}_item_${baseIndex + i}` : undefined}
        >
          <CrisisTimelineListItem
            isCrisisPeak={item.ID === props.crisisPeakID}
            item={item}
            color={props.showRiskColor ? getColorForX({ x: xPositions[baseIndex + i] }) : undefined}
          />
        </Pressable>
      );
    });
  }

  return (
    <View testID={props.testID}>
      <View
        style={{
          position: 'absolute',
          width: 1,
          top: 8,
          left: 4.5,
          bottom: 8,
          backgroundColor: '#c5c5d3',
        }}
      />
      {props.splitSections ? (
        <View spacing={15}>
          {props.skipBeforePeak ? null : (
            <CrisisTimelineSectionHeading
              icon="risk-curve-rising"
              text="Rising"
              backgroundColor={props.backgroundColor}
            />
          )}
          {props.skipBeforePeak ? null : renderItems(before, 0)}
          <View style={{ marginTop: 25 }}>
            <CrisisTimelineSectionHeading
              icon="risk-curve-peak"
              text="Peak"
              backgroundColor={props.backgroundColor}
            />
          </View>
          {renderItems(peak, before.length)}
          {props.skipAfterPeak ? null : (
            <View style={{ marginTop: 25 }}>
              <CrisisTimelineSectionHeading
                icon="risk-curve-falling"
                text="Falling"
                backgroundColor={props.backgroundColor}
              />
            </View>
          )}
          {props.skipAfterPeak ? null : after.length ? (
            renderItems(after, before.length + peak.length)
          ) : (
            <Text
              text="Not started"
              size={17}
              color={theme.color.gray400}
              style={{ marginLeft: 30 }}
            />
          )}
        </View>
      ) : (
        <View spacing={15}>{renderItems(props.timeline, 0)}</View>
      )}
    </View>
  );
}
