import { z } from 'zod';

import { TextItem } from './common';

export const MoodPlanStrategy = TextItem;
export type MoodPlanStrategy = TextItem;

export const MoodPlanMoodEnum = {
  SAD: 'SAD',
  STRESSED: 'STRESSED',
  GUILTY: 'GUILTY',
  BORED: 'BORED',
  ANGRY: 'ANGRY',
  HAPPY: 'HAPPY',
} as const;
export type MoodPlanMoodEnum = (typeof MoodPlanMoodEnum)[keyof typeof MoodPlanMoodEnum];

export const MoodPlan = z
  .object({
    moods: z.array(
      z.object({
        mood: z.nativeEnum(MoodPlanMoodEnum),
        strategy: z.array(MoodPlanStrategy),
      }),
    ),
  })
  .default({
    moods: [],
  });
export type MoodPlan = z.infer<typeof MoodPlan>;
