import { z } from 'zod';

export const NumericDiagnostic = z
  .object({
    type: z.enum(['UNKNOWN', 'BECK', 'PHQ9', 'SCS', 'BSCS']).default('UNKNOWN'),
    occurrenceDate: z.string().default(''),
    responses: z.array(z.number()).default([]),
  })
  .default({
    occurrenceDate: '',
    responses: [],
    type: 'UNKNOWN',
  });
export type NumericDiagnostic = z.infer<typeof NumericDiagnostic>;
