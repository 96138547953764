import { z } from 'zod';

export const EatingCommitmentHealthyFoodEnum = {
  FRUITS: 'FRUITS',
  VEGETABLES: 'VEGETABLES',
  LEAN_MEATS: 'LEAN_MEATS',
  GRAINS: 'GRAINS',
  HEALTHY_FATS: 'HEALTHY_FATS',
  OTHER: 'OTHER',
} as const;
export type EatingCommitmentHealthyFoodEnum =
  (typeof EatingCommitmentHealthyFoodEnum)[keyof typeof EatingCommitmentHealthyFoodEnum];

export const EatingCommitmentUnhealthyFoodEnum = {
  SODA: 'SODA',
  TAKEOUT: 'TAKEOUT',
  DESSERTS: 'DESSERTS',
  JUNK_FOOD: 'JUNK_FOOD',
  CANDY: 'CANDY',
  OTHER: 'OTHER',
} as const;
export type EatingCommitmentUnhealthyFoodEnum =
  (typeof EatingCommitmentUnhealthyFoodEnum)[keyof typeof EatingCommitmentUnhealthyFoodEnum];

export const EatingCommitment = z
  .object({
    increase: z.nativeEnum(EatingCommitmentHealthyFoodEnum).nullable(),
    increaseOther: z.string().optional(),
    decrease: z.nativeEnum(EatingCommitmentUnhealthyFoodEnum).nullable(),
    decreaseOther: z.string().optional(),
  })
  .default({
    increase: null,
    decrease: null,
  });
export type EatingCommitment = z.infer<typeof EatingCommitment>;
