export enum ChatSide {
  START = 'chat-start',
  END = 'chat-end',
}

export enum Kind {
  NULL = 0,
  ChatText = 1,
  ChatAsset = 2,
  ChatArtifactPreview = 3,
  ChatTable = 4,
  InputText = 101,
  InputChoice = 102,
  InputSlider = 103,
  InputCarousel = 104,
  InputTimePicker = 105,
  InputContactsPicker = 106,
  InputButton = 107,
  InputSignature = 108,
  InputMediaPicker = 109,
  InputSingleChoice = 110,
  InputMultipleChoice = 111,
  InputDatePicker = 112,
  InputActivityPlan = 113,
  InputArtifact = 114,

  SideEffectNavigate = 400,
  Loop = 1001,
}

export enum Actor {
  NULL = 0,
  Bot = 1,
  User = 2,
  Clinician = 3,
  System = 4,
}

export const CompositionTemplateEnum = {
  BINGE_EATING_MY_PLAN: 'BINGE_EATING_MY_PLAN',
  EATING_COMMITMENT: 'EATING_COMMITMENT',
  EATING_SCHEDULE: 'EATING_SCHEDULE',
  TEST: 'TEST',
  MYSTORYMYPLAN: 'MYSTORYMYPLAN',
  MYSTORYMYPLAN_CLINICAL: 'MYSTORYMYPLAN_CLINICAL',
  SCREENER_NUMERIC: 'SCREENER_NUMERIC',
  SCREENER_CSSRS: 'SCREENER_CSSRS',
  YST_MENTAL_HEALTH_CONCERNS: 'YST_MENTAL_HEALTH_CONCERNS',
} as const;
export type CompositionTemplateEnum =
  (typeof CompositionTemplateEnum)[keyof typeof CompositionTemplateEnum];
