import lightFormat from 'date-fns/lightFormat';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';

import { parseGQLDateTime } from './gqlDate';
import { GQLDateTime, GQLTimeOfDay } from './types/scalars';

export function getHoursAndMinutes(date: GQLDateTime | Date): GQLTimeOfDay {
  return lightFormat(
    typeof date === 'string' ? parseGQLDateTime(date) : (date as Date),
    'HH:mm',
  ) as GQLTimeOfDay;
}

export function parseHoursAndMinutes(hhmm: string, baseDate = new Date()) {
  const [hours, minutes] = hhmm.split(':').map(Number);
  if (hours > 23 || hours < 0) return Number.NaN as unknown as Date;
  if (minutes > 59 || hours < 0) return Number.NaN as unknown as Date;
  return setHours(setMinutes(baseDate, minutes), hours);
}
