import { ComponentProps } from 'react';
import {
  HeaderButtonProps,
  HeaderButtonsProps,
  Item,
  ItemProps,
  HeaderButton as RNHeaderButton,
  HeaderButtons as RNHeaderButtons,
} from 'react-navigation-header-buttons';

import { Icon } from '../components/Icon';

// define IconComponent, color, sizes and OverflowIcon in one place
const HeaderButton = (props: HeaderButtonProps) => (
  <RNHeaderButton IconComponent={Icon} {...props} role="button" />
);

export const HeaderButtons = (props: HeaderButtonsProps) => {
  return <RNHeaderButtons HeaderButtonComponent={HeaderButton} {...props} />;
};

export const HeaderItem = (
  props: Omit<ItemProps, 'iconName'> & {
    iconName: ComponentProps<typeof Icon>['name'];
    'aria-label': string | undefined;
  },
) => {
  // @ts-expect-error Our Icon component for Item accepts a different prop type
  return <Item {...props} />;
};
