import { StaticSessionContent } from './static';
import { ContentType } from '../../types/graphql.generated';

const SUPPORT_SESSION_01: StaticSessionContent = [
  { type: 'SessionCover', title: 'How to Help' },
  `
### What is the aim of this app?

Welcome to the Caregiver App. This app is a companion app to the one being used by the teen in your care. The aim of this app is to give you more context about the app your teen is using and to give you some suggestions on how you can best help your teen. Each of your sessions will have these two parts.

We also want to provide some encouragement and affirmation for you. We understand that trying to help a suicidal teen is a complex and emotional process, and want to try to be a support to you too.

  `,
  { type: 'SessionCover', title: 'What is your teen learning?' },
  `
### How does this app compare to my teen’s app? (1/2)

The teen’s app has two main pieces: 

- Sessions that teach skills
- Practice that reinforces those skills


**Sessions**
The teen’s app has 12 sessions where they will learn skills that will hopefully both reduce their suicide risk, and help them through any suicidal crises. 

Your Caregiver App, aside from this Intro session, also has 12 sessions. Your sessions will briefly explain what your teen is learning in that particular session and why they are doing it. Additionally, each of your sessions will have some suggestions on ways you can provide support to your teen.

**Practice**
The teen’s app recommends daily practice activities or exercises after each session. These are called ‘Practice’. It is beneficial for your teen to complete these practices to help develop the skills they are learning. 

Your Caregiver App won’t have any practice activities.
`,
  `
### How does this app compare to my teen’s app? (2/2)

The focus of your app is to give you suggestions on how you can help and support your teen. Not only will there be a section in each session about this, we’ll also summarize these suggestions on your homepage. The hope is that this will make it easier for you to make use of these suggestions. 

The other difference in this app compared to your teen’s app is that we try to make sure that your needs are also remembered. Obviously, the main focus is on your teen and trying to do everything to help them, and making sure you’re looked after is a big part of that. That’s why this app is for you. 
`,
  { type: 'SessionCover', title: 'What can you do to help your teen?' },
  `### How do I even talk to my teen about suicide? (1/3)

There’s no denying it - it is hard to talk to your teen about suicide. It’s a subject that makes most people really uncomfortable and causes all sorts of big, confusing feelings. . It’s hard to keep your cool or stay calm when you talk about suicide with your teen, and it’s probably hard for them, too. All of this is perfectly understandable. There’s no easy answer on how to talk to your teen about this topic, but here are six general principles we hope can be a helpful start. We’ll have more suggestions on how to broach certain topics with your teen in later sessions.

**1. Be genuine**

Only say things you genuinely believe. You know better than anyone that teenagers pick up on insincerity very quickly. 

Don’t say ‘Everything will be ok’ or ‘It’s not so bad’ if you don’t believe it. It’s ok to be scared and to share that with your teen. 

**2. Convey warmth and openness**

You’re probably stressed and worried. That might come across in your body language or conversation as you not being open or willing to talk.

Try to project warmth. If you find yourself feeling nervous or tense, stop and take a few deep breaths and unclench your muscles. Use soft and inviting gestures with open palms and avoid crossing your arms. Try to hear your teen out rather than stopping their flow. 
`,
  `### How do I even talk to my teen about this topic? (2/3)

**3. Listen carefully & try to put yourself in your teen’s shoes**

We’ve found the following steps help after the teen has finished speaking

Play back to the teen what you think the teen is saying. This will help check for misunderstandings and show the teen you’re really trying to listen.
Acknowledge what they think and feel. You don’t have to agree with it or like it, just to recognize it from their point of view.
Offer your thoughts and feelings. 

**4. Start sentences with ‘I’, not ‘You’**

This helps express concern and care. It helps the teen not feel attacked or accused.

Suggested | Perhaps avoid
-- | --
I don’t agree with that. | You’re wrong about that.
I appreciate you being honest with me. . | You did what? You let that happen?
I’m feeling upset. | You make me upset.
I’m worried by what I’m seeing - I’m concerned about you. | You’re just doing the same thing over and over.
`,
  `
### How do I even talk to my teen about this topic? (3/3)

**5. Use specifics when giving feedback** 

More detail shows your teen you’re paying attention and have more impact than general statements.

Suggested | Perhaps avoid
-- | --
I’m really proud of you for getting a B on that math test. | You’re doing fine.
I’m concerned that you got drunk with Alex last night. | You’re a drunk and aren’t even trying to  change.
I’m going for a walk in the park this afternoon and I’d love it if you joined me  - do you want to come? | You should exercise.

**6. Get input from your teen about how best to communicate**

Check in with your teen about how the conversations are going and how you can best help. If you’re able to maintain their trust and faith that you’ve got their best interests at heart, you’re well on the way to doing everything you can for your teen.
`,
  `
**What do I do in an emergency?**

In a crisis situation:

- Call the National Suicide Prevention Lifeline at 1-800-273-TALK
- Text the Crisis Text Line by texting ‘TALK’ to 741741 to speak or text with a trained counselor who can provide guidance on the most appropriate next steps and resources 
- Call 911 when self-harm is occurring or is about to occur
`,
  `
**What’s next?**

As you know, the immediate period after a suicidal crisis is the most risky time for your teen. In the next session, we’ll discuss how you can work with your teen to make their environment safer. Thank you for making it through this introductory session. We hope you’re a little more optimistic that there is a path forward to keep your teen safe. 

After this introductory session will be the 12 sessions. Good luck and take care.
`,
];
const SUPPORT_SESSION_02: StaticSessionContent = [
  { type: 'SessionCover', title: 'How to Help' },
  { type: 'SessionCover', title: 'What is your teen learning?' },
  `
  ### What is the Risk Curve & MyPlan? (1/2)

Session 1 is the longest session in your teen’s app, and also the most important. After this session, they may be overwhelmed or may not want to talk about it. Try to give them some time before asking them about their experience with the app. 

Your teen will do three things in session 1:

- Learn about the suicide risk curve
- Organize their last crisis along the curve
- Build their MyPlan

First, they will learn about the suicide risk curve. This will help them understand that suicide risk can rise, peak, and fall, and that there are things that can be done at each stage to help them stay safe. The focus of your teen’s app is to try to keep your teen safe by keeping their suicide risk low. The concept of risk is helpful because it shows that being suicidal isn’t black or white. The idea is that suicide risk will lower over time. And that’s very important to remember.

**Risk Curve**

![{ "height": 250 }](https://storage.googleapis.com/asset.oui.dev/static/RiskCurve.png)
`,
  `
**What is the Risk Curve & MyPlan? (2/2)**

Second, your teen will try to organize their last suicide crisis along the risk curve. They will identify their warning signs so they know when their risk might be rising.

Third, they will build their MyPlan. This is a plan they can use to stop their risk from getting too high. The steps in the plan include things like their warning signs, ways to calm down which have worked for them before, and key people they can talk to. 

`,
  `
**Why is MyPlan important?**

During a crisis, it is often hard to think about anything other than the crisis itself. MyPlan has steps your teen can take to stop them from reaching the crisis peak and thus keeps them safe. 

MyPlan helps stop risk from rising

![{ "height": 250 }](https://storage.googleapis.com/asset.oui.dev/static/RiskCurveRising.png)

You have access to your teen’s MyPlan. You can access it from this app’s home screen when your teen completes it or from the MyPlan tab.
`,
  { type: 'SessionCover', title: 'What can you do to help your teen?' },
  `**How can I make my teen’s environment safer?**

Experts suggest that one of the best ways to reduce your teen’s suicide risk is to make their environment safer. Basically, that means making it hard for them to access and use things to hurt themselves. 

The last section of your teen’s MyPlan is about making their environment safe. There they list specific items that they have considered using for self-harm and ways they might be secured at home. You can take a look at this section in this app by going to the MyPlan tab.

Below are four common self-harm means and ways to help reduce the risk of suicide at home:

1. Guns are high risk and the leading means of death for suicidal people. They should be taken out of the home and secured.
2. Overdoses are common and can be lethal. If it is necessary to keep pain relievers such as aspirin, Advil, and Tylenol in the home, only keep small quantities. Or consider keeping medications in a locked container. Remove unused or expired medicine from the home.
3. Cutting and use of knives are also commonly used by suicidal people. Try to secure these as best you can such as keeping them in a locked drawer or cabinet.
4. Alcohol use or abuse can decrease inhibitions and cause people to act more freely on their feelings. If possible, keep only small quantities of alcohol in the home under lock and key, or simply keep none at all.
`,
  `
**How do I talk to my teen about doing this?**

As part of their MyPlan, your teen will identify some things they think would help make their environment safer. If you can, have a look at this in their MyPlan and try to work together with your teen to do these things.

It is important that you and your teen discuss the steps you’ll both take to make the environment safer. The goal is that you both agree the actions that will be taken are in your teen’s best interest, and that this is a very normal and important way to help suicidal people stay safe. 

Hopefully your teen can understand that you wanting to make their environment safer doesn’t mean you don’t trust them or are angry with them. One way to do that is to explain to them that your only intention with doing these things is to keep them safe. 

`,
  `
**What’s next? (1/2)**

The most immediate next action is to work with your teen to make their environment safer. Talk to your teen about what you want to do, and try to get them to buy-in to the steps your family will take to store, or remove things they can use to hurt themselves. 

Thank you for making it through session 1 and learning about the Risk Curve and MyPlan. Hopefully this gives you a framework and some language which you can use with your teen. More importantly, we hope it gives you some clarity about how the app aims to keep your teen safe and thus some peace of mind.
`,
  `
**What’s next? (2/2)**

In the next session, your teen will learn about creating a ‘Hope Kit’. We’ll explain what this is and why it’s helpful. We’ll also share suggestions on how to identify suicide risk in your teen and what you can do if you spot such risks.

It’s ok that at times you feel overwhelmed or are struggling. This is a stressful situation. Try not to be too hard on yourself or dwell on any mistakes made. You’re doing your best and that’s all anyone can ask for.

Take care and we look forward to seeing you back for session 2.
`,
];

const SUPPORT_SESSION_03: StaticSessionContent = [
  { type: 'SessionCover', title: 'Hope Kit' },
  { type: 'SessionCover', title: 'What is your teen learning?' },
  `**What is the ‘Hope Kit’?** 

A common sign of suicide risk is losing all hope and thinking there is no way things will get better. To try to counter these thoughts and feelings of hopelessness, your teen’s app will encourage them to build a ‘Hope Kit’. It's a place to store pictures of things that remind them of things that give them hope about their future and remind them of the meaningful things in their life. 

In this app, Hope Kits include things like photos of family and friends, inspiring quotes, pictures of favorite places and activities, and other things like that. The idea is that when they feel terrible, they can use the Hope Kit to focus on the positive parts of their life. 
`,
  `
**Why is the ‘Hope Kit’ important?**

The ‘Hope Kit’ aims to instill some hope, something that is often lacking in suicidal people. It’s a reminder of their reasons for living, and why they’re trying new things to get better.n. The Hope Kit reminds people that there are lots of good things in life and that life is worth living.

Research has proven the Hope Kit has helped thousands of people with their suicide risk. Even people in the military have made one and found it useful.`,
  `
**What will your teen do in this session?**

Your teen’s activity in this session is to use the app to make their ‘Hope Kit’. They’ll be asked to use images that remind them of things they look forward to, goals they have, or places they’d like to go. Anything that helps them remember the good things in their life that are worth living for.

`,
  { type: 'SessionCover', title: 'What can you do to help your teen?' },
  `
**How do I identify suicide risk in my teen?**

This is a question that is asked a lot. But there’s no way for you to possibly see everything. Don’t expect that of yourself. The best you can do is to try to spot your teen’s warning signs.

Your teen will identify some of their specific warning signs in their MyPlan. Their MyPlan is available to you in this app under the ‘MyPlan’ tab. When you can, take a look at it. 

Below are some common warning signs that may indicate your teen is contemplating suicide. You may be already familiar with many of these already, but hopefully there are one or two which can be helpful. 

- Verbal signals:
    - “‘I won’t be around much longer”
    - “Soon I won’t be a problem for you”
    - “I wish I was dead”

- Obsession with death
- Putting affairs in order (eg. giving or throwing away favorite possessions)
- Lack of interest in future plans
- Depression, sense of guilt, shame or rejection
- Increased alcohol and /or drug use
- Recent suicide attempt by a friend or family member
- Impulsiveness and taking unnecessary risks
- Perception that there is no one to talk to
- Sudden and dramatic improvement in mood and energy after a long depressed episode

`,
  `
**What can I do?**

If you do think your teen has suicide risk, we recommend three things you can do. There’s a simple acronym called ‘ACT’ which summarizes those three actions - Ask, Care, Tell. 

#### A is for Ask
- Clearly and directly your teen if they’re considering suicide or if they have a plan
- It’s ok to be direct - try not to use metaphors or say things like “Are you thinking about ending it all?”
- Try to be non-judgemental and focus on showing care
#### C is for Care
- If you think they’re in danger, stay with them or offer to go with them to get help
- Even if it don’t agree with what they’re saying, just offer support
- Try not to leave someone with suicidal thoughts alone
#### T is for Tell
- Tell a trusted professional
- It’s ok that you can’t handle this situation yourself
- The most helpful thing you can do is guide your teen to someone whose trained to help suicidal people

`,
  `
**What’s next? (1/2)**

When you can, review the suicide risk warning signs explained in this session and in your teen’s MyPlan, which is available to you in this app. It’s really hard to know exactly when to take action as you likely don’t want to respond too quickly (overreacting) or respond too slowly (missing the risk). And as you know from the suicide risk curve, your teen’s suicide risk can rise and fall. Because there’s no way to exactly know when you should take action, don’t be too hard on yourself. Trust your instincts if you think there’s something wrong and use ACT - Ask, Care, Tell. 
`,
  `
**What's next (2/2)**

In the next session, your teen will learn about the importance of ‘Getting going’. We’ll explain what this is and why it’s helpful. We’ll share suggestions on how to help your teen ‘get going’. 

It’s often easy to focus on where you think you might be falling short or letting your teen down. But try to also reflect on all the ways you’re helping your teen. Give yourself some credit too for what’s going well here, and maybe take some time to think about the other things you do well.

Take care and we look forward to seeing you back for session 3.
`,
];
export const SUPPORT_SESSION_04: StaticSessionContent = [
  { type: 'SessionCover', title: 'Get Going' },
  { type: 'SessionCover', title: 'What is your teen learning?' },
  `**What is ‘Get Going’ about?**

This session is about encouraging your teen to get active and doing things that improve their mood. Research shows that getting active or moving improves how a person can feel Your teen doesn’t need to wait to feel motivated to get going -  getting active and doing things even when they feel down or unmotivated can benefit their well-being. This is one of the fastest and simplest ways to improve mood. Reducing suicide risk isn’t just about dealing with emotions and thoughts. 
`,
  `
**Why is ‘Get Going’ important?**

It is easy to get stuck in a rut. If your teen is feeling low, they’re less likely to feel motivated to get up and do things, and therefore less likely to do all that much during the day.  For a lot of people, doing nothing after feeling low just makes them feel worse, and they fall into a cycle of feeling even less motivated to do anything. 

Encouraging them to ‘get going’ helps them break that cycle. This is one of the simplest interventions on this app, but it’s one of the hardest to accomplish. Your teen will probably feel like it’s hard to do anything at all, so it’s best not to try and push them too far too soon. Small steps like taking a walk around the block, making a meal, taking a shower in the morning, or spending time outside can be very helpful.`,
  `
**What will your teen do in this session?**

Your teen is going to be asked to use the Activity Diary in the app. In the diary, they’ll be encouraged to log how they felt before and after doing different activities. The idea is to show them that while they may not feel like doing an activity, they usually feel better after doing it. In this way, the Activity Diary will hopefully act as a good reminder that it is worthwhile to ‘get going’. And over time it will show your teen how much they do for themselves.
`,
  { type: 'SessionCover', title: 'What can you do to help your teen?' },
  `
  **How can I help my teen ‘get going’? (1/2)**

Helping a teen to ‘get going’ can be very hard. As you know only too well, getting your teen to do anything is hard. Hopefully, some of the suggestions below can help. 

1. Remind them how behavior affects mood

Encourage them to use their activity diary in their app. This is a log that tracks how they feel before and after doing activities. This log is helpful because before doing an activity, motivation levels are often low. But after the activity, people are often very glad they did it. Take the opportunity to gently remind them that when they do ‘get going’, they tend to feel better afterwards.`,
  `**How can I help my teen ‘get going’? (2/2)**

2. Identify activities to do

Observe if there are certain activities or behaviors that help improve your teen’s mood. It might be as simple as making breakfast first thing in the morning or playing sports with a friend. The more activities you and your identify, the more likely they are to ‘get going’.

3. Keep adjusting the helpful activities

It’s important you and your teen recognize there’s no one magic formula that will always work for your teen. Things they previously enjoyed may no longer be fun or interesting to them. This is perfectly normal. The challenge is to keep searching for the positive behaviors that help improve your teen’s mood, and encourage those. And if they stop being helpful, swap them out for something else. 

`,
  `
**What’s next?**

Getting going is a really helpful way to reduce your teen’s suicide risk. If you can constructively remind them of the benefits of doing this, this will be very helpful. Again, it’s very difficult to strike the right balance in doing this. You know that nagging will be unproductive, but you also know that avoiding the topic also doesn’t help your teen. Pick a time when you think they’ll be most receptive to such a conversation and try to discuss activities they can do to get going.

In the next session, your teen will learn about the importance of ‘Relaxation and Mindfulness’. We’ll explain what this is and why it’s helpful. We’ll also share suggestions on how you can help your teen if they don’t want to talk.

With all your responsibilities, you likely feel pretty tired . But you’re still making time to go through this app to help your teen. You should feel good about that. 

Take care and we look forward to seeing you back for session 4.
`,
];
export const SUPPORT_SESSION_05: StaticSessionContent = [
  { type: 'SessionCover', title: 'Relaxation and Mindfulness' },
  { type: 'SessionCover', title: 'What is your teen learning?' },
  `**What is ‘Relaxation & Mindfulness’ about?**

In the previous session, your teen learned about how to ‘Get Going’, and the importance of doing so. The goal of that session was to help change their mood and hopefully give them more energy and hope about the future. 

This session switches tack a little. It looks to tackle situations when your teen is stressed or agitated. They will learn about Relaxation and Mindfulness, two separate techniques that can help them manage stress. They are tools that have been clinically proven to help people. When people are worried or stressed, it can be hard to think clearly. Problems might seem bigger than they really are. These techniques help people to see things more clearly and calm down a bit when they feel like this. . It can be as simple as sitting in a quiet place and focusing on breathing slowly and steadily.`,
  `**Why is ‘Relaxation & Mindfulness’ important?**

Your teen probably has moments where it’s hard for them to solve problems. Maybe it’s because they’re stressed and overwhelmed, or maybe they’re having a lot of racing negative thoughts and aren’t able to stop them.During these times, they might then get into a spiral where things look bleak or hopeless, and where it looks like there’s no way out.

This is where relaxation and mindfulness can help reduce suicide risk. At times of high stress, if they can take a step back and do a relaxation or mindfulness exercise, this can help reduce stress, or let go of negative thoughts. And this will hopefully help them see things a bit more clearly and with the hope that things can get better.`,
  `**What is the activity in this session?**

Your teen is asked to do a relaxation activity using the app. It will help them with various techniques to relax, and hopefully reduce their stress. They can also do a mindfulness exercise. Here, they will be guided to be aware of their own thoughts, but not hold onto them.`,
  { type: 'SessionCover', title: 'What can you do to help your teen?' },
  `**How can I help my teen if they don’t want to talk? (1/2)**

There are two good ways you can be helpful to your teen even if they don’t want to talk.

1. Leave an open invitation to talk 

You may want to talk with your teen, but often the timing might not be right for them. That’s ok. The key is to leave the invitation to talk open for them. Phrases like

- “Whenever you want to talk, I’m here to listen and support you.” 
- “I love you. I’m always here.”
- “I get that now might not be a good time. Come talk to me any time.

You can also add “I won’t judge, and I’ll never stop supporting you, no matter what challenges you face.”`,
  `**How can I help my teen if they don’t want to talk? (2/2)**

2. Model good mental health habits

As a parent, you can teach and model healthy habits for mental health just as you would with physical health. Taking care of your own mental health, talking about it openly, and seeking therapy when warranted is one way to model healthy practices.

If your teen sees you facing your own challenges in an open way, they will understand that it’s okay to struggle and to learn from mistakes. They may develop flexibility and compassion for their own mistakes. They will also understand that life is messy and challenging for everyone at different times. And that it’s ok to get help from others when it’s needed.
`,
  `**What’s next? (1/2)**

As you know, trying to get your teen to talk when they don’t want to is very hard. So focus on what you can control. If you can focus on keeping the lines of communication open, and modeling good mental health habits, this can go a long way in setting good foundations for your relationship. And when your teen is ready to talk, hopefully this will help you have productive conversations with them.

In the next session, your teen will learn about the importance of ‘Improving Sleep’. We’ll explain what this is and why it’s helpful. We’ll share suggestions on how you can help your teen improve their sleep.`,
  `**What's next? (2/2)**

Helping people is hard, let alone helping teens. You might feel like things aren’t getting better, like you’re just making things worse, or like you’ve let your teen down somehow. All of these feelings are completely normal. Frankly, they’re all signs that you’re doing things right - it means you care enough about your teen to think critically about how your actions affect them. That caring spirit is the most important piece of the recovery puzzle - so long as we have that we’re golden. 

Take care and we look forward to seeing you back for session 5.`,
];
export const SUPPORT_SESSION_06: StaticSessionContent = [
  { type: 'SessionCover', title: 'Improve Sleep' },
  { type: 'SessionCover', title: 'What is your teen learning?' },
  `**What is ‘Improve Sleep’ about?**

In the previous session, your teen learned about Relaxation and Mindfulness techniques. This was to help them reduce stress, calm down so they could think more clearly.

Today’s session about Improving Sleep is similar. As you likely know, when your teen is tired or low on energy, they’re more prone to negative thinking and more reluctant about doing things. And similar to being stressed, being tired also likely makes them think small problems are bigger than they really are. 

In this session, your teen will learn about the importance of sleep, and how to improve their sleep. There are a number of small changes they can make that can have a huge positive effect on their sleep, and their energy levels the next day.`,
  `**Why is ‘Improve Sleep’ important?**

Data reveals that most teens don’t get the recommended amount of sleep each night. Sleep is not just important for your teen’s development, it is also very important for their mood. 

Improving your teen’s sleep can help reduce their suicide risk. Things tend to look less bleak and hopeless when you’re not exhausted. Getting better sleep is a really helpful step in reducing the likelihood of your teen’s suicide risk rising.`,
  `**What is the activity in this session?**

Your teen will be asked to use the Sleep Diary. In the session, they will be given a number of helpful suggestions on how to improve the quality of their sleep. The Sleep Diary is a way for them to record if they’ve been using those suggestions, and then seeing how that’s affected the quality of their sleep. 

Each night, they will complete a “sleep check-in” with questions about things like their screen time, if they’ve exercised that day, and other things that can affect sleep. And then in the morning, they complete another check-in where they record how many hours of sleep they got, and rate the sleep quality. Over time, a graph will be built tracking their sleep and showing how it is often affected by choices they make earlier in the day.`,
  { type: 'SessionCover', title: 'What can you do to help your teen?' },
  `**How can I help my teen improve their sleep? (1/2)**

Getting good sleep is so important in helping reduce your teen’s suicide risk. Bad sleep habits can make your teen more tired and negative about the future. Here are some suggestions on how to help them improve their sleep.

1. Let in morning light

Open the blinds or curtains in the morning to expose your teen to bright sunlight. This light is a timing cue for the body that helps promote alertness, and sunlight can help promote a better mood. If it’s available to you, try to get outside.

2. Be consistent with your teen’s schedule

Get in agreement with your teen on when they will go to sleep and wake up each day. This consistency is really important in building good sleep habits. It’s also important that the schedule is kept up during breaks and weekends too.`,
  `**How can I help my teen improve their sleep? (2/2)**

3. Set a communication curfew

It’s common to use a sleep curfew for teens, but a communication curfew can also be helpful. Set a reasonable time after which your teen can no longer use their devices to communicate with their friends. For example, it can be agreed that all screens will be off thirty minutes before bedtime. This might mean getting your teen an alarm clock so they rely on their phone to wake them up.

4. Set a good example

If you can, try to model good sleep habits for your teen. It’ll be a lot easier for you to persuade them to put their devices away before bed, or to keep a consistent sleep schedule, if you’re doing the same.`,
  `**What’s next?**

Try to put these suggestions in place to help improve your teen’s sleep. As with any change, you might face resistance from your teen. So before putting in place any of the suggestions, talk to your teen about what you’re planning to do, and why you want to do them. Hear any objections they may have and see if there are any alternatives that you both think could be more effective. 

In the next session, your teen starts the first of three sessions on understanding their own thoughts and how to address unhelpful thoughts. The first session is called ‘Spot It’ where they will learn to identify unhelpful or untrue thoughts that might be causing them to feel bad about themselves. We’ll share suggestions on how you can be helpful when your teen wants to talk.

It’s ok if you feel like you’re struggling. This is very normal. You’re in what we call an “upward spiral” - it’s not a straight line to getting better but you’re headed in the right direction. Great job! 

Take care and we look forward to seeing you back for session 6.`,
];
const SUPPORT_SESSION_07: StaticSessionContent = [
  { type: 'SessionCover', title: 'Spot it' },
  { type: 'SessionCover', title: 'What is your teen learning?' },
  `**What is ‘Spot It’ about?**

The previous few sessions were focused on actions your teen can take to help improve their lives and reduce their suicide risk. This session will now look at how your teen’s thoughts can also affect their suicide risk.

We all have habits - what time we do things, the order we do things, and how we like things to be. And these habits are usually pretty easy to spot. But one habit we have in our lives that are harder to spot are our thinking habits - or the common patterns of how we think.

In this session, your teen will learn how to spot their own patterns of thinking. In particular, we want them to learn to spot if they have patterns of negative thinking. They may be using ‘all or nothing thinking’, where they can only see things as completely good or completely terrible. Or they may be jumping to conclusions even though there’s not much evidence to support their reasoning.`,
  `**Why is ‘Spot It’ important?**

How a person thinks can have a huge effect on how they feel. If your teen has thoughts like ‘I’m always a failure’ or ‘Everybody hates me’, this kind of thinking will likely make them not feel great about themselves. This will likely lead to them feeling badly about themselves, and perhaps lead to them isolating themselves or other unhelpful behaviors. 

The first step is to spot these patterns of thinking. And then in subsequent sessions, we’ll teach your teen what to do after they’ve done this.`,
  `**What is the activity in this session?**

In this session, and in the next two, your teen will learn to use the Thought Diary. In this diary, they will record thoughts that they’ve spotted that might be problematic. The Thought Diary will help them learn more about their own thought patterns and if any of those patterns are causing problems in your teen’s life.`,
  { type: 'SessionCover', title: 'What can you do to help your teen?' },
  `**How can I be helpful when my teen does want to talk? (1/2)**

Your teen may not often want to talk about their struggles with suicide. So when they do indicate their willingness to talk, this is a great opportunity to provide support. Below are two concrete suggestions on how you can help when they want to talk.

1. Be a great listener 

The first way to be a great listener is to resist being a problem solver. You see your teen suffering so it’s only natural you want to suggest solutions. You want to take away whatever is causing pain for your teen. It might be surprising, but sometimes simply listening rather than jumping to fix what’s wrong is where the real healing (or ‘fixing’) happens. 

Allowing your teen to talk without providing unsolicited advice can also give them a chance to work through their emotions. And part of being a good listener is to ask follow up questions to learn more about their situation, how they feel, what their triggers are, and how you can help. You can also try reflecting their thoughts back to them by starting your responses with phrases like, “What I’m hearing you say is…” or “It sounds like…”`,
  `**How can I be helpful when my teen does want to talk? (1/2)**

2. Sit with their emotions

When teens feel overwhelmed or scared, they look to their parents for safety, reassurance and emotional comfort. Even if you’re also scared or frustrated, a calm reaction from you can instill peace in them. The opposite of sitting with their emotion is to meet their emotion with your own emotional response. Unfortunately, doing that will likely only make the situation worse. This means resisting the urge to say “That scares me,” or “That’s really upsetting to me,” try to start with something like “I hear that you’re feeling hurt,” or “That sounds really hard, I’m sorry.”

Many teens engage in self-harm to deal with overwhelming emotions. Encourage them to talk to you instead of going down that path. Be ready to respond calmly and help them manage their emotions. This is obviously a very hard thing to do given the situation is so scary. But if you’re able to be both open about your fears, while staying calm at the same time, this will be a huge help for your teen. Before reacting, try thanking your teen for sharing this with you - it takes a lot of courage to share these thoughts.`,
  `**What’s next?**

The next time your teen indicates they want to talk, try to make use of the suggestions from this session. It’s hard to be a good listener, especially when you’re worried about your teen’s safety and want to stop them from doing anything dangerous. Your teen might just want to talk through a problem and not want to hear solutions. Try to be a container for their emotions by reassuring them. 

This session was the first of three in a series focusing on your teen’s thoughts. After ‘Spot It’ will be a session called ‘Test It’. In this next session, your teen will learn ways to test if their thoughts are true or helpful. We’ll share suggestions on how to respond to your teen’s suicidal attempt or ideation.

You’re more than halfway through this app - way to go! There’s still more to cover, but you’ve already tackled some of the toughest content. You and your teen should both feel proud of yourself - we are.  Take care and we look forward to seeing you back for session 7.`,
];
const SUPPORT_SESSION_08: StaticSessionContent = [
  { type: 'SessionCover', title: 'Test it' },
  { type: 'SessionCover', title: 'What is your teen learning?' },
  `**What is ‘Test It’ about?**

In the previous session, your teen learned about patterns of problematic thinking, and how to spot these patterns. The activity was to practice spotting their thoughts and logging them in their Thought Diary.

In this session, your teen will be guided on how to test the validity and usefulness of those thoughts. The key point here is that just because we have a thought doesn’t make it true. We can look for evidence that supports and that disproves the thought. Or even if a thought is true, if it’s not helpful, why have that thought? This is what it means to ‘Test It’.`,
  `**Why is ‘Test It’ important?**

It’s an important step in the process of helping teens become aware that not all their thoughts are true, or are helpful. And starting to challenge those thoughts, and realizing that some are not true or are unhelpful, is a really useful skill.

And as you can imagine, the step after ‘Testing It’ is to ‘Switch It’ which is what is covered in the next session, the last of this series on thoughts. So if a thought is not true or not helpful, we’ll suggest ways to help the teen switch their thoughts.`,
  { type: 'SessionCover', title: 'What can you do to help your teen?' },
  `**How do I respond to my teen’s suicidal attempt or ideation? (1/2)**

It’s impossible to feel prepared on how to best respond to a teen’s suicide attempt or ideation. It’s frightening. It’s common to also feel guilt, shame or helplessness. All of this is normal. But at this time, let’s talk about what you can control - your own actions and how you can help your teen.

Remember to stay calm. Many teens aren’t good at reading others’ emotions. And they might get defensive if they think you’re upset. Sit down with your child and talk about what’s going on. You might say:

- “I’m very concerned about what you just said”
- “Are you thinking about killing yourself?”
- “I love you"
- “I don’t want anything to happen to you”

And then ask them if they’d be willing to share a little more about how they’re feeling.
`,
  `**How do I respond to my teen’s suicidal attempt or ideation? (2/2)**

It’s very hard to do, but try your best to not get angry or yell at your teen. You might be frustrated and really scared. It’s ok to admit to yourself and your teen that you’re scared or upset, but they need to see you as stable, safe, and supportive. Anything you can do to stay calm and projecting care for them is great. Try to first thank your teen for sharing with you and acknowledge how brave they were for doing it. This has the benefit of assuring your teen and helping you find a little peace and calm in a tough situation.`,
  `**What’s next?**

This session was the second of three in a series focusing on your teen’s thoughts. After ‘Spot It’ and ‘Test It’, the third session is ‘Switch It’. In this next session, your teen will learn ways to switch their thoughts that are untrue or unhelpful. We’ll share suggestions on how to keep lines of communication open with your teen.

No one can get everything right, and admitting that is a crucial first step to learning and growing. You’ve shown us time and time again how committed you are to learning and doing better by your teen, and that’s amazing.

Take care and we look forward to seeing you back for session 8.`,
];
const SUPPORT_SESSION_09: StaticSessionContent = [
  { type: 'SessionCover', title: 'Switch it' },
  { type: 'SessionCover', title: 'What is your teen learning?' },
  `**What is ‘Switch It’ about?**

In the previous two sessions, your teen first learned how to spot problematic thoughts and then test if those thoughts are untrue or unhelpful. This session follows that up with teaching your teen how to switch untrue or unhelpful thoughts. The goal is to help them reframe how they look at situations so they are more realistic and helpful.`,
  `**Why is ‘Switch It’ important?**

How we think can really affect how we feel. If your teen is holding onto untrue or unhelpful thoughts, this can then affect their mood and energy levels. Teaching your teen to switch these thoughts can hopefully break this cycle. With ‘Switch It’, the goal is that they will see things more realistically and avoid falling into a funk.`,
  `**What is the activity in this session?**

In this session, your teen will continue using the Thought Diary. In the Thought Diary, they will find alternative ways to frame their thoughts. In effect, they will be taught how to switch unhelpful or factually incorrect thoughts.`,
  { type: 'SessionCover', title: 'What can you do to help your teen?' },
  `**How do I keep the lines of communication open with my teen?** 

As discussed previously, your teen may often be uncommunicative or unwilling to share. Try to be patient and not get frustrated with this. One of the most important things to do is to resist lecturing them or punishing them for their suicide crisis. Their suicide crisis may have been a form of self-punishment. So any actions by parents / guardians to take away their cell phone or other privileges to make them stop would not be helpful. It’d just be punishing them for punishing themselves.

Lectures and punishments may unintentionally teach your teen it’s not safe to talk to you about suicide. So they may choose to continue to engage in the behavior and just not talk to you about it. A good relationship with your teen is one of the strongest and most useful coping strategies they can have. You know you care about your teen so consider how you can best show your care for your teen.`,
  `**What’s next?**

Things might be up and down for your teen. The most important thing you can do for them is to keep an even keel and not mirror their ups and downs. If you can be calm for them, and consistently show them that you care for them, this will go a long way in keeping the lines of communication strong between you and your teen. 

This session was the last in a series of sessions focusing on your teen’s thoughts. After these three sessions, the next session is called ‘Coping Cards’. In this session, your teen will learn how to use these cards to remember key skills they have learned in this app. We’ll share suggestions on how to maintain hope for your teen.

When things get challenging with your teen, remind yourself that your teen is not trying to give you a hard time. Instead, they are having a hard time and you can help them. 

Take care and we look forward to seeing you back for session 9.`,
];
const SUPPORT_SESSION_10: StaticSessionContent = [
  { type: 'SessionCover', title: 'Coping Cards' },
  { type: 'SessionCover', title: 'What is your teen learning?' },
  `**What are ‘Coping Cards’?**

Coping Cards are flashcards. On one side, your teen will write common unhelpful thoughts they might have, or a tough situation they might be in. On the other side, they'll write more helpful thoughts, or how they might handle the situation.`,
  `**Why are ‘Coping Cards’ important?**

They’ve covered a lot of new skills and concepts in their app. It’d be very hard to remember it all and know how to apply them. In the same way flashcards are helpful when studying, they’ve been proven to be very helpful in learning skills to reduce suicide risk.

Coping Cards help remind your teen of skills they've learned already and help them deal with negative thoughts. They can use the coping cards when they need to lower their risk.`,
  `**What is the activity in this session?**

The activity in this session is for your teen to make their own coping cards. Their app has a tool where they can write unhelpful thoughts they might have, or a tough situation they might face. 

And on the other side, they can write more helpful thoughts, or coping skills they might use to handle the situation. They will hopefully make use of these coping cards to remind themselves of what to do when they have unhelpful thoughts, or face a challenging situation.`,
  { type: 'SessionCover', title: 'What can you do to help your teen?' },
  `**How do I maintain hope for my teen?**

Trying to provide hope and care for a suicidal teen can be both physically and emotionally exhausting. Relatives and friends can help provide a safety net and a vision of hope for your teen so that you don’t have to do it all yourself. 

You don’t have to feel like it’s all on you to handle this. Get support from friends, relatives, and organizations such as the National Alliance on Mental Illness (NAMI). Get professional help whenever possible. You don’t have to travel this road alone. There are many others out there who have gone through what you’re going through. 

You can also encourage your teen to use their Hope Kit. They used their app to make one so they should be able to access it on their phone whenever they need. Their Hope Kit should contain images and quotes that remind them of their reasons for living and can hopefully give them more hope about the future.`,
  `**What’s next?**

It’s important to balance being hopeful about the future with a recognition of the real problems your teen faces. Both things can be true and it’s important your teen can understand this. Anything you can do to help them believe that things can get better, and that there are people around them that want to help, will be very important in keeping them safe.

In the next session, your teen will start the first of two review sessions that will complete the app. The first one will review the Risk Curve and MyPlan. We’ll share suggestions on how to ask your teen if they are suicidal.

Take a minute this week to reflect on everything that’s gone on with you, your teen and the rest of your family. Also, be sure to check in with yourself. How are you feeling? Stressed, overwhelmed, numb, angry, or something else entirely?  How are you coping with the stressors in your life, and is there room for you to do more self-care?

Take care and we look forward to seeing you back for session 10.`,
];
const SUPPORT_SESSION_11: StaticSessionContent = [
  { type: 'SessionCover', title: 'Risk Curve & MyPlan' },
  { type: 'SessionCover', title: 'What is your teen learning?' },
  `**What is this review session about?**

This is the first of two review sessions. In this session, your teen will review the suicide risk curve. As you’ll recall, the curve helps explain how suicide risk rises, peaks and then falls over time. And it emphasizes that there are warning signs that the risk is rising, and your teen can try to spot them and take actions to stop the risk rising by using their MyPlan.

The session also reviews MyPlan. It reminds your teen of the role of MyPlan as a series of steps your teen can take when they feel their risk is rising.`,
  `**Why is this review session important?**

The suicide risk curve and MyPlan are two of the most important ideas in this app. The risk curve provides the overall context in understanding suicide and risk, and explains that there are actions that can be taken to reduce that risk. MyPlan is a really important series of steps that can be used at any time to reduce suicide risk.`,
  `**What is the activity in this session?**

There are two short activities for your teen. The first is to review the risk curve that they had filled in back in session 1. The second activity is to review their MyPlan.`,
  { type: 'SessionCover', title: 'What can you do to help your teen?' },
  `**How do I ask my teen if they are suicidal? (1/2)**

Talking to your child about this may feel difficult, daunting and scary. It’s normal to be worried about saying the wrong thing, or making things worse. But it’s far better to ask your teen and give them a chance to talk. Don’t let the fear of saying the wrong thing lead you to not saying anything at all. 

It’s normal to feel uncomfortable talking about suicide. However, asking your teen whether he or she is depressed or thinking about suicide can be helpful. Use clear wording such as 'suicide’, ‘ending your life’ or ‘taking your own life’. Avoid ambiguous language such as ‘dark thoughts’ or ‘thoughts of harming yourself’. Specific examples of questions you can use include:

- Are you feeling sad or depressed?
- Are you thinking about killing yourself?
- Have you thought about killing yourself in the last week?`,
  `**How do I ask my teen if they are suicidal? (2/2)**

You can start the conversation gently by using phrases like:

- “I’m wondering what is going on for you at the moment”
- “I’ve noticed that…” 

This will help feel less abrupt for both of you. Then you can move on to a clear, direct question on whether they are thinking about suicide. Being direct lets your child know that it’s okay to talk about it. Rather than putting thoughts in your teen’s head, these questions can provide assurance that somebody cares and will give your teen the chance to talk about problems.

Many people have found it helpful to practice these conversations before talking to their teen about it. Just saying the words out loud will make having the conversation easier. Take your time so you can make sure your words and tone come out as you want them to.`,
  `**What’s next?**

It’s a real skill to be able to combine gentle conversation starters with clear, direct questions. Like all the suggestions in this app, there’s a hard balancing act. You want to be gentle and not abrupt in bringing up the topic, but you also want to be very clear so there are no misunderstandings. Try to use some of the phrases suggested and hopefully they can help. Also, be gentle with yourself. If you stumble over your words initially, that’s okay - just take a deep breath and try again.

This was the first review session and focused on the Risk Curve and MyPlan. The next review session will review all the skills learned in the app. This includes the skills that focuses on actions (ie. sleep, getting going, relaxation techniques) and thoughts (‘Spot It’, ‘Check It’, ‘Switch It’). We’ll share suggestions on how to help your teen stick with their skills.

It’s ok that some moments or days are tough. Remind yourself that they will pass. Everyone makes mistakes, and learning to recover is a part of life and parenting. You don’t do you or your teen any favors by trying to be perfect - see if you can find a way to be “good enough.”

Take care and we look forward to seeing you back for session 11.`,
];
const SUPPORT_SESSION_12: StaticSessionContent = [
  { type: 'SessionCover', title: 'Skills to Reduce Risk' },
  { type: 'SessionCover', title: 'What is your teen learning?' },
  `**What is ‘Skills to Reduce Risk’ about?**

In their app, your teen will have learned many skills to help reduce their suicide risk. Some of them have been about actions they can take. Others have been about changing how they think about things.

This session is a chance for your teen to refresh their memory of the progress they’ve made in learning all these skills. And hopefully consolidate the learnings and make more use of them.`,
  `**Why is this session important?**

The skills taught in your teen’s app can have a huge effect on reducing their suicide risk. But it is easy to forget about the skill, or how to use it. The hope is that this review session will serve as a strong reminder about the skills your teen has learned, and how they can be very helpful.

The goal of these skills is to help your teen develop healthy habits that can help keep their suicide risk from rising. As discussed in this app, there are a number of things you can do to help them to get going, to improve their sleep and to maintain hope.`,
  `**What is the activity in this session?**

There is no specific activity in this session. Your teen will be asked to select some skills they’ve previously learned in the app that they’d like to do more practice with.`,
  { type: 'SessionCover', title: 'What can you do to help your teen?' },
  `**How do I help my teen stick with their skills? (1/2)**

After learning their new skills, your teen probably feels a lot better than they did before. When this happens, people sometimes feel like they don’t need to keep doing the things that make them feel better. Maybe they’ll stop making time for meditation or start taking their phone into bed. Sometimes these little slip ups can be okay and a person can recover pretty quickly, but sometimes it’s a slippery slope.

You know that suggesting can sometimes sound like nagging, so it’s probably best not to tell your teen what you think they should be doing. Instead, try to be curious and non-judgmental first by asking them what’s going on and why they’re not sticking with their old skills with some of these questions:

- I noticed you’ve been sleeping in late, how are you feeling?
- How are your coping skills working for you? Do you want to try some new things?`,
  `**How do I help my teen stick with their skills? (2/2)**

If your teen tells you that they’re feeling fine or that they don’t need to use their skills anymore, it might be worth it to gently remind them of some of the benefits of those skills. It might also help to remind them that it’s helpful to practice these skills when they’re feeling good so they have them ready when they’re feeling better. You could try things like:

- I remember you telling me how much better once you had a set bedtime and time to get up. Do you think it would help you to get back to that?
- I’m so glad that you’re feeling better and I hope you never feel as badly as you did before, but I also think it would be helpful to you to practice now while you’re feeling better so that you have those skills ready if you ever need them again.`,
  `**What’s next?**

The next session will be the last session of your teen’s app. In that session, your teen will learn about things they can do once they’ve finished using the app. We’ll share suggestions on how you can help your teen stay safe in the future.

You’ve nearly reached the end of the app. Take some time to congratulate yourself! It’s not easy material to get through but you did it. You’ve shown up for your teen at a time when they really needed you and didn’t give up on them - that is one of the best things a parent can do.. Take care and we look forward to seeing you back for session 12.`,
];
const SUPPORT_SESSION_13: StaticSessionContent = [
  { type: 'SessionCover', title: 'What’s Next?' },
  { type: 'SessionCover', title: 'What is your teen learning?' },
  `**What is this session about?**

In this session, your teen will receive some suggestions about what to do after finishing all the sessions in the app. They will be encouraged to keep using the app. They can re-watch the animated videos and clinical vignettes, as well as use all the tools in the app to practice the skills learned.`,
  `**Why is this session important?**

This session is important because it celebrates your teen in making it so far. It also emphasizes that the journey continues, and that they can keep using the app to help them if needed. `,
  `**What is the activity in this session?**

There is no activity in this session. Your teen is encouraged to keep using the app to do the practices, and to use their MyPlan and Hope Kit as they need.`,
  { type: 'SessionCover', title: 'What can you do to help your teen?' },

  `**How do I help my teen stay safe in the future? (1/2)**
While it’s normal to want to put all of this behind you and not think about your teen’s suicide risk any more, it’s important to think realistically about the challenges they’ll face in the future. Maybe you’re hesitant to bring up something so unpleasant right now, and that makes sense too. 

We certainly want to make sure that you and your teen take time to celebrate their progress, but you can do that while keeping an eye on the future too. Here are some guidelines to get you started:

1. Anticipate life’s challenges

It’s normal for people to turn to unhelpful behaviors during stressful times. For your teen, that might mean renewed suicidal ideation. And while we can’t anticipate all of life’s challenges, we can do our best to prepare for the ones we see coming. 

For your teen, that might mean things like exams, applying for jobs or schools, break ups, or other major life events. When those things happen, it might be worth it to shore up additional support for your teen, schedule relaxing activities, and/or check in with them more frequently. It also helps to maintain skill practice during less stressful times so that your teen has them ready when they really need them.   
  `,
  `**How do I help my teen stay safe in the future? (2/2)**  

2. Keep open channels of communication

While we hope your teen doesn’t experience suicidal ideation again, we acknowledge that this may not be realistic for everybody. That’s why it’s so important to maintain ongoing communication about mental health with your teen by regularly asking them how they feel, thanking them for sharing with you, and responding to them thoughtfully and without judgment if they disclose any suicidal ideation. 

Take your teen seriously and let them know that you value their honesty, and include them in discussions about safety and treatment planning if and when you have them.

3. Model good coping skills and self care

Children learn behavior by watching their parents, so think carefully about the example you’re setting.  If you’re feeling stressed out, consider trying meditation, relaxation, or other coping skills you’d like to see your teen use to deal with stress. And if there’s something you don’t want your teen to do, like turning to substances, consider avoiding that behavior all together yourself. 

It’s also normal for parents in your situation to feel overwhelmed and unsure who to talk to about these or other problems. If that’s the case for you, it might be worth it to consider seeking out a therapist or counselor of your own if you haven’t already. Showing your teen that you take your mental health seriously will encourage them to do the same.
  
   `,
  `**What’s next?**

This is the last session for both you and your teen. Hopefully you’ve found this useful in understanding the app your teen is using to reduce their suicide risk. 

If you ever want to review anything, the app will always be available. We truly hope you found things in this app that you could use to help yourself and your teen. And that you can continue with these positive things in the future. You’ve done really well to finish this entire app. Thank you for trusting the process.

Good luck and take care.`,
];

export const SUPPORT_CONTENT: Record<string, StaticSessionContent> = {
  [ContentType.HOW_TO_HELP]: SUPPORT_SESSION_01,
  [ContentType.MYPLAN]: SUPPORT_SESSION_02,
  [ContentType.HOPE_KIT]: SUPPORT_SESSION_03,
  [ContentType.ACTIVITY_PLANNING]: SUPPORT_SESSION_04,
  [ContentType.RELAXATION]: SUPPORT_SESSION_05,
  [ContentType.SLEEP]: SUPPORT_SESSION_06,
  [ContentType.SPOT_IT]: SUPPORT_SESSION_07,
  [ContentType.TEST_IT]: SUPPORT_SESSION_08,
  [ContentType.SWITCH_IT]: SUPPORT_SESSION_09,
  [ContentType.COPING_CARDS]: SUPPORT_SESSION_10,
  [ContentType.MYPLAN_REVIEW]: SUPPORT_SESSION_11,
  [ContentType.REDUCE_RISK]: SUPPORT_SESSION_12,
  [ContentType.POST_AVIVA]: SUPPORT_SESSION_13,
};
