import pickBy from 'lodash/pickBy';
import { stringify } from 'querystringify';

import videoMapping from './metadata/videoMapping.json';

type MuxUrl = string & { _mux: true };
type PlaybackID = string & { _muxID: true };

function getPlaybackID(url: MuxUrl) {
  return url.split('/')[3].split('.')[0] as PlaybackID;
}

export const getMuxPlaybackID = getPlaybackID;

export function isMuxUrl(str?: string): str is MuxUrl {
  return !!str?.startsWith('https://stream.mux.com');
}

// https://docs.mux.com/guides/video/get-images-from-a-video#thumbnail-query-string-parameters
export function getMuxThumbnail(
  muxUrl: MuxUrl,
  {
    format,
    width,
    height,
    fitMode = 'smartcrop',
    time,
    getPixelSizeForLayoutSize,
  }: {
    format: 'png' | 'jpg';
    width: number;
    height: number;
    fitMode?: 'preserve' | 'crop' | 'smartcrop' | 'pad' | null;
    time?: number | null;
    getPixelSizeForLayoutSize: (dimension: number) => number;
  },
) {
  const id = getPlaybackID(muxUrl);
  const paramObj = {
    width: getPixelSizeForLayoutSize(width),
    height: getPixelSizeForLayoutSize(height),
    fit_mode: fitMode,
    time,
  };
  const params = stringify(pickBy(paramObj));
  return `https://image.mux.com/${id}/thumbnail.${format}?${params}`;
}

export function getMuxUrlFromGCSUrl(url: string): MuxUrl {
  const playbackId = (videoMapping as Record<string, { playbackId: string } | null>)[url]
    ?.playbackId;
  if (!playbackId) {
    throw new Error('Invalid url for videoMapping: ' + url);
  }
  return `https://stream.mux.com/${playbackId}.m3u8` as MuxUrl;
}
