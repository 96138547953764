import { ActionEnvelope } from '../types/envelopes';

export * from '../types/enums';

export * from '../types/envelopes';
export * from '../types/props';
export * from '../types/graphql.generated';

export type JsonMap = {
  [key: string]: null | string | number | boolean | object | unknown;
};

export type Convo = {
  session: string;
};

export type Content = {
  convoID: string;
  name: string;
  desc: string;
  createdAt: number;
  updatedAt: number;
  active: number;
};

export type Engine = {
  addr: string;
  content: Content[];
};

export type OnInputCallbackPayload = ActionEnvelope;
export type OnInputCallback<T = {}> = (payload: T & OnInputCallbackPayload) => void;

export type ChatInputBaseProps = {
  lastTextContent?: string;
  style?: any; // eslint-disable-line
  onInput: (payload: OnInputCallbackPayload) => void;
  disabled?: boolean;
};

export type User = {
  email: string;
  ID: string;
  avatar: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressCity?: string;
  addressState?: string;
  addressZip?: string;
};

export type Address = {
  line?: Array<string> | null;
  city?: string | null;
  state?: string | null;
  postalCode?: string | null;
};

export type DaysOfWeek = 'MO' | 'TU' | 'WE' | 'TH' | 'FR' | 'SA' | 'SU';

export type DetoxNotificationData =
  | {
      type: 'NETWORK';
      payload: { isInternetReachable: boolean };
    }
  | { type: 'ASYNC_STORAGE'; payload: { key: string; value: string } };

export interface NotificationPayloadRegistry {
  unknown: { type: unknown; payload: never };
  navigate: {
    type: 'navigate';
    payload:
      | {
          /*
           * the part of the query after the domain. Can contain query parameters in addition to route segments
           * May or may not contain a leading "/"
           */
          path: string;
        }
      // deprecated, prefer path format above
      | { name?: string; routeName: string; params?: object };
  };
}
