import {
  initGraphQLTada,
  omitFragmentRefsRec,
  ResultOf,
  TadaDocumentNode,
  type $tada,
  type maskNestedFragments as maskNestedFragmentsType,
} from 'gql.tada';
import merge from 'lodash/merge';

import type { introspection } from './graphql-env.d.ts';
import type { GraphQLTadaScalars } from '../types/scalars';

export const graphql = initGraphQLTada<{
  introspection: introspection;
  scalars: GraphQLTadaScalars;
}>();

export type {
  $tada,
  FragmentOf,
  ResultOf,
  TadaDocumentNode,
  VariablesOf,
  omitFragmentRefsRec,
} from 'gql.tada';
export { initGraphQLTada, readFragment, maskFragments, unsafe_readResult } from 'gql.tada';

// declare function maskNestedFragments<const Fragments extends readonly FragmentShape[], Shape = resultOfFragmentsRec<Fragments>>(
//   _fragments: Fragments,
//   children: Pick<Shape, $tada.fragmentRefs>,
//   fragment: Omit<Shape, $tada.fragmentRefs>,
// ): fragmentRefsOfFragmentsRec<Fragments> {}
export const maskNestedFragments: typeof maskNestedFragmentsType = (
  _fragments,
  children,
  fragment,
) => {
  return merge({}, children, fragment) as any; // eslint-disable-line
};

type FragmentType<T> = { [$tada.fragmentRefs]: T };
export function mergeFragments<A, B, C, D, E, F, G, H, Other>(
  a: FragmentType<A> & Other,
  b?: FragmentType<B>,
  c?: FragmentType<C>,
  d?: FragmentType<D>,
  e?: FragmentType<E>,
  f?: FragmentType<F>,
  g?: FragmentType<G>,
  h?: FragmentType<H>,
): { [$tada.fragmentRefs]: A & B & C & D & E & F & G & H } & Other {
  return merge(a, b, c, d, e, f, g, h) as any; // eslint-disable-line
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UnsafeResultOf<Document extends TadaDocumentNode<any, any>> = omitFragmentRefsRec<
  ResultOf<Document>
>;
