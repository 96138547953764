import type { SetData } from '../../content/quizSet/quizSetData';
import * as Frank from '../../content/quizSet/quizSetDataAdultFrank';
import * as Priyanka from '../../content/quizSet/quizSetDataAdultPriyanka';
import * as Sue from '../../content/quizSet/quizSetDataAdultSue';
import { getMuxUrlFromGCSUrl } from '../../muxUrl';

const MYSTORY_LEARN_VIGNETTE_JEFF: SetData = {
  ID: 'mystory-learn-vignette-jeff',
  name: 'Jeff',
  description: 'Jeff: 45, male, married with kids, lost his job',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S1/Jeff_S1a_Jeffs_Story_200331.mp4',
        ),
        title: "Jeff's Story",
        compulsory: true,
      },
    },
  ],
};

const RISK_CURVE_LEARN_VIGNETTE_JEFF_COLLAB: SetData = {
  ID: 'risk-curve-learn-vignette-jeff-collab',
  name: 'Jeff',
  description: 'Jeff: 45, male, married with kids, lost his job',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S1/Jeff_S1b_Making_Sense_200331.mp4',
        ),
        title: 'Making Sense',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'To relive a very difficult day again',
          '1': 'To learn about your own specific warning signs so that you can keep an eye on them and work to lower your risk',
          '2': 'You probably won’t need to learn about your own Risk Curve. Your warning signs are probably the same as Jeff’s warning signs.',
        },
      },
      dir: {
        prompt: 'Why is it important to learn about your own Risk Curve?',
        feedback: {
          '0': {
            correct: false,
            text: 'Though it may be difficult to spend time thinking about what happened before a suicide attempt, it is important to learn what pushes you up the Risk Curve.',
          },
          '1': {
            correct: true,
            text: 'By understanding what pushes you up the Risk Curve, you can keep an eye on warning signs specific to you and work to lower your risk.',
          },
          '2': {
            correct: false,
            text: 'What goes in a Risk Curve is actually unique to each person. It is important to learn about what things increase your risk and these things might be different than the things that increase Jeff’s risk.',
          },
        },
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S1/Jeff_S1c_How_Did_RC_Help_Explain_200331.mp4',
        ),
        title: 'Risk Curve',
        compulsory: true,
      },
    },
  ],
};

const RISK_CURVE_LEARN_VIGNETTE_JEFF: SetData = {
  ID: 'risk-curve-learn-vignette-jeff',
  name: 'Jeff',
  description: 'Jeff: 45, male, married with kids, lost his job',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S1/Jeff_S1a_Jeffs_Story_200331.mp4',
        ),
        title: "Jeff's Story",
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S1/Jeff_S1b_Making_Sense_200331.mp4',
        ),
        title: 'Making Sense',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'To relive a very difficult day again',
          '1': 'To learn about your own specific warning signs so that you can keep an eye on them and work to lower your risk',
          '2': 'You probably won’t need to learn about your own Risk Curve. Your warning signs are probably the same as Jeff’s warning signs.',
        },
      },
      dir: {
        prompt: 'Why is it important to learn about your own Risk Curve?',
        feedback: {
          '0': {
            correct: false,
            text: 'Though it may be difficult to spend time thinking about what happened before a suicide attempt, it is important to learn what pushes you up the Risk Curve.',
          },
          '1': {
            correct: true,
            text: 'By understanding what pushes you up the Risk Curve, you can keep an eye on warning signs specific to you and work to lower your risk.',
          },
          '2': {
            correct: false,
            text: 'What goes in a Risk Curve is actually unique to each person. It is important to learn about what things increase your risk and these things might be different than the things that increase Jeff’s risk.',
          },
        },
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S1/Jeff_S1c_How_Did_RC_Help_Explain_200331.mp4',
        ),
        title: 'Risk Curve',
        compulsory: true,
      },
    },
  ],
};

const MYPLAN_LEARN_VIGNETTE_JEFF: SetData = {
  ID: 'myplan-learn-vignette-jeff',
  name: 'Jeff',
  description: 'Jeff: 45, male, married with kids, lost his job',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S1/Jeff_S1d_How_MP_Helps_Me_210407.mp4',
        ),
        title: 'My Plan',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S1/Jeff_S1e_Making_My_Environ_Safe_210407.mp4',
        ),
        title: 'Safe Environment',
        compulsory: true,
      },
    },
  ],
};

const HOPE_KIT_LEARN_VIGNETTE_JEFF: SetData = {
  ID: 'hope-kit-learn-jeff',
  name: 'Jeff',
  description: 'Jeff: 45, male, married with kids, lost his job',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S2/Jeff_S2a_How_HK_Reduces_Risk_210407.mp4',
        ),
        title: 'About Hope Kit',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': "It reminds him why it's worth it to keep living",
          '1': 'It lets him think about his good memories from the past',
          '2': 'It gets rid of his bad thoughts completely',
        },
      },
      dir: {
        prompt: 'How has the Hope Kit helped Jeff?',
        feedback: {
          '0': {
            correct: true,
            text: ``,
          },
          '1': {
            correct: false,
            text: 'The Hope Kit helps Jeff think about his future.',
          },
          '2': {
            correct: false,
            text: `Instead, it can help him direct his thoughts to other things, so that bad thoughts don't hurt him so much.`,
          },
        },
        afterAll: `The Hope Kit reminds Jeff that there are lots of good things in his life and that life is worth living. Let’s now see how the Hope Kit is made and used.`,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S2/Jeff_S2b_What_Did_I_Include_210407.mp4',
        ),
        title: 'What Include',
        compulsory: true,
      },
    },
  ],
};

const HOPE_KIT_APPLY_VIGNETTE_JEFF: SetData = {
  ID: 'hope-kit-apply-jeff',
  name: 'Jeff',
  description: 'Jeff: 45, male, married with kids, lost his job',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S2/Jeff_S2c_Making_My_HK_210623.mp4',
        ),
        title: 'Making Hope Kit',
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S2/Jeff_S2d_Using_My_HK_210407.mp4',
        ),
        title: 'Using Hope Kit',
      },
    },
  ],
};

const GETTING_GOING_LEARN_VIGNETTE_JEFF: SetData = {
  ID: 'getting-going-learn-vignette-jeff',
  name: 'Jeff',
  description: 'Jeff: 45, male, married with kids, lost his job',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S3/Jeff_S3a_What_Do_I_Do_To_GG_210520.mp4',
        ),
        title: 'What Jeff does to get going',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Lying in bed and thinking about going on vacation',
          '1': 'Helped a friend with their garden',
          '2': 'Got out of bed & brushed teeth',
        },
      },
      dir: {
        prompt: 'How did Jeff start to "get going"?',
        feedback: {
          '0': {
            correct: false,
            text: ``,
          },
          '1': {
            correct: false,
            text: `Jeff could do this later on, but he tried something simpler first.`,
          },
          '2': {
            correct: true,
            text: ``,
          },
        },
        afterAll: `Jeff's first try to “get going” was to simply to get out of bed, brush his teeth, and go outside.`,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S3/Jeff_S3b_How_Do_I_Make_Sure_GG_210520.mp4',
        ),
        title: 'How Jeff makes sure to get going',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Thinking about all the things he will do',
          '1': 'Giving himself no option but to do it',
          '2': 'Asking a friend if he should do it',
        },
      },
      dir: {
        prompt: 'What helps Jeff "get going"?',
        feedback: {
          '0': {
            correct: false,
            text: ``,
          },
          '1': {
            correct: true,
            text: ``,
          },
          '2': {
            correct: false,
            text: ``,
          },
        },
        afterAll: 'Jeff creates a thought process that enables him to get going.',
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S3/Jeff_S3c_How_Has_GG_Reduced_Risk_210520.mp4',
        ),
        title: 'How getting going reduces risk',
        compulsory: true,
      },
    },
  ],
};

const GETTING_GOING_APPLY_VIGNETTE_JEFF: SetData = {
  ID: 'getting-going-apply-vignette-jeff',
  name: 'Jeff',
  description: 'Jeff: 45, male, married with kids, lost his job',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S3/Jeff_S3d_Immersive_Apply_Vignette_210520.mp4',
        ),
        title: 'Getting going example',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S3/Jeff_S3e_How_Do_I_Use_AD_210520.mp4',
        ),
        title: 'How use activity diary',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S3/Jeff_S3f_How_Does_AD_Help_Overall_210520.mp4',
        ),
        title: 'How activity diary helps',
        compulsory: true,
      },
    },
  ],
};

const RELAXATION_MINDFULNESS_LEARN_VIGNETTE_JEFF: SetData = {
  ID: 'relaxation-mindfulness-learn-vignette-jeff',
  name: 'Jeff',
  description: 'Jeff: 45, male, married with kids, lost his job',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S4/Jeff_S4a_When_Do_I_Use_It_210520.mp4',
        ),
        title: 'When use it',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S4/Jeff_S4b_How_Has_RM_Helped_210520.mp4',
        ),
        title: 'How it has helped',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Jeff practices once a week.',
          '1': 'It was easy for Jeff to practice when he first started.',
          '2': "Jeff practices to reduce stress when he's feeling worked up.",
        },
      },
      dir: {
        prompt: "Which of these is true about Jeff's practice of relaxation and mindfulness?",
        feedback: {
          '0': {
            correct: false,
            text: ``,
          },
          '1': {
            correct: false,
            text: ``,
          },
          '2': {
            correct: true,
            text: ``,
          },
        },
        afterAll: [
          "Jeff makes sure to practice relaxation and mindfulness at the start of each day, and when he's upset or dealing with tough emotions.",
          "It wasn't easy at first, but with practice, applying these skills has become easier and a regular part of his life.",
        ].join('\n\n'),
      },
    },
  ],
};

const RELAXATION_MINDFULNESS_APPLY_VIGNETTE_JEFF: SetData = {
  ID: 'relaxation-mindfulness-apply-vignette-jeff',
  name: 'Jeff',
  description: 'Jeff: 45, male, married with kids, lost his job',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S4/Jeff_S4c_Where_Do_I_Start_210520.mp4',
        ),
        title: 'Apply Relaxation & Mindfulness',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S4/Jeff_S4d_Where_Do_I_Do_The_Exercises_210520.mp4',
        ),
        title: 'Apply Relaxation & Mindfulness',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S4/Jeff_S4e_What_Do_I_Do_If_Neg_Thoughts_210520.mp4',
        ),
        title: 'Apply Relaxation & Mindfulness',
        compulsory: true,
      },
    },
  ],
};

const SLEEP_DIARY_LEARN_VIGNETTE_JEFF: SetData = {
  ID: 'sleep-diary-learn-vignette-jeff',
  name: 'Jeff',
  description: 'Jeff: 45, male, married with kids, lost his job',
  previewOptions: {
    fitMode: 'crop',
  },
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S5/Jeff_S5a_How_Do_I_Normally_Sleep_210520.mp4',
        ),
        title: 'Improving sleep',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S5/Jeff_S5b_What_Have_I_Changed_210520.mp4',
        ),
        title: 'Improving sleep',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Made a napping schedule to limit his napping.',
          '1': 'Used his phone and laptop more before bed.',
          '2': 'Changed his sleeping area to be very dark.',
        },
      },
      dir: {
        prompt: 'What did Jeff change about his sleep?',
        feedback: {
          '0': {
            correct: false,
            text: `Jeff made an effort to stop all his napping.`,
          },
          '1': {
            correct: false,
            text: `Jeff tried to remove or decrease use of these items, to make sure his mind could relax before sleep.`,
          },
          '2': {
            correct: true,
            text: ``,
          },
        },
        afterAll:
          "Jeff tried to decrease or stop certain habits, like napping and technology use and made sure he was tired enough to sleep at night. Also, his darkened room now cues his body that it's bedtime.",
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S5/Jeff_S5c_How_Has_Improving_Sleep_Reduced_Risk_210520.mp4',
        ),
        title: 'Improving sleep',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Jeff gets more and better sleep, so he copes better with issues.',
          '1': 'Jeff uses his sleep diary to track his warning signs.',
          '2': "Jeff's been in bed twice as much, so his risk is lower.",
        },
      },
      dir: {
        prompt: "How did changing his sleep habits reduce Jeff's risk?",
        feedback: {
          '0': {
            correct: true,
            text: `Getting better sleep will improve your mood, but it won't completely erase negative thoughts.`,
          },
          '1': {
            correct: false,
            text: `Jeff uses his sleep diary to track his sleep habits, which lowers risk.`,
          },
          '2': {
            correct: false,
            text: `Being in bed more often doesn't always mean more or better rest.`,
          },
        },
        afterAll: [
          'Jeff focused on improving habits so when he is in bed, his sleep quality is better.',
          "Jeff didn't struggle as much with things after being well-rested, and his suicide risk lowered.",
        ].join('\n\n'),
      },
    },
  ],
};

const SLEEP_DIARY_APPLY_VIGNETTE_JEFF: SetData = {
  ID: 'sleep-diary-jeff-vignette-jeff',
  name: 'Jeff',
  description: 'Jeff: 45, male, married with kids, lost his job',
  previewOptions: {
    fitMode: 'crop',
  },
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S5/Jeff_S5d_How_Do_I_Start_210520.mp4',
        ),
        title: 'Apply Sleep Diary',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S5/Jeff_S5e_How_Does_Using_SD_Help_210520.mp4',
        ),
        title: 'Apply Sleep Diary',
        compulsory: true,
      },
    },
  ],
};

const SPOT_IT_LEARN_VIGNETTE_JEFF: SetData = {
  ID: 'spot-it-learn-vignette-jeff',
  name: 'Jeff',
  description: 'Jeff: 45, male, married with kids, lost his job',
  previewOptions: {
    fitMode: 'crop',
  },
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S6/Jeff_S6a_When_Do_I_Use_SI_210522.mp4',
        ),
        title: 'Improving sleep',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S6/Jeff_S6b_Whats_My_Experience_SI_210522.mp4',
        ),
        title: 'Improving sleep',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'He notices negative thoughts quickly.',
          '1': 'He experiences a happy emotion first.',
          '2': "He feels a pit in his stomach or like he's shutting down.",
        },
      },
      dir: {
        prompt: 'What helps Jeff know how to "spot" a thought?',
        feedback: {
          '0': {
            correct: false,
            text: `Jeff is still learning, so he doesn't always notice when they pop up.`,
          },
          '1': {
            correct: false,
            text: `Jeff waits until he's feeling negative emotions, then tries to trace them back to a negative thought.`,
          },
          '2': {
            correct: true,
            text: ``,
          },
        },
        afterAll: [
          'After practicing a while, it was easier for Jeff to realize when he should try to "spot" a thought.',
          'Now he can do this whenever he feels that something is "off."',
        ].join('\n\n'),
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S6/Jeff_S6c_How_Has_SI_Helped_Reduce_Risk_210522.mp4',
        ),
        title: 'Improving sleep',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Jeff can see how his negative thoughts affect how he feels.',
          '1': 'Jeff realizes his negative thoughts are true.',
          '2': 'Jeff can ignore his emotions.',
        },
      },
      dir: {
        prompt: "How has Jeff's suicide risk gone down using Spot It?",
        feedback: {
          '0': {
            correct: true,
            text: ``,
          },
          '1': {
            correct: false,
            text: `Jeff learned recognizing his negative thoughts is the first step to changing them and help prevent a suicidal crisis.`,
          },
          '2': {
            correct: false,
            text: `Jeff shouldn't ignore his emotions, but he can use them to become more aware of his thoughts.`,
          },
        },
        afterAll: [
          'Now that Jeff has learned bad feelings can start with thoughts, he can try to change his thoughts.',
          'This will help him to feel better about himself and the future and lower his risk.',
        ].join('\n\n'),
      },
    },
  ],
};

const SPOT_IT_APPLY_VIGNETTE_JEFF: SetData = {
  ID: 'spot-it-apply-vignette-jeff',
  name: 'Jeff',
  description: 'Jeff: 45, male, married with kids, lost his job',
  previewOptions: {
    fitMode: 'crop',
  },
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S6/Jeff_S6d_How_Do_I_Start_210522.mp4',
        ),
        title: 'Apply Spot it',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S6/Jeff_S6e_What_Was_First_Thought_210522.mp4',
        ),
        title: 'Apply Spot it',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S6/Jeff_S6f_How_Was_SI_Helpful_210522.mp4',
        ),
        title: 'Apply Spot it',
        compulsory: true,
      },
    },
  ],
};

const TEST_IT_LEARN_VIGNETTE_JEFF: SetData = {
  ID: 'test-it-learn-vignette-jeff',
  name: 'Jeff',
  description: 'Jeff: 45, male, married with kids, lost his job',
  previewOptions: {
    fitMode: 'crop',
  },
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S7/Jeff_S7a_Whats_My_Experience_TI_210524.mp4',
        ),
        title: 'Test it example',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'They are always true.',
          '1': 'He tends to interpret situations in a negative way.',
          '2': 'His negative thoughts are new.',
        },
      },
      dir: {
        prompt: 'What did Jeff find out about his thoughts?',
        feedback: {
          '0': {
            correct: false,
            text: `Jeff started to find through testing that his negative thoughts might be too harsh or not realistic.`,
          },
          '1': {
            correct: true,
            text: ``,
          },
          '2': {
            correct: false,
            text: `Jeff's reviewing his thoughts revealed he'd been thinking about many of them for a long time.`,
          },
        },
        afterAll:
          "Jeff came to know that his thoughts were hurtful to himself. He'd been telling himself many of them for quite a while, and after challenging them found they often didn't make sense anymore.",
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S7/Jeff_S7b_How_Has_TI_Helped_Reduce_SR_210623.mp4',
        ),
        title: 'Test it example',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': "Events in Jeff's life aren't going wrong.",
          '1': 'Jeff feels better by stopping his thoughts.',
          '2': 'Jeff tests his negative thoughts and finds they are often not true.',
        },
      },
      dir: {
        prompt: "How is Jeff's suicide risk lower since using Test It?",
        feedback: {
          '0': {
            correct: false,
            text: `Sometimes things still crop up that aren't great.`,
          },
          '1': {
            correct: false,
            text: `Jeff doesn't stop his thoughts.`,
          },
          '2': {
            correct: true,
            text: ``,
          },
        },
        afterAll: [
          "Even if something goes wrong, Jeff can think more realistically, and know that bad events don't have to mean bad things about himself. ",
          'Jeff has been able to lower his risk by testing his negative thoughts.',
        ].join('\n\n'),
      },
    },
  ],
};

const TEST_IT_APPLY_VIGNETTE_JEFF: SetData = {
  ID: 'test-it-apply-vignette-jeff',
  name: 'Jeff',
  description: 'Jeff: 45, male, married with kids, lost his job',
  previewOptions: {
    fitMode: 'crop',
  },
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S7/Jeff_S7c_How_Do_I_Start_210524.mp4',
        ),
        title: 'Test it practice',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S7/Jeff_S7d_How_Did_I_Test_Thought_210524.mp4',
        ),
        title: 'Test it practice',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S7/Jeff_S7e_How_Was_TI_Helpful_210524.mp4',
        ),
        title: 'Test it practice',
        compulsory: true,
      },
    },
  ],
};

const SWITCH_IT_LEARN_VIGNETTE_JEFF: SetData = {
  ID: 'switch-it-learn-vignette-jeff',
  name: 'Jeff',
  description: 'Jeff: 45, male, married with kids, lost his job',
  previewOptions: {
    height: 100,
  },
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S8/Jeff_S8a_Whats_My_Experience_SI_210525.mp4',
        ),
        title: 'Switch it example',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Changed "always" to "never."',
          '1': 'Replaced "a failure" to "a success."',
          '2': 'Stopped using "always" and "a failure."',
        },
      },
      dir: {
        prompt: 'How did Jeff "switch" his thought?',
        feedback: {
          '0': {
            correct: false,
            text: `Jeff does sometimes fail.`,
          },
          '1': {
            correct: false,
            text: `Jeff doesn't always succeed.`,
          },
          '2': {
            correct: true,
            text: ``,
          },
        },
        afterAll: `Jeff knows that he isn't \"always\" a failure or a success. Sometimes he fails, sometimes he succeeds, just like many other people.`,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S8/Jeff_S8b_Has_SI_Helped_Me_210525.mp4',
        ),
        title: 'Switch it example',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Tried to distract himself from them.',
          '1': 'Realized his friends might be busy.',
          '2': 'Decided he didn’t like his friends.',
        },
      },
      dir: {
        prompt: 'How did Jeff "switch" these thoughts?',
        feedback: {
          '0': {
            correct: false,
            text: `Jeff switched his thoughts to something else.`,
          },
          '1': {
            correct: true,
            text: ``,
          },
          '2': {
            correct: false,
            text: `Jeff still wants to maintain his friendships.`,
          },
        },
        afterAll: `Jeff took a look at his thoughts and realized that his friends, like him, might cancel because of many different reasons. It doesn’t mean they don’t like him.`,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S8/Jeff_S8c_How_Has_SI_Helped_Reduce_SR_210525.mp4',
        ),
        title: 'Switch it example',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'His thinking is more realistic.',
          '1': 'His thoughts are happier.',
          '2': 'He thinks more about the past.',
        },
      },
      dir: {
        prompt: 'How does "switching" thoughts reduce Jeff\'s risk?',
        feedback: {
          '0': {
            correct: true,
            text: ``,
          },
          '1': {
            correct: false,
            text: `Jeff's thoughts aren't always happy, which is ok.`,
          },
          '2': {
            correct: false,
            text: `Jeff tries to keep his thinking open and about the future.`,
          },
        },
        afterAll: [
          'Substituting more balanced, realistic thoughts for negative ones has helped Jeff break out of his negative emotion spirals.',
          "Being realistic about causes of events, and how he sees himself, reduces Jeff's risk.",
        ].join('\n\n'),
      },
    },
  ],
};

const SWITCH_IT_APPLY_VIGNETTE_JEFF: SetData = {
  ID: 'switch-it-apply-vignette-jeff',
  name: 'Jeff',
  description: 'Jeff: 45, male, married with kids, lost his job',
  previewOptions: {
    height: 100,
  },
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S8/Jeff_S8d_How_Do_I_Start_210525.mp4',
        ),
        title: 'Switch it practice',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S8/Jeff_S8e_How_Do_I_Switch_My_Thought_210525.mp4',
        ),
        title: 'Switch it practice',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S8/Jeff_S8f_How_Was_SI_Helpful_210525.mp4',
        ),
        title: 'Switch it practice',
        compulsory: true,
      },
    },
  ],
};

const COPING_CARDS_LEARN_VIGNETTE_JEFF: SetData = {
  ID: 'coping-cards-learn-vigenette-jeff',
  name: 'Jeff',
  description: 'Jeff: 45, male, married with kids, lost his job',
  previewOptions: {
    height: 120,
  },
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S9/Jeff_S9a_Whats_My_Experience_CC_210527.mp4',
        ),
        title: 'Jeff',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S9/Jeff_S9b_How_Have_CC_Reduced_SR_210527.mp4',
        ),
        title: 'Jeff',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'He can review many small details.',
          '1': 'Reminders of ways to cope with tough situations.',
          '2': 'He can write down all of his thoughts.',
        },
      },
      dir: {
        prompt: 'How do Coping Cards help Jeff?',
        feedback: {
          '0': {
            correct: false,
            text: ``,
          },
          '1': {
            correct: true,
            text: ``,
          },
          '2': {
            correct: false,
            text: `Coping Cards aren't the same as a diary or journal.`,
          },
        },
        afterAll: [
          'Jeff uses Coping Cards to help review real examples of tough situations or unhelpful thoughts.',
          'And then to look at ways to handle them.',
        ].join('\n\n'),
      },
    },
  ],
};

const COPING_CARDS_APPLY_VIGNETTE_JEFF: SetData = {
  ID: 'coping-cards-apply-vigenette-jeff',
  name: 'Jeff',
  description: 'Jeff: 45, male, married with kids, lost his job',
  previewOptions: {
    height: 120,
  },
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S9/Jeff_S9c_How_Do_I_Start_210527.mp4',
        ),
        title: 'Jeff',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S9/Jeff_S9d_Whats_Another_Example_210527.mp4',
        ),
        title: 'Jeff',
        compulsory: true,
      },
    },
  ],
};

const RISK_CURVE_MYPLAN_REVIEW_LEARN_VIGNETTE_JEFF: SetData = {
  ID: 'risk-curve-myplan-review-learn-vignette-jeff',
  name: 'Jeff',
  description: 'Jeff: 45, male, married with kids, lost his job',
  previewOptions: {
    height: 140,
  },
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S10/Jeff_S10a_Whats_My_Experience_Review_RC_210527.mp4',
        ),
        title: 'Jeff',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S10/Jeff_S10b_Whats_My_Experience_Review_MP_210527.mp4',
        ),
        title: 'Jeff',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Skills learned in Aviva were to reduce his suicide risk',
          '1': 'He should never change his MyPlan',
          '2': 'Warning signs never change',
        },
      },
      dir: {
        prompt: 'What did Jeff realize when reviewing the Risk Curve and MyPlan?',
        feedback: {
          '0': {
            correct: true,
            text: ``,
          },
          '1': {
            correct: false,
            text: ``,
          },
          '2': {
            correct: false,
            text: ``,
          },
        },
        afterAll:
          'Reviewing MyPlan and the Risk Curve helped Jeff see how skills he learned in Aviva directly helped him lower his risk. He knew his warning signs do change and that he should update his MyPlan as a result.',
      },
    },
  ],
};

const REDUCE_RISK_LEARN_VIGNETTE_JEFF: SetData = {
  ID: 'reduce-risk-learn-vignette-jeff',
  name: 'Jeff',
  description: 'Jeff: 45, male, married with kids, lost his job',
  previewOptions: {
    height: 140,
  },
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S11/Jeff_S11a_Whats_My_Experience_Reviewing_Skills_210527.mp4',
        ),
        title: 'Jeff',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S11/Jeff_S11b_Whats_Most_Helpful_Reviewing_Skills_210527.mp4',
        ),
        title: 'Jeff',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Completely overwhelmed.',
          '1': 'Proud and ready to use them.',
          '2': 'Not sure how they will help.',
        },
      },
      dir: {
        prompt: 'How does Jeff feel after reviewing the skills?',
        feedback: {
          '0': {
            correct: false,
            text: ``,
          },
          '1': {
            correct: true,
            text: ``,
          },
          '2': {
            correct: false,
            text: ``,
          },
        },
        afterAll: [
          'Jeff knows how he can use what he learned going forward. If he gets stuck, he can always go back and practice more.',
          "Jeff should be proud that he's put in the work to help himself.",
        ].join('\n\n'),
      },
    },
  ],
};

const POSTAVIVA_LEARN_VIGNETTE_JEFF: SetData = {
  ID: 'postaviva-learn-vignette-jeff',
  name: 'Jeff',
  description: 'Jeff: 45, male, married with kids, lost his job',
  previewOptions: {
    time: 10,
  },
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/jeff/S12/Jeff_S12a_How_Do_I_Feel_Finished_210527.mp4',
        ),
        title: 'Jeff',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Stop using his sleep diary.',
          '1': 'Ask a doctor about depression.',
          '2': 'Stop using Aviva.',
        },
      },
      dir: {
        prompt: 'What does Jeff plan to do after finishing Aviva?',
        feedback: {
          '0': {
            correct: false,
            text: ``,
          },
          '1': {
            correct: true,
            text: ``,
          },
          '2': {
            correct: false,
            text: ``,
          },
        },
        afterAll: [
          "Jeff plans to keep using Aviva to keep his sleep diary and practice relaxation, as well as to do the 'changing thoughts' exercises. ",
          "He's going to ask his doctor to get help with depression, too.",
        ].join('\n\n'),
      },
    },
  ],
};

//Session 1 - Onboarding
//Text & quizzes here - https://docs.google.com/document/d/18LQuTbGXj0j2kXW2T6IdCYtzrtw1vBv_iGBgWmx2tt8/edit?usp=sharing
//Videos stored here -

const MYSTORY_LEARN_VIGNETTE_MIA: SetData = {
  ID: 'mystory-learn-vignette-mia',
  name: 'Mia',
  description: 'Mia: 27, female, college educated, abusive ex',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S1/Mia_S1a_Suicide_Story_210527.mp4',
        ),
        title: "Mia's Story",
        compulsory: true,
      },
    },
  ],
};

const RISK_CURVE_LEARN_VIGNETTE_MIA_COLLAB: SetData = {
  ID: 'risk-curve-learn-vignette-mia-collab',
  name: 'Mia',
  description: 'Mia: 27, female, college educated, abusive ex',
  previewTime: 23,
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S1/Mia_S1b_Making_Sense_210527.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'To relive a very difficult day again',
          '1': 'To learn about your own specific warning signs so that you can keep an eye on them and work to lower your risk',
          '2': 'You probably won’t need to learn about your own Risk Curve. Your warning signs are probably the same as Mia’s warning signs.',
        },
      },
      dir: {
        prompt: 'Why is it important to learn about your own Risk Curve?',
        feedback: {
          '0': {
            correct: false,
            text: 'Though it may be difficult to spend time thinking about what happened before a suicide attempt, it is important to learn what pushes you up the Risk Curve.',
          },
          '1': {
            correct: true,
            text: 'By understanding what pushes you up the Risk Curve, you can keep an eye on warning signs specific to you and work to lower your risk.',
          },
          '2': {
            correct: false,
            text: 'What goes in a Risk Curve is actually unique to each person. It is important to learn about what things increase your risk and these things might be different than the things that increase Mia’s risk.',
          },
        },
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S1/Mia_S1c_How_Did_RC_Explain_210527.mp4',
        ),
        compulsory: true,
      },
    },
  ],
};

const RISK_CURVE_LEARN_VIGNETTE_MIA: SetData = {
  ID: 'risk-curve-learn-vignette-mia',
  name: 'Mia',
  description: 'Mia: 27, female, college educated, abusive ex',
  previewTime: 23,
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S1/Mia_S1a_Suicide_Story_210527.mp4',
        ),
        title: "Mia's Story",
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S1/Mia_S1b_Making_Sense_210527.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'To relive a very difficult day again',
          '1': 'To learn about your own specific warning signs so that you can keep an eye on them and work to lower your risk',
          '2': 'You probably won’t need to learn about your own Risk Curve. Your warning signs are probably the same as Mia’s warning signs.',
        },
      },
      dir: {
        prompt: 'Why is it important to learn about your own Risk Curve?',
        feedback: {
          '0': {
            correct: false,
            text: 'Though it may be difficult to spend time thinking about what happened before a suicide attempt, it is important to learn what pushes you up the Risk Curve.',
          },
          '1': {
            correct: true,
            text: 'By understanding what pushes you up the Risk Curve, you can keep an eye on warning signs specific to you and work to lower your risk.',
          },
          '2': {
            correct: false,
            text: 'What goes in a Risk Curve is actually unique to each person. It is important to learn about what things increase your risk and these things might be different than the things that increase Mia’s risk.',
          },
        },
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S1/Mia_S1c_How_Did_RC_Explain_210527.mp4',
        ),
        compulsory: true,
      },
    },
  ],
};

const MYPLAN_LEARN_VIGNETTE_MIA: SetData = {
  ID: 'myplan-learn-vignette-mia',
  name: 'Mia',
  description: 'Mia: 27, female, college educated, abusive ex',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S1/Mia_S1d_How_MyPlan_Helps_Me_210527.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S1/Mia_S1e_Making_My_Environment_Safe_210527.mp4',
        ),
        compulsory: true,
      },
    },
  ],
};

//Session 2 - Hope Kit
//Text & quizzes here - https://docs.google.com/document/d/1b9YwNzU-GHhOYumXWriXeqJKyiUd6yRRp2gRM9F3ESw/edit?usp=sharing
//Videos stored here -

const HOPE_KIT_LEARN_VIGNETTE_MIA: SetData = {
  ID: 'hope-kit-learn-vignette-mia',
  name: 'Mia',
  description: 'Mia: 27, female, college educated, abusive ex',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S2/Mia_Session2_How_HK_Reduces_Risk_210407.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S2/Mia_Session2a_How_Does_HK_Help_Me_210407.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'It reminds her to get enough sleep',
          '1': 'It reminds her that life is worth living',
          '2': 'It gets rid of her bad thoughts completely',
        },
      },
      dir: {
        prompt: 'How has the Hope Kit helped Mia?',
        feedback: {
          '0': {
            correct: false,
            text: 'Sleep is important but by itself won’t give her hope.',
          },
          '1': {
            correct: true,
            text: '',
          },
          '2': {
            correct: false,
            text: 'Instead, it can help her direct her thoughts to other things, so that bad thoughts don’t hurt her so much.',
          },
        },
        afterAll:
          'The Hope Kit will help Mia remember the good things she has in her life, and what she can look forward to.',
      },
    },
  ],
};

const HOPE_KIT_APPLY_VIGNETTE_MIA: SetData = {
  ID: 'hope-kit-apply-vignette-mia',
  name: 'Mia',
  description: 'Mia: 27, female, college educated, abusive ex',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S2/Mia_Session2_Making_My_HK_210407.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S2/Mia_Session2b_Using_My_HK_210407.mp4',
        ),
        compulsory: true,
      },
    },
  ],
};

//Session 3 - Getting Going
//Text & quizzes here - https://docs.google.com/document/d/1TOej3II3cPoHXEFAMZqsrL4LRHmP_7t-Rqq9U0gBADI/edit?usp=sharing
//Videos stored here -

const GETTING_GOING_LEARN_VIGNETTE_MIA: SetData = {
  ID: 'getting-going-learn-vignette-mia',
  name: 'Mia',
  description: 'Mia: 27, female, college educated, abusive ex',
  items: [
    //Video 3a & quiz
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S3/Mia_Session3a_What_Do_I_Do_To_GG_200405.mp4',
        ),
        compulsory: true,
      },
    },

    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Lying in bed and thinking about going on vacation',
          '1': 'Playing video games',
          '2': 'Got out of bed and brushed her hair',
        },
      },
      dir: {
        prompt: 'How did Mia start to “get going”?',
        feedback: {
          '0': {
            correct: false,
            text: '',
          },
          '1': {
            correct: false,
            text: '',
          },
          '2': {
            correct: true,
            text: '',
          },
        },
        afterAll:
          'Mia “got going” by getting out of bed, brushing her hair, and trying to do a chore.',
      },
    },

    //Video 3b & quiz
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S3/Mia_Session3b_How_Do_I_Make_Sure_I_GG_200405.mp4',
        ),
        compulsory: true,
      },
    },

    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Thinking about all the things she will do',
          '1': 'Asking a friend if she should do it',
          '2': 'Giving herself no option but to do it',
        },
      },
      dir: {
        prompt: 'What helps Mia get going?',
        feedback: {
          '0': {
            correct: false,
            text: '',
          },
          '1': {
            correct: false,
            text: '',
          },
          '2': {
            correct: true,
            text: '',
          },
        },
        afterAll: 'Mia creates urgency to get going. She tells herself there is no other option.',
      },
    },

    //Video 3c
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S3/Mia_Session3c_How_Has_GG_Helped_To_Reduce_Risk_200405.mp4',
        ),
        compulsory: true,
      },
    },
  ],
};

const GETTING_GOING_APPLY_VIGNETTE_MIA: SetData = {
  ID: 'getting-going-apply-vignette-mia',
  name: 'Mia',
  description: 'Mia: 27, female, college educated, abusive ex',
  items: [
    //Video 3d
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S3/Mia_Session3d_Activity_Diary_200405.mp4',
        ),
        compulsory: true,
      },
    },

    //Video 3e
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S3/Mia_Session3e_How_Do_I_Use_Activity_Diary_200405.mp4',
        ),
        compulsory: true,
      },
    },

    //Video 3f
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S3/Mia_Session3f_How_Does_Activity_Diary_Help_Me_200405.mp4',
        ),
        compulsory: true,
      },
    },
  ],
};

//Session 4 - Relaxation & mindfulness
//Text & quizzes here - https://docs.google.com/document/d/1kc9-na8EliuyRMZVOoNV8EMqAR0lRgdtUc6b2RIaiOM/edit?usp=sharing
//Videos stored here -

const RELAXATION_MINDFULNESS_LEARN_VIGNETTE_MIA: SetData = {
  ID: 'relaxation-mindfulness-learn-vignette-mia',
  name: 'Mia',
  description: 'Mia: 27, female, college educated, abusive ex',
  previewTime: 2,
  items: [
    //Video 4a
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S4/Mia_Session4a_When_Do_I_Use_210407.mp4',
        ),
        compulsory: true,
      },
    },
    //Video 4b & quiz
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S4/Mia_Session4b_How_Have_RM_Reduce_Risk_210407.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Mia practices once a week',
          '1': 'Mia had a clear routine when she started',
          '2': 'Mia practices them to help her cope with the everyday stressors in her life',
        },
      },
      dir: {
        prompt: 'Which of these is true about Mia’s practice of relaxation and mindfulness?',
        feedback: {
          '0': {
            correct: false,
            text: '',
          },
          '1': {
            correct: false,
            text: '',
          },
          '2': {
            correct: true,
            text: '',
          },
        },
        afterAll: 'Mia makes sure to practice them at the start of each day.',
      },
    },
  ],
};

const RELAXATION_MINDFULNESS_APPLY_VIGNETTE_MIA: SetData = {
  ID: 'relaxation-mindfulness-apply-vignette-mia',
  name: 'Mia',
  description: 'Mia: 27, female, college educated, abusive ex',
  previewTime: 25,
  items: [
    //Video 4c
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S4/Mia_Session4c_Where_Do_I_Start_210407.mp4',
        ),
        compulsory: true,
      },
    },
    //Video 4d
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S4/Mia_Session4d_Where_Do_I_Do_Exercises_210407.mp4',
        ),
        compulsory: true,
      },
    },
    //Video 4e
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S4/Mia_Session4e_What_Do_I_Do_Neg_Thoughts_210407.mp4',
        ),
        compulsory: true,
      },
    },
  ],
};

//Session 5 - Improving Sleep
//Text & quizzes here - https://docs.google.com/document/d/1ZyyT2P5vKxmLlFKI2B-icEihTjCIZkSNjxvVgoVmit4/edit?usp=sharing
//Videos stored here -

const SLEEP_DIARY_LEARN_VIGNETTE_MIA: SetData = {
  ID: 'sleep-diary-learn-vignette-mia',
  name: 'Mia',
  description: 'Mia: 27, female, college educated, abusive ex',
  items: [
    //Video 5a
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S5/Mia_S5a_How_Is_My_Sleep_210411.mp4',
        ),
        compulsory: true,
      },
    },
    //Video 5b & quiz
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S5/Mia_S5b_What_Have_I_Changed_210411.mp4',
        ),
        compulsory: true,
      },
    },

    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Made a napping schedule for late in the afternoon to control her napping',
          '1': 'Used her phone and laptop more before bed',
          '2': 'Tried to cut back her wine intake, exercise more and avoid napping',
        },
      },
      dir: {
        prompt: 'What did Mia change to help her sleep?',
        feedback: {
          '0': {
            correct: false,
            text: '',
          },
          '1': {
            correct: false,
            text: '',
          },
          '2': {
            correct: true,
            text: '',
          },
        },
        afterAll:
          'Mia tried to exercise more and plan when she would eat dinner, when and how much wine she would drink, and when she would take naps.',
      },
    },

    //Videos 5c and quiz
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S5/Mia_S5c_How_Has_Improving_Sleep_Reduced_Risk_210411.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Mia’s been in bed twice as much, so her risk is lower',
          '1': 'Mia uses her sleep diary to track her warning signs',
          '2': 'Mia gets sleep more regularly which help her manage her thoughts and emotions better',
        },
      },
      dir: {
        prompt: 'How did changing her sleep habits reduce Mia’s risk?',
        feedback: {
          '0': {
            correct: false,
            text: 'Being in bed more often doesn’t always mean more or better rest.',
          },
          '1': {
            correct: false,
            text: 'Warning signs are kept in Mia’s MyPlan.',
          },
          '2': {
            correct: true,
            text: '',
          },
        },
        afterAll:
          'Mia has focused on improving her sleep and that has made her better able to cope with her life.',
      },
    },
  ],
};

const SLEEP_DIARY_APPLY_VIGNETTE_MIA: SetData = {
  ID: 'sleep-diary-apply-vignette-mia',
  name: 'Mia',
  description: 'Mia: 27, female, college educated, abusive ex',
  items: [
    //Videos 5d
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S5/Mia_S5d_How_Do_I_Start_210411.mp4',
        ),
        compulsory: true,
      },
    },
    //Videos 5e
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S5/Mia_S5e_How_Does_Using_Sleep_Diary_Help_210411.mp4',
        ),
        compulsory: true,
      },
    },
  ],
};

//Session 6 - Spot It
//Text & quizzes here - https://docs.google.com/document/d/1lOeKkO-bs6yzRIUIyNYJ25CKN5a-g9F2JZiPkO6YERY/edit?usp=sharing
//Videos stored here -

const SPOT_IT_LEARN_VIGNETTE_MIA: SetData = {
  ID: 'spot-it-learn-vignette-mia',
  name: 'Mia',
  description: 'Mia: 27, female, college educated, abusive ex',
  items: [
    //Video 6a
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S6/Mia_S6a_When_Do_I_Use_Spot_It_211027.mp4',
        ),
        compulsory: true,
      },
    },
    //Video 6b & quiz
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S6/Mia_S6b_Whats_Experience_Spot_It_Been_Like_210411.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'She notices negative thoughts quickly',
          '1': 'She feels a pit in her stomach or like she’s shutting down',
          '2': 'She experiences a happy emotion first',
        },
      },
      dir: {
        prompt: 'What helps Mia know how to “spot” a thought?',
        feedback: {
          '0': {
            correct: false,
            text: 'Mia is still learning, so she doesn’t always notice when they pop up.',
          },
          '1': {
            correct: true,
            text: '',
          },
          '2': {
            correct: false,
            text: 'Mia waits until she’s feeling negative emotions, then tries to trace them back to a negative thought.',
          },
        },
        afterAll:
          'After practicing a while, it was easier for Mia to realize when she should try to “spot” a thought. Now she can do this when she feels that something is “off.”',
      },
    },
    //Video 6c & quiz
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S6/Mia_S6c_How_Has_Spot_It_Helped_Reduce_Risk_210411.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'She can ignore her feelings',
          '1': 'She realizes her negative thoughts are true',
          '2': 'She can see the source of her negative feelings are thoughts',
        },
      },
      dir: {
        prompt: 'How has Mia’s risk gone down using Spot It?',
        feedback: {
          '0': {
            correct: false,
            text: 'Mia shouldn’t ignore her feelings, but can use them to track her thoughts.',
          },
          '1': {
            correct: false,
            text: 'Mia learned the negative thoughts that cause her bad feelings may be overblown.',
          },
          '2': {
            correct: true,
            text: '',
          },
        },
        afterAll:
          'Now that Mia has learned bad feelings can start with thoughts, she can try to change  her thoughts. This will help her state of mind and lower her risk.',
      },
    },
  ],
};

const SPOT_IT_APPLY_VIGNETTE_MIA: SetData = {
  ID: 'spot-it-apply-vignette-mia',
  name: 'Mia',
  description: 'Mia: 27, female, college educated, abusive ex',
  items: [
    //Videos 6d
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S6/Mia_S6d_How_Do_I_Start_210411.mp4',
        ),
        compulsory: true,
      },
    },
    //Videos 6e
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S6/Mia_S6e_What_Was_First_Thought_Spotted_210411.mp4',
        ),
        compulsory: true,
      },
    },
    //Videos 6f
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S6/Mia_S6f_How_Was_Spot_It_Helpful_210411.mp4',
        ),
        compulsory: true,
      },
    },
  ],
};

//Session 7 - Test It
//Text & quizzes here - https://docs.google.com/document/d/1eiSqHjhSyDApRGEuNFyytgoxHUuIIovhP9bquYDRdxw/edit?usp=sharing
//Videos stored here -

const TEST_IT_LEARN_VIGNETTE_MIA: SetData = {
  ID: 'test-it-learn-vignette-mia',
  name: 'Mia',
  description: 'Mia: 27, female, college educated, abusive ex',
  items: [
    //Video 7a & quiz
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S7/Mia_S7a_Whats_My_Experience_With_Test_It_210412.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'They were often wrong',
          '1': 'They are always true',
          '2': 'Her negative thoughts are new',
        },
      },
      dir: {
        prompt: 'What did Mia find out about her negative thoughts?',
        feedback: {
          '0': {
            correct: true,
            text: '',
          },
          '1': {
            correct: false,
            text: 'Mia started to find through testing that her negative thoughts might be too harsh or not realistic.',
          },
          '2': {
            correct: false,
            text: 'Not quite. Mia examining her thoughts revealed she’d had many of them for a long time.',
          },
        },
        afterAll:
          'Mia came to know that her thoughts hurt herself. She’d been telling herself many of them for quite a while but many of them weren’t true.',
      },
    },
    //Video 7b & quiz
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S7/Mia_S7b_How_Has_Test_It_Reduced_Risk_210412.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Events in Mia’s life aren’t going wrong',
          '1': 'Mia avoids problems by stopping her thoughts',
          '2': 'Mia tests thoughts and sees what’s true',
        },
      },
      dir: {
        prompt: 'How is Mia’s suicide risk lower since using Test It?',
        feedback: {
          '0': {
            correct: false,
            text: 'Sometimes things still crop up that aren’t great.',
          },
          '1': {
            correct: false,
            text: 'Not quite. Mia doesn’t stop her thoughts.',
          },
          '2': {
            correct: true,
            text: '',
          },
        },
        afterAll:
          'Mia knows to test thoughts she has spotted that could be unhelpful or untrue. Testing the thoughts helps her be more balanced in her thinking.',
      },
    },
  ],
};

const TEST_IT_APPLY_VIGNETTE_MIA: SetData = {
  ID: 'test-it-apply-vignette-mia',
  name: 'Mia',
  description: 'Mia: 27, female, college educated, abusive ex',
  items: [
    //Video 7c
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S7/Mia_S7c_How_Do_I_Start_210412.mp4',
        ),
        compulsory: true,
      },
    },
    //Video 7d
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S7/Mia_S7d_How_Did_I_Test_My_Thought_210412.mp4',
        ),
        compulsory: true,
      },
    },
    //Video 7e
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S7/Mia_S7e_How_Was_Test_It_Helpful_210412.mp4',
        ),
        compulsory: true,
      },
    },
  ],
};

//Session 8 - Switch It
//Text & quizzes here - https://docs.google.com/document/d/1SGphAQKxE9g8Yy8tfibH8q3gJaOZwTU6XSMw95v5QTw/edit?usp=sharing
//Videos stored here -

const SWITCH_IT_LEARN_VIGNETTE_MIA: SetData = {
  ID: 'switch-it-learn-vignette-mia',
  name: 'Mia',
  description: 'Mia: 27, female, college educated, abusive ex',
  items: [
    //Video 8a & quiz
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S8/Mia_S8A_Whats_My_Experience_With_Switch_It_210518.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Found a new explanation for why her friend ignored her',
          '1': 'Distracted herself by thinking about dinner',
          '2': 'Continued thinking her friend was mad at her',
        },
      },
      dir: {
        prompt: 'How did Mia “switch” her thought?',
        feedback: {
          '0': {
            correct: true,
            text: '',
          },
          '1': {
            correct: false,
            text: '',
          },
          '2': {
            correct: false,
            text: '',
          },
        },
        afterAll:
          'Mia had a thought to explain a negative experience, and that thought made her upset. When she tested the thought she couldn’t think of much evidence to support it. So she found a new thought which was more likely to be true and switched it.',
      },
    },
    //Video 8b & quiz
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S8/Mia_S8B_Has_Switch_It_Helped_210518.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Told herself she had to do the things she should do',
          '1': 'Switched from strict ‘should’ rules to ‘it’d be nice’',
          '2': 'Decided she didn’t like doing things',
        },
      },
      dir: {
        prompt: 'How did Mia “switch” these thoughts?',
        feedback: {
          '0': {
            correct: false,
            text: 'Mia switched her thoughts to something else.',
          },
          '1': {
            correct: true,
            text: '',
          },
          '2': {
            correct: false,
            text: '',
          },
        },
        afterAll:
          'Mia took a look at her unhelpful thought and changed it. She became less strict and harsh on herself.',
      },
    },
    //Video 8c & quiz
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S8/Mia_S8C_How_Has_Switch_It_Helped_Reduce_SR_210518.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Her thinking is more realistic',
          '1': 'Her thoughts are happy',
          '2': 'She thinks more about the past',
        },
      },
      dir: {
        prompt: 'How does “switching” thoughts reduce Mia’s risk?',
        feedback: {
          '0': {
            correct: true,
            text: '',
          },
          '1': {
            correct: false,
            text: 'Mia’s thoughts aren’t always happy, which is ok.',
          },
          '2': {
            correct: false,
            text: 'Mia tries to keep her thinking open and about the future.',
          },
        },
        afterAll:
          'Thinking in a more realistic way has helped Mia break out of her negative emotion spirals. Correcting her thinking about causes of events, and how she sees herself, reduces Mia’s suicide risk.',
      },
    },
  ],
};

const SWITCH_IT_APPLY_VIGNETTE_MIA: SetData = {
  ID: 'switch-it-apply-vignette-mia',
  name: 'Mia',
  description: 'Mia: 27, female, college educated, abusive ex',
  items: [
    //Video 8d
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S8/Mia_S8D_How_Did_I_Start_210518.mp4',
        ),
        compulsory: true,
      },
    },
    //Video 8e
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S8/Mia_S8E_How_Did_I_Switch_Thought_210518.mp4',
        ),
        compulsory: true,
      },
    },
    //Video 8f
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S8/Mia_S8F_How_Was_Switch_It_Helpful_210518.mp4',
        ),
        compulsory: true,
      },
    },
  ],
};

//Session 9 - Coping Cards
//Text & quizzes here - https://docs.google.com/document/d/13MwRfuv8viFKUpiamn-VQScQy2RpLVRRqC8ByWcZvqs/edit?usp=sharing
//Videos stored here -

const COPING_CARDS_LEARN_VIGNETTE_MIA: SetData = {
  ID: 'coping-cards-learn-vignette-mia',
  name: 'Mia',
  description: 'Mia: 27, female, college educated, abusive ex',
  items: [
    //Video 9a
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S9/Mia_S9a_Whats_My_Exp_With_CC_210522.mp4',
        ),
        compulsory: true,
      },
    },
    //Video 9b & quiz
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S9/Mia_S9b_How_Have_CC_Helped_Reduce_Risk_210522.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'She can review many small details',
          '1': 'Reminds her of ways to cope with negative thoughts and tough situations',
          '2': 'She can write down all of her thoughts',
        },
      },
      dir: {
        prompt: 'How do Coping Cards help Mia?',
        feedback: {
          '0': {
            correct: false,
            text: '',
          },
          '1': {
            correct: true,
            text: '',
          },
          '2': {
            correct: false,
            text: 'Coping Cards aren’t the same as a diary or journal.',
          },
        },
        afterAll:
          'Mia uses Coping Cards to help deal with stressful situations and unhelpful thoughts. They help by providing immediate access to reminders of the specific skill she has learned to deal with the specific issue.',
      },
    },
  ],
};

const COPING_CARDS_APPLY_VIGNETTE_MIA: SetData = {
  ID: 'coping-cards-apply-vignette-mia',
  name: 'Mia',
  description: 'Mia: 27, female, college educated, abusive ex',
  items: [
    //Video 9c
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S9/Mia_S9c_How_Do_I_Start_210522.mp4',
        ),
        compulsory: true,
      },
    },
    //Video 9d
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Apply',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S9/Mia_S9d_Whats_Another_Example_210522.mp4',
        ),
        compulsory: true,
      },
    },
  ],
};

//Session 10 - Risk Curve & MyPlan Review
//Text & quizzes here - https://docs.google.com/document/d/1Oc9tFFeR7OEe4usNoDz0dPDvqM1uSaXO1ffkqVbt4GM/edit?usp=sharing
//Videos stored here -

const RISK_CURVE_MYPLAN_REVIEW_LEARN_VIGNETTE_MIA: SetData = {
  ID: 'risk-curve-myplan-review-learn-vignette-mia',
  name: 'Mia',
  description: 'Mia: 27, female, college educated, abusive ex',
  items: [
    //Video 10a
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S10/Mia_S10a_Whats_My_Experience_Reviewing_RC_210527.mp4',
        ),
        compulsory: true,
      },
    },
    //Video 10b & quiz
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S10/Mia_S10b_Whats_My_Experience_Reviewing_MP_210527.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Skills learned in Aviva were to reduce her suicide risk',
          '1': 'She should never change her MyPlan',
          '2': 'Warning signs never change',
        },
      },
      dir: {
        prompt: 'What did Mia realize when reviewing the Risk Curve and MyPlan?',
        feedback: {
          '0': {
            correct: true,
            text: '',
          },
          '1': {
            correct: false,
            text: '',
          },
          '2': {
            correct: false,
            text: '',
          },
        },
        afterAll:
          'Reviewing MyPlan and the Risk Curve helped Mia identify a new warning sign, and  see how skills she learned in Aviva directly helped her lower her suicide risk.',
      },
    },
  ],
};

//Session 11 - Reduce Risk Review
//Text & quizzes here - https://docs.google.com/document/d/1TDlBrrubAPOEYtwCO2Z5BwLHe5MHzoeETrbVoc1kd30/edit?usp=sharing
//Videos stored here -

const REDUCE_RISK_LEARN_VIGNETTE_MIA: SetData = {
  ID: 'reduce-risk-learn-vignette-mia',
  name: 'Mia',
  description: 'Mia: 27, female, college educated, abusive ex',
  previewOptions: {
    fitMode: 'smartcrop',
    height: 180,
  },
  items: [
    //Video 11a
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S11/Mia_S11a_Whats_My_Experience_Reviewing_Skills_210524.mp4',
        ),
        compulsory: true,
      },
    },
    //Video 11b & quiz
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S11/Mia_S11b_What_Was_Most_Helpful_210524.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Completely overwhelmed',
          '1': 'Proud and ready to use them',
          '2': 'Not sure how they will help',
        },
      },
      dir: {
        prompt: 'How does Mia feel after reviewing the skills?',
        feedback: {
          '0': {
            correct: false,
            text: '',
          },
          '1': {
            correct: true,
            text: '',
          },
          '2': {
            correct: false,
            text: '',
          },
        },
        afterAll:
          'Mia knows how she can use what she learned going forward. If she gets stuck, she can always go back and practice more. Her thoughts about the future have changed.',
      },
    },
  ],
};

//Session 12 - Post-Aviva
//Text & quizzes here - https://docs.google.com/document/d/1fDBsfLViEtfAPsbqmWDvnm7ZG1-YPnNBgXIYmxgX1zo/edit?usp=sharing
//Videos stored here -

const POSTAVIVA_LEARN_VIGNETTE_MIA: SetData = {
  ID: 'postaviva-learn-vignette-mia',
  name: 'Mia',
  description: 'Mia: 27, female, college educated, abusive ex',
  items: [
    //Video 12 & quiz
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        title: 'Learn',
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/vignette/adult/mia/S12/Mia_S12a_How_Do_I_Feel_210525.mp4',
        ),
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Talk to her doctor about her other issues',
          '1': 'Stopped using her sleep diary',
          '2': 'Stopped using Aviva',
        },
      },
      dir: {
        prompt: 'What does Mia plan to do after finishing Aviva?',
        feedback: {
          '0': {
            correct: true,
            text: '',
          },
          '1': {
            correct: false,
            text: '',
          },
          '2': {
            correct: false,
            text: '',
          },
        },
        afterAll:
          'Mia is going to keep using Aviva, and also talk to her doctor. She knows there’s more work to do but she knows there’s hope.',
      },
    },
  ],
};

export const ADULT_QUIZ_SET_DATA: Record<string, SetData[] | undefined> = {
  MYSTORY_LEARN_VIGNETTE: [
    MYSTORY_LEARN_VIGNETTE_JEFF,
    MYSTORY_LEARN_VIGNETTE_MIA,
    Frank.MYSTORY_LEARN_VIGNETTE_FRANK,
    Sue.MYSTORY_LEARN_VIGNETTE_SUE,
    Priyanka.MYSTORY_LEARN_VIGNETTE_PRIYANKA,
  ],
  RISK_CURVE_LEARN_VIGNETTE: [
    RISK_CURVE_LEARN_VIGNETTE_JEFF,
    RISK_CURVE_LEARN_VIGNETTE_MIA,
    Frank.RISK_CURVE_LEARN_VIGNETTE_FRANK,
    Sue.RISK_CURVE_LEARN_VIGNETTE_SUE,
    Priyanka.RISK_CURVE_LEARN_VIGNETTE_PRIYANKA,
  ],
  RISK_CURVE_LEARN_VIGNETTE_COLLAB: [
    RISK_CURVE_LEARN_VIGNETTE_JEFF_COLLAB,
    RISK_CURVE_LEARN_VIGNETTE_MIA_COLLAB,
    Frank.RISK_CURVE_LEARN_VIGNETTE_FRANK_COLLAB,
    Sue.RISK_CURVE_LEARN_VIGNETTE_SUE_COLLAB,
    Priyanka.RISK_CURVE_LEARN_VIGNETTE_PRIYANKA_COLLAB,
  ],
  MYPLAN_LEARN_VIGNETTE: [
    MYPLAN_LEARN_VIGNETTE_JEFF,
    MYPLAN_LEARN_VIGNETTE_MIA,
    Frank.MYPLAN_LEARN_VIGNETTE_FRANK,
    Sue.MYPLAN_LEARN_VIGNETTE_SUE,
    Priyanka.MYPLAN_LEARN_VIGNETTE_PRIYANKA,
  ],
  HOPE_KIT_LEARN_VIGNETTE: [
    HOPE_KIT_LEARN_VIGNETTE_JEFF,
    HOPE_KIT_LEARN_VIGNETTE_MIA,
    Frank.HOPE_KIT_LEARN_VIGNETTE_FRANK,
    Sue.HOPE_KIT_LEARN_VIGNETTE_SUE,
    Priyanka.HOPE_KIT_LEARN_VIGNETTE_PRIYANKA,
  ],
  HOPE_KIT_APPLY_VIGNETTE: [
    HOPE_KIT_APPLY_VIGNETTE_JEFF,
    HOPE_KIT_APPLY_VIGNETTE_MIA,
    Frank.HOPE_KIT_APPLY_VIGNETTE_FRANK,
    Sue.HOPE_KIT_APPLY_VIGNETTE_SUE,
    Priyanka.HOPE_KIT_APPLY_VIGNETTE_PRIYANKA,
  ],
  GETTING_GOING_LEARN_VIGNETTE: [
    GETTING_GOING_LEARN_VIGNETTE_JEFF,
    GETTING_GOING_LEARN_VIGNETTE_MIA,
    Frank.GETTING_GOING_LEARN_VIGNETTE_FRANK,
    Sue.GETTING_GOING_LEARN_VIGNETTE_SUE,
    Priyanka.GETTING_GOING_LEARN_VIGNETTE_PRIYANKA,
  ],
  GETTING_GOING_APPLY_VIGNETTE: [
    GETTING_GOING_APPLY_VIGNETTE_JEFF,
    GETTING_GOING_APPLY_VIGNETTE_MIA,
    Frank.GETTING_GOING_APPLY_VIGNETTE_FRANK,
    Sue.GETTING_GOING_APPLY_VIGNETTE_SUE,
    Priyanka.GETTING_GOING_APPLY_VIGNETTE_PRIYANKA,
  ],
  RELAXATION_MINDFULNESS_LEARN_VIGNETTE: [
    RELAXATION_MINDFULNESS_LEARN_VIGNETTE_JEFF,
    RELAXATION_MINDFULNESS_LEARN_VIGNETTE_MIA,
    Frank.RELAXATION_MINDFULNESS_LEARN_VIGNETTE_FRANK,
    Sue.RELAXATION_MINDFULNESS_LEARN_VIGNETTE_SUE,
    Priyanka.RELAXATION_MINDFULNESS_LEARN_VIGNETTE_PRIYANKA,
  ],
  RELAXATION_MINDFULNESS_APPLY_VIGNETTE: [
    RELAXATION_MINDFULNESS_APPLY_VIGNETTE_JEFF,
    RELAXATION_MINDFULNESS_APPLY_VIGNETTE_MIA,
    Frank.RELAXATION_MINDFULNESS_APPLY_VIGNETTE_FRANK,
    Sue.RELAXATION_MINDFULNESS_APPLY_VIGNETTE_SUE,
    Priyanka.RELAXATION_MINDFULNESS_APPLY_VIGNETTE_PRIYANKA,
  ],
  SLEEP_DIARY_LEARN_VIGNETTE: [
    SLEEP_DIARY_LEARN_VIGNETTE_JEFF,
    SLEEP_DIARY_LEARN_VIGNETTE_MIA,
    Frank.SLEEP_DIARY_LEARN_VIGNETTE_FRANK,
    Sue.SLEEP_DIARY_LEARN_VIGNETTE_SUE,
    Priyanka.SLEEP_DIARY_LEARN_VIGNETTE_PRIYANKA,
  ],
  SLEEP_DIARY_APPLY_VIGNETTE: [
    SLEEP_DIARY_APPLY_VIGNETTE_JEFF,
    SLEEP_DIARY_APPLY_VIGNETTE_MIA,
    Frank.SLEEP_DIARY_APPLY_VIGNETTE_FRANK,
    Sue.SLEEP_DIARY_APPLY_VIGNETTE_SUE,
    Priyanka.SLEEP_DIARY_APPLY_VIGNETTE_PRIYANKA,
  ],
  SPOT_IT_LEARN_VIGNETTE: [
    SPOT_IT_LEARN_VIGNETTE_JEFF,
    SPOT_IT_LEARN_VIGNETTE_MIA,
    Frank.SPOT_IT_LEARN_VIGNETTE_FRANK,
    Sue.SPOT_IT_LEARN_VIGNETTE_SUE,
    Priyanka.SPOT_IT_LEARN_VIGNETTE_PRIYANKA,
  ],
  SPOT_IT_APPLY_VIGNETTE: [
    SPOT_IT_APPLY_VIGNETTE_JEFF,
    SPOT_IT_APPLY_VIGNETTE_MIA,
    Frank.SPOT_IT_APPLY_VIGNETTE_FRANK,
    Sue.SPOT_IT_APPLY_VIGNETTE_SUE,
    Priyanka.SPOT_IT_APPLY_VIGNETTE_PRIYANKA,
  ],
  TEST_IT_LEARN_VIGNETTE: [
    TEST_IT_LEARN_VIGNETTE_JEFF,
    TEST_IT_LEARN_VIGNETTE_MIA,
    Frank.TEST_IT_LEARN_VIGNETTE_FRANK,
    Sue.TEST_IT_LEARN_VIGNETTE_SUE,
    Priyanka.TEST_IT_LEARN_VIGNETTE_PRIYANKA,
  ],
  TEST_IT_APPLY_VIGNETTE: [
    TEST_IT_APPLY_VIGNETTE_JEFF,
    TEST_IT_APPLY_VIGNETTE_MIA,
    Frank.TEST_IT_APPLY_VIGNETTE_FRANK,
    Sue.TEST_IT_APPLY_VIGNETTE_SUE,
    Priyanka.TEST_IT_APPLY_VIGNETTE_PRIYANKA,
  ],
  SWITCH_IT_LEARN_VIGNETTE: [
    SWITCH_IT_LEARN_VIGNETTE_JEFF,
    SWITCH_IT_LEARN_VIGNETTE_MIA,
    Frank.SWITCH_IT_LEARN_VIGNETTE_FRANK,
    Sue.SWITCH_IT_LEARN_VIGNETTE_SUE,
    Priyanka.SWITCH_IT_LEARN_VIGNETTE_PRIYANKA,
  ],
  SWITCH_IT_APPLY_VIGNETTE: [
    SWITCH_IT_APPLY_VIGNETTE_JEFF,
    SWITCH_IT_APPLY_VIGNETTE_MIA,
    Frank.SWITCH_IT_APPLY_VIGNETTE_FRANK,
    Sue.SWITCH_IT_APPLY_VIGNETTE_SUE,
    Priyanka.SWITCH_IT_APPLY_VIGNETTE_PRIYANKA,
  ],
  COPING_CARDS_LEARN_VIGNETTE: [
    COPING_CARDS_LEARN_VIGNETTE_JEFF,
    COPING_CARDS_LEARN_VIGNETTE_MIA,
    Frank.COPING_CARDS_LEARN_VIGNETTE_FRANK,
    Sue.COPING_CARDS_LEARN_VIGNETTE_SUE,
    Priyanka.COPING_CARDS_LEARN_VIGNETTE_PRIYANKA,
  ],
  COPING_CARDS_APPLY_VIGNETTE: [
    COPING_CARDS_APPLY_VIGNETTE_JEFF,
    COPING_CARDS_APPLY_VIGNETTE_MIA,
    Frank.COPING_CARDS_APPLY_VIGNETTE_FRANK,
    Sue.COPING_CARDS_APPLY_VIGNETTE_SUE,
    Priyanka.COPING_CARDS_APPLY_VIGNETTE_PRIYANKA,
  ],
  RISK_CURVE_MYPLAN_REVIEW_LEARN_VIGNETTE: [
    RISK_CURVE_MYPLAN_REVIEW_LEARN_VIGNETTE_JEFF,
    RISK_CURVE_MYPLAN_REVIEW_LEARN_VIGNETTE_MIA,
    Frank.RISK_CURVE_MYPLAN_REVIEW_LEARN_VIGNETTE_FRANK,
    Sue.RISK_CURVE_MYPLAN_REVIEW_LEARN_VIGNETTE_SUE,
    Priyanka.RISK_CURVE_MYPLAN_REVIEW_LEARN_VIGNETTE_PRIYANKA,
  ],
  REDUCE_RISK_LEARN_VIGNETTE: [
    REDUCE_RISK_LEARN_VIGNETTE_JEFF,
    REDUCE_RISK_LEARN_VIGNETTE_MIA,
    Frank.REDUCE_RISK_LEARN_VIGNETTE_FRANK,
    Sue.REDUCE_RISK_LEARN_VIGNETTE_SUE,
    Priyanka.REDUCE_RISK_LEARN_VIGNETTE_PRIYANKA,
  ],
  POSTAVIVA_LEARN_VIGNETTE: [
    POSTAVIVA_LEARN_VIGNETTE_JEFF,
    POSTAVIVA_LEARN_VIGNETTE_MIA,
    Frank.POSTAVIVA_LEARN_VIGNETTE_FRANK,
    Sue.POSTAVIVA_LEARN_VIGNETTE_SUE,
    Priyanka.POSTAVIVA_LEARN_VIGNETTE_PRIYANKA,
  ],
};
