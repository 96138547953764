import * as Sentry from '@sentry/core';
import * as MediaLibrary from 'expo-media-library';
import { useState } from 'react';
import { IntlShape } from 'react-intl';
import { StyleProp, ViewStyle } from 'react-native';
import ViewShot from 'react-native-view-shot';

import { Button } from '@oui/app-core/src/components/Button';
import { ErrorPresenter } from '@oui/app-core/src/components/ErrorPresenter';
import { Icon } from '@oui/app-core/src/components/Icon';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { APP_SLUG, CLOUD_FUNCTION_URL, manifest } from '@oui/app-core/src/constants';
import { useCurrentPatient } from '@oui/app-core/src/hooks/useCurrentUser';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';

async function exportMyPlan(
  $t: IntlShape['$t'],
  viewShot: ViewShot | null,
  method: 'email' | 'download',
  userEmail?: string,
) {
  if (viewShot) {
    const result = await viewShot.capture?.();
    if (!result) return '';
    if (method === 'download') {
      const { granted } = await MediaLibrary.requestPermissionsAsync(true);
      if (granted) {
        await MediaLibrary.saveToLibraryAsync(result);
        return $t({
          id: 'MyPlan_exportDownloadSuccess',
          defaultMessage: 'MyPlan downloaded to media library',
        });
      }
    } else {
      const body = new FormData();
      body.append('to', userEmail!);
      body.append('file', {
        uri: result,
        type: 'image/png',
        name: 'MyPlan.png',
      } as any); // eslint-disable-line @typescript-eslint/no-explicit-any
      await fetch(`${CLOUD_FUNCTION_URL}/sendMyPlan`, {
        method: 'POST',
        headers: {
          'X-Oui-Client': APP_SLUG!,
          'X-Oui-Client-Version': manifest.version ?? '0.0.0',
        },
        body,
      });
      return $t(
        { id: 'MyPlan_exportEmailSuccess', defaultMessage: 'MyPlan sent to {email}' },
        { email: userEmail },
      );
    }
  }
  return '';
}

export function MyPlanExport(props: {
  style?: StyleProp<ViewStyle>;
  getViewShot: () => ViewShot | null;
}) {
  const { theme } = useTheme();
  const [exportSuccess, setExportSuccess] = useState<string | false>('');
  const { user } = useCurrentPatient();
  const { $t } = useI18n();

  async function exportMyPlanWithState(method: 'email' | 'download') {
    try {
      setExportSuccess('');
      setExportSuccess(await exportMyPlan($t, props.getViewShot(), method, user?.person.email!));
    } catch (e) {
      Sentry.captureException(e);
      setExportSuccess(false);
    }
  }

  return user ? (
    <View style={[{ alignItems: 'center' }, props.style]} spacing={12}>
      <Text text={$t({ id: 'MyPlanExport_export', defaultMessage: 'Export MyPlan via' })} />
      <View row style={{ justifyContent: 'space-around', width: '80%' }}>
        <Button
          icon="email"
          text={$t({ id: 'MyPlanExport_exportEmailButton', defaultMessage: 'Email' })}
          variant="text"
          onPress={() => exportMyPlanWithState('email')}
        />
        <Button
          icon="download"
          text={$t({ id: 'MyPlanExport_exportDownloadButton', defaultMessage: 'Download' })}
          variant="text"
          onPress={() => exportMyPlanWithState('download')}
          testID="MyPlanExport_downloadMyPlanButton"
        />
      </View>
      {exportSuccess === false ? (
        <ErrorPresenter errorString="Something went wrong" />
      ) : exportSuccess ? (
        <View row spacing={8} testID="MyPlanExport_downloadSuccess">
          <Icon name="check" color={theme.color.success} />
          <Text text={exportSuccess} color={theme.color.success} weight="semibold" />
        </View>
      ) : null}
    </View>
  ) : null;
}
