import type { SetData } from '../../content/quizSet/quizSetData';
import { getMuxUrlFromGCSUrl } from '../../muxUrl';
import { ContentType } from '../../types/graphql.generated';

// NB: We removed what was originally session 1 and moved up other sessions by 1 position.
// That is why the video URLs dont match up exactly with session numbers. The S1/*_Revised
// videos came in after the renaming and were properly named by session number so that is
// why only those videos have the right session number in the url
const STATIC_SESSION_01: SetData = {
  ID: 'understanding suicide',
  name: 'Understanding Suicide',
  description: 'Learn more about suicide',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S1/Video_1_1_211203.mp4',
        ),
        title: 'Adults Over the Age of 45',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S1/Video_1_2_211203.mp4',
        ),
        title: 'American Indians',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S1/Video_1_3_211203.mp4',
        ),
        title: 'Alaskan Natives',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S1/Video_1_4_211203.mp4',
        ),
        title: 'Other Groups',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Teens living in Alaska and American Indians',
          '1': 'Teens who suffer from chronic illness',
          '2': 'Teens who have divorced parents',
        },
      },
      dir: {
        prompt: 'What are some of the groups of teens at a high risk of suicide?',
        feedback: {
          '0': {
            correct: true,
            text: 'Teens living in Alaska and American Indians are at a high risk.',
          },
          '1': {
            correct: false,
            text: 'Try another response.',
          },
          '2': {
            correct: false,
            text: 'Try another response.',
          },
        },
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S1/Video_1_5_211203.mp4',
        ),
        title: 'SSRIs',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S1/Video_1_6_211203.mp4',
        ),
        title: 'SNRIs',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Aspirin and Acetaminophen',
          '1': 'Caffeine and more caffeine',
          '2': 'SSRIs and SNRIs',
        },
      },
      dir: {
        prompt: 'What are the two most commonly prescribed types of antidepressants?',
        feedback: {
          '0': {
            correct: false,
            text: 'Try another response.',
          },
          '1': {
            correct: false,
            text: 'Try another response.',
          },
          '2': {
            correct: true,
            text: 'SSRIs and SNRIs are the most commonly prescribed antidepressants.',
          },
        },
      },
    },
  ],
};

const STATIC_SESSION_02: SetData = {
  ID: 'suicide statistics',
  name: 'Suicide Statistics',
  description: 'Knowing the stats helps set context',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S2/Video_2_1_220110.mp4',
        ),
        title: 'Suicide Stats by Age',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S2/Video_2_2_211206.mp4',
        ),
        title: 'Suicide Stats by Ethnicity',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S2/Video_2_3_211206.mp4',
        ),
        title: 'Suicide Stats by Method',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Drowning',
          '1': 'Choking',
          '2': 'Firearms',
        },
      },
      dir: {
        prompt: 'What is the most common means of death from suicide?',
        feedback: {
          '0': {
            correct: false,
            text: 'Try another response.',
          },
          '1': {
            correct: false,
            text: 'Try another response.',
          },
          '2': {
            correct: true,
            text: 'Firearms are the most deadly.',
          },
        },
      },
    },
  ],
};

const STATIC_SESSION_03: SetData = {
  ID: 'stress & suicide',
  name: 'Stress & Suicide',
  description: 'Stress is a risk factor of suicide',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S3/Video_3_1_211206.mp4',
        ),
        title: 'Examples of Stress',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S3/Video_3_2_211206.mp4',
        ),
        title: 'Stress and Genes',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Relaxing on the beach',
          '1': 'Failing a test',
          '2': 'Going to the movies',
        },
      },
      dir: {
        prompt: 'What are some common examples of stress?',
        feedback: {
          '0': {
            correct: false,
            text: 'Try another response.',
          },
          '1': {
            correct: true,
            text: 'Failing a test is a type of stress.',
          },
          '2': {
            correct: false,
            text: 'Try another response.',
          },
        },
      },
    },
  ],
};

const STATIC_SESSION_04: SetData = {
  ID: 'depression & suicide',
  name: 'Depression & Suicide',
  description: 'Depression is a risk factor of suicide',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S4/Video_4_1_211206.mp4',
        ),
        title: 'Types of Major Depression',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S4/Video_4_2_211206.mp4',
        ),
        title: 'Symptoms of Depression',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Anxiety',
          '1': 'Composition',
          '2': 'Persistent',
        },
      },
      dir: {
        prompt: 'Which of the following is a common form of depression?',
        feedback: {
          '0': {
            correct: false,
            text: 'Try another response.',
          },
          '1': {
            correct: false,
            text: 'Try another response.',
          },
          '2': {
            correct: true,
            text: 'Persistent depression is a form of depression that lasts for 2 or more years.',
          },
        },
      },
    },
  ],
};

const STATIC_SESSION_05: SetData = {
  ID: 'anxiety & suicide',
  name: 'Anxiety & Suicide',
  description: 'Anxiety is a risk factor of suicide',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S5/Video_5_1_211207.mp4',
        ),
        title: 'Types of Anxiety',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S5/Video_5_2_211207.mp4',
        ),
        title: 'Symptoms of Anxiety',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Panic',
          '1': 'Anger',
          '2': 'Melancholy',
        },
      },
      dir: {
        prompt: 'Which of the following is a common form of anxiety?',
        feedback: {
          '0': {
            correct: true,
            text: 'Panic is a common form of anxiety.',
          },
          '1': {
            correct: false,
            text: 'Try another response.',
          },
          '2': {
            correct: false,
            text: 'Try another response.',
          },
        },
      },
    },
  ],
};

const STATIC_SESSION_06: SetData = {
  ID: 'chronic illness & suicide',
  name: 'Chronic Illness & Suicide',
  description: 'Chronic illness is a risk factor of suicide',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S6/Video_6_1_211207.mp4',
        ),
        title: 'Types of Chronic Illness',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S6/Video_6_2_211207.mp4',
        ),
        title: 'Symptoms of Chronic Illness',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Blister',
          '1': 'Asthma',
          '2': 'Hangnail',
        },
      },
      dir: {
        prompt: 'Which of the following is a chronic illness related to suicide?',
        feedback: {
          '0': {
            correct: false,
            text: 'Try another response.',
          },
          '1': {
            correct: true,
            text: 'Asthma is a chronic illness related to suicide.',
          },
          '2': {
            correct: false,
            text: 'Try another response.',
          },
        },
      },
    },
  ],
};

const STATIC_SESSION_07: SetData = {
  ID: 'cancer & suicide',
  name: 'Cancer & Suicide',
  description: 'Cancer is a risk factor of suicide',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S7/Video_7_1_211207.mp4',
        ),
        title: 'Types of Cancer',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S7/Video_7_2_211207.mp4',
        ),
        title: 'Symptoms of Cancer',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Skin',
          '1': 'Breast',
          '2': 'Tooth',
        },
      },
      dir: {
        prompt: 'Which of the following is not a type of cancer?',
        feedback: {
          '0': {
            correct: false,
            text: 'Try another response.',
          },
          '1': {
            correct: false,
            text: 'Try another response.',
          },
          '2': {
            correct: true,
            text: 'There is no such thing as tooth cancer.',
          },
        },
      },
    },
  ],
};

const STATIC_SESSION_08: SetData = {
  ID: 'schizophrenia & suicide',
  name: 'Schizophrenia & Suicide',
  description: 'Schizophrenia is a risk factor of suicide',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S8/Video_8_1_211207.mp4',
        ),
        title: 'Delusions',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S8/Video_8_2_211207.mp4',
        ),
        title: 'Negative Symptoms of Schizophrenia',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Being shy',
          '1': 'Being bald',
          '2': 'Being intelligent',
        },
      },
      dir: {
        prompt: 'Which of the following is a risk factor of suicide for people with schizophrenia?',
        feedback: {
          '0': {
            correct: false,
            text: 'Try another response.',
          },
          '1': {
            correct: false,
            text: 'Try another response.',
          },
          '2': {
            correct: true,
            text: 'Being intelligent can be a risk factor of suicide for people with schizophrenia.',
          },
        },
      },
    },
  ],
};

const STATIC_SESSION_09: SetData = {
  ID: 'addiction & suicide',
  name: 'Addiction & Suicide',
  description: 'Addiction is a risk factor of suicide',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S9/Video_9_1_211207.mp4',
        ),
        title: 'Types of Addiction',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S9/Video_9_2_211207.mp4',
        ),
        title: 'Symptoms of Addiction',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Intense cravings',
          '1': 'Foot tapping',
          '2': 'Locking doors',
        },
      },
      dir: {
        prompt: 'Which of the following is a symptom of addiction?',
        feedback: {
          '0': {
            correct: true,
            text: 'Intense cravings can be a symptom of addiction.',
          },
          '1': {
            correct: false,
            text: 'Try another response.',
          },
          '2': {
            correct: false,
            text: 'Try another response.',
          },
        },
      },
    },
  ],
};

const STATIC_SESSION_10: SetData = {
  ID: 'common warning signs',
  name: 'Common Warning Signs',
  description: 'Go over common warning signs again',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S10/Video_10_1_211207.mp4',
        ),
        title: 'SSRIs',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S10/Video_10_2_211207.mp4',
        ),
        title: 'SNRIs',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Aspirin and Acetaminophen',
          '1': 'Caffeine and more caffeine',
          '2': 'Benzodiazepines and antidepressants',
        },
      },
      dir: {
        prompt: 'Which of the following is a risk factor for suicide?',
        feedback: {
          '0': {
            correct: false,
            text: 'Try another response.',
          },
          '1': {
            correct: false,
            text: 'Try another response.',
          },
          '2': {
            correct: true,
            text: 'Benzodiazepines and antidepressants are the most commonly prescribed medications.',
          },
        },
      },
    },
  ],
};

const STATIC_SESSION_11: SetData = {
  ID: 'common risk factors',
  name: 'Common Risk Factors',
  description: 'Review common risk factors',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S11/Video_11_1_211207.mp4',
        ),
        title: 'Common Risk Factors',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Easy access to lethal means',
          '1': 'Working hard',
          '2': 'Losing a night’s sleep',
        },
      },
      dir: {
        prompt: 'Which of the following is a risk factor for suicide?',
        feedback: {
          '0': {
            correct: true,
            text: 'Easy access to lethal means is a risk factor for suicide.',
          },
          '1': {
            correct: false,
            text: 'Try another response.',
          },
          '2': {
            correct: false,
            text: 'Try another response.',
          },
        },
      },
    },
  ],
};

const STATIC_SESSION_12: SetData = {
  ID: 'suicide survival stats',
  name: 'Suicide Survival Stats',
  description: 'Statistics about suicide survival',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S12/Video_12_1_211207.mp4',
        ),
        title: 'Suicide Stats by Age',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemSingleChoiceQuestion',
      props: {
        label: {
          '0': 'Lesbian, gay, or bisexual teens',
          '1': 'Atheists',
          '2': 'People who live in cities',
        },
      },
      dir: {
        prompt: 'What are some of the groups of people at a higher risk of suicide?',
        feedback: {
          '0': {
            correct: true,
            text: 'Lesbian, gay, or bisexual teens are at a higher risk.',
          },
          '1': {
            correct: false,
            text: 'Try another response.',
          },
          '2': {
            correct: false,
            text: 'Try another response.',
          },
        },
      },
    },
  ],
};

export const STATIC_QUIZ_SET_DATA: Record<string, SetData[] | undefined> = {
  [ContentType.STATIC_01]: [STATIC_SESSION_01],
  [ContentType.STATIC_02]: [STATIC_SESSION_02],
  [ContentType.STATIC_03]: [STATIC_SESSION_03],
  [ContentType.STATIC_04]: [STATIC_SESSION_04],
  [ContentType.STATIC_05]: [STATIC_SESSION_05],
  [ContentType.STATIC_06]: [STATIC_SESSION_06],
  [ContentType.STATIC_07]: [STATIC_SESSION_07],
  [ContentType.STATIC_08]: [STATIC_SESSION_08],
  [ContentType.STATIC_09]: [STATIC_SESSION_09],
  [ContentType.STATIC_10]: [STATIC_SESSION_10],
  [ContentType.STATIC_11]: [STATIC_SESSION_11],
  [ContentType.STATIC_12]: [STATIC_SESSION_12],
};
