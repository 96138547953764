/**
 * Returns the first day of the week using an Intl compatible numbering system.
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale/getWeekInfo#firstday
 * MON = 1
 * SUN = 7
 *
 * See compatibility table implementation notes for reasoning around different property accesses.
 */
export function getIntlFirstWeekday(localeString: string): 7 | 1 | 2 | 3 | 4 | 5 | 6 {
  if (!global.Intl?.Locale) return 1;
  const locale = new Intl.Locale(localeString);

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale/getWeekInfo
  // @ts-expect-error
  const weekInfo = locale.getWeekInfo?.() || locale.weekInfo;

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale/getWeekInfo#firstday
  // 1 = MON, 7 = SUN
  return (weekInfo?.firstDay as 7 | 1 | 2 | 3 | 4 | 5 | 6) ?? 7;
}

/**
 * Returns the first day of the week with a value compatible with Date.getDay
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getDay
 * SUN = 0
 * MON = 1
 */
export function getIntlFirstWeekdayAsDateDay(localeString: string): 0 | 1 | 2 | 3 | 4 | 5 | 6 {
  const firstWeekday = getIntlFirstWeekday(localeString);
  return ([null!, 1, 2, 3, 4, 5, 6, 0] as const)[firstWeekday];
}
