import { ContentType } from '../../types/graphql.generated';

export const STATIC_REVIEW_CARDS: Partial<
  Record<ContentType, Array<{ frontChildren: string; backChildren: string }>>
> = {
  [ContentType.STATIC_01]: [
    {
      frontChildren: `What are some risk factors of suicide?`,
      backChildren: `* Previous suicide attempt
* Family history of suicide
* Medical and/or mental illness
* Family violence or sexual abuse
* Having guns at home
`,
    },
    {
      frontChildren: `Why do some people become suicidal but other people do not?`,
      backChildren: `People who try to kill themselves react differently to triggering events.`,
    },
  ],
  [ContentType.STATIC_02]: [
    {
      frontChildren: `True or False: Suicide is not very common.`,
      backChildren: `False. Suicides rank as the 10th leading cause of death in the US.`,
    },
    {
      frontChildren: `Where do most suicides occur in the US?`,
      backChildren: `About three-quarters of suicide incidents occur at home.`,
    },
  ],
  [ContentType.STATIC_03]: [
    {
      frontChildren: `What is stress?`,
      backChildren: `Stress is the physical or mental response to an external cause.`,
    },
    {
      frontChildren: `What are some signs of stress?`,
      backChildren: `Stress can affect your mind and body. You may experience symptoms such as:
* Excessive worry
* Uneasiness
* Tension
* Headaches or body pain
* Loss of sleep
`,
    },
    {
      frontChildren: `What happens to your body during stress?`,
      backChildren: `Your body is on full alert and is ready to fight to survive, or run away to safety.`,
    },
    {
      frontChildren: `How is stress related to suicide?`,
      backChildren: `Stress is the most common cause of suicide.`,
    },
  ],
  [ContentType.STATIC_04]: [
    {
      frontChildren: `What are signs of depression?`,
      backChildren: `Depression symptoms can vary from mild to severe and can include:
* Feeling sad or having a depressed mood
* Loss of interest or pleasure in activities
* Increase or decrease in appetite
* Increase or decrease in sleep
* Loss of energy or increased fatigue
`,
    },
    {
      frontChildren: `How widespread is depression?`,
      backChildren: `Depression affects about 1 in 15 adults in any given year.`,
    },
    {
      frontChildren: `What is the relationships between depression and suicide.`,
      backChildren: `There is a strong link between suicide and depression.`,
    },
  ],
  [ContentType.STATIC_05]: [
    {
      frontChildren: `What are signs of anxiety?`,
      backChildren: `Below are some common signs of anxiety:
* Trouble relaxing
* Hard time concentrating
* Trouble falling asleep or staying asleep
* Easily tired or tired all the time
* Headaches, muscle aches, stomach aches, or unexplained pains
`,
    },
    {
      frontChildren: `How widespread is Generalized Anxiety Disorder (GAD)?`,
      backChildren: `GAD affects 3.1% of the US population in any given year.`,
    },
    {
      frontChildren: `Why is it hard to link anxiety and suicide?`,
      backChildren: `Anxiety disorders often co-occur with depression.`,
    },
  ],
  [ContentType.STATIC_06]: [
    {
      frontChildren: `What are chronic illnesses?`,
      backChildren: `Chronic illnesses are medical conditions that last 1 or more years.`,
    },
    {
      frontChildren: `How is chronic illness related to suicide?`,
      backChildren: `People with chronic health problems seem to have a higher risk of suicide.`,
    },
    {
      frontChildren: `True or False:
      Young adults with chronic illnesses are 4 times likely to attempt suicide as their healthy peers.`,
      backChildren: `True`,
    },
  ],
  [ContentType.STATIC_07]: [
    {
      frontChildren: `How common is cancer?`,
      backChildren: `Cancer is the second leading cause of death in the US.`,
    },
    {
      frontChildren: `What are the most common cancer specific risk factors for suicide?`,
      backChildren: `* Time with cancer is less than a year
* Advanced or terminal cancer
* Loss of physical ability
* Loss of meaning, dignity or status
* Feeling like a burden
`,
    },
    {
      frontChildren: `What is the link between cancer and suicide?`,
      backChildren: `Suicide rates among cancer patients are twice as high as the general population.`,
    },
    {
      frontChildren: `What is the desire for hastened death?`,
      backChildren: `Longing for death to occur more rapidly than it otherwise would.`,
    },
  ],
  [ContentType.STATIC_08]: [
    {
      frontChildren: `What is schizophrenia?`,
      backChildren: `A serious condition in which people interpret reality abnormally.`,
    },
    {
      frontChildren: `What is the link between schizophrenia and suicide?`,
      backChildren: `About 10% of people with schizophrenia die by suicide.`,
    },
    {
      frontChildren: `When are people with schizophrenia most at risk for death by suicide?`,
      backChildren: `During the first 10 years of living with the condition of schizophrenia. `,
    },
  ],
  [ContentType.STATIC_09]: [
    {
      frontChildren: `What is addiction?`,
      backChildren: `Not having control over doing, taking, or using something that could be harmful to you.`,
    },
    {
      frontChildren: `How do the enjoyable feelings caused by substances impact your use?`,
      backChildren: `These feelings can make you want to keep using them.`,
    },
    {
      frontChildren: `What is the link between depression, addiction and suicide?`,
      backChildren: `Most people who die by suicide suffer from depression, addiction, or both.`,
    },
  ],
  [ContentType.STATIC_10]: [
    {
      frontChildren: `What are the two most commonly prescribed types of medications for suicidal patients?`,
      backChildren: `Benzodiazepines and antidepressants`,
    },
  ],
  [ContentType.STATIC_11]: [
    {
      frontChildren: `Who is at risk of suicide?`,
      backChildren: `Below is a list of some risk factors for suicide:
* Previous suicide attempt
* Depression and other mental health issues
* Family history of suicide
* Family violence or sexual abuse
* Having guns at home
`,
    },
    {
      frontChildren: `What are some relationship risk factors for suicide?`,
      backChildren: `* Child abuse
* Neglect
* Bullying
* Family history of suicide
* Relationship problems
`,
    },
    {
      frontChildren: `What are some community risk factors for suicide?`,
      backChildren: `* Barriers to health care
* Cultural and religious beliefs
* Suicide cluster in the community
`,
    },
    {
      frontChildren: `What are some societal risk factors for suicide?`,
      backChildren: `* Stigma of mental illness or help-seeking
* Easy access to lethal means
* Unsafe media portrayals of suicide
`,
    },
  ],
  [ContentType.STATIC_12]: [
    {
      frontChildren: `Fill in the blank:
      Suicide is the number ____ leading cause of death in the US.`,
      backChildren: `10`,
    },
    {
      frontChildren: `In which regions of the US are suicide rates the highest?`,
      backChildren: `Suicide rates are highest in rural areas.`,
    },
    {
      frontChildren: `True or False:
      Middle aged and older adults are at a high risk of suicide.`,
      backChildren: `True`,
    },
  ],
};
