import { JsonArray, JsonObject, JsonValue } from 'type-fest';

import { CompositionScalars } from '../compositionScalar';

export * from '../compositionScalar';

export type GQLAny = object | string | boolean | number | null;
export type GQLProgramID = string;
export type GQLSiteID = string;
export type GQLTreatmentID = string;
export type GQLTime = string & { _TimeOfDay: true };
export type GQLTimeOfDay = GQLTime;
export type GQLDate = string & { _DateOnly: true };
export type GQLDateOnly = GQLDate;
export type GQLArtifactID = string & { _ArtifactID: true };
export type GQLMap = { [key: string]: any }; // eslint-disable-line
export type GQLUpload = File;
export type GQLUserID = string;
export type GQLAssetID = string;
export type GQLGCSAsset = { assetID: string; signedURL: string };

export type GQLJSONValue = JsonValue;
export type GQLJSONArray = JsonArray[];
export type GQLJSONObject = JsonObject;

// map types are not representable in GQL so we share the type here and leverage codegen to keep
// type safety
export type GQLQuizSetItemQuestionLabel = { [key: string]: string };
export type GQLQuizSetItemQuestionDirectiveFeedback = {
  [key: string]: { correct: boolean; text: string };
};

// These are unused by the server but exist in the schema
export type GQLInt64 = number;
export type GQLDuration = unknown;

export type GQLUUID = string;
export type GQLDateTime = string & { _DateTime: true };

// We have the first condition because our opaque types do extend {} but shouldn't recurse
export type DeepWriteable<T> = T extends string
  ? T
  : T extends {}
    ? { -readonly [P in keyof T]: DeepWriteable<T[P]> }
    : T;

export type GraphQLTadaScalars = {
  Any: GQLAny;
  AssetID: GQLAssetID;
  Date: GQLDate;
  DateTime: GQLDateTime;
  ID: GQLUUID;
  Int: GQLInt64;
  JSONObject: GQLJSONObject;
  Map: GQLMap;
  UUID: string;
  UserID: GQLUserID;
} & CompositionScalars;
