import { FragmentOf, graphql } from '@oui/lib/src/graphql/tada';

export const IsEnvironmentSafetyLockedFragment = graphql(`
  fragment IsEnvironmentSafetyLocked on Role @_unmask {
    sessions(contentType: "ENVIRONMENT_SAFETY") {
      __typename
    }
    progress {
      content
      completion
      updatedAt
    }
  }
`);

export function useIsEnvironmentSafetyLocked(
  fragment: FragmentOf<typeof IsEnvironmentSafetyLockedFragment> | null | undefined,
) {
  const session = fragment?.sessions[0];

  // If a session exists, then there is explicit unlocking of environment safety
  // Only if a progress exists with non-zero complet
  if (session) {
    const progress = fragment?.progress.find((p) => p.content === 'ENVIRONMENT_SAFETY');
    return (progress?.completion ?? 0) === 0;
  }

  return false;
}
