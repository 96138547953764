import { z } from 'zod';

import { Contact, Place } from './common';

export const ProfessionalHelpContact = Contact;
export const ProfessionalHelpPlace = Place;
export const ProfessionalHelpContactAndPlace = z.object({
  contact: ProfessionalHelpContact,
  location: ProfessionalHelpPlace,
});

export const ProfessionalHelpContacts = z.array(ProfessionalHelpContactAndPlace).default([]);

export type ProfessionalHelpContacts = z.infer<typeof ProfessionalHelpContacts>;
export type ProfessionalHelpContact = z.infer<typeof ProfessionalHelpContact>;
export type ProfessionalHelpPlace = z.infer<typeof ProfessionalHelpPlace>;
export type ProfessionalHelpContactAndPlace = z.infer<typeof ProfessionalHelpContactAndPlace>;
